import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';

import AddIcon from '@material-ui/icons/Add';

import { GetGiftCardCategoryAdmin, GiftCardProduct, GiftCardCategory } from '../../types';

import TableComponent, { HeadCell, TableResultRow } from '../common/table-component';

import useAdminStyles from '../styles';
import storeContext from '../../contexts/store-context';
import Switch from '@material-ui/core/Switch';

import moment from 'moment';
import { joinNameParts, scrollTop } from '../../utilities';
import ConfirmationDialog from '../common/confirmation-dialog';
import HasPermission from '../../utilities/can';
import AddEditForm from './form';
import StatusButton from '../common/status-button';
import { useUserService } from '../../contexts/user-context';
import { EGifterProduct } from '../../types/eGifter';
import { useApiService } from '../../contexts/api-service-context';
import { useToasterData } from '../../contexts/toaster-context';
import { GifterCardTypeEnum, SavedProducts } from '../../types/savedProducts';
import CategoryFilter, { useCategoryFilter } from '../common/category-filter';

export type ProductsNotInEGifterType = { name: string; categories: string[] };

type ProductsNotInEGifterProps = {
    productsNotInEGifter: ProductsNotInEGifterType[];
};
export const ProductsNotInEGifter = (props: ProductsNotInEGifterProps) => {
    const { productsNotInEGifter } = props;

    return (
        <Box my={3}>
            <Card>
                <Box p={2}>
                    <Typography variant="h3" align="left" gutterBottom>
                        Products not saved to DB:
                    </Typography>
                    <List>
                        {productsNotInEGifter.map((product) => (
                            <div key={product.name}>
                                <ListItem>
                                    <Box display="flex" alignItems="center">
                                        {product.name}
                                        <Box display="flex" flexWrap="wrap">
                                            {product.categories.map((c, i) => (
                                                <Box ml={1} mb={1} key={c}>
                                                    <Chip
                                                        size="small"
                                                        label={c}
                                                        color={i % 2 === 0 ? 'primary' : 'secondary'}
                                                    />
                                                </Box>
                                            ))}
                                        </Box>
                                    </Box>
                                </ListItem>
                                <Divider />
                            </div>
                        ))}
                    </List>
                </Box>
            </Card>
        </Box>
    );
};

type State = {
    isLoading: boolean;
    isDeleting: boolean;
    isSaving: boolean;
    renderAddEditForm: boolean;
    menuAnchorEle: HTMLElement | null;
    activeCategoryId: number | null;
    categories: GetGiftCardCategoryAdmin[];
    products: GiftCardProduct[];
    selectedRows: TableResultRow[];
    isConfirmationDialogOpen: boolean;
    productsNotInEGifter: ProductsNotInEGifterType[];
    productsSavedInDB: SavedProducts[];
};

const MlCategory = () => {
    const adminClasses = useAdminStyles();
    const history = useHistory();
    const toasterContext = useToasterData();
    const userService = useUserService();
    const apiService = useApiService();

    const { mlCategoryAction, giftCardAction, appAction } = storeContext();

    const { giftCardType, countryCode, onCountryCodeChange, onGiftCardTypeChange } = useCategoryFilter();

    const canReadCategory = HasPermission(userService.user, 'ML_CATEGORY', 'READ');
    const canCreateCategory = HasPermission(userService.user, 'ML_CATEGORY', 'CREATE');
    const canUpdateCategory = HasPermission(userService.user, 'ML_CATEGORY', 'UPDATE');

    useEffect(() => {
        if (!canReadCategory) {
            history.push('/dashboard');
            return;
        }
        appAction()?.renderFullHeader();
    }, []);

    useEffect(() => {
        closeForm();
        getAllCategories(giftCardType, countryCode);
    }, [giftCardType, countryCode]);

    const [state, setState] = useState<State>({
        isLoading: false,
        isDeleting: false,
        isSaving: false,
        renderAddEditForm: false,
        menuAnchorEle: null,
        activeCategoryId: null,
        categories: [],
        products: [],
        selectedRows: [],
        isConfirmationDialogOpen: false,
        productsNotInEGifter: [],
        productsSavedInDB: []
    });

    const activeCategory = state.categories.find((p) => p.id === state.activeCategoryId);

    const getAllCategories = async (giftCardType: typeof GifterCardTypeEnum[number], countryCode: string) => {
        setState((prevState) => ({ ...prevState, isLoading: true }));

        const [categories, products, apiResponse] = await Promise.all([
            mlCategoryAction()?.getAllCategories(giftCardType, countryCode),
            giftCardType === 'B2C'
                ? giftCardAction()?.getAllGiftCards()
                : giftCardAction()?.getAllB2BGiftCards({ cultureCode: `en-${countryCode}` }),
            apiService.get(`/api/admin/manage-product/get-all`)
        ]);

        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return;
        }

        const productsSavedInDB = response.data as SavedProducts[];
        const productsNotInEGifter: Record<string, ProductsNotInEGifterType> = {};

        for (const category of categories || []) {
            for (const productId of category.productIds) {
                const productFoundInEGifter = products.find((p: EGifterProduct) => p.id === productId);
                const productFoundInDB = productsSavedInDB.find((p) => p.productId === productId);

                if (!productFoundInEGifter) {
                    if (productsNotInEGifter[productId]) {
                        productsNotInEGifter[productId].categories.push(category.name);
                    } else {
                        productsNotInEGifter[productId] = {
                            name: productFoundInDB?.name || productId,
                            categories: [category.name]
                        };
                    }
                }
            }
        }

        setState((prevState) => ({
            ...prevState,
            categories: categories || [],
            products: products || [],
            isLoading: false,
            productsNotInEGifter: Object.values(productsNotInEGifter),
            productsSavedInDB
        }));
    };

    const onIsActiveChange = async (isActive: boolean, id: number) => {
        const activeCategory = state.categories.find((p) => p.id === id);
        if (!activeCategory) {
            return;
        }
        const savedPromoCode = await mlCategoryAction()?.saveCategory({
            ...activeCategory,
            isActive,
            giftCardType,
            countryCode
        });
        setState((prevState) => {
            const newCategories = prevState.categories.map((p) => {
                if (p.id === id) {
                    return { ...p, ...savedPromoCode };
                }
                return p;
            });
            return { ...prevState, categories: newCategories };
        });
    };

    const headCells: Array<HeadCell> = [
        { id: 'name', label: 'Name' },
        { id: 'isActive', label: 'Is Active' },
        { id: 'options', label: '' }
    ];

    const tableRows: Array<TableResultRow> = React.useMemo(
        () =>
            state.categories.map((obj) => {
                return {
                    id: {
                        text: obj.id?.toString() || ''
                    },
                    name: {
                        align: 'left',
                        text: obj.name || '-'
                    },
                    isActive: {
                        align: 'left',
                        text: '',
                        element: canUpdateCategory ? (
                            <Switch
                                checked={obj.isActive}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onIsActiveChange(!obj.isActive, obj.id);
                                }}
                                color="primary"
                            />
                        ) : (
                            <StatusButton
                                options={[
                                    { type: 'ACTIVE', text: 'Active', color: 'active' },
                                    { type: 'INACTIVE', text: 'Inactive', color: 'warning' }
                                ]}
                                type={obj.isActive ? 'ACTIVE' : 'INACTIVE'}
                            />
                        )
                    },
                    options: {
                        align: 'right',
                        text: '',
                        helperText:
                            (obj.user &&
                                `updated by - ${obj.user?.firstName ? joinNameParts(obj.user) : obj.user?.email || ''}${
                                    obj?.updatedAt && ' - '
                                }${obj?.updatedAt && moment(new Date(obj?.updatedAt)).fromNow()}`) ||
                            undefined,
                        element:
                            (canUpdateCategory && (
                                <IconButton
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setState((prevState) => ({
                                            ...prevState,
                                            menuAnchorEle: e.currentTarget,
                                            activeCategoryId: obj.id
                                        }));
                                    }}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                            )) ||
                            undefined
                    }
                };
            }),
        [state.categories]
    );

    const headerComponent = (
        <Grid item={true} xs={12} className={adminClasses.headerComponent}>
            <Button
                onClick={() =>
                    setState((prevState) => ({ ...prevState, renderAddEditForm: true, activeCategoryId: null }))
                }
                size="small"
                color="primary"
                variant="contained"
            >
                <AddIcon />
                &nbsp;Create New ML Category
            </Button>
        </Grid>
    );

    const handleMenuClose = () => {
        setState((prevState) => ({
            ...prevState,
            menuAnchorEle: null,
            activeCategoryId: null
        }));
    };

    const saveCategory = async (category: GiftCardCategory, keepRenderAddEditFormOpen?: boolean) => {
        setState((prevState) => ({ ...prevState, isSaving: true }));
        const savedCategory = await mlCategoryAction()?.saveCategory({
            ...category,
            countryCode,
            giftCardType
        });
        if (!savedCategory) {
            setState((prevState) => ({ ...prevState, isSaving: false }));
            return;
        }
        setState((prevState) => {
            let newCategories: GetGiftCardCategoryAdmin[] = [];

            if (category.id) {
                // update
                newCategories = prevState.categories.map((p) => {
                    if (p.id === savedCategory.id) {
                        return {
                            ...p,
                            ...savedCategory
                        };
                    }
                    return p;
                });
            } else {
                // add
                if (savedCategory) {
                    newCategories = [...prevState.categories, savedCategory];
                }
            }

            return {
                ...prevState,
                isSaving: false,
                categories: newCategories,
                renderAddEditForm: keepRenderAddEditFormOpen || !savedCategory
            };
        });
        getAllCategories(giftCardType, countryCode);
    };

    const openFormForEdit = (id: number) => {
        if (!canUpdateCategory) {
            return;
        }

        setState((prevState) => ({
            ...prevState,
            renderAddEditForm: true,
            activeCategoryId: id,
            menuAnchorEle: null
        }));
        setTimeout(() => scrollTop());
    };

    const onDeleteConfirm = async () => {
        const ids: number[] = [];

        if (activeCategory?.id) {
            ids.push(activeCategory.id);
        } else if (state.selectedRows) {
            const idsToDelete = state.selectedRows.map((row) => +row.id.text);
            Array.prototype.push.apply(ids, idsToDelete);
        }

        setState((prevState) => ({ ...prevState, isDeleting: true }));
        const CategoriesToDelete = state.categories
            .filter((p) => ids.includes(p.id))
            .map((p) => ({ ...p, countryCode, giftCardType, isDeleted: true }));
        await mlCategoryAction()?.updateAllCategories(CategoriesToDelete);
        setState((prevState) => {
            const newCategories = prevState.categories.filter((p) => !ids.includes(p.id));
            return {
                ...prevState,
                categories: newCategories,
                isDeleting: false,
                isConfirmationDialogOpen: false,
                menuAnchorEle: null
            };
        });
        getAllCategories(giftCardType, countryCode);
    };

    const closeForm = () => setState((prevState) => ({ ...prevState, renderAddEditForm: false }));

    return (
        <Grid item={true} xs={12} className={adminClasses.root}>
            <Container className={adminClasses.container}>
                <CategoryFilter
                    countryCode={countryCode}
                    giftCardType={giftCardType}
                    onCountryCodeChange={onCountryCodeChange}
                    onGiftCardTypeChange={onGiftCardTypeChange}
                />

                {state.renderAddEditForm && (
                    <AddEditForm
                        isSaving={state.isSaving}
                        products={state.products}
                        productsSavedInDB={state.productsSavedInDB}
                        category={activeCategory}
                        onCancel={closeForm}
                        onSave={saveCategory}
                    />
                )}

                <Menu anchorEl={state.menuAnchorEle} open={!!state.menuAnchorEle} onClose={handleMenuClose}>
                    <MenuItem onClick={() => state.activeCategoryId && openFormForEdit(state.activeCategoryId)}>
                        Edit
                    </MenuItem>
                    <MenuItem
                        onClick={() => setState((prevState) => ({ ...prevState, isConfirmationDialogOpen: true }))}
                    >
                        Delete
                    </MenuItem>
                </Menu>

                <TableComponent
                    headerComponent={(!state.renderAddEditForm && canCreateCategory && headerComponent) || undefined}
                    showPaginator={{ bottom: true }}
                    showCheckbox={true}
                    showSearch={true}
                    isLoading={state.isLoading}
                    rows={tableRows}
                    headCells={headCells}
                    selectedRows={state.selectedRows}
                    onDelete={() => setState((prevState) => ({ ...prevState, isConfirmationDialogOpen: true }))}
                    onCheckboxSelect={(selectedRows) => setState((prevState) => ({ ...prevState, selectedRows }))}
                    keyField="id"
                    rowTooltip={(canUpdateCategory && 'Click to edit') || undefined}
                    onRowClick={(id) => openFormForEdit(+id)}
                    fillEmptyRows={false}
                />

                {state.productsNotInEGifter?.length ? (
                    <ProductsNotInEGifter productsNotInEGifter={state.productsNotInEGifter} />
                ) : null}
            </Container>
            <ConfirmationDialog
                header={'Are you sure?'}
                subHeader={'Click CONFIRM to delete the category'}
                isLoading={state.isDeleting}
                open={state.isConfirmationDialogOpen}
                onClose={() =>
                    setState({
                        ...state,
                        isConfirmationDialogOpen: false,
                        activeCategoryId: null,
                        menuAnchorEle: null
                    })
                }
                onConfirm={onDeleteConfirm}
            />
        </Grid>
    );
};

export default MlCategory;
