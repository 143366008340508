import React from 'react';

const ApplePaySvg = (props) => {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="1016.000000pt"
            height="571.000000pt"
            viewBox="0 0 1016.000000 571.000000"
            preserveAspectRatio="xMidYMid meet"
            {...props}
        >
            <g transform="translate(0.000000,571.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                <path
                    d="M2045 4844 c-139 -34 -294 -133 -388 -249 -109 -135 -195 -391 -161
-479 14 -36 169 -11 281 46 222 111 383 372 383 620 l0 78 -27 -1 c-16 0 -55
-7 -88 -15z"
                />
                <path
                    d="M3690 3126 l0 -1506 240 0 240 0 0 515 0 515 368 0 c398 0 488 8 629
55 240 81 432 244 540 460 39 78 78 200 93 297 18 113 8 361 -19 462 -49 182
-121 307 -251 436 -120 119 -249 191 -435 242 -78 22 -96 22 -742 26 l-663 3
0 -1505z m1219 1080 c163 -42 292 -140 354 -269 49 -100 62 -164 62 -297 0
-181 -41 -302 -139 -407 -67 -73 -145 -119 -249 -152 -79 -24 -93 -25 -424
-29 l-343 -4 0 592 0 592 338 -5 c273 -3 349 -7 401 -21z"
                />
                <path
                    d="M1935 4051 c-38 -10 -133 -43 -210 -71 -185 -70 -206 -70 -388 -1
-174 65 -241 81 -342 81 -204 0 -378 -75 -536 -230 -169 -167 -258 -382 -275
-670 -20 -323 97 -752 290 -1069 118 -193 250 -352 349 -420 133 -91 250 -93
455 -9 126 51 199 68 296 68 92 0 158 -16 295 -71 172 -69 287 -72 410 -13
163 80 428 452 534 751 l26 71 -64 38 c-182 106 -302 259 -350 449 -24 94 -25
258 -2 345 40 147 138 293 262 391 36 28 65 55 65 59 0 18 -84 113 -138 156
-68 54 -194 118 -277 140 -95 26 -313 29 -400 5z"
                />
                <path
                    d="M6760 3829 c-300 -37 -546 -186 -660 -400 -32 -60 -70 -191 -70 -241
l0 -28 215 0 214 0 10 38 c32 119 159 221 311 253 70 15 238 6 307 -15 67 -21
140 -71 180 -124 48 -63 63 -126 63 -263 l0 -117 -77 -6 c-43 -4 -193 -13
-333 -21 -140 -9 -295 -22 -345 -30 -336 -54 -542 -192 -623 -418 -24 -64 -26
-86 -26 -207 0 -120 3 -143 26 -208 38 -106 83 -175 162 -248 80 -75 190 -133
311 -165 115 -31 336 -31 450 0 191 52 360 168 445 306 l25 40 3 -177 2 -178
220 0 220 0 0 790 c0 878 0 880 -65 1015 -97 200 -297 339 -566 390 -105 20
-300 27 -399 14z m570 -1338 c0 -136 -18 -209 -70 -291 -91 -141 -274 -230
-474 -230 -245 0 -401 115 -399 295 1 150 100 247 293 289 41 9 201 25 355 35
154 10 283 19 288 20 4 0 7 -53 7 -118z"
                />
                <path
                    d="M7965 3793 c3 -10 182 -503 396 -1095 l389 -1077 -35 -106 c-40 -123
-74 -184 -133 -242 -68 -66 -133 -86 -279 -87 l-123 0 0 -182 0 -183 57 -7
c80 -10 294 1 383 20 225 48 385 193 515 469 43 92 311 831 839 2319 31 86 59
164 62 173 5 13 -22 15 -237 15 l-244 0 -269 -873 c-148 -479 -272 -877 -275
-883 -3 -7 -116 344 -251 780 -134 435 -257 833 -273 884 l-29 92 -250 0
c-234 0 -250 -1 -243 -17z"
                />
            </g>
        </svg>
    );
};

export default ApplePaySvg;
