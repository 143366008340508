import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import StatusButton from '../common/status-button';
import Container from '@material-ui/core/Container';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem,Button } from '@material-ui/core';

import useAdminStyles from '../styles';
import TableComponent, { HeadCell, TableResultRow } from '../common/table-component';
import ConfirmationDialog from '../common/confirmation-dialog';
import { FetchPayoutTransactionResponse, PayoutTransactionAttributes } from '../../types/influencerPayout';
import { formatDinero, getDinero, joinNameParts } from '../../utilities';
import { influencerReferralListUseStyles } from '../manage-influencer/referral-list.styles';
import { useToasterData } from '../../contexts/toaster-context';
import { useApiService } from '../../contexts/api-service-context';
import { ErrorType } from '../../types';
import moment from 'moment';
import { nanoid } from 'nanoid';

export const useStyles = makeStyles((theme) => ({
    toolbar: {
        textAlign: 'left'
    }
}));

type Props = {
    isLoading: boolean;
    transactions: FetchPayoutTransactionResponse;
};

type State = {
    selectedDateRange: number;
    dateRange: Array<Date | null>;
    paymentReason: number;
    isConfirmationDialogOpen: boolean;
    isPaymentLoading: boolean;
    activePaymentTransactionId: number | null;
};

const headCells: Array<HeadCell> = [
    { id: 'amount', label: 'Amount' },
    { id: 'paymentFee', label: 'Payment Fee' },
    { id: 'sender', label: 'Sender' },
    { id: 'receiver', label: 'Receiver' },
    { id: 'paymentMethodType', label: 'Payment Method' },
    { id: 'paidOn', label: 'Paid On' },
    { id: 'paymentStatus', label: 'Payment Status' },
    { id: 'paypalAccount', label: 'PayPal Account' }
];
const dateRanges = [
    { key: 0, label: 'All' },
    { key: 1, label: 'Last month' },
    { key: 3, label: 'Last 3 months' },
    { key: 6, label: 'Last 6 months' },
    { key: 12, label: 'Last year' }
];
const paymentReasons: { key: number; label: PayoutTransactionAttributes['paymentReason'] | 'All' }[] = [
    {
        key: 0,
        label: 'All'
    },
    {
        key: 1,
        label: 'SUCCESSFUL_REGISTRATION'
    },
    {
        key: 2,
        label: 'SUCCESSFUL_TRANSACTION'
    }
];

const filterTransactionList = (transactions: FetchPayoutTransactionResponse, state: State) => {
    const { dateRange, paymentReason: paymentReasonKey } = state;
    let newTransactions: FetchPayoutTransactionResponse = transactions.slice();
    let totalRecords = transactions.length;

    // eslint-disable-next-line prefer-const
    let [startDate, endDate] = dateRange;
    endDate =
        (endDate && moment(endDate).add(1, 'day').toDate()) ||
        (startDate && moment(startDate).endOf('day').toDate()) ||
        null;

    // filter the users for matching date range
    newTransactions =
        (startDate && newTransactions.filter((o) => moment(o.paidOn).isAfter(startDate))) || newTransactions;
    newTransactions = (endDate && newTransactions.filter((o) => moment(o.paidOn).isBefore(endDate))) || newTransactions;

    const paymentReason = (paymentReasonKey && paymentReasons.find((p) => p.key === paymentReasonKey)) || null;

    newTransactions =
        (paymentReason && newTransactions.filter((o) => o.paymentReason === paymentReason?.label)) || newTransactions;

    // if one of these filter props change, we adjust the total records
    if (startDate || endDate || paymentReason) {
        totalRecords = newTransactions.length;
    }

    return { transactions: newTransactions, totalRecords };
};

const PayoutTransactions = (props: Props) => {
    const classes = useStyles();
    const adminClasses = useAdminStyles();
    const referralClasses = influencerReferralListUseStyles();
    const toasterContext = useToasterData();
    const apiService = useApiService();

    const [state, setState] = React.useState<State>({
        selectedDateRange: 0,
        dateRange: [null, null],
        paymentReason: 0,
        isConfirmationDialogOpen: false,
        isPaymentLoading: false,
        activePaymentTransactionId: null
    });

    const { transactions } = filterTransactionList(props.transactions, state);
    const activeTransaction = transactions.find((t) => t.id === state.activePaymentTransactionId);

    const influencer = {
        amount: activeTransaction?.amount || 0,
        email: activeTransaction?.receiver.email|| null,
        userId: activeTransaction?.receiver.userId|| null,
        paypalAccount: activeTransaction?.paypalAccount|| null
    };

    const rows: Array<TableResultRow> = transactions.map((p) => {
        return {
            id: {
                align: 'left',
                text: p.id?.toString() || ''
            },
            amount: {
                align: 'left',
                text: formatDinero(getDinero(p.amount))
            },
            paymentFee: {
                align: 'left',
                text: formatDinero(getDinero(p.paymentFee || 0))
            },
            sender: {
                align: 'left',
                text: (joinNameParts(p.sender) || p.sender.email) + ` (userId: ${p.sender.userId})`
            },
            receiver: {
                align: 'left',
                text: (joinNameParts(p.receiver) || p.receiver.email) + ` (userId: ${p.receiver.userId})`
            },
            paymentMethodType: {
                align: 'left',
                text: p.paymentMethodType || ''
            },
            paidOn: {
                align: 'left',
                text: p.paidOn || ''
            },
            paymentStatus: {
                align: 'left',
                text: p.paymentStatus || 'PENDING',
                element: (
                    <Box display="flex" gridColumnGap={12}>
                        <StatusButton
                            options={[
                                {
                                    color: 'active',
                                    text: 'SUCCESS',
                                    type: 'SUCCESS'
                                },
                                {
                                    color: 'danger',
                                    text: 'FAIL',
                                    type: 'FAIL'
                                },
                                {
                                    color: 'warning',
                                    text: 'PENDING',
                                    type: 'PENDING'
                                }
                            ]}
                            type={p.paymentStatus}
                        />
                        {p.paymentStatus === 'FAIL' && (
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    if (p.id) {
                                        setState((prevState) => ({
                                            ...prevState,
                                            isConfirmationDialogOpen: true,
                                            activePaymentTransactionId: p.id!
                                        }));
                                    }
                                }}
                            >
                                Retry Payment
                            </Button>
                        )}
                    </Box>
                )
            },
            paypalAccount: {
                align: 'left',
                text: p.paypalAccount || ''
            }
        };
    });

    const headerComponent = (
        <Grid item={true} xs={12} className={adminClasses.headerComponent}>
            <Box display="flex" justifyContent="space-between" width="100%">
                <Typography className={adminClasses.tableHeading}>Influencer Payout Transactions</Typography>

                <Box display="flex">
                    <FormControl variant="filled" className={referralClasses.picker}>
                        <InputLabel id="referral-list-date-filter">
                            <Typography color="textSecondary" component="span">
                                Date:
                            </Typography>
                        </InputLabel>
                        <Select
                            className={referralClasses.pickerSelect}
                            variant="standard"
                            labelId="referral-list-date-filter"
                            value={state.selectedDateRange}
                            onChange={(e) => {
                                const selectedDateRange = Number(e.target.value);
                                const currentMonth = new Date().getMonth();
                                const dateRange = [
                                    new Date(new Date().setMonth(currentMonth - selectedDateRange)),
                                    new Date()
                                ];
                                setState((prevState: State) => ({
                                    ...prevState,
                                    selectedDateRange,
                                    dateRange: selectedDateRange ? dateRange : [null, null]
                                }));
                            }}
                        >
                            {dateRanges.map((d) => (
                                <MenuItem key={d.key} value={d.key}>
                                    {d.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl variant="filled" className={referralClasses.picker}>
                        <InputLabel id="referral-list-date-filter">
                            <Typography color="textSecondary" component="span">
                                Payment Reason:
                            </Typography>
                        </InputLabel>
                        <Select
                            className={referralClasses.pickerSelect}
                            variant="standard"
                            labelId="referral-list-date-filter"
                            value={state.paymentReason}
                            onChange={(e) => {
                                setState((prevState: State) => ({
                                    ...prevState,
                                    paymentReason: +(e.target.value as string)
                                }));
                            }}
                        >
                            {paymentReasons.map((d) => (
                                <MenuItem key={d.key} value={d.key}>
                                    {d.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>
        </Grid>
    );

    const retryPaypalPaymentRequest = async () => {
        try {
            setState((prevState) => ({ ...prevState, isPaymentLoading: true }));
            const apiResponse = await apiService.post(`/api/admin/manage-payout/retry-paypal-payment`, {
                paypalTransaction: {
                    senderBatchHeader: {
                        sender_batch_id: nanoid(),
                        email_message: 'You have received a payout! Thanks for using our service!',
                        email_subject: 'You have a payout!'
                    },
                    items: [
                        {
                            amount: {
                                value: influencer.amount.toFixed(2),
                                currency: 'USD'
                            },
                            sender_item_id: nanoid(),
                            receiver: influencer.paypalAccount
                        }
                    ]
                },
                payoutTransactionId: state.activePaymentTransactionId
            });
            const response = apiResponse.parsedBody;
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'success'
            });
            setState((prevState) => ({
                ...prevState,
                isPaymentLoading: false,
                isConfirmationDialogOpen: false,
                activePaymentTransactionId: null
            }));
            // props.onRetryPayment();
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
            setState((prevState) => ({
                ...prevState,
                isPaymentLoading: false,
                isConfirmationDialogOpen: false,
                activePaymentTransactionId: null
            }));
            return null;
        }
    };

    return (
        <Grid item={true} xs={12} className={adminClasses.root}>
            <Container className={adminClasses.container}>
                <TableComponent
                    rowHover={false}
                    headerComponent={headerComponent}
                    showPaginator={{ bottom: true }}
                    showSearch={true}
                    isLoading={props.isLoading}
                    rows={rows}
                    headCells={headCells}
                    fillEmptyRows={false}
                    keyField="id"
                    showCheckbox={false}
                    overrideClasses={{
                        toolbarClass: classes.toolbar
                    }}
                />

                <ConfirmationDialog
                    header={'Confirm payment'}
                    subHeader={
                        <>
                            You will pay <strong>{formatDinero(getDinero(influencer.amount || 0))}</strong> to{' '}
                            <strong>
                                {influencer.email} (User Id: {influencer.userId})
                            </strong>
                            .<br />
                            <br />
                            Click <strong>CONFIRM</strong> to complete the payment.
                        </>
                    }
                    isLoading={state.isPaymentLoading}
                    open={state.isConfirmationDialogOpen}
                    onClose={() => setState((prevState) => ({ ...prevState, isConfirmationDialogOpen: false }))}
                    onConfirm={() => retryPaypalPaymentRequest()}
                />
            </Container>
        </Grid>
    );
};

export default PayoutTransactions;
