import React from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    FormControl,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Typography
} from '@material-ui/core';
import { GeoLocationCountry } from '../../../types';
import ConfirmationDialog from '../../common/confirmation-dialog';
import { JsonEditor } from 'json-edit-react';
import { useApiService } from '../../../contexts/api-service-context';
import { useToasterData } from '../../../contexts/toaster-context';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 200,
        '& .MuiSelect-root': {
            textAlign: 'left'
        }
    }
}));

type Props = {
    allGeoData: GeoLocationCountry[];
    filteredGeoDataShortNames: Set<string>;
    isSaving: boolean;
    isDialogOpen: boolean;
    onDialogOpen: () => void;
    onDialogClose: () => void;
    onCountryAdd: (geoData: GeoLocationCountry) => void;
};

const ChooseCountry = (props: Props) => {
    const classes = useStyles();
    const apiService = useApiService();
    const toasterContext = useToasterData();

    const [countryCode, setCountryCode] = React.useState<string>('');
    const [openDialog, setOpenDialog] = React.useState(false);
    const [giftCardResponse, setGiftCardResponse] = React.useState([]);
    const [fetching, setFetching] = React.useState(false);

    const selectedGeoData = props.allGeoData.find((geoData) => geoData.shortName === countryCode);

    const handleGeoDataChange = (
        event: React.ChangeEvent<{
            name?: string;
            value: unknown;
        }>
    ) => {
        setCountryCode(event.target.value as string);
    };

    const fetchGiftCards = async () => {
        setFetching(true);
        const apiResponse = await apiService.get(`/public/business/products?cultureCode=en-${countryCode}`);
        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            setFetching(false);
            return;
        }

        if (response.data.totalCount === 0) {
            toasterContext.setToaster({
                isOpen: true,
                message: `No gift cards available for "${countryCode}"`,
                severity: 'error'
            });
            setFetching(false);
            return;
        }

        setGiftCardResponse(response.data);
        setOpenDialog(true);
        setFetching(false);
    };

    return (
        <Box display="flex" justifyContent="flex-start" alignItems="flex-end" gridGap={12} mt={1}>
            <FormControl className={classes.formControl}>
                <InputLabel id="select-country-label">Choose Country</InputLabel>
                <Select
                    labelId="select-country-label"
                    id="select-country"
                    value={countryCode}
                    onChange={handleGeoDataChange}
                >
                    {props.allGeoData.map((geoData) => (
                        <MenuItem key={geoData.shortName} value={geoData.shortName}>
                            {geoData.name} ({geoData.shortName})
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {!props.filteredGeoDataShortNames.has(countryCode) && (
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        disabled={!countryCode || fetching}
                        onClick={() => {
                            if (countryCode) {
                                props.onDialogOpen();
                            }
                        }}
                    >
                        Add Country
                    </Button>
                </div>
            )}

            <div>
                <Button
                    variant="contained"
                    color="secondary"
                    type="button"
                    disabled={!countryCode || fetching}
                    onClick={fetchGiftCards}
                >
                    Fetch Gift Cards
                    {fetching && <CircularProgress className="button-loader" />}
                </Button>
            </div>

            <ConfirmationDialog
                header={'Are you sure?'}
                subHeader={`Click CONFIRM to add ${selectedGeoData?.name}`}
                isLoading={props.isSaving}
                open={props.isDialogOpen}
                onClose={props.onDialogClose}
                onConfirm={() => {
                    if (selectedGeoData) {
                        props.onCountryAdd(selectedGeoData);
                    }
                }}
            />

            <Dialog open={openDialog} fullWidth maxWidth="xl" onClose={() => setOpenDialog(false)}>
                <DialogTitle>
                    Fetched gift cards for {selectedGeoData?.name} ({selectedGeoData?.shortName})
                </DialogTitle>
                <JsonEditor
                    data={giftCardResponse}
                    collapse={1}
                    maxWidth="xl"
                    restrictAdd
                    restrictDelete
                    restrictEdit
                    restrictTypeSelection
                />
            </Dialog>
        </Box>
    );
};

export default ChooseCountry;
