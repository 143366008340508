import { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { OrderPayloadGiftCardProduct, UserAccount, UserBillingAddress } from '../../types';
import {
    DateFormatTypeEnum,
    formatDinero,
    formatTimeStamp,
    generateDinero,
    getDinero,
    joinNameParts,
    validateNumber
} from '../../utilities';
import StatusButton from '../common/status-button';

import { FormattedPhoneNumber, manageUserEditFormUseStyles } from '../manage-user/editForm';
import { useToasterData } from '../../contexts/toaster-context';
import TableComponent, { HeadCell, TableResultRow } from '../common/table-component';
import storeContext from '../../contexts/store-context';
import ConfirmationDialog from '../common/confirmation-dialog';
import { Box, CircularProgress, Divider } from '@material-ui/core';
import { AppTextField } from '../common/app-textfield';
import { B2BOrderSummaryPayout } from '../../types/b2bPayout';
import OrderDetails, { PayoutTypeEnum } from './order-details';

export const TransactionTypeEnum = ['CREDIT', 'DEBIT'] as const;
export const TransactionStatusEnum = ['SUCCESS', 'FAILED', 'ON_HOLD'] as const;

export type UserFundTransaction = {
    id: number;
    account?: UserAccount;
    orderId?: number;
    transactionType: typeof TransactionTypeEnum[number];
    transactionStatus: typeof TransactionStatusEnum[number];
    amount: number;
    balance: number;
    createdAt: string;
    updatedAt: string;
};

type UserAccountWithBillingAddress = Partial<UserAccount> & { billingAddresses?: Array<UserBillingAddress> };

type Props = {
    user: UserAccountWithBillingAddress;
    onCancel: () => void;
    onPaymentComplete: () => void;
};

type State = {
    user: UserAccountWithBillingAddress | null;
    orders: B2BOrderSummaryPayout[];
    activeOrderItems: OrderPayloadGiftCardProduct[];
    amount: string;
    discountedTotal: number;
    totalPayoutamount: number;
    totalIncentiveFee: number;
    totalCardCreationFee: number;
    totalOrderFee: number;
    totalGiftCardFee: number;
    totalPaymentGatewayFee: number;
    makePaymentBtnLoading: boolean;
};

type ModalState = {
    activeOrderId: number | null;
    isLoading: boolean;
    isOpen: boolean;
};
const DEFAULT_MODAL_STATE: ModalState = {
    activeOrderId: null,
    isLoading: false,
    isOpen: false
};

const DEFAULT_STATE: State = {
    user: null,
    orders: [],
    activeOrderItems: [],
    amount: '',
    discountedTotal: 0,
    totalPayoutamount: 0,
    totalIncentiveFee: 0,
    totalCardCreationFee: 0,
    totalOrderFee: 0,
    totalGiftCardFee: 0,
    totalPaymentGatewayFee: 0,
    makePaymentBtnLoading: false
};

const NULL_TEXT = 'Information not available';

export const PendingOrderPayoutDetails = (props: Props) => {
    const classes = manageUserEditFormUseStyles();
    const toasterContext = useToasterData();
    const { manageBusinessUserAction } = storeContext();
    const { onCancel } = props;

    const [state, setState] = useState<State>({ ...DEFAULT_STATE, user: props.user });

    const [modal, setModal] = useState<ModalState>(DEFAULT_MODAL_STATE);

    const activeOrder = state.orders.find((o) => o.orderId === modal.activeOrderId);
    const userId = props.user.userId;

    useEffect(() => {
        setState((prevState) => ({ ...prevState, ...DEFAULT_STATE, user: props.user }));
        setModal(DEFAULT_MODAL_STATE);
        fetchPendingOrderPayouts();
        fetchPendingPayoutFeesByUserId();
    }, [props.user]);

    const fetchPendingOrderPayouts = async () => {
        const userId = props.user.userId;
        if (!userId) {
            return;
        }

        const orders = await manageBusinessUserAction()?.getAllPendingPayoutOrdersByUserId(userId);
        setState((prevState) => ({
            ...prevState,
            orders: orders || []
        }));
    };

    const fetchPendingPayoutFeesByUserId = async () => {
        const userId = props.user.userId;
        if (!userId) {
            return;
        }

        const payoutAmount = await manageBusinessUserAction()?.getPendingPayoutFeesByUserId(userId);
        setState((prevState) => ({
            ...prevState,
            discountedTotal: payoutAmount?.discountedTotal || 0,
            totalPayoutamount: payoutAmount?.totalPayoutAmount || 0,
            totalIncentiveFee: payoutAmount?.totalIncentiveFees || 0,
            totalCardCreationFee: payoutAmount?.totalCardCreationFees || 0,
            totalOrderFee: payoutAmount?.totalOrderFees || 0,
            totalGiftCardFee: payoutAmount?.totalGiftCardFees || 0,
            totalPaymentGatewayFee: payoutAmount?.totalPaymentGatewayFees || 0
        }));
    };

    const closeModal = () => {
        setModal((prevState) => ({
            ...prevState,
            isOpen: false,
            activeOrderId: null
        }));
    };

    const makePayout = async () => {
        const amount = state.amount;
        if (!amount) {
            toasterContext.setToaster({ isOpen: true, message: 'Please enter an amount.', severity: 'error' });
            return;
        }
        if (!validateNumber(amount)) {
            toasterContext.setToaster({ isOpen: true, message: 'Please enter valid amount.', severity: 'error' });
            return;
        }

        setModal((prevState) => ({ ...prevState, isLoading: true }));
        const success = await manageBusinessUserAction()?.makePayoutOrder(userId!, { amount: +amount });

        if (success) {
            props.onPaymentComplete();
        }
        setModal((prevState) => ({
            ...prevState,
            isLoading: false
        }));
    };

    const onAmountChange = (amount: string) => {
        setState((prevState) => ({ ...prevState, amount }));
    };

    const onRowClick = async (id: string) => {
        const [giftCardType, orderId] = id.split('-');

        setState((prevState) => ({
            ...prevState,
            makePaymentBtnLoading: true
        }));

        const activeOrderItems = await manageBusinessUserAction()?.getAllPendingPayoutOrderItems(
            userId!,
            id
        );

        setState((prevState) => ({
            ...prevState,
            makePaymentBtnLoading: false,
            activeOrderItems: activeOrderItems || []
        }));

        setModal((prevState) => ({
            ...prevState,
            isOpen: true,
            activeOrderId: +orderId
        }));
    };

    const headCells: Array<HeadCell> = [
        { id: 'orderId', label: 'Order ID' },
        { id: 'orderDate', label: 'Order Date' },
        { id: 'orderTotal', label: 'Order Total' },
        { id: 'payoutAmount', label: 'Payout Amount' }
    ];

    const tableRows: TableResultRow[] = state.orders.map((item) => {
        
        const orderTotal = item.total || item.baseTotal || 0;
        const data: TableResultRow = {
            id: {
                text: `${item.giftCardType}-${item.orderId}`,
                align: 'left'
            },
            orderId: {
                text: `${item.orderId}`,
                align: 'left'
            },
            orderDate: {
                text: formatTimeStamp(item.createdAt, DateFormatTypeEnum.MMMM_D_YYYY_h_mm_a) || '',
                align: 'left'
            },
            orderTotal: {
                text: formatDinero(generateDinero(orderTotal)),
                align: 'left'
            },
            payoutAmount: {
                text: formatDinero(generateDinero(item.payoutAmount || 0)),
                align: 'left'
            }
        };

        return data;
    });

    const name = joinNameParts(state.user);
    const totalFee = state.totalCardCreationFee + state.totalIncentiveFee;

    const orderTotal = state.orders.reduce((acc, cur) => acc.add(generateDinero(cur.total || cur.baseTotal || 0)), getDinero(0));
    const payoutAmount = state.totalPayoutamount - totalFee;

    const maxAmountEntryError =
        payoutAmount > 0 ? getDinero(+state.amount || 0).greaterThan(getDinero(payoutAmount)) : false;
    const minAmountError = getDinero(totalFee).greaterThanOrEqual(getDinero(state.totalPayoutamount));

    return (
        <Container className={classes.mainContainer}>
            <Grid container={true} className={classes.inner}>
                <Grid item={true} xs={12} className={classes.form}>
                    <Grid container={true} alignItems="center" justifyContent="space-between">
                        <Typography className={classes.heading}>Merchant Pending Order Payouts</Typography>

                        <StatusButton
                            options={[
                                {
                                    type: 'APPROVAL_PENDING',
                                    text: 'Pending',
                                    color: 'warning'
                                },
                                {
                                    type: 'REVOKED',
                                    text: 'Access Revoked',
                                    color: 'danger'
                                },
                                {
                                    type: 'ON_WATCH',
                                    text: 'ON WATCH',
                                    color: 'warning'
                                }
                            ]}
                            type={state.user?.accessStatus}
                        />
                    </Grid>

                    <Grid item={true} xs={12} className={classes.details}>
                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                User Id:
                            </Typography>
                            <Typography component="span">#{state.user?.userId}</Typography>
                        </Grid>

                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Name:
                            </Typography>
                            <Typography component="span">{name}</Typography>
                        </Grid>

                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Email:
                            </Typography>
                            <Typography component="span">{state.user?.email || NULL_TEXT}</Typography>
                        </Grid>

                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Mobile number:
                            </Typography>
                            <FormattedPhoneNumber phone={state?.user?.phone} country={state?.user?.country} />
                        </Grid>
                    </Grid>

                    <Grid item={true} xs={12} className={classes.details}>
                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Company Name:
                            </Typography>
                            <Typography component="span">
                                <strong>{state.user?.companyName || NULL_TEXT}</strong>
                            </Typography>
                        </Grid>
                    </Grid>

                    <TableComponent
                        header="Pending Order Payouts"
                        showCheckbox={false}
                        showPaginator={{ bottom: true }}
                        showSearch={true}
                        rows={tableRows}
                        headCells={headCells}
                        fillEmptyRows={false}
                        keyField="id"
                        rowTooltip={'Click to view payout details' || undefined}
                        onRowClick={(id) => onRowClick(id)}
                    />

                    <Grid item={true} xs={12} className={classes.details}>
                        <Grid item={true} xs={12}>
                            <Box display="flex" justifyContent="space-between" mb={2}>
                                <Typography color="textSecondary" component="span">
                                    Order Total:
                                </Typography>
                                <Typography component="span">{formatDinero(orderTotal)}</Typography>
                            </Box>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Box display="flex" justifyContent="space-between" mb={2}>
                                <Typography color="textSecondary" component="span">
                                    Discounted Total (Order Total - after deducting promo code, gift card discounts):
                                </Typography>
                                <Typography component="span">{formatDinero(getDinero(state.discountedTotal))}</Typography>
                            </Box>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Box display="flex" justifyContent="space-between" mb={2}>
                                <Typography color="textSecondary" component="span">
                                    Total Order Fee:
                                </Typography>
                                <Typography component="span">-{formatDinero(getDinero(state.totalOrderFee))}</Typography>
                            </Box>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Box display="flex" justifyContent="space-between" mb={2}>
                                <Typography color="textSecondary" component="span">
                                    Total Gift Card Fee:
                                </Typography>
                                <Typography component="span">
                                    -{formatDinero(getDinero(state.totalGiftCardFee))}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Box display="flex" justifyContent="space-between" mb={2}>
                                <Typography color="textSecondary" component="span">
                                    Total Payment Gateway Fee:
                                </Typography>
                                <Typography component="span">
                                    -{formatDinero(getDinero(state.totalPaymentGatewayFee))}
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box pb={2}>
                                <Divider />
                            </Box>
                        </Grid>

                        <Grid item={true} xs={12}>
                            <Box display="flex" justifyContent="space-between" mb={2}>
                                <Typography color="textSecondary" component="span">
                                    <strong>= Total Payout Amount:</strong>
                                </Typography>
                                <Typography component="span">
                                    <strong>{formatDinero(getDinero(state.totalPayoutamount))}</strong>
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box pb={2}>
                                <Divider />
                            </Box>
                        </Grid>

                        <Grid item={true} xs={12}>
                            <Box display="flex" justifyContent="space-between" mb={2}>
                                <Typography color="textSecondary" component="span">
                                    Pending Over-Quota Card Fee:
                                </Typography>
                                <Typography component="span">
                                    -{formatDinero(getDinero(state.totalCardCreationFee))}
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item={true} xs={12}>
                            <Box display="flex" justifyContent="space-between" mb={2}>
                                <Typography color="textSecondary" component="span">
                                    Pending Incentive Fee:
                                </Typography>
                                <Typography component="span">
                                    -{formatDinero(getDinero(state.totalIncentiveFee))}
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box pb={2}>
                                <Divider />
                            </Box>
                        </Grid>

                        <Grid item={true} xs={12}>
                            <Box display="flex" justifyContent="space-between" mb={2}>
                                <Typography color="textSecondary" component="span">
                                    <strong>= Pending Payout Amount:</strong>
                                </Typography>
                                <Typography component="span">
                                    <strong>{formatDinero(getDinero(payoutAmount))}</strong>
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box pb={2}>
                                <Divider />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item={true} xs={12} className={classes.details}>
                        {!minAmountError && (
                            <Box mt={4}>
                                <AppTextField
                                    label="Enter Payout Amount"
                                    placeholder="Enter Amount"
                                    value={state.amount}
                                    fullWidth
                                    focused
                                    error={maxAmountEntryError}
                                    helperText={
                                        maxAmountEntryError && 'Amount cannot be greater than Pending Payout Amount'
                                    }
                                    onChange={(e) => onAmountChange(e.target.value)}
                                />
                            </Box>
                        )}
                        {minAmountError && (
                            <Box mt={4}>
                                <Typography color="textSecondary" component="span">
                                    The payout amount is insufficient to cover the deduction of both incentive and card
                                    creation fees. Consider making the payout at a later time.
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                    <Grid item={true} xs={12} className={classes.footer}>
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={(e) => {
                                onCancel();
                                setState(DEFAULT_STATE);
                            }}
                        >
                            Cancel
                        </Button>
                        {!minAmountError && (
                            <Button
                                variant="contained"
                                size="small"
                                disabled={modal.isLoading}
                                onClick={(e) => {
                                    makePayout();
                                }}
                            >
                                Make Payment
                                {modal.isLoading && <CircularProgress className="button-loader" />}
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Grid>

            <ConfirmationDialog
                header={''}
                maxWidth="xl"
                isLoading={modal.isLoading}
                additionalContent={
                    <Box minWidth={600}>
                        {activeOrder && (
                            <OrderDetails
                                order={activeOrder}
                                orderItems={state.activeOrderItems}
                                payoutType={PayoutTypeEnum.UNPAID}
                                onCancel={closeModal}
                            />
                        )}
                    </Box>
                }
                isDisabled={maxAmountEntryError}
                open={modal.isOpen}
                customButtons={<></>}
            />
        </Container>
    );
};

export default PendingOrderPayoutDetails;
