export const PaymentStatusEnum = ['SUCCESS', 'PENDING', 'FAIL', 'CANCELED', 'CANCELLATION_FAILED'] as const;

export interface PaymentAttributes {
    paymentId?: number;
    userId: number;
    orderId: number;
    paymentMethodId: number;
    amount: number;
    status: typeof PaymentStatusEnum[number];
}
