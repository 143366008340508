import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: '1.2rem',
        textShadow: '1px 1px 8px #555',
        color: 'white',
        fontFamily: 'Arial'
    },
    btnContainer: {
        '& > *': {
            margin: theme.spacing(1)
        }
    }
}));

type Props = {
    headingFont: string;
    primaryColor: string;
    secondaryColor: string;
    buttonTextColor: string;
    backgroundColor: string;
};
const ThemePreview = (props: Props) => {
    const classes = useStyles();
    const { headingFont, primaryColor, secondaryColor, buttonTextColor, backgroundColor } = props;

    return (
        <>
            <Box style={{ background: backgroundColor }} p={2} mb={2}>
                <Typography variant="h1" component="h2" align="left" gutterBottom className={clsx(classes.heading)}>
                    Preview
                </Typography>

                <Typography style={{ fontFamily: headingFont, fontSize: '48px', fontWeight: 900 }} gutterBottom>
                    Heading Font
                </Typography>

                <div className={classes.btnContainer}>
                    <Button
                        type="button"
                        variant="contained"
                        style={{ backgroundColor: primaryColor, color: buttonTextColor }}
                    >
                        Primary button
                    </Button>

                    <Button
                        type="button"
                        variant="contained"
                        style={{ backgroundColor: secondaryColor, color: buttonTextColor }}
                    >
                        Secondary button
                    </Button>
                </div>
            </Box>
        </>
    );
};

export default ThemePreview;
