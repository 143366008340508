import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import InputMask from 'react-input-mask';

import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Popper from '@material-ui/core/Popper';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';
import { FilledInputProps } from '@material-ui/core/FilledInput';
import { InputBaseComponentProps } from '@material-ui/core/InputBase';

import { COUNTRY_CODES } from '../../constants';

const useStyles = makeStyles({
    root: {
        width: '100%',
        position: 'relative',
        '& $inner': {
            display: 'flex'
        }
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18
        }
    },
    autoComplete: {
        width: 102,
        '& $inputBase': {
            height: 48,
            borderTopRightRadius: 0
        },
        '& input': {
            padding: '0 12px'
        }
    },
    inputMask: {
        width: '100%',
        '& $inputBase': {
            borderTopLeftRadius: 0
        }
    },
    autoCompleteTextField: {},
    inner: {},
    inputBase: {}
});

export type Props = {
    phone: string | null;
    size?: 'small' | 'medium';
    hideCountrySelector?: boolean;
    country: keyof typeof COUNTRY_CODES | null;
    isPhoneInvalid: boolean;
    helperText?: string;
    required?: boolean;
    className?: string;
    InputProps?: Partial<FilledInputProps>;
    inputProps?: FilledInputProps['inputProps'];
    onChange: (phone: string, country: string) => void;
};

type State = {
    country: keyof typeof COUNTRY_CODES;
    anchorEl: HTMLDivElement | null;
};

const countryToFlag = (isoCode: string) =>
    typeof String.fromCodePoint !== 'undefined'
        ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;

/**
 *
 * filter by:
 * code example (+1)
 * name example (united states)
 * key example (US)
 *
 **/
const filterOptions = (options: string[], { inputValue }: { inputValue: string }) => {
    inputValue = (inputValue || '').trim().toLowerCase();
    return options.filter((o) => {
        const option = COUNTRY_CODES[o];
        return Boolean(
            option.name.toLowerCase().indexOf(inputValue) !== -1 ||
                option.code.toLowerCase().indexOf(inputValue) !== -1 ||
                o.toLowerCase().indexOf(inputValue) !== -1
        );
    });
};

const PhoneNumberTextField = (props: Props) => {
    const classes = useStyles();
    const [state, setState] = useState<State>({
        country: 'US', // default 'US'
        anchorEl: null
    });

    const {
        phone,
        isPhoneInvalid,
        helperText,
        onChange,
        className,
        inputProps,
        InputProps,
        hideCountrySelector,
        required,
        size
    } = props;

    useEffect(() => {
        if (!props.country || props.country === state.country) {
            return;
        }
        setState({ ...state, country: props.country as string });
    }, [props.country]);

    const errorHelperText = helperText ? helperText : 'Please enter valid Mobile Number.';

    return (
        <Grid
            item={true}
            xs={12}
            className={clsx(className, classes.root)}
            ref={(ref) => {
                if (ref && !state.anchorEl) {
                    setState({ ...state, anchorEl: ref });
                }
            }}
        >
            <Grid item={true} xs={12} className={classes.inner}>
                {(!hideCountrySelector && (
                    <Autocomplete
                        className={classes.autoComplete}
                        PopperComponent={(props) => (
                            <Popper
                                {...props}
                                style={{ width: '100%' }}
                                placement="bottom-start"
                                anchorEl={state.anchorEl}
                                container={state.anchorEl}
                            />
                        )}
                        value={state.country as string}
                        onChange={(event, newValue) => {
                            const _newValue = newValue || 'US';
                            if (state.country !== _newValue) {
                                // reset phone on country change
                                onChange('', _newValue as string);
                                return;
                            }
                            setState({ ...state, country: _newValue });
                            onChange(phone || '', _newValue as string);
                        }}
                        options={Object.keys(COUNTRY_CODES)}
                        classes={{ option: classes.option }}
                        autoHighlight={true}
                        disableClearable={true}
                        getOptionLabel={(c) => c}
                        filterOptions={filterOptions}
                        renderOption={(c) => {
                            const _country = COUNTRY_CODES[c];
                            return (
                                <React.Fragment>
                                    <span>{countryToFlag(c)}</span>
                                    {_country.name} ({c}) {_country.code}
                                </React.Fragment>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className={classes.autoCompleteTextField}
                                size={size || 'medium'}
                                variant="filled"
                                inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}
                                InputProps={{ ...params.InputProps, className: classes.inputBase }}
                                error={Boolean(isPhoneInvalid)}
                            />
                        )}
                    />
                )) ||
                    null}
                <InputMask
                    className={classes.inputMask}
                    mask={COUNTRY_CODES[state.country].phoneMask}
                    value={phone || ''}
                    onChange={(e) => onChange(e.target.value, state.country as string)}
                >
                    {(inputPropsIM: InputBaseComponentProps) => (
                        <TextField
                            InputProps={{
                                autoComplete: 'new-password',
                                className: classes.inputBase,
                                ...InputProps
                            }}
                            inputProps={{ ...inputProps, ...inputPropsIM }}
                            fullWidth={true}
                            required={required}
                            size={size || 'medium'}
                            label="Mobile Number"
                            variant="filled"
                            error={Boolean(isPhoneInvalid)}
                        />
                    )}
                </InputMask>
            </Grid>
            {isPhoneInvalid ? (
                <FormHelperText error={Boolean(isPhoneInvalid)}>
                    {phone?.length === 0 ? 'Please enter Mobile Number.' : errorHelperText}
                </FormHelperText>
            ) : null}
        </Grid>
    );
};

export default PhoneNumberTextField;
