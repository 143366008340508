import React from 'react';

import userSessionAction, { UserSessionAction } from './user-session.action';
import giftCardAction, { GiftCardAction } from './gift-card.action';
import appAction, { AppAction } from './app.action';

import { StoreState } from '../reducers';

import { AppActionType } from '../reducers/app.reducer';
import { GiftCardActionType } from '../reducers/gift-card.reducer';
import { UserSessionActionType } from '../reducers/user-session.reducer';
import promoCodeAction, { PromoCodeAction } from './promo-code.action';
import categoryAction, { CategoryAction } from './category.action';
import mlCategoryAction, { MlCategoryAction } from './ml-category.action';
import { AccessTokenContextProps } from '../contexts/access-token-context';
import { ApiServiceContextProps } from '../contexts/api-service-context';
import { ToasterContextProps } from '../contexts/toaster-context';
import { UserContextProps } from '../contexts/user-context';
import { IdleTimeoutContextProps } from '../contexts/idle-timeout-context';
import themeAction, { ThemeAction } from './theme.action';
import manageInfluencerAction, { ManageInfluencerAction } from './manage-influencer.action';
import influencerReferralPaymentAction, { InfluencerReferralPaymentAction } from './influencer-referral-payment.action';
import influencerPayoutAction, { InfluencerPayoutAction } from './influencer-payout.action';
import manageBusinessUserAction, { ManageBusinessUserAction } from './manage-business-user.action';
import subscriptionAction, { SubscriptionAction } from './subscription.action';
import b2bApiConfigAction, { B2BApiConfigAction } from './b2b-api-config.action';
import b2bGiftCardAction, { B2BGiftCardAction } from './b2b-gift-card.action';
import manageUserAction, { ManageUserAction } from './manage-user.action';
import manageTransactionLimitSettingsAction, { ManageTransactionLimitSettingsAction } from './manage-transaction-limit-settings.action';
import blackListedEmailsAction, { BlackListedEmailsAction } from './black-listed-emails.action';
import otpAction, { OtpAction } from './otp.action';
import geoLocationAction, { GeoLocationAction } from './geo-location';
import exchangeRatesAction, { ExchangeRatesAction } from './exchange-rates.action';
import manageB2CWhitelistedCountriesAction, { ManageB2CWhitelistedCountriesAction } from './manage-b2c-whitelisted-countries.action';

export interface Props {
    state: StoreState;
    apiService: ApiServiceContextProps;
    userService: UserContextProps;
    accessTokenService: AccessTokenContextProps;
    toasterContext: ToasterContextProps;
    idleTimeoutService: IdleTimeoutContextProps;
    dispatch: React.Dispatch<StoreActionType>;
}

export type StoreActions = UserSessionAction &
    GiftCardAction &
    AppAction &
    PromoCodeAction &
    InfluencerReferralPaymentAction &
    CategoryAction &
    MlCategoryAction &
    ManageInfluencerAction &
    InfluencerPayoutAction &
    ThemeAction &
    SubscriptionAction & 
    B2BApiConfigAction &
    ManageBusinessUserAction &
    B2BGiftCardAction &
    ManageUserAction &
    ManageTransactionLimitSettingsAction &
    BlackListedEmailsAction &
    OtpAction &
    GeoLocationAction &
    ExchangeRatesAction & 
    ManageB2CWhitelistedCountriesAction;

export type StoreActionProps = Props & StoreActions;
export type StoreActionType = AppActionType | GiftCardActionType | UserSessionActionType;

const useActions = (
    state: StoreState,
    apiService: ApiServiceContextProps,
    userService: UserContextProps,
    accessTokenService: AccessTokenContextProps,
    toasterContext: ToasterContextProps,
    idleTimeoutService: IdleTimeoutContextProps,
    dispatch: React.Dispatch<StoreActionType>
) => {
    const props: StoreActionProps = {
        state,
        apiService,
        userService,
        accessTokenService,
        toasterContext,
        idleTimeoutService,
        dispatch,
        userSessionAction,
        giftCardAction,
        appAction,
        promoCodeAction,
        categoryAction,
        mlCategoryAction,
        themeAction,
        influencerReferralPaymentAction,
        manageInfluencerAction,
        influencerPayoutAction,
        subscriptionAction,
        b2bApiConfigAction,
        manageBusinessUserAction,
        b2bGiftCardAction,
        manageUserAction,
        manageTransactionLimitSettingsAction,
        blackListedEmailsAction,
        otpAction,
        geoLocationAction,
        exchangeRatesAction,
        manageB2CWhitelistedCountriesAction,
    };

    return {
        userSessionAction: userSessionAction({ ...props }),
        giftCardAction: giftCardAction({ ...props }),
        appAction: appAction({ ...props }),
        promoCodeAction: promoCodeAction({ ...props }),
        categoryAction: categoryAction({ ...props }),
        mlCategoryAction: mlCategoryAction({ ...props }),
        themeAction: themeAction({ ...props }),
        influencerReferralPaymentAction: influencerReferralPaymentAction({ ...props }),
        manageInfluencerAction: manageInfluencerAction({ ...props }),
        influencerPayoutAction: influencerPayoutAction({ ...props }),
        subscriptionAction: subscriptionAction({ ...props }),
        b2bApiConfigAction: b2bApiConfigAction({ ...props }),
        manageBusinessUserAction: manageBusinessUserAction({ ...props }),
        b2bGiftCardAction: b2bGiftCardAction({ ...props }),
        manageUserAction: manageUserAction({ ...props }),
        manageTransactionLimitSettingsAction: manageTransactionLimitSettingsAction({ ...props }),
        blackListedEmailsAction: blackListedEmailsAction({ ...props }),
        otpAction: otpAction({ ...props }),
        geoLocationAction: geoLocationAction({ ...props }),
        exchangeRatesAction: exchangeRatesAction({ ...props }),
        manageB2CWhitelistedCountriesAction: manageB2CWhitelistedCountriesAction({ ...props })
    };
};

export default useActions;
