import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { DateRangeDelimiter, StaticDateRangePicker, TimePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';

import { UserRoleEnum } from '../../types';

const useStyles = makeStyles((theme) => ({
    input: {
        minWidth: 220,
        flex: 1,
        textAlign: 'left',
        [theme.breakpoints.up('md')]: {
            maxWidth: 290
        }
    },
    dateRangePicker: {
        border: `1px solid ${theme.palette.grey['400']}`,
        borderRadius: 4
    },
    break: {
        flexBasis: '100%'
    },
    userTypeDropdown: {
        width: 300,
        marginBottom: 16
    }
}));

type DateOption = {
    offset: number;
    startOf: 'day' | 'month' | 'year';
    label: string;
};
const DATE_OPTIONS: DateOption[] = [
    {
        offset: 0,
        startOf: 'day',
        label: 'Today'
    },
    {
        offset: 1,
        startOf: 'day',
        label: 'Yesterday'
    },
    {
        offset: 7,
        startOf: 'day',
        label: 'Last 7 days'
    },
    {
        offset: 30,
        startOf: 'day',
        label: 'Last 30 days'
    },
    {
        offset: 0,
        startOf: 'month',
        label: 'This month'
    },
    {
        offset: 1,
        startOf: 'month',
        label: 'Last month'
    },
    {
        offset: 0,
        startOf: 'year',
        label: 'This year'
    }
];

export const USER = 'USER';
export const BUSINESS_USER = 'BUSINESS_USER';
export const UserRatingEnum = [1, 2, 3, 4, 5] as const; 

type State = {
    rating: typeof UserRatingEnum[number] | 'ALL';
    dateRange: [string | null, string | null];
    dateOptionSelected: string;
    userRoleType: typeof UserRoleEnum[number];
};

const DEFAULT_STATE: State = {
    rating: 'ALL',
    dateRange: [null, null],
    dateOptionSelected: 'ALL',
    userRoleType: USER
};

type Props = {
    onFilter: (data: State) => void;
};

const UserRatingsFilter = (props: Props) => {
    const classes = useStyles();

    const [state, setState] = React.useState<State>(DEFAULT_STATE);

    const onFilterApply = () => {
        props.onFilter(state);
    };

    const onFilterClear = () => {
        setState({ ...DEFAULT_STATE });
        props.onFilter(DEFAULT_STATE);
    };

    const onDateOptionPick = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        const dateOptionSelected = e.target.value as State['dateOptionSelected'];

        const dateOption = DATE_OPTIONS.find((option) => option.label === dateOptionSelected);
        if (!dateOption) {
            setState((prevState) => ({
                ...prevState,
                dateOptionSelected,
                dateRange: [null, null]
            }));
            return;
        }

        const { offset, startOf } = dateOption;
        const startDateObj = moment().subtract(offset, startOf).startOf(startOf);
        const endDateObj = startDateObj
            .clone()
            .add(offset === 1 ? 0 : offset, startOf)
            .endOf(startOf);
        console.log({
            start: startDateObj.format('MM/DD/YYYY HH:mm'),
            end: endDateObj.format('MM/DD/YYYY HH:mm')
        });
        setState((prevState) => ({
            ...prevState,
            dateOptionSelected,
            dateRange: [startDateObj.format(), endDateObj.format()]
        }));
    };

    const onDateRangePick = (newValue: [string | null, string | null]) => {
        let endDate = newValue[1] ? moment(newValue[1]).format() : null;

        if (
            newValue[0] &&
            newValue[1] &&
            moment(newValue[0]).format('MM/DD/YYYY HH:mm') === moment(newValue[1]).format('MM/DD/YYYY HH:mm')
        ) {
            endDate = moment(newValue[1]).endOf('day').format();
        }
        setState((prevState) => ({ ...prevState, dateRange: [newValue[0], endDate] }));
    };

    return (
        <Accordion defaultExpanded>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="search-filter-content"
                id="search-filter-header"
            >
                <Typography>Search Filter</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box display="flex" mb={2} flexWrap="wrap" gridGap="1rem" width="100%">
                    <FormControl variant="outlined" className={classes.input} size="small">
                        <InputLabel id="select-order-user-type-label">User Type</InputLabel>
                        <Select
                            labelId="select-order-user-type-label"
                            id="select-order-user-type-helper"
                            value={state.userRoleType}
                            label="User Type"
                            onChange={(e) =>
                               { const userType = e.target.value as typeof UserRoleEnum[number];
                                setState((prevState) => ({ ...prevState, userRoleType: userType }));
                            }
                            }
                        >
                            <MenuItem value={USER}>B2C User Ratings</MenuItem>
                            <MenuItem value={BUSINESS_USER}>Business User Ratings</MenuItem>
                        </Select>
                    </FormControl>
        
                    <div className={classes.break} />

                    <FormControl variant="outlined" className={classes.input} size="small">
                        <InputLabel id="user-rating-select-label">Rating</InputLabel>
                        <Select
                            labelId="user-rating-select-label"
                            id="user-rating-select"
                            value={state.rating}
                            label="Rating"
                            onChange={(e) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    rating: e.target.value as State['rating']
                                }))
                            }
                        >
                            <MenuItem value={'ALL'}>All Ratings</MenuItem>
                            {UserRatingEnum.map((rating) => (
                                <MenuItem value={rating} key={rating}>
                                    {rating}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" className={classes.input} size="small">
                        <InputLabel id="date-select-label">Select Date</InputLabel>
                        <Select
                            labelId="date-select-label"
                            id="date-select"
                            value={state.dateOptionSelected}
                            label="Select Date"
                            onChange={onDateOptionPick}
                        >
                            <MenuItem value={'ALL'}>All</MenuItem>
                            {DATE_OPTIONS.map((option) => (
                                <MenuItem key={option.label} value={option.label}>
                                    {option.label}
                                </MenuItem>
                            ))}
                            <MenuItem value={'OTHER'}>Other</MenuItem>
                        </Select>
                    </FormControl>

                    {state.dateOptionSelected === 'OTHER' && (
                        <StaticDateRangePicker
                            displayStaticWrapperAs="desktop"
                            showToolbar={false}
                            value={state.dateRange}
                            onChange={onDateRangePick}
                            renderInput={(startProps, endProps) => (
                                <React.Fragment>
                                    <TextField {...startProps} />
                                    <DateRangeDelimiter> to </DateRangeDelimiter>
                                    <TextField {...endProps} />
                                </React.Fragment>
                            )}
                            className={classes.dateRangePicker}
                        />
                    )}

                    {state.dateRange[0] &&
                        (!state.dateRange[1] ||
                            moment(state.dateRange[0])?.format('MM/DD/YYYY') ===
                                moment(state.dateRange[1])?.format('MM/DD/YYYY')) && (
                            <Box>
                                <Typography align="left" variant="body2">
                                    From:
                                </Typography>

                                <TimePicker
                                    renderInput={(props) => <TextField {...props} />}
                                    value={state.dateRange[0]}
                                    inputFormat="HH:mm a"
                                    onChange={(date) => {
                                        if (!moment(date).isValid()) {
                                            return;
                                        }
                                        const hours = moment(date).format('HH');
                                        const minutes = moment(date).format('mm');

                                        const startDate = moment(state.dateRange[0])
                                            .set('hours', +hours)
                                            .set('minutes', +minutes);

                                        setState((prevState) => ({
                                            ...prevState,
                                            dateRange: [startDate.format(), state.dateRange[1]]
                                        }));
                                    }}
                                />

                                <Box mt={3} />

                                <Typography align="left" variant="body2">
                                    To:
                                </Typography>

                                <TimePicker
                                    renderInput={(props) => <TextField {...props} />}
                                    value={state.dateRange[1]}
                                    inputFormat="HH:mm a"
                                    onChange={(date) => {
                                        if (!moment(date).isValid()) {
                                            return;
                                        }
                                        const hours = moment(date).format('HH');
                                        const minutes = moment(date).format('mm');

                                        const endDate = moment(state.dateRange[1])
                                            .set('hours', +hours)
                                            .set('minutes', +minutes);

                                        setState((prevState) => ({
                                            ...prevState,
                                            dateRange: [state.dateRange[0], endDate.format()]
                                        }));
                                    }}
                                />
                            </Box>
                        )}

                    <Box display="flex" justifyContent="flex-end" gridGap="1rem" width="100%">
                        <Button variant="contained" size="small" onClick={onFilterClear}>
                            Clear
                        </Button>
                        <Button color="primary" size="small" variant="contained" onClick={onFilterApply}>
                            Apply
                        </Button>
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default UserRatingsFilter;

