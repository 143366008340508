import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import RemoveIcon from '@material-ui/icons/Remove';

import { AdminUser, PrivilegeType, PrivilegeAccessTypeEnum, PrivilegeTypeEnum, ErrorType } from '../../types';
import { getRawPhoneNumber, validateEmail } from '../../utilities';

import { AppTextField } from '../common/app-textfield';
import PhoneNumberTextField from '../common/phone-number-texfield';

import { COUNTRY_CODES } from '../../constants';
import Password from '../auth/password';
import { useApiService } from '../../contexts/api-service-context';
import { useToasterData } from '../../contexts/toaster-context';
import { useUserService } from '../../contexts/user-context';
import storeContext from '../../contexts/store-context';

const useStyles = makeStyles((theme) => ({
    form: {
        padding: 16,
        boxShadow: theme.shadows[4],
        borderRadius: 8,
        margin: '24px auto',
        '& $heading': {
            textAlign: 'center',
            fontSize: 20,
            fontWeight: 600,
            margin: '12px 0',
            [theme.breakpoints.up('sm')]: {
                textAlign: 'left'
            }
        },
        '& $footer': {
            width: '100%',
            display: 'flex',
            padding: '16px 0',
            justifyContent: 'center',
            [theme.breakpoints.up('sm')]: {
                justifyContent: 'flex-end'
            },
            '& button': {
                margin: '0 8px',
                [theme.breakpoints.up('sm')]: {
                    margin: '0 0 0 12px'
                }
            }
        }
    },
    textField: {
        marginBottom: 24,
        width: '100%'
    },
    visibilityIcon: {
        marginRight: 8,
        cursor: 'pointer'
    },
    passwordContainer: {
        minWidth: '100%',
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            width: 320,
            maxWidth: 320,
            minWidth: 320,
            height: 'max-content'
        },
        '& $footer': {
            [theme.breakpoints.up('lg')]: {
                justifyContent: 'center'
            }
        }
    },
    divider: {
        width: '100%'
    },
    passowrdSection: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    checkboxLabel: {
        fontWeight: 500,
        fontSize: 14
    },
    privileges: {
        overflowX: 'auto'
    },
    privilegeContainer: {
        paddingBottom: 32
    },
    mainContainer: {
        [theme.breakpoints.up('lg')]: {
            display: 'flex'
        },
        '& $inner': {
            [theme.breakpoints.up('lg')]: {
                display: 'flex'
            }
        },
        '& $form': {
            [theme.breakpoints.up('lg')]: {
                margin: '24px 20px'
            }
        },
        '& $passowrdSection': {
            '& $divider': {
                marginBottom: 24,
                [theme.breakpoints.up('sm')]: {
                    display: 'none'
                },
                [theme.breakpoints.up('lg')]: {
                    display: 'block'
                }
            }
        }
    },
    inner: {},
    table: {},
    heading: {},
    footer: {}
}));

// By default all privileges will have all access
const DEFAULT_PRIVILEGES: PrivilegeType = PrivilegeTypeEnum.reduce((acc, key) => {
    acc[key] = [];
    PrivilegeAccessTypeEnum.forEach((accessKey) => (acc[key] = [...acc[key], accessKey]));
    return acc;
}, {} as PrivilegeType);

type PrivilegesProps = {
    privileges: PrivilegeType;
    readonly?: boolean;
    onChange: (privileges: PrivilegeType) => void;
};

type PrivilegesState = {
    privileges: PrivilegeType;
};

const Privileges = (props: PrivilegesProps) => {
    const classes = useStyles();
    const { readonly } = props;

    const [state, setState] = useState<PrivilegesState>({
        privileges: props.privileges
    });

    useEffect(() => {
        props.onChange(state.privileges);
    }, [state.privileges]);

    const statePrivilegesKeys = Object.keys(state.privileges) as Array<keyof typeof state.privileges>;

    const handleChangeAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            statePrivilegesKeys.map((key) =>
                setState((prevState) => ({
                    ...prevState,
                    privileges: { ...prevState.privileges, [key]: DEFAULT_PRIVILEGES[key] }
                }))
            );
            return;
        }
        statePrivilegesKeys.map((key) =>
            setState((prevState) => ({ ...prevState, privileges: { ...prevState.privileges, [key]: [] } }))
        );
    };

    const handlePrivilegeChangeAll = (
        event: React.ChangeEvent<HTMLInputElement>,
        key: typeof PrivilegeTypeEnum[number]
    ) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            setState((prevState) => ({
                ...prevState,
                privileges: { ...prevState.privileges, [key]: DEFAULT_PRIVILEGES[key] }
            }));
            return;
        }
        setState((prevState) => ({ ...prevState, privileges: { ...prevState.privileges, [key]: [] } }));
    };

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        key: typeof PrivilegeTypeEnum[number],
        accessKey: typeof PrivilegeAccessTypeEnum[number]
    ) => {
        const isChecked = event.target.checked;
        const hasAccessKey = state.privileges[key].includes(accessKey);

        if (isChecked && !hasAccessKey) {
            setState((prevState) => ({
                ...prevState,
                privileges: { ...prevState.privileges, [key]: [...prevState.privileges[key], accessKey] }
            }));
            return;
        }
        setState((prevState) => ({
            ...prevState,
            privileges: {
                ...prevState.privileges,
                [key]: prevState.privileges[key].filter((paKey) => paKey !== accessKey)
            }
        }));
    };

    const statePrivilegeTruthyArray = statePrivilegesKeys.reduce((acc, key) => {
        const privilegeAccessList = state.privileges[key] || ([] as Array<typeof PrivilegeAccessTypeEnum[number]>);
        const hasCompletePrivilegeAccess = PrivilegeAccessTypeEnum.every((accessKey) =>
            privilegeAccessList.includes(accessKey)
        );
        return (acc = [...acc, hasCompletePrivilegeAccess]);
    }, [] as Array<boolean>);

    const isAllSelected = statePrivilegeTruthyArray.every((value) => value);
    const isSomeSelected = statePrivilegeTruthyArray.some((value) => value);

    return (
        <Grid item={true} xs={12} className={classes.privileges}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell size="small" align="left">
                            {(!readonly && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            indeterminate={!isAllSelected && isSomeSelected}
                                            checked={isAllSelected || isSomeSelected}
                                            onChange={handleChangeAll}
                                        />
                                    }
                                    label="ALL"
                                    classes={{ label: classes.checkboxLabel }}
                                />
                            )) ||
                                null}
                        </TableCell>

                        {PrivilegeAccessTypeEnum.map((key) => (
                            <TableCell size="small" align="center" key={key}>
                                {key}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {statePrivilegesKeys.map((key, index) => {
                        const privilegeAccessList = state.privileges[key] || [];
                        const isAllPrivilegeAccessSelected = PrivilegeAccessTypeEnum.every((accessKey) =>
                            privilegeAccessList.includes(accessKey)
                        );
                        const isSomePrivilegeAccessSelected = PrivilegeAccessTypeEnum.some((accessKey) =>
                            privilegeAccessList.includes(accessKey)
                        );

                        return (
                            <TableRow key={key} id={key}>
                                <TableCell size="small" align="left">
                                    {(readonly && <Typography className={classes.checkboxLabel}>{key}</Typography>) || (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    indeterminate={
                                                        !isAllPrivilegeAccessSelected && isSomePrivilegeAccessSelected
                                                    }
                                                    checked={
                                                        isSomePrivilegeAccessSelected || isSomePrivilegeAccessSelected
                                                    }
                                                    onChange={(e) => handlePrivilegeChangeAll(e, key)}
                                                />
                                            }
                                            label={key}
                                            classes={{ label: classes.checkboxLabel }}
                                        />
                                    )}
                                </TableCell>

                                {PrivilegeAccessTypeEnum.map((accessKey) => {
                                    const isSelected = privilegeAccessList.includes(accessKey);

                                    return (
                                        <TableCell
                                            size="small"
                                            align="center"
                                            key={`${key}_${accessKey}`}
                                            id={`${key}_${accessKey}`}
                                        >
                                            {readonly ? (
                                                (isSelected && <CheckBoxIcon color="disabled" />) || (
                                                    <RemoveIcon color="disabled" />
                                                )
                                            ) : (
                                                <Checkbox
                                                    checked={isSelected}
                                                    color="primary"
                                                    onChange={(e) => handleChange(e, key, accessKey)}
                                                />
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Grid>
    );
};

type Props = {
    renderPasswordSection?: boolean;
    renderReadonlyPrivilegesSection?: boolean;
    heading?: string;
    hideCancelButton?: boolean;
    user: AdminUser | null;
    onCancel?: () => void;
    onCreate?: (user: AdminUser) => void;
    onUpdate?: (user: AdminUser) => void;
};

type PasswordState = {
    isPasswordSaving: boolean;
    oldPassword: string | null;
    showOldPassword: boolean;
    newPassword: string | null;
    isNewPasswordValid: boolean;
    clearPasswordFields: boolean;
};

type State = PasswordState & {
    isSaving: boolean;
    isPhoneValid: null | boolean;
    user: AdminUser;
    emailError: string | null;
};

const DEFAULT_PASSWORD_STATE: PasswordState = {
    isPasswordSaving: false,
    oldPassword: null,
    showOldPassword: false,
    newPassword: null,
    isNewPasswordValid: false,
    clearPasswordFields: false
};

export const UserAddEditForm = (props: Props) => {
    const classes = useStyles();
    const apiService = useApiService();
    const toasterContext = useToasterData();
    const theme = useTheme();
    const isAboveSmWidth = useMediaQuery(theme.breakpoints.up('sm'));
    const isAboveLgWidth = useMediaQuery(theme.breakpoints.up('lg'));

    const undefinedFunction = () => null;

    const {
        onCancel = undefinedFunction,
        onCreate = undefinedFunction,
        onUpdate = undefinedFunction,
        renderPasswordSection
    } = props;

    const [state, setState] = useState<State>({
        isSaving: false,
        isPhoneValid: true,
        user: props.user || { privileges: DEFAULT_PRIVILEGES },
        emailError: null,
        ...DEFAULT_PASSWORD_STATE
    });

    const handlePhoneChange = (phone: string, country: string) => {
        const phoneLength = getRawPhoneNumber(phone, country)?.length;
        const isPhoneValid = Boolean(
            country && phone && phoneLength && phoneLength === COUNTRY_CODES[country].phoneLength
        );
        setState((prevState) => ({
            ...prevState,
            isPhoneValid: phoneLength ? isPhoneValid : null,
            user: { ...prevState.user, email: state.user?.email || null, phone, country }
        }));
    };

    const createUser = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const isEmailValid = validateEmail(state.user?.email);
        if (!isEmailValid) {
            setState((prevState) => ({ ...prevState, emailError: 'Please enter valid Email.' }));
            return;
        }

        setState((prevState) => ({ ...prevState, isSaving: true, emailError: null }));

        try {
            const apiResponse = await apiService.post(`/api/admin/user/create`, {
                user: {
                    ...state.user,
                    phone: getRawPhoneNumber(state.user.phone, state.user.country || undefined, false) || null
                }
            });
            const response = apiResponse.parsedBody;

            setState((prevState) => ({ ...prevState, isSaving: false }));

            if (!response || !response.status) {
                toasterContext.setToaster({
                    isOpen: true,
                    message: response.message,
                    severity: 'error'
                });

                setState((prevState) => ({
                    ...prevState,
                    emailError: (response?.data?.emailError && response?.message) || null
                }));
                return;
            }

            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'success'
            });

            onCreate(response?.data?.user);
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
            setState((prevState) => ({ ...prevState, isSaving: false }));
        }
    };

    const updateUser = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (props.user?.email !== state.user?.email) {
            toasterContext.setToaster({
                isOpen: true,
                message: `Email has been deliberately changed!`,
                severity: 'error'
            });
            return;
        }

        setState((prevState) => ({ ...prevState, isSaving: true }));

        try {
            const apiResponse = await apiService.post(`/api/admin/user/update`, {
                user: {
                    ...state.user,
                    phone: getRawPhoneNumber(state.user.phone, state.user.country || undefined, false) || null
                }
            });

            const response = apiResponse.parsedBody;

            setState((prevState) => ({ ...prevState, isSaving: false }));

            if (!response || !response.status) {
                toasterContext.setToaster({
                    isOpen: true,
                    message: response.message,
                    severity: 'error'
                });
                return;
            }

            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'success'
            });

            onUpdate(response?.data?.user);
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
            setState((prevState) => ({ ...prevState, isSaving: false }));
        }
    };

    const changePassword = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setState({ ...state, isPasswordSaving: true });

        const requestBody = {
            oldPassword: state.oldPassword,
            newPassword: state.newPassword
        };

        try {
            const apiResponse = await apiService.post('/api/admin/user/change-password', requestBody);

            const response = apiResponse.parsedBody;
            setState({ ...state, ...DEFAULT_PASSWORD_STATE, clearPasswordFields: true });

            if (!response.status) {
                toasterContext.setToaster({
                    isOpen: true,
                    message: response.message,
                    severity: 'error'
                });
                return;
            }

            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'success'
            });
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
            setState({ ...state, ...DEFAULT_PASSWORD_STATE, clearPasswordFields: true });
        }
    };

    const footer = (
        <Grid item={true} xs={12} className={classes.footer}>
            {(!props.hideCancelButton && (
                <Button
                    variant="contained"
                    size="small"
                    onClick={(e) => {
                        onCancel();
                        setState({ ...state, user: { privileges: DEFAULT_PRIVILEGES } });
                    }}
                    disabled={state.isSaving}
                >
                    Cancel
                </Button>
            )) ||
                null}
            <Button type="submit" variant="contained" color="primary" size="small" disabled={state.isSaving}>
                {(props.user && 'update') || 'save'}
                {state.isSaving && <CircularProgress className="button-loader" />}
            </Button>
        </Grid>
    );

    const privileges = (
        <Grid
            container={true}
            className={
                (props.renderReadonlyPrivilegesSection && clsx(classes.form, classes.privilegeContainer)) || undefined
            }
        >
            {(!props.renderReadonlyPrivilegesSection && <Divider className={classes.divider} />) || null}
            <Typography variant="h2" className={classes.heading}>
                Privileges
            </Typography>
            <Privileges
                readonly={props.renderReadonlyPrivilegesSection}
                privileges={state.user?.privileges}
                onChange={(privileges) =>
                    setState((prevState) => ({ ...prevState, user: { ...prevState.user, privileges } }))
                }
            />
        </Grid>
    );

    const isPasswordButtonDisabled = !state.oldPassword || !state.newPassword || !state.isNewPasswordValid;

    return (
        <Container className={classes.mainContainer}>
            <Grid container={true} className={classes.inner}>
                <Grid item={true} xs={12} className={classes.form}>
                    <Typography className={classes.heading}>
                        {props.heading || (props.user && 'Update User') || 'Create New User'}{' '}
                    </Typography>

                    <form noValidate autoComplete="off" onSubmit={(e) => (props.user ? updateUser(e) : createUser(e))}>
                        <Grid container={true} spacing={(isAboveSmWidth && 4) || 0}>
                            <Grid item={true} xs={12} sm={6}>
                                <AppTextField
                                    className={classes.textField}
                                    required={true}
                                    variant="filled"
                                    size="small"
                                    label="Email"
                                    fullWidth={true}
                                    error={!!state.emailError}
                                    helperText={state.emailError || ''}
                                    value={state.user?.email || ''}
                                    onChange={(e) =>
                                        setState({
                                            ...state,
                                            emailError: null,
                                            user: { ...state.user, email: e.target.value }
                                        })
                                    }
                                    InputProps={{ readOnly: !!props.user, disabled: !!props.user }}
                                />
                            </Grid>

                            <Grid item={true} xs={12} sm={6}>
                                <PhoneNumberTextField
                                    className={classes.textField}
                                    country={state.user?.country || null}
                                    phone={state.user?.phone || null}
                                    isPhoneInvalid={!state.isPhoneValid}
                                    onChange={handlePhoneChange}
                                    size="small"
                                />
                            </Grid>
                        </Grid>

                        <Grid container={true} spacing={(isAboveSmWidth && 4) || 0}>
                            <Grid item={true} xs={12} sm={6}>
                                <AppTextField
                                    className={classes.textField}
                                    variant="filled"
                                    size="small"
                                    label="First Name"
                                    fullWidth={true}
                                    value={state.user?.firstName || ''}
                                    onChange={(e) =>
                                        setState({ ...state, user: { ...state.user, firstName: e.target.value } })
                                    }
                                />
                            </Grid>

                            <Grid item={true} xs={12} sm={6}>
                                <AppTextField
                                    className={classes.textField}
                                    variant="filled"
                                    size="small"
                                    label="Last Name"
                                    fullWidth={true}
                                    value={state.user?.lastName || ''}
                                    onChange={(e) =>
                                        setState({ ...state, user: { ...state.user, lastName: e.target.value } })
                                    }
                                />
                            </Grid>
                        </Grid>

                        {!props.renderReadonlyPrivilegesSection && privileges}

                        {footer}
                    </form>
                </Grid>
                {props.renderReadonlyPrivilegesSection && privileges}
            </Grid>

            {renderPasswordSection && props.user && (
                <Grid item={true} xs={12} className={clsx(classes.form, classes.passwordContainer)}>
                    <form noValidate autoComplete="off" onSubmit={changePassword}>
                        <Grid item={true} xs={12} className={classes.passowrdSection}>
                            <Typography variant="h2" className={classes.heading}>
                                Change Password
                            </Typography>

                            <Grid container={true} spacing={(isAboveSmWidth && !isAboveLgWidth && 4) || 0}>
                                <Grid item={true} xs={12} sm={6} lg={12}>
                                    <AppTextField
                                        size="small"
                                        label="Previous Password"
                                        autoComplete="new-password"
                                        className={classes.textField}
                                        variant="filled"
                                        fullWidth={true}
                                        value={state.oldPassword || ''}
                                        type={(state.showOldPassword && 'text') || 'password'}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                oldPassword: e.target.value,
                                                clearPasswordFields: false
                                            })
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {state.showOldPassword ? (
                                                        <VisibilityIcon
                                                            className={classes.visibilityIcon}
                                                            onClick={() =>
                                                                setState({ ...state, showOldPassword: false })
                                                            }
                                                        />
                                                    ) : (
                                                        <VisibilityOffIcon
                                                            className={classes.visibilityIcon}
                                                            onClick={() =>
                                                                setState({ ...state, showOldPassword: true })
                                                            }
                                                        />
                                                    )}
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>

                                <Divider className={classes.divider} />

                                <Grid item={true} xs={12} sm={6} lg={12}>
                                    <Password
                                        size="small"
                                        clearPasswordFields={state.clearPasswordFields}
                                        className={classes.textField}
                                        onPasswordChange={({
                                            password,
                                            isPasswordValid
                                        }: {
                                            password: string;
                                            isPasswordValid: boolean;
                                        }) =>
                                            setState({
                                                ...state,
                                                newPassword: password,
                                                isNewPasswordValid: isPasswordValid,
                                                clearPasswordFields: false
                                            })
                                        }
                                    />
                                </Grid>
                            </Grid>

                            <Grid item={true} xs={12} className={classes.footer}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    type="submit"
                                    disabled={state.isPasswordSaving || isPasswordButtonDisabled}
                                >
                                    Change Password
                                    {state.isPasswordSaving && <CircularProgress className="button-loader" />}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            )}
        </Container>
    );
};

const MyAccount = () => {
    const userService = useUserService();
    const { appAction } = storeContext();

    useEffect(() => {
        appAction()?.renderFullHeader();
    }, []);

    if (!userService.user) {
        return <Redirect to="/login" />;
    }

    return (
        <UserAddEditForm
            user={userService.user as AdminUser}
            heading="My Account"
            renderReadonlyPrivilegesSection={true}
            renderPasswordSection={true}
            hideCancelButton={true}
        />
    );
};

export default MyAccount;
