import React from 'react';
import { JsonEditor } from 'json-edit-react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { OrderAttributes } from '../../../types';
import { fraudDataStyles } from '../../manage-black-listed-emails/fraud-details';

type Props = {
    order: OrderAttributes;
};

export const OrderDetails = (props: Props) => {
    const classes = fraudDataStyles();

    // eslint-disable-next-line @typescript-eslint/ban-types
    const requestPayload = props.order.fraudRequestPayload as object;
    // eslint-disable-next-line @typescript-eslint/ban-types
    const response = props.order.fraudResponse as object;

    return (
        <Container className={classes.mainContainer}>
            <Grid container={true} className={classes.inner}>
                <Grid item={true} xs={12} className={classes.form}>
                    <Grid container={true} alignItems="center" justifyContent="space-between">
                        <Typography className={classes.heading}>Fraud Details:</Typography>
                    </Grid>

                    <Grid item={true} xs={12} className={classes.details}>
                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Order Id:
                            </Typography>
                            <Typography component="span">#{props.order.orderId}</Typography>
                        </Grid>

                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Request:
                            </Typography>
                            <JsonEditor
                                data={requestPayload}
                                collapse
                                restrictAdd
                                restrictDelete
                                restrictEdit
                                restrictTypeSelection
                            />
                        </Grid>

                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Response:
                            </Typography>
                            <JsonEditor
                                data={response}
                                collapse
                                restrictAdd
                                restrictDelete
                                restrictEdit
                                restrictTypeSelection
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default OrderDetails;
