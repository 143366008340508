import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import Avatar, { AvatarProps } from '@material-ui/core/Avatar';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

import { generateNameInitials } from '../../utilities';
import { UserAccount, AdminUser, UserRoleEnum, AdminUserRoleEnum, AccountInactiveReasonEnum, AdminAccountInactiveReasonEnum } from '../../types';
import { getBase64Url } from '../../utilities/photo.util';

export type CombinedUserType = Omit<UserAccount, 'inActiveReason' | 'role'> &
    Omit<AdminUser, 'inActiveReason' | 'role'> & {
        role?: typeof UserRoleEnum[number] | typeof AdminUserRoleEnum[number] | null;
        inActiveReason?:
        | typeof AccountInactiveReasonEnum[number]
        | typeof AdminAccountInactiveReasonEnum[number]
        | null;
    };

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        textTransform: 'uppercase !important' as 'uppercase'
    }
}));

type State = {
    photo: string | null;
    isLoading: boolean;
}

type Props = AvatarProps & {
    user: Omit<CombinedUserType, 'privileges'> | null;
};

const UserAvatar = (props: Props) => {
    const classes = useStyles();
    const { className, ...others } = props;

    const [state, setState] = useState<State>({
        photo: null,
        isLoading: true
    });

    useEffect(() => { setPhoto(); }, [props.user?.photo]);

    const setPhoto = async () => {
        if (state.isLoading && props.user && !props.user?.photo) {
            setState(prevState => ({ ...prevState, isLoading: false, photo: null }));
        }

        if (state.isLoading && props.user && props.user?.photo) {
            const photo = await getBase64Url(props.user);
            setState(prevState => ({ ...prevState, isLoading: false, photo }));
        }
    }

    const nameInitials = generateNameInitials(props.user) || undefined;

    if (!props.user) {
        return null;
    }

    return (
        <Avatar
            src={!state.isLoading && state.photo || undefined}
            alt={nameInitials}
            className={clsx(classes.root, className)}
            onLoad={() => setState(prevState => ({ ...prevState, isLoading: false }))}
            {...others}
        >
            {(state.isLoading && <CircularProgress className="button-loader" />) ||
                nameInitials}
        </Avatar>
    )
};

export default UserAvatar;
