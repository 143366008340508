import React from 'react';
import { UserAccount } from '../../../types';
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Switch,
    Typography
} from '@material-ui/core';
import { B2BGiftCardAttributes } from '../../../types/b2bGiftCard';
import storeContext from '../../../contexts/store-context';
import useAdminStyles from '../../styles';

const useStyles = makeStyles(() => ({
    filter: {
        width: 200,
        '& .MuiSelect-root': {
            textAlign: 'left'
        }
    }
}));

type Props = {
    merchants: UserAccount[];
    b2bGiftCard?: B2BGiftCardAttributes | null;
    activeMerchantUserId: number | null;
    displayOnB2cBuyCardsPage: boolean;
    displayOnB2bBuyCardsPage: boolean;
    isSaving: boolean;
    onChangeMerchant: (merchantUserId: number) => void;
    onChangeDisplayOnB2cBuyCardsPage: (display: boolean) => void;
    onChangeDisplayOnB2bBuyCardsPage: (display: boolean) => void;
    onCancel: () => void;
    onSubmit: (giftCardId: number) => void;
};

const SaveMerchantCardsToBuyCardsForm = (props: Props) => {
    const classes = useStyles();
    const adminClasses = useAdminStyles();

    const { b2bGiftCardAction } = storeContext();

    const [giftCards, setGiftCards] = React.useState<B2BGiftCardAttributes[]>([]);
    const [isLoading, setLoading] = React.useState(false);
    const [giftCardId, setGiftCardId] = React.useState<number | null>(null);

    const activeGiftCard = giftCards.find((gc) => gc.id === giftCardId);

    const fetchMerchantGiftCards = async (activeMerchantUserId: number | null) => {
        if (!activeMerchantUserId) {
            setGiftCards([]);
            return;
        }
        setLoading(true);
        const giftCards = await b2bGiftCardAction()?.getAllGiftCardsByMerchantId(activeMerchantUserId);
        setGiftCards(giftCards ? giftCards : []);
        setLoading(false);
    };

    const onSubmit = () => {
        if (giftCardId) {
            props.onSubmit(giftCardId);
        }
    };

    React.useEffect(() => {
        fetchMerchantGiftCards(props.activeMerchantUserId);
    }, [props.activeMerchantUserId]);

    React.useEffect(() => {
        setGiftCardId(props.b2bGiftCard?.id || null);
    }, [props.b2bGiftCard?.id]);

    return (
        <Paper className={adminClasses.form} id="save-merchant-cards-form">
            <Typography className={adminClasses.heading}>{props.b2bGiftCard ? 'Update' : 'Add'} Entry</Typography>

            <Grid container>
                <Grid item xs={12} sm={3}>
                    <FormControl variant="outlined" fullWidth className={classes.filter}>
                        <InputLabel id="merchant-filter-label">Select Merchant:</InputLabel>
                        <Select
                            labelId="select-merchant-filter"
                            value={props.activeMerchantUserId}
                            label="Select Merchant"
                            onChange={(e) => {
                                const userId = e.target.value as number;
                                props.onChangeMerchant(userId);
                            }}
                        >
                            {props.merchants.map((merchant) => (
                                <MenuItem key={merchant.userId} value={merchant.userId!}>
                                    {merchant.companyName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={3}>
                    <FormControl variant="outlined" fullWidth className={classes.filter}>
                        <InputLabel id="gift-card-filter-label">Select Gift Card:</InputLabel>
                        <Select
                            labelId="select-gift-card-filter"
                            value={giftCardId || ''}
                            label="Select Gift Card"
                            onChange={(e) => {
                                const giftCardId = e.target.value as number;
                                setGiftCardId(giftCardId);
                            }}
                        >
                            {giftCards.map((gc) => (
                                <MenuItem key={gc.id} value={gc.id!}>
                                    {gc.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                {(!activeGiftCard || activeGiftCard?.currencyCode === 'USD') && (
                    <Grid item xs={12} sm={3}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={props.displayOnB2cBuyCardsPage}
                                    onChange={(e, checked) => props.onChangeDisplayOnB2cBuyCardsPage(checked)}
                                />
                            }
                            label="Display on B2C Buy Cards Page"
                        />
                    </Grid>
                )}

                <Grid item xs={12} sm={3}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={props.displayOnB2bBuyCardsPage}
                                onChange={(e, checked) => props.onChangeDisplayOnB2bBuyCardsPage(checked)}
                            />
                        }
                        label="Display on B2B Buy Cards Page"
                    />
                </Grid>
            </Grid>

            <Box textAlign="right" mt={2}>
                <Button type="button" disabled={props.isSaving} onClick={props.onCancel}>
                    Cancel
                </Button>
                <Button type="button" variant="contained" color="primary" disabled={props.isSaving} onClick={onSubmit}>
                    Save
                    {props.isSaving && <CircularProgress className="button-loader" />}
                </Button>
            </Box>
        </Paper>
    );
};

export default SaveMerchantCardsToBuyCardsForm;
