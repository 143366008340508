import React, { createContext, useContext, useState } from 'react';
import { AdminUser } from '../types';

interface State {
    user: AdminUser | null;
}

export type UserContextProps = State & {
    setUser: (user: AdminUser | null) => void;
};

type Props = { children?: React.ReactNode };
export const UserContext = createContext<UserContextProps | undefined>(undefined);
export const UserProvider: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
    const [state, setState] = useState<State>({ user: null });
    const setUser = (user?: AdminUser | null) => setState({ ...state, user: user || null });
    return <UserContext.Provider value={{ user: state.user, setUser }}>{props.children}</UserContext.Provider>;
};

export const useUserService = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUserService must be used with a UserProvider');
    }
    return context;
};
