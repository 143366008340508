import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import AddIcon from '@material-ui/icons/Add';

import { GetTheme, ThemeCreateUpdateRequest } from '../../types';

import TableComponent, { HeadCell, TableResultRow } from '../common/table-component';

import useAdminStyles from '../styles';
import storeContext from '../../contexts/store-context';
import Switch from '@material-ui/core/Switch';

import moment from 'moment';
import { joinNameParts, scrollTop } from '../../utilities';
import ConfirmationDialog from '../common/confirmation-dialog';
import HasPermission from '../../utilities/can';
import AddEditForm, { ThemeFormState } from './form';
import StatusButton from '../common/status-button';
import { useUserService } from '../../contexts/user-context';

type State = {
    isLoading: boolean;
    isDeleting: boolean;
    isSaving: boolean;
    renderAddEditForm: boolean;
    menuAnchorEle: HTMLElement | null;
    activeId?: number | null;
    themes: GetTheme[];
    selectedRows: TableResultRow[];
    isConfirmationDialogOpen: boolean;
};

const cssToColorObj = (cssValue: string): ThemeFormState['bgColor'] => {
    const defaultObj = [
        { offset: '0.00', color: '#000', opacity: 1 },
        { offset: '1.00', color: '#000', opacity: 1 }
    ];
    const matchedObj = cssValue.match(/rgba\(([^)]+)\)/g);
    if (!matchedObj) {
        return defaultObj;
    }

    return matchedObj.map((rgba, i) => {
        const [r, g, b, a] = rgba?.match(/\d+/g) || [];

        return {
            offset: `${i}.00`,
            color: `rgb(${r}, ${g}, ${b})`,
            opacity: +a
        };
    });
};

const rgbToRgba = (rgb: string, a = 1) => rgb.replace('rgb(', 'rgba(').replace(')', `, ${a})`);

export const colorObjToCss = (obj: ThemeFormState['bgColor']): string => {
    return `linear-gradient(90deg, ${rgbToRgba(obj[0].color, obj[0].opacity)} 0%, ${rgbToRgba(
        obj[1].color,
        obj[1].opacity
    )} 100%)`;
};

const ThemeComponent = () => {
    const adminClasses = useAdminStyles();
    const history = useHistory();
    const { themeAction, appAction } = storeContext();

    const userService = useUserService();
    const canReadTheme = HasPermission(userService.user, 'MANAGE_THEME', 'READ');
    const canCreateTheme = HasPermission(userService.user, 'MANAGE_THEME', 'CREATE');
    const canUpdateTheme = HasPermission(userService.user, 'MANAGE_THEME', 'UPDATE');

    useEffect(() => {
        if (!canReadTheme) {
            history.push('/dashboard');
            return;
        }
        appAction()?.renderFullHeader();
        getAllthemes();
    }, []);

    const [state, setState] = useState<State>({
        isLoading: false,
        isDeleting: false,
        isSaving: false,
        renderAddEditForm: false,
        menuAnchorEle: null,
        activeId: null,
        themes: [],
        selectedRows: [],
        isConfirmationDialogOpen: false
    });

    const activeTheme = state.themes.find((p) => p.id === state.activeId);

    const getAllthemes = async () => {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        const themes = await themeAction()?.getAllThemes();
        setState((prevState) => ({ ...prevState, themes: themes || [], isLoading: false }));
    };

    const onIsActiveChange = async (isActive: boolean, id: number) => {
        const activeTheme = state.themes.find((p) => p.id === id);
        if (!activeTheme) {
            return;
        }
        const savedthemes = await themeAction()?.updateTheme({
            ...activeTheme,
            isActive
        });
        setState((prevState) => {
            return { ...prevState, themes: savedthemes || prevState.themes };
        });
    };

    const headCells: Array<HeadCell> = [
        { id: 'type', label: 'Type' },
        { id: 'isActive', label: 'Is Active' },
        { id: 'options', label: '' }
    ];

    const tableRows: Array<TableResultRow> = state.themes.map((theme) => {
        return {
            id: {
                text: theme.id?.toString() || ''
            },
            type: {
                align: 'left',
                text: theme.type || '-'
            },
            isActive: {
                align: 'left',
                text: '',
                element: canUpdateTheme ? (
                    <Switch
                        checked={theme.isActive}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onIsActiveChange(!theme.isActive, theme.id);
                        }}
                        color="primary"
                    />
                ) : (
                    <StatusButton
                        options={[
                            { type: 'ACTIVE', text: 'Active', color: 'active' },
                            { type: 'INACTIVE', text: 'Inactive', color: 'warning' }
                        ]}
                        type={theme.isActive ? 'ACTIVE' : 'INACTIVE'}
                    />
                )
            },
            options: {
                align: 'right',
                text: '',
                helperText:
                    (theme.user &&
                        `updated by - ${theme.user?.firstName ? joinNameParts(theme.user) : theme.user?.email || ''}${
                            theme?.updatedAt && ' - '
                        }${theme?.updatedAt && moment(new Date(theme?.updatedAt)).fromNow()}`) ||
                    undefined,
                element:
                    (canUpdateTheme && (
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setState((prevState) => ({
                                    ...prevState,
                                    menuAnchorEle: e.currentTarget,
                                    activeId: theme.id
                                }));
                            }}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    )) ||
                    undefined
            }
        };
    });

    const headerComponent = (
        <Grid item={true} xs={12} className={adminClasses.headerComponent}>
            <Button
                onClick={() => setState((prevState) => ({ ...prevState, renderAddEditForm: true, activeId: null }))}
                size="small"
                color="primary"
                variant="contained"
            >
                <AddIcon />
                &nbsp;Create New Theme
            </Button>
        </Grid>
    );

    const handleMenuClose = () => {
        setState((prevState) => ({
            ...prevState,
            menuAnchorEle: null,
            activeId: null
        }));
    };

    const savetheme = async (theme: ThemeCreateUpdateRequest) => {
        setState((prevState) => ({ ...prevState, ...theme, activeId: null, isSaving: true }));
        const newthemes = await (theme.id ? themeAction()?.updateTheme(theme) : themeAction()?.createTheme(theme));

        setState((prevState) => {
            return {
                ...prevState,
                activeId: theme.id,
                isSaving: false,
                themes: newthemes || prevState.themes,
                renderAddEditForm: false
            };
        });
    };

    const openFormForEdit = (id: number) => {
        if (!canUpdateTheme) {
            return;
        }

        setState((prevState) => ({
            ...prevState,
            renderAddEditForm: true,
            activeId: id,
            menuAnchorEle: null
        }));
        setTimeout(() => scrollTop());
    };

    const onDeleteConfirm = async () => {
        const ids: number[] = [];

        if (activeTheme?.id) {
            ids.push(activeTheme.id);
        } else if (state.selectedRows) {
            const idsToDelete = state.selectedRows.map((row) => +row.id.text);
            Array.prototype.push.apply(ids, idsToDelete);
        }

        setState((prevState) => ({ ...prevState, isDeleting: true }));
        const themesToDelete = state.themes.filter((p) => ids.includes(p.id)).map((p) => ({ ...p, isDeleted: true }));
        await themeAction()?.updateAllThemes(themesToDelete);
        setState((prevState) => {
            const newthemes = prevState.themes.filter((p) => !ids.includes(p.id));
            return {
                ...prevState,
                themes: newthemes,
                isDeleting: false,
                isConfirmationDialogOpen: false,
                menuAnchorEle: null
            };
        });
    };

    return (
        <Grid item={true} xs={12} className={adminClasses.root}>
            <Container className={adminClasses.container}>
                {state.renderAddEditForm && (
                    <AddEditForm
                        isSaving={state.isSaving}
                        theme={
                            activeTheme ? { ...activeTheme, bgColor: cssToColorObj(activeTheme.bgColor) } : undefined
                        }
                        onCancel={() => setState((prevState) => ({ ...prevState, renderAddEditForm: false }))}
                        onSave={(theme) => {
                            savetheme({
                                ...theme,
                                bgColor: colorObjToCss(theme.bgColor)
                            });
                        }}
                    />
                )}

                <Menu anchorEl={state.menuAnchorEle} open={!!state.menuAnchorEle} onClose={handleMenuClose}>
                    <MenuItem onClick={() => state.activeId && openFormForEdit(state.activeId)}>Edit</MenuItem>
                    <MenuItem
                        onClick={() => setState((prevState) => ({ ...prevState, isConfirmationDialogOpen: true }))}
                    >
                        Delete
                    </MenuItem>
                </Menu>

                <TableComponent
                    headerComponent={(!state.renderAddEditForm && canCreateTheme && headerComponent) || undefined}
                    showPaginator={{ bottom: true }}
                    showCheckbox={true}
                    showSearch={true}
                    isLoading={state.isLoading}
                    rows={tableRows}
                    headCells={headCells}
                    selectedRows={state.selectedRows}
                    onDelete={() => setState((prevState) => ({ ...prevState, isConfirmationDialogOpen: true }))}
                    onCheckboxSelect={(selectedRows) => setState((prevState) => ({ ...prevState, selectedRows }))}
                    keyField="id"
                    rowTooltip={(canUpdateTheme && 'Click to edit') || undefined}
                    onRowClick={(id) => openFormForEdit(+id)}
                    fillEmptyRows={false}
                />
            </Container>
            <ConfirmationDialog
                header={'Are you sure?'}
                subHeader={'Click CONFIRM to delete the theme'}
                isLoading={state.isDeleting}
                open={state.isConfirmationDialogOpen}
                onClose={() =>
                    setState({
                        ...state,
                        isConfirmationDialogOpen: false,
                        activeId: null,
                        menuAnchorEle: null
                    })
                }
                onConfirm={onDeleteConfirm}
            />
        </Grid>
    );
};

export default ThemeComponent;
