import { GiftCardPaginator, GiftCardProduct } from '../types';

export interface GiftCardState {
    cards: Array<GiftCardProduct>;
    paginator: GiftCardPaginator;
    activeCardId: string | null;
    activeValueId: number | null;
    isLoading: boolean;
}

export const initialState: GiftCardState = {
    cards: [],
    paginator: {
        page: 1,
        pageSize: 24,
        totalCount: 0
    },
    activeCardId: null,
    activeValueId: null,
    isLoading: false
};

export const giftCardReducer = (state: GiftCardState, action: GiftCardActionType) => {
    switch (action.type) {
        case 'SET_GIFT_CARDS':
            return { ...state, cards: action.cards, paginator: action.paginator || initialState.paginator };
        case 'SET_GIFT_CARD_LOADING':
            return { ...state, isLoading: action.isLoading };
        default:
            return state;
    }
};

type SetGiftCards = {
    type: 'SET_GIFT_CARDS';
    cards: Array<GiftCardProduct>;
    paginator: GiftCardPaginator | null;
};

type SetLoading = {
    type: 'SET_GIFT_CARD_LOADING';
    isLoading: boolean;
};

export type GiftCardActionType = SetGiftCards | SetLoading;
