import React from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Card, Checkbox, Divider, makeStyles, Typography } from '@material-ui/core';
import { GetInfluencerReferralPaymentAttributes, GetPendingRealizedReferrals } from '../../types';
import { influencerReferralListUseStyles } from '../manage-influencer/referral-list.styles';
import { formatDinero, generateDinero, getDinero } from '../../utilities';
import moment from 'moment';
import { PayoutTransactionAttributes } from '../../types/influencerPayout';

const useStyles = makeStyles((theme) => ({
    registrationDivider: {
        backgroundColor: theme.palette.error.dark
    }
}));

type Props = {
    pendingReferralsTransactions: GetPendingRealizedReferrals[];
    pendingReferralsRegistrations: GetPendingRealizedReferrals[];
    activePaymentConstant?: GetInfluencerReferralPaymentAttributes | null;
    referralsSelectedForRegistrationPayment: Array<GetPendingRealizedReferrals>;
    referralsSelectedForTransactionPayment: Array<GetPendingRealizedReferrals>;
    paypalPayoutFee: number;
    onCheckboxChange: (
        referral: GetPendingRealizedReferrals,
        reason: PayoutTransactionAttributes['paymentReason'],
        isChecked: boolean
    ) => void;
};

export const getAmountForTransaction = (
    paymentConstant?: GetInfluencerReferralPaymentAttributes | null,
    orderTotal = 0
) => {
    let amount = 0;

    if (paymentConstant?.influencerTransactionType && paymentConstant?.influencerTransactionValue) {
        amount =
            paymentConstant.influencerTransactionType === 'AMOUNT'
                ? paymentConstant?.influencerTransactionValue
                : ((orderTotal / 100) * paymentConstant.influencerTransactionValue) / 100;
    }

    return amount;
};

export const getAmountForRegistration = (
    paymentConstant?: GetInfluencerReferralPaymentAttributes | null,
    noOfReferrals = 0
) => {
    const amountReceivedForNoOfRegistration =
        ((paymentConstant?.amount || 0) * noOfReferrals) / (paymentConstant?.noOfRegistration || 0);

    return amountReceivedForNoOfRegistration;
};

const PayoutDetails = (props: Props) => {
    const referralClasses = influencerReferralListUseStyles();
    const classes = useStyles();

    const amountReceivedForNoOfRegistration = getAmountForRegistration(
        props.activePaymentConstant,
        props.referralsSelectedForRegistrationPayment.length
    );

    const totalAmountReceivedForTransactions = props.pendingReferralsTransactions
        .filter((r) => props.referralsSelectedForTransactionPayment.find((rp) => rp.userId === r.userId))
        .reduce((acc, cur) => acc + getAmountForTransaction(props.activePaymentConstant, cur.orders[0]?.baseTotal), 0);

    const total = amountReceivedForNoOfRegistration + totalAmountReceivedForTransactions;
    const grandTotal = total - props.paypalPayoutFee;

    const onFirstTransactionCheckboxChange = (referral: GetPendingRealizedReferrals, isChecked: boolean) => {
        props.onCheckboxChange(referral, 'SUCCESSFUL_TRANSACTION', isChecked);
    };

    const onRegistrationCheckboxChange = (referral: GetPendingRealizedReferrals, isChecked: boolean) => {
        props.onCheckboxChange(referral, 'SUCCESSFUL_REGISTRATION', isChecked);
    };

    const isEndOfTheGroup = (index: number) => {
        const noOfRegistrations = props.activePaymentConstant?.noOfRegistration || 0;

        return (
            props.activePaymentConstant?.noOfRegistration === 1 || index % noOfRegistrations === noOfRegistrations - 1
        );
    };

    const isPayoutForRegistrationsValid =
        props.activePaymentConstant?.noOfRegistration === 1 ||
        (props.activePaymentConstant?.noOfRegistration &&
            props.pendingReferralsRegistrations.length % props.activePaymentConstant?.noOfRegistration === 0) ||
        !props.activePaymentConstant?.noOfRegistration;

    const payoutGroupsCount =
        (props.pendingReferralsRegistrations.length || 0) / (props.activePaymentConstant?.noOfRegistration || 1);
    const payoutGroups =
        !props.activePaymentConstant?.noOfRegistration || props.activePaymentConstant?.noOfRegistration === 1
            ? props.pendingReferralsRegistrations.length
            : Number.isInteger(payoutGroupsCount)
            ? payoutGroupsCount
            : Math.floor(payoutGroupsCount);
    const payoutMaxIndex = payoutGroups * (props.activePaymentConstant?.noOfRegistration || 1);

    return (
        <Box marginY={2}>
            <Grid item xs={12}>
                <Typography align="left">1. Referrals who completed successful first transaction:</Typography>
                <br />
                {props.pendingReferralsTransactions.map((referral) => {
                    const isChecked = !!props.referralsSelectedForTransactionPayment.find(
                        (r) => r.userId === referral.userId
                    );
                    const total = referral.orders[0]?.total || referral.orders[0]?.baseTotal || 0;

                    return (
                        <Card key={referral.userId} className={referralClasses.cardRow}>
                            <Grid
                                container
                                className={referralClasses.cardRowGrid}
                                justifyContent="space-between"
                                spacing={4}
                            >
                                <Grid item xs={6} sm={'auto'}>
                                    <Checkbox
                                        checked={isChecked}
                                        onChange={(e, checked) => onFirstTransactionCheckboxChange(referral, checked)}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={'auto'}>
                                    <Typography component="h6">EMAIL</Typography>
                                    <Typography>{referral.email}</Typography>
                                </Grid>
                                <Grid item xs={6} sm={'auto'} className={referralClasses.cardRowGridPullRight}>
                                    <Typography component="h6">USER ID</Typography>
                                    <Typography>{referral.userId}</Typography>
                                </Grid>
                                <Grid item xs={5} sm={'auto'}>
                                    <Typography component="h6">AMOUNT TO PAY</Typography>
                                    <Typography>
                                        {formatDinero(
                                            getDinero(
                                                getAmountForTransaction(
                                                    props.activePaymentConstant,
                                                    total
                                                )
                                            )
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} sm={'auto'}>
                                    <Typography component="h6">ORDER TOTAL</Typography>
                                    <Typography>{formatDinero(generateDinero(total))}</Typography>
                                </Grid>
                                <Grid item xs={5} sm={'auto'}>
                                    <Typography component="h6">REGISTERED DATE</Typography>
                                    <Typography>{moment(referral.createdAt).format('MM/DD/YYYY')}</Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    );
                })}
                {
                    <Typography align="left">
                        &nbsp;= {formatDinero(getDinero(totalAmountReceivedForTransactions))}
                    </Typography>
                }
            </Grid>

            <Grid item xs={12}>
                <br />
                <Typography align="left">
                    2. List of registrations:
                    <br />
                </Typography>
                {props.pendingReferralsRegistrations.length > 0 && (
                    <>
                        <br />
                        {props.pendingReferralsRegistrations.map((referral, i) => {
                            const isChecked = !!props.referralsSelectedForRegistrationPayment.find(
                                (r) => r.userId === referral.userId
                            );

                            return (
                                <Box key={referral.userId} display="flex" flexWrap="wrap">
                                    <Grid item xs={6} sm={1} className={referralClasses.cardRow}>
                                        <Checkbox
                                            disabled={i >= payoutMaxIndex}
                                            checked={isChecked}
                                            onChange={(e, checked) => onRegistrationCheckboxChange(referral, checked)}
                                        />
                                    </Grid>

                                    <Card className={referralClasses.cardRow}>
                                        <Grid
                                            container
                                            className={referralClasses.cardRowGrid}
                                            justifyContent="space-between"
                                            spacing={4}
                                        >
                                            <Grid item xs={6} sm={'auto'}>
                                                <Typography component="h6">EMAIL</Typography>
                                                <Typography>{referral.email}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={'auto'}>
                                                <Typography component="h6">USER ID</Typography>
                                                <Typography>{referral.userId}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={'auto'}>
                                                <Typography component="h6">AMOUNT</Typography>
                                                <Typography>
                                                    {formatDinero(
                                                        getDinero(
                                                            (props.activePaymentConstant?.amount || 0) /
                                                                (props.activePaymentConstant?.noOfRegistration || 0)
                                                        )
                                                    )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Card>

                                    {isEndOfTheGroup(i) && (
                                        <Box width="100%" paddingY={4}>
                                            <Divider className={classes.registrationDivider} />
                                        </Box>
                                    )}
                                </Box>
                            );
                        })}
                        <Typography align="left">
                            &nbsp;${props.activePaymentConstant?.amount} per{' '}
                            {props.activePaymentConstant?.noOfRegistration || 0} registration(s)
                            <br />= {formatDinero(getDinero(amountReceivedForNoOfRegistration))}
                        </Typography>
                    </>
                )}
                <br />
                {props.pendingReferralsRegistrations.length === 0 && (
                    <Typography align="left">&nbsp;= {formatDinero(getDinero(0))}</Typography>
                )}
                <br />
                {!isPayoutForRegistrationsValid && (
                    <Typography color="textSecondary">
                        ** Some of the registration payouts are not possible. Payouts can be made in groups of{' '}
                        {props.activePaymentConstant?.noOfRegistration}
                    </Typography>
                )}
            </Grid>

            {grandTotal > 0 && (
                <>
                    <Divider />
                    <br />
                    <Grid item xs={12}>
                        <Typography align="left">
                            &nbsp;Subtotal:&nbsp;
                            {formatDinero(getDinero(total))}{' '}
                        </Typography>
                        <br />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography align="left">
                            &nbsp;Fee:&nbsp;-{formatDinero(getDinero(props.paypalPayoutFee))}{' '}
                        </Typography>
                        <br />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography align="left">
                            <strong>
                                &nbsp;Total:&nbsp;
                                {formatDinero(getDinero(grandTotal))}{' '}
                            </strong>
                        </Typography>
                        <br />
                    </Grid>
                </>
            )}
        </Box>
    );
};

export default PayoutDetails;
