import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cardmoolaLoader from '../../assets/images/loader.gif';

const useStyles = makeStyles((theme) => ({
    loader: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        '& svg': {
            width: '30% !important',
            height: '30% !important'
        },
        '& img': {
            maxWidth: 300,
            width: '80% !important',
            height: 'auto !important',
            [theme.breakpoints.up('sm')]: {
                width: '50% !important'
            },
            [theme.breakpoints.up('sm')]: {
                width: '30% !important'
            }
        }
    }
}));

const AppLoader = () => {
    const classes = useStyles();

    return (
        <div className={classes.loader}>
            <img src={cardmoolaLoader} alt="cardmoola-buy-gift-card" />
        </div>
    );
};

export default AppLoader;
