import { StoreActionProps } from '.';

import { prepareHeaders, HttpRequest } from '../contexts/api-service-context';
import { setLastActivityInStorage } from '../contexts/store-context';
import { UserSessionAuthData } from '../reducers/user-session.reducer';
import { AdminUser, ErrorType } from '../types';

export type LoginLogoutOptions = {
    skipFireEvent?: boolean;
    skipApiCall?: boolean;
    forceApiCall?: boolean;
};

const logout = async (props: StoreActionProps, options?: LoginLogoutOptions) => {
    const { userService, toasterContext, accessTokenService, idleTimeoutService: { idleTimer } } = props;

    // fire logout event
    if (!options?.skipFireEvent) {
        accessTokenService.fireLogoutEvent();
    }

    accessTokenService.clearAutomaticRefreshTokenInterval();
    accessTokenService.setAccessToken(null);
    userService.setUser(null);
    toasterContext.closeToaster();
    idleTimer.pause();

    if (options?.skipApiCall) {
        return null;
    }

    try {
        const headers = prepareHeaders();
        const request: HttpRequest = {
            credentials: 'same-origin',
            method: 'POST',
            headers,
            body: ''
        };
        await fetch(`/api/admin/auth/logout`, request);
    } catch (e: ErrorType) {
        console.log(`error logging out`); // eslint-disable-line no-console
    }

    return null;
};

const login = async (props: StoreActionProps, options?: LoginLogoutOptions): Promise<AdminUser | null> => {
    const { userService, accessTokenService, idleTimeoutService: { idleTimer } } = props;
    const userExists = Boolean(userService.user);

    // fire login event
    if (!options?.skipFireEvent) {
        accessTokenService.fireLoginEvent();
    }

    if (options?.forceApiCall) {
        setLastActivityInStorage();
    }

    // fetch user details
    if (!userExists || !accessTokenService.accessToken || options?.forceApiCall) {
        const { user } = await accessTokenService.refreshToken(true); // force fetch refresh token

        if (user) {
            idleTimer.start();
            return user;
        }
    }

    logout(props);
    return null;
};

const setUser = (props: StoreActionProps, user: AdminUser | null) => {
    if (!user) {
        logout(props);
        return;
    }

    props.userService.setUser(user);
};

const setLoading = (props: StoreActionProps, isLoading: boolean) => {
    props.dispatch({ type: 'SET_LOADING', isLoading });
};

const userSessionAction = (props?: StoreActionProps) => {
    if (!props) {
        return null;
    }
    return {
        setLoading: (isLoading: boolean) => setLoading(props, isLoading),
        setUser: (user: AdminUser | null) => setUser(props, user),
        setAuthData: (authData: UserSessionAuthData | null) => props.dispatch({ type: 'SET_AUTH_DATA', authData }),
        logout: (options?: LoginLogoutOptions) => logout(props, options),
        login: (options?: LoginLogoutOptions) => login(props, options)
    };
};

export default userSessionAction;

export interface UserSessionAction {
    userSessionAction: typeof userSessionAction;
}
