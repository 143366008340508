import React from 'react';

const VenmoSvg = (props) => {
    return (
        <svg
            {...props}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            enableBackground="new 0 0 792 550"
            viewBox="107.55 251 576.9 109.7"
        >
            {' '}
            <g>
                {' '}
                <path
                    fill="#3D95CE"
                    d="M202.376,250.999c3.895,6.433,5.651,13.059,5.651,21.429c0,26.696-22.789,61.376-41.285,85.728h-42.246   l-16.943-101.312l36.991-3.512l8.958,72.09c8.37-13.636,18.699-35.065,18.699-49.675c0-7.997-1.37-13.444-3.511-17.929   L202.376,250.999z"
                ></path>{' '}
                <path
                    fill="#3D95CE"
                    d="M250.318,295.611c6.807,0,23.944-3.114,23.944-12.854c0-4.677-3.307-7.01-7.204-7.01   C260.24,275.747,251.293,283.923,250.318,295.611z M249.538,314.901c0,11.893,6.613,16.559,15.38,16.559   c9.547,0,18.688-2.333,30.569-8.371l-4.475,30.388c-8.371,4.09-21.417,6.819-34.08,6.819c-32.121,0-43.617-19.48-43.617-43.833   c0-31.564,18.7-65.079,57.253-65.079c21.226,0,33.095,11.892,33.095,28.451C303.665,306.53,269.403,314.708,249.538,314.901z"
                ></path>{' '}
                <path
                    fill="#3D95CE"
                    d="M410.414,274.773c0,3.896-0.591,9.547-1.179,13.24l-11.1,70.142h-36.019l10.125-64.298   c0.192-1.744,0.782-5.255,0.782-7.203c0-4.677-2.922-5.844-6.435-5.844c-4.666,0-9.343,2.141-12.458,3.704l-11.484,73.642h-36.222   l16.548-105.015h31.35l0.397,8.382c7.396-4.87,17.135-10.137,30.953-10.137C403.979,251.384,410.414,260.739,410.414,274.773z"
                ></path>{' '}
                <path
                    fill="#3D95CE"
                    d="M517.344,262.88c10.316-7.396,20.057-11.496,33.488-11.496c18.495,0,24.928,9.355,24.928,23.389   c0,3.896-0.589,9.547-1.177,13.24l-11.087,70.142h-36.029l10.318-65.657c0.19-1.755,0.589-3.896,0.589-5.254   c0-5.266-2.923-6.434-6.435-6.434c-4.474,0-8.946,1.948-12.266,3.704l-11.482,73.642h-36.02l10.318-65.657   c0.19-1.755,0.577-3.896,0.577-5.254c0-5.266-2.924-6.434-6.424-6.434c-4.676,0-9.343,2.141-12.458,3.704l-11.493,73.642h-36.21   l16.546-105.015h30.967l0.973,8.767c7.204-5.254,16.935-10.521,29.98-10.521C506.242,251.384,513.637,256.255,517.344,262.88z"
                ></path>{' '}
                <path
                    fill="#3D95CE"
                    d="M647.444,293.472c0-8.573-2.144-14.418-8.564-14.418c-14.215,0-17.134,25.131-17.134,37.987   c0,9.753,2.729,15.789,9.147,15.789C644.329,332.83,647.444,306.327,647.444,293.472z M585.141,315.489   c0-33.117,17.52-64.105,57.83-64.105c30.374,0,41.477,17.929,41.477,42.676c0,32.733-17.331,66.631-58.613,66.631   C595.265,360.691,585.141,340.621,585.141,315.489z"
                ></path>{' '}
            </g>{' '}
        </svg>
    );
};

export default VenmoSvg;
