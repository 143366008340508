export interface AppState {
    header: {
        isHidden: boolean;
        showMiniHeader: boolean;
    };
}

export const initialState: AppState = {
    header: {
        isHidden: false,
        showMiniHeader: true
    }
};

export const appReducer = (state: AppState, action: AppActionType): AppState => {
    switch (action.type) {
        case 'SET_HEADER':
            return {
                ...state,
                header: {
                    isHidden: action.isHidden,
                    showMiniHeader: action.showMiniHeader,
                }
            };
        default:
            return state;
    }
};

type SetHeader = {
    type: 'SET_HEADER';
    isHidden: boolean;
    showMiniHeader: boolean;
};

export type AppActionType = SetHeader;
