import React, { useState } from 'react';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { TransactionLimitSettingValue } from '../../types';
import { AppTextField } from '../common/app-textfield';
import useAdminStyles from '../styles';
import { randomId } from '../../utilities';

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: 780
    },
    heading: {
        fontSize: 24,
        fontFamily: '"Work Sans", sans-serif',
        marginBottom: 24
    },
    footer: {
        gap: 16,
        display: 'flex',
        justifyContent: 'flex-end'
    }
}));

type State = {
    isKeyInValid: boolean;
    transactionLimit: TransactionLimitSettingValue;
};

type Props = {
    isSaving: boolean;
    order: number;
    keyMap: Array<string>;
    onSave: (transactionLimit: TransactionLimitSettingValue) => void;
    onCancel: () => void;
}
const TransactionLimitForm = (props: Props) => {
    const adminClasses = useAdminStyles();
    const classes = useStyles();

    const [state, setState] = useState<State>({
        isKeyInValid: false,
        transactionLimit: {
            order: props.order,
            transactionLimitSettingId: `TL-${randomId()}`,
            key: 'NEW',
            transactionCostLimit: 0,
            transactionCountLimit: 0,
            isDefault: false,
        }
    });

    const findExistingKey = () => props.keyMap.find(k => k.toLowerCase() === state.transactionLimit.key.toLowerCase());

    const onSave = () => {
        // validate the new key
        if (findExistingKey()) {
            setState(prevState => ({ ...prevState, isKeyInValid: true }));
            return;
        }
        // save new transaction limit
        props.onSave(state.transactionLimit);
    }

    return (
        <Grid item={true} xs={12} className={adminClasses.root}>
            <Container className={clsx(adminClasses.container, classes.container)}>
                <Box>
                    <Typography variant="h1" className={classes.heading}>
                        Add New Transaction Limit
                    </Typography>

                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <AppTextField
                                fullWidth={true}
                                variant="filled"
                                label="Key"
                                value={state.transactionLimit.key}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setState(prevState => ({ ...prevState, isKeyInValid: false, transactionLimit: { ...prevState.transactionLimit, key: value } }));
                                }}
                                error={state.isKeyInValid}
                                helperText={state.isKeyInValid && 'Key already exists!' || undefined}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <AppTextField
                                fullWidth={true}
                                variant="filled"
                                label="Cost limit"
                                value={state.transactionLimit.transactionCostLimit}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    )
                                }}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (!value || value.match(/^\d{1,}(\.\d{0,2})?$/)) {
                                        setState(prevState => ({ ...prevState, transactionLimit: { ...prevState.transactionLimit, transactionCostLimit: +value } }));
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AppTextField
                                fullWidth={true}
                                variant="filled"
                                label="Count limit"
                                value={state.transactionLimit.transactionCountLimit}
                                onChange={(e) => {
                                    const value = +e.target.value;
                                    if (!isNaN(value)) {
                                        setState(prevState => ({ ...prevState, transactionLimit: { ...prevState.transactionLimit, transactionCountLimit: value } }));
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Box textAlign="right" marginTop={4} className={classes.footer}>
                        <Button
                            type="button"
                            variant="contained"
                            onClick={props.onCancel}
                            disabled={props.isSaving}
                        >
                            {props.isSaving && <CircularProgress className="button-loader" />}
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={onSave}
                            disabled={props.isSaving}
                        >
                            {props.isSaving && <CircularProgress className="button-loader" />}
                            Add
                        </Button>
                    </Box>
                </Box>
            </Container>
        </Grid>
    );
};

export default TransactionLimitForm;
