/* eslint-disable */

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import { AppTextField } from '../common/app-textfield';
import { Alert } from '@material-ui/lab';
import { Box } from '@material-ui/core';

const STRONG_COLOR = '#00C853';
const DANGER_COLOR = '#E53935';
const WEAK_COLOR = '#D19702';

export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    textField: {
        width: '100%',
        marginBottom: 24
    },
    helperText: {
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 12,
        listStyleType: 'disc',
        color: 'rgba(0, 0, 0, 0.87)',
        '& span': {
            display: 'list-item'
        }
    },
    helperTextTitle: {
        display: 'block',
        color: theme.palette.primary.main
    },
    visibilityIcon: {
        marginRight: 8,
        cursor: 'pointer'
    },
    weakColor: {
        color: WEAK_COLOR
    },
    dangerColor: {
        color: DANGER_COLOR
    },
    strongColor: {
        color: STRONG_COLOR
    },
    weakLine: {
        '&::after': {
            border: `1px solid ${WEAK_COLOR}`,
            transform: 'scaleX(1)'
        }
    },
    dangerLine: {
        '&::after': {
            border: `1px solid ${DANGER_COLOR}`,
            transform: 'scaleX(1)'
        }
    },
    strongLine: {
        '&::after': {
            border: `1px solid ${STRONG_COLOR}`,
            transform: 'scaleX(1)'
        }
    }
}));

interface Props {
    onPasswordChange: (data: { password: string; isPasswordValid: boolean }) => void;
    clearPasswordFields?: boolean;
    required?: boolean;
    className?: string;
    fullWidth?: boolean;
    size?: 'small' | 'medium';
}

type State = {
    /**
     * @default false
     */
    showPassword: boolean;
    /**
     * @default false
     */
    showConfirmPassword: boolean;
    password: string;
    confirmPassword: string;
    passwordError: string | null;
    confirmPasswordError: string | null;
    /**
     * @default false
     */
    focused: boolean;
    isPasswordValid: boolean;
    strongPasswordErrors: {
        specialCharacterRequiredError: boolean;
        captialLetterRequiredError: boolean;
        lowercaseLetterRequiredError: boolean;
        minPasswordError: boolean;
    };
    minPasswordErrors?: {
        digitRequiredError: boolean;
        characterRequiredError: boolean;
        minPasswordError: boolean;
        maxPasswordError: boolean;
    };
    errors: {
        maxPasswordError: string | null;
        specialCharacterRequiredError: string | null;
        captialLetterRequiredError: string | null;
        lowercaseLetterRequiredError: string | null;
        minPasswordError: string | null;
    };
};

const DEFAULT_STATE: State = {
    showPassword: false,
    showConfirmPassword: false,
    password: '',
    confirmPassword: '',
    passwordError: null,
    confirmPasswordError: null,
    focused: false,
    isPasswordValid: false,
    strongPasswordErrors: {
        specialCharacterRequiredError: false,
        captialLetterRequiredError: false,
        lowercaseLetterRequiredError: false,
        minPasswordError: false
    },
    minPasswordErrors: {
        digitRequiredError: false,
        characterRequiredError: false,
        minPasswordError: false,
        maxPasswordError: false
    },
    errors: {
        maxPasswordError: null,
        specialCharacterRequiredError: null,
        captialLetterRequiredError: null,
        lowercaseLetterRequiredError: null,
        minPasswordError: null
    }
};

const Password: React.FC<Props> = (props) => {
    useEffect(() => {
        if (props.clearPasswordFields) {
            setState(DEFAULT_STATE);
        }
    }, [props.clearPasswordFields]);

    const classes = useStyles();
    const [state, setState] = useState(DEFAULT_STATE);

    const isValidPassword = state.passwordError && ['Weak password', 'Strong password'].includes(state.passwordError);

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = (e.target.value || '').trim();
        const confirmPasswordError =
            Boolean(state.confirmPassword) && state.confirmPassword !== value
                ? 'Password and Repeat password should match.'
                : '';

        const specialCharacterRegex = /(?=.*[%$#@!&*?^])/;
        const restrictedSpecialCharactersRegex = /[\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-\s]/;
        const capitalCaseRegex = /(?=.*[A-Z])/;
        const lowerCaseRegex = /(?=.*[a-z])/;
        const digitRegex = /^(?=.*\d).+$/;

        const strongPasswordErrors = {
            specialCharacterRequiredError:
                restrictedSpecialCharactersRegex.test(value) || !specialCharacterRegex.test(value),
            captialLetterRequiredError: !capitalCaseRegex.test(value),
            lowercaseLetterRequiredError: !lowerCaseRegex.test(value),
            minPasswordError: value.length < 8
        };

        const minPasswordErrors = {
            maxPasswordError: value.length > 20 || value.length === 0,
            minPasswordError: value.length < 6,
            digitRequiredError: !digitRegex.test(value),
            characterRequiredError: !capitalCaseRegex.test(value) && !lowerCaseRegex.test(value)
        };

        const strongPasswordErrorsArray = Object.values(strongPasswordErrors);
        const minPasswordErrorsArray = Object.values(minPasswordErrors);

        const strongPasswordNoOfErrors = strongPasswordErrorsArray.reduce((acc, cur) => acc + Number(cur), 0);
        const minPasswordNoOfErrors = minPasswordErrorsArray.reduce((acc, cur) => acc + Number(cur), 0);

        const isBadPassword = minPasswordNoOfErrors !== 0;
        const isStrongPassword = strongPasswordNoOfErrors === 0;
        const passwordError = isBadPassword ? 'Bad password' : isStrongPassword ? 'Strong password' : 'Weak password';

        const isPasswordValid = Boolean(
            passwordError && passwordError !== 'Bad password' && confirmPasswordError === '' && state.confirmPassword
        );

        setState({
            ...state,
            password: e.target.value,
            passwordError,
            confirmPasswordError,
            strongPasswordErrors,
            minPasswordErrors,
            isPasswordValid
        });

        props.onPasswordChange({
            password: e.target.value,
            isPasswordValid
        });
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const confirmPasswordError =
            state.password !== e.target.value ? 'Password and Repeat password should match.' : '';
        setState({
            ...state,
            confirmPassword: e.target.value,
            confirmPasswordError
        });
        props.onPasswordChange({
            password: state.password,
            isPasswordValid: Boolean(
                state.passwordError && state.passwordError !== 'Bad password' && confirmPasswordError === ''
            )
        });
    };

    const colorClass = () => {
        switch (state.passwordError) {
            case 'Weak password': {
                return {
                    lineClass: classes.weakLine,
                    colorClass: classes.weakColor,
                    color: WEAK_COLOR
                };
            }
            case 'Strong password': {
                return {
                    lineClass: classes.strongLine,
                    colorClass: classes.strongColor,
                    color: STRONG_COLOR
                };
            }
            case 'Bad password': {
                return {
                    lineClass: classes.dangerLine,
                    colorClass: classes.dangerColor,
                    color: DANGER_COLOR
                };
            }
            default:
                return { lineClass: '', colorClass: '', color: '' };
        }
    };

    const isConfirmPasswordValid =
        isValidPassword && !Boolean(state.confirmPasswordError) && state.confirmPassword === state.password;

    return (
        <Grid item={true} xs={12} className={classes.root}>
            <AppTextField
                required={props.required}
                className={clsx(classes.textField, props.className)}
                size={props.size || 'small'}
                type={state.showPassword ? 'text' : 'password'}
                fullWidth={props.fullWidth}
                helperText={
                    <React.Fragment>
                        <span className={clsx(classes.helperTextTitle, colorClass().colorClass)}>
                            {' '}
                            {state.passwordError}
                        </span>
                        {state.focused && (
                            <React.Fragment>
                                <span className={classes.helperTextTitle}>Your password must contain</span>
                                <span className={classes.helperText}>
                                    <span
                                        className={
                                            state.passwordError && !state.minPasswordErrors?.digitRequiredError
                                                ? classes.strongColor
                                                : ''
                                        }
                                    >
                                        one digit
                                    </span>
                                    <span
                                        className={
                                            state.passwordError && !state.minPasswordErrors?.characterRequiredError
                                                ? classes.strongColor
                                                : ''
                                        }
                                    >
                                        one character
                                    </span>
                                    <span
                                        className={
                                            state.passwordError && !state.minPasswordErrors?.minPasswordError
                                                ? classes.strongColor
                                                : ''
                                        }
                                    >
                                        minimum length 6 characters
                                    </span>
                                    <span
                                        className={
                                            state.passwordError && !state.minPasswordErrors?.maxPasswordError
                                                ? classes.strongColor
                                                : ''
                                        }
                                    >
                                        maximum length 20 characters
                                    </span>
                                </span>
                                <span className={classes.helperTextTitle}>Strong password should include</span>
                                <span className={classes.helperText}>
                                    <span
                                        className={
                                            state.passwordError &&
                                            !state.strongPasswordErrors?.specialCharacterRequiredError
                                                ? classes.strongColor
                                                : ''
                                        }
                                    >
                                        {`one special character (%$#@!&*?^)`}
                                    </span>
                                    <span
                                        className={
                                            state.passwordError &&
                                            !state.strongPasswordErrors?.captialLetterRequiredError
                                                ? classes.strongColor
                                                : ''
                                        }
                                    >
                                        one uppercase letter
                                    </span>
                                    <span
                                        className={
                                            state.passwordError &&
                                            !state.strongPasswordErrors?.lowercaseLetterRequiredError
                                                ? classes.strongColor
                                                : ''
                                        }
                                    >
                                        one lowercase letter
                                    </span>
                                    <span
                                        className={
                                            state.passwordError && !state.strongPasswordErrors?.minPasswordError
                                                ? classes.strongColor
                                                : ''
                                        }
                                    >
                                        minimum length 8 characters
                                    </span>
                                </span>
                                <Box mt={1} color="#7A7A7A">Note: New password cannot be same as the previous password</Box>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                }
                label="Password"
                variant="filled"
                onPaste={(e) => e.preventDefault()}
                value={state.password}
                InputLabelProps={{
                    style: {
                        color: colorClass().color
                    }
                }}
                maxLength={20}
                FormHelperTextProps={{
                    className: colorClass().colorClass
                }}
                InputProps={{
                    endAdornment: (
                        <>
                            <InputAdornment position="end">
                                {state.showPassword ? (
                                    <Visibility
                                        className={classes.visibilityIcon}
                                        onClick={() => setState({ ...state, showPassword: false })}
                                    />
                                ) : (
                                    <VisibilityOff
                                        className={classes.visibilityIcon}
                                        onClick={() => setState({ ...state, showPassword: true })}
                                    />
                                )}
                            </InputAdornment>
                            {state.passwordError === 'Bad password' && (
                                <WarningRoundedIcon className={classes.dangerColor}></WarningRoundedIcon>
                            )}
                            {state.isPasswordValid && (
                                <CheckCircleRoundedIcon className={classes.strongColor}></CheckCircleRoundedIcon>
                            )}
                        </>
                    )
                }}
                inputProps={{ autoComplete: 'new-password' }}
                onChange={handlePasswordChange}
                onFocus={() => setState({ ...state, focused: true })}
            />

            <AppTextField
                size={props.size || 'small'}
                required={props.required}
                className={clsx(classes.textField, props.className)}
                error={Boolean(state.confirmPasswordError)}
                helperText={state.confirmPasswordError || ''}
                type={state.showConfirmPassword ? 'text' : 'password'}
                label="Repeat Password"
                variant="filled"
                onPaste={(e) => e.preventDefault()}
                value={state.confirmPassword}
                onChange={handleConfirmPasswordChange}
                InputLabelProps={{
                    style: { color: isConfirmPasswordValid ? STRONG_COLOR : '' }
                }}
                InputProps={{
                    endAdornment: (
                        <>
                            <InputAdornment position="end">
                                {state.showConfirmPassword ? (
                                    <Visibility
                                        className={classes.visibilityIcon}
                                        onClick={() => setState({ ...state, showConfirmPassword: false })}
                                    />
                                ) : (
                                    <VisibilityOff
                                        className={classes.visibilityIcon}
                                        onClick={() => setState({ ...state, showConfirmPassword: true })}
                                    />
                                )}
                            </InputAdornment>
                            {state.confirmPasswordError && (
                                <WarningRoundedIcon className={classes.dangerColor}></WarningRoundedIcon>
                            )}
                            {isConfirmPasswordValid && (
                                <CheckCircleRoundedIcon className={classes.strongColor}></CheckCircleRoundedIcon>
                            )}
                        </>
                    )
                }}
                inputProps={{ autoComplete: 'new-password' }}
            />
        </Grid>
    );
};

export default Password;
