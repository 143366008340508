/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Card, FormControlLabel, InputAdornment, Switch } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import TablePagination from '@material-ui/core/TablePagination';

import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import CachedIcon from '@material-ui/icons/Cached';
import SearchIcon from '@material-ui/icons/Search';

import useAdminStyles from '../styles';
import { useUserService } from '../../contexts/user-context';
import { ErrorType, TransactionLimitSettingAttributes, TransactionLimitSettingValue, UserAccount } from '../../types';
import { DateFormatTypeEnum, formatTimeStamp, joinNameParts, scrollTop } from '../../utilities';
import HasPermission from '../../utilities/can';
import UserAvatar from '../common/user-avatar';
import ManageUserEditForm from './editForm';
import StatusButton from '../common/status-button';
import storeContext from '../../contexts/store-context';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, Select } from '@material-ui/core';
import { UserInfluencerAccountAttributes } from '../../types/influencer';
import { getDefaultSetting } from '../../utilities/transactionLimitSettings.util';
import { AppTextField } from '../common/app-textfield';
import { DataLoader } from '../common/data-loader';
import { useOrderStyles } from '../dashboard/order-history';
import { useToolbarStyles } from '../common/table-component';
import clsx from 'clsx';

export const useStyles = makeStyles((theme) => ({
    userAvatarOuter: {
        display: 'flex',
        alignItems: 'center',
        '& $userAvatar': {
            marginRight: 16
        }
    },
    userEmail: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        wordBreak: 'break-word'
    },
    approveButton: {
        background: theme.palette.success.main,
        color: theme.palette.common.white,
        margin: '0 8px',
        '&:hover': {
            background: theme.palette.success.light
        }
    },
    rejectButton: {
        background: theme.palette.error.main,
        color: theme.palette.common.white,
        margin: '0 8px',
        '&:hover': {
            background: theme.palette.error.light
        }
    },
    tableHeading: {
        fontWeight: 600,
        fontSize: 20,
        color: theme.palette.primary.main
    },
    toolbar: {
        textAlign: 'left'
    },
    menuItem: {
        '&$approveButton': {
            color: theme.palette.success.main,
            background: theme.palette.common.white,
            '&:hover': {
                color: theme.palette.success.dark
            }
        },
        '&$rejectButton': {
            color: theme.palette.error.main,
            background: theme.palette.common.white,
            '&:hover': {
                color: theme.palette.error.dark
            }
        }
    },
    headerComponent: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 16px'
    },
    headerButtons: {
        width: '40%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    card: {
        background: theme.palette.common.white,
        boxShadow: theme.shadows['1']
    },
    searchInput: {
        marginRight: 32,
        marginTop: 16,
        width: '100%',
        borderRadius: 2,
        background: '#fff',
        '& input': {
            padding: 10
        },
        '& svg': {
            color: 'rgba(0, 0, 0, 0.24)'
        }
    },
    margin16: {
        marginTop: 16
    },
    showButton: {
        marginLeft: 1
    },
    selectedTitle: {
        flex: '1 1 100%',
        fontFamily: `'Work Sans', sans-serif`
    },
    filter: {
        width: 200,
        '& .MuiSelect-root': {
            textAlign: 'left'
        }
    },
    userAvatar: {}
}));

type State = {
    isRefreshing: boolean;
    isLoading: boolean;
    renderEditForm: boolean;
    menuAnchorEle: HTMLElement | null;
    selectAll: boolean;
    selectedUserIds: Array<number>;
    users: Array<UserInfluencerAccountAttributes>;
    filteredUsers: Array<UserInfluencerAccountAttributes>;
    showInfluencer: boolean;
    searchText: string | null;
    page: number;
    rowsPerPage: number;
    totalUsers: number;
    filteredTotal: number;
    expandedAccordions: Set<number>;
};

const ManageUser = () => {
    const classes = useStyles();
    const orderClasses = useOrderStyles();
    const adminClasses = useAdminStyles();
    const toolBarClasses = useToolbarStyles();

    const history = useHistory();
    const userService = useUserService();
    const { appAction, manageInfluencerAction, manageUserAction, manageTransactionLimitSettingsAction } =
        storeContext();

    const canRead = HasPermission(userService.user, 'MANAGE_USER', 'READ');
    const canUpdate = HasPermission(userService.user, 'MANAGE_USER', 'UPDATE');
    const canReadInfluencer = HasPermission(userService.user, 'MANAGE_INFLUENCER', 'READ');

    useEffect(() => {
        if (!canRead) {
            history.push('/dashboard');
            return;
        }
        appAction()?.renderFullHeader();
        init();
    }, []);

    const [state, setState] = useState<State>({
        users: [],
        filteredUsers: [],
        isLoading: false,
        isRefreshing: false,
        renderEditForm: false,
        menuAnchorEle: null,
        selectAll: false,
        selectedUserIds: [],
        showInfluencer: false,
        searchText: null,
        page: 0,
        rowsPerPage: 5,
        totalUsers: 0,
        filteredTotal: 0,
        expandedAccordions: new Set()
    });

    const [transactionLimitSettings, setTransactionLimitSettings] = useState<TransactionLimitSettingValue[]>([]);
    const [defaultLimitSettings, setDefaultSettings] = useState<TransactionLimitSettingValue | null>(null);
    const [userTransactionLimitSettings, setUserTransactionLimitSettings] = useState<
        TransactionLimitSettingAttributes[]
    >([]);

    useEffect(() => {
        init();
    }, [state.page, state.rowsPerPage, state.searchText]);

    const init = async () => {
        try {
            setState((prevState) => ({ ...prevState, isLoading: true, isRefreshing: false }));

            const [paginationUserData, transactionLimitSettings = [], userTransactionLimitSettings = []] =
                await Promise.all([
                    manageUserAction()?.getAllUsers({
                        page: state.page || 0,
                        rowsPerPage: state.rowsPerPage || 5,
                        searchText: state.searchText || null
                    }),
                    manageTransactionLimitSettingsAction()?.getTransactionLimitSettings(),
                    manageTransactionLimitSettingsAction()?.getUserTransactionLimitSettings()
                ]);

            //fetch default setting
            const defaultSetting = getDefaultSetting(transactionLimitSettings);

            setState((prevState) => ({
                ...prevState,
                users: paginationUserData ? paginationUserData.users : [],
                filteredUsers: paginationUserData ? paginationUserData.users : [],
                totalUsers: paginationUserData ? paginationUserData.totalRecords : 0,
                isLoading: false
            }));
            setTransactionLimitSettings(transactionLimitSettings);
            setDefaultSettings(defaultSetting);
            setUserTransactionLimitSettings(userTransactionLimitSettings);
        } catch (e: ErrorType) {
            setState((prevState) => ({ ...prevState, isLoading: false, page: 0, isRefreshing: false }));
        }
    };

    const getDefaultSetting = (transactionLimitSettings: TransactionLimitSettingValue[]) => {
        if (transactionLimitSettings) {
            const defaultSetting = transactionLimitSettings.find((setting) => setting.isDefault === true);

            if (!defaultSetting) {
                // If the 'isDefault' setting is not found, use the 'Moderate' key as the default
                const moderateSetting = transactionLimitSettings.find((setting) => setting.key === 'Moderate');
                if (moderateSetting) {
                    return moderateSetting;
                }
            } else {
                return defaultSetting;
            }
        }
        return null;
    };

    const refreshUsers = async (options: { isLoading: boolean; isRefreshing: boolean; isInfluencer?: boolean }) => {
        setState((prevState) => ({ ...prevState, isLoading: options.isLoading, isRefreshing: options.isRefreshing }));

        if (options.isInfluencer) {
            const [userTransactionLimitSettings = [], users = []] = await Promise.all([
                manageTransactionLimitSettingsAction()?.getUserTransactionLimitSettings(),
                manageInfluencerAction()?.getAllInfluencers()
            ]);

            setState((prevState) => ({ ...prevState, users, isLoading: false, page: 0, isRefreshing: false }));
            setUserTransactionLimitSettings(userTransactionLimitSettings);
        } else {
            const [userTransactionLimitSettings = [], paginationUserData] = await Promise.all([
                manageTransactionLimitSettingsAction()?.getUserTransactionLimitSettings(),
                manageUserAction()?.getAllUsers({
                    page: 0,
                    rowsPerPage: state.rowsPerPage || 5,
                    searchText: state.searchText || null
                })
            ]);

            setState((prevState) => ({
                ...prevState,
                users: paginationUserData ? paginationUserData.users : [],
                filteredUsers: paginationUserData ? paginationUserData.users : [],
                totalUsers: paginationUserData ? paginationUserData.totalRecords : 0,
                isLoading: false,
                isRefreshing: false
            }));
            setUserTransactionLimitSettings(userTransactionLimitSettings);
        }
    };

    const onSwitchChange = async (isChecked: boolean) => {
        setState({
            ...state,
            showInfluencer: isChecked
        });
        refreshUsers({ isLoading: true, isRefreshing: false, isInfluencer: isChecked });
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        setState((prevState) => ({ ...prevState, searchText: text.toLowerCase(), page: 0 }));
    };

    const handleChangePage = (event: unknown, page: number) => {
        setState({ ...state, page });
        setTimeout(() => scrollTop());
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value || 0;
        if (state.rowsPerPage > +value) {
            setTimeout(() => scrollTop());
        }
        setState({ ...state, page: 0, rowsPerPage: (value && parseInt(value, 10)) || 5 });
    };

    const onSelectedHeaderComponent = (
        <Grid item={true} xs={12} className={clsx(classes.headerComponent, toolBarClasses.highlight)}>
            <Grid item={true} xs={6}>
                <Typography className={toolBarClasses.title} color="inherit" variant="subtitle1" component="div">
                    {state.selectedUserIds.length} selected
                </Typography>
            </Grid>
            <Grid item={true} xs={12} className={adminClasses.headerComponent}>
                <Button
                    onClick={(e) => approveAll()}
                    size="small"
                    color="inherit"
                    variant="contained"
                    className={classes.approveButton}
                >
                    <ThumbUpIcon />
                    &nbsp;Approve
                </Button>

                <Button
                    onClick={(e) => rejectAll()}
                    size="small"
                    color="inherit"
                    variant="contained"
                    className={classes.rejectButton}
                >
                    <ThumbDownIcon />
                    &nbsp;Revoke
                </Button>
            </Grid>
        </Grid>
    );

    const headerComponent = (
        <Grid item={true} xs={12} className={classes.headerComponent}>
            <Box>
                <Typography className={classes.tableHeading}>Manage Users</Typography>
            </Box>
            <Box className={classes.headerButtons}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={state.showInfluencer}
                            onChange={(e) => {
                                const isChecked = e.target.checked;
                                onSwitchChange(isChecked);
                            }}
                            color="primary"
                            disabled={!canReadInfluencer}
                        />
                    }
                    label="Show Influencers"
                />
                <Button
                    onClick={() =>
                        refreshUsers({ isLoading: false, isRefreshing: true, isInfluencer: state.showInfluencer })
                    }
                    size="small"
                    variant="contained"
                    disabled={state.isRefreshing}
                >
                    <CachedIcon className={(state.isRefreshing && 'rotation-loading') || undefined} />
                    &nbsp;Refresh
                </Button>
            </Box>
        </Grid>
    );

    const updateUsers = async (users: Array<Partial<UserAccount>>) => {
        const newUsers = await manageUserAction()?.updateUsers(users);
        if (newUsers) {
            setState((prevState) => ({ ...prevState, users: newUsers || [] }));
        }
    };

    const approveAll = () => {
        updateUsers(
            state.users
                .filter((u) => state.selectedUserIds.includes(u.userId || -1))
                .map((u) => ({ ...u, accessStatus: 'APPROVED' }))
        );
    };

    const rejectAll = () => {
        updateUsers(
            state.users
                .filter((u) => state.selectedUserIds.includes(u.userId || -1))
                .map((u) => ({
                    ...u,
                    accessUpdateReason: null,
                    accessStatus: 'REVOKED'
                }))
        );
    };

    const onCheckboxSelect = (userId: number) => {
        let selectedIds: Array<number> = [];
        if (state.selectedUserIds.includes(userId)) {
            // User is unselected
            selectedIds = state.selectedUserIds.filter((id) => id !== userId);
        } else {
            // User is selected
            selectedIds = [...state.selectedUserIds, userId];
        }
        setState({ ...state, selectedUserIds: selectedIds });
    };

    const onCheckboxSelectAllToggle = (value: boolean) => {
        setState({ ...state, selectedUserIds: value ? state.users.map((u) => u.userId!) : [], selectAll: value });
    };

    const toggleAccordion = (userId: number) => {
        setState((prevState) => {
            const expandedAccordions = new Set(prevState.expandedAccordions);
            if (expandedAccordions.has(userId)) {
                expandedAccordions.delete(userId);
            } else {
                expandedAccordions.add(userId);
            }

            return {
                ...prevState,
                expandedAccordions
            };
        });
    };

    const toggleFraudCheck = (userId: number) => {
        manageUserAction()?.updateUserFraudCheck(userId);
        setState((prevState) => ({
            ...prevState,
            users: prevState.users.map((user) => {
                if (user.userId === userId) {
                    return {
                        ...user,
                        checkFraud: !user.checkFraud
                    };
                }
                return user;
            })
        }));
    };

    const isExpanded = (userId: number) => state.expandedAccordions.has(userId);

    return (
        <Grid item={true} xs={12} className={adminClasses.root}>
            <Container className={adminClasses.container}>
                {state.selectedUserIds.length ? onSelectedHeaderComponent : headerComponent}

                <React.Fragment>
                    <AppTextField
                        variant="outlined"
                        placeholder="Search"
                        className={classes.searchInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                        value={state.searchText || null}
                        onChange={handleSearch}
                    />
                    {state.users.length ? (
                        <Card key={'key'} className={orderClasses.cardRow}>
                            <Grid container className={orderClasses.cardRowGrid} justifyContent="space-between">
                                <Grid item xs={6} sm={'auto'}>
                                    <Checkbox
                                        checked={state.selectAll}
                                        onChange={() => onCheckboxSelectAllToggle(!state.selectAll)}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={'auto'} lg={1}>
                                    <Typography component="strong" variant="subtitle2">
                                        UserId #
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={'auto'} lg={2}>
                                    <Typography component="strong" variant="subtitle2">
                                        Name
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={'auto'} lg={2}>
                                    <Typography component="strong" variant="subtitle2">
                                        Email
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} sm={'auto'} lg={2}>
                                    <Typography component="strong" variant="subtitle2">
                                        Registration Date
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} sm={'auto'} lg={1}>
                                    <Typography component="strong" variant="subtitle2">
                                        Status
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} sm={'auto'} lg={2}>
                                    <Typography component="strong" variant="subtitle2">
                                        Transaction Cost/ Transaction Count
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} sm={'auto'} lg={1}>
                                    <Typography component="strong" variant="subtitle2">
                                        Check Fraud
                                    </Typography>
                                </Grid>
                                <Grid item xs={'auto'} sm={'auto'} lg={2} />
                            </Grid>
                        </Card>
                    ) : null}
                </React.Fragment>

                {state.users && !state.users.length ? (
                    <DataLoader isLoading={state.isLoading} items={state.users} />
                ) : null}

                {state.users.length
                    ? state.users.map((user) => {
                          const name = joinNameParts(user) || '-';
                          const transactionLimitSetting = userTransactionLimitSettings.find(
                              (i) => i.userId === user.userId
                          );

                          const activeTransactionLimitSetting =
                              transactionLimitSettings.find(
                                  (t) =>
                                      t.transactionLimitSettingId === transactionLimitSetting?.transactionLimitSettingId
                              ) || defaultLimitSettings;

                          return (
                              <React.Fragment key={user.userId}>
                                  <Card key={user.userId} className={orderClasses.cardRow}>
                                      <Grid
                                          container
                                          className={orderClasses.cardRowGrid}
                                          justifyContent="space-between"
                                      >
                                          <Grid item xs={6} sm={'auto'}>
                                              <Checkbox
                                                  checked={state.selectedUserIds.includes(user.userId!)}
                                                  onChange={() => onCheckboxSelect(user.userId!)}
                                              />
                                          </Grid>
                                          <Grid item xs={6} sm={'auto'} lg={1}>
                                              <Typography>#{user.userId}</Typography>
                                          </Grid>
                                          <Grid item xs={6} sm={'auto'} lg={2}>
                                              <Grid item={true} className={classes.userAvatarOuter}>
                                                  <UserAvatar className={classes.userAvatar} user={user} />
                                                  <Typography>{name}</Typography>
                                              </Grid>
                                          </Grid>
                                          <Grid item xs={6} sm={'auto'} lg={2}>
                                              <Grid item={true} className={classes.userEmail}>
                                                  <Typography>{user.email}</Typography>
                                              </Grid>
                                          </Grid>
                                          <Grid item xs={6} sm={'auto'} lg={2}>
                                              <Grid item={true}>
                                                  <Typography>
                                                      {formatTimeStamp(
                                                          user.createdAt,
                                                          DateFormatTypeEnum.MMMM_D_YYYY_h_mm_a
                                                      )}
                                                  </Typography>
                                              </Grid>
                                          </Grid>
                                          <Grid item xs={5} sm={'auto'} lg={1}>
                                              <StatusButton
                                                  options={[
                                                      {
                                                          type: 'APPROVAL_PENDING',
                                                          text: 'Pending',
                                                          color: 'warning'
                                                      },
                                                      {
                                                          type: 'REVOKED',
                                                          text: 'Revoked',
                                                          color: 'danger'
                                                      },
                                                      {
                                                          type: 'ON_WATCH',
                                                          text: 'ON WATCH',
                                                          color: 'warning'
                                                      }
                                                  ]}
                                                  type={user.accessStatus}
                                              />
                                          </Grid>
                                          <Grid item xs={5} sm={'auto'} lg={2}>
                                              <FormControl variant="standard" style={{ width: '100%' }}>
                                                  <Select
                                                      labelId={`transaction-limit-${user.userId}`}
                                                      id={`transaction-limit-select-${user.userId}`}
                                                      displayEmpty
                                                      value={
                                                          transactionLimitSetting?.transactionLimitSettingId ||
                                                          defaultLimitSettings?.transactionLimitSettingId ||
                                                          ''
                                                      }
                                                      onChange={async (e) => {
                                                          e.preventDefault();
                                                          e.stopPropagation();
                                                          await manageTransactionLimitSettingsAction()?.updateUserTransactionLimitSetting(
                                                              (e.target.value as string) || null,
                                                              user.userId as number
                                                          );
                                                          await refreshUsers({
                                                              isLoading: false,
                                                              isRefreshing: true,
                                                              isInfluencer: state.showInfluencer
                                                          });
                                                      }}
                                                      label="Age"
                                                  >
                                                      {transactionLimitSettings.map((tls) => (
                                                          <MenuItem
                                                              value={`${tls.transactionLimitSettingId}`}
                                                              key={`${tls.transactionLimitSettingId}`}
                                                          >
                                                              {tls.key}: ${tls.transactionCostLimit} /{' '}
                                                              {tls.transactionCountLimit}
                                                          </MenuItem>
                                                      ))}
                                                  </Select>
                                              </FormControl>
                                          </Grid>

                                          <Grid item xs={6} sm={'auto'} lg={1}>
                                              <Switch
                                                  checked={user.checkFraud}
                                                  onClick={(e) => {
                                                      e.preventDefault();
                                                      e.stopPropagation();
                                                      toggleFraudCheck(user.userId!);
                                                  }}
                                                  color="primary"
                                              />
                                          </Grid>

                                          <Grid item xs={'auto'} sm={'auto'} lg={2} className={classes.showButton}>
                                              {canUpdate && (
                                                  <Box>
                                                      <Button
                                                          variant="outlined"
                                                          onClick={() => {
                                                              toggleAccordion(user.userId!);
                                                          }}
                                                      >
                                                          {isExpanded(user.userId!) ? 'HIDE DETAILS' : 'SHOW DETAILS'}
                                                      </Button>
                                                  </Box>
                                              )}
                                          </Grid>
                                      </Grid>
                                  </Card>
                                  <Collapse in={isExpanded(user.userId!)} timeout="auto" unmountOnExit={true}>
                                      <ManageUserEditForm
                                          user={user}
                                          transactionLimitSetting={activeTransactionLimitSetting}
                                          onCancel={() => {
                                              toggleAccordion(user.userId!);
                                              setState((prevState) => ({
                                                  ...prevState,
                                                  renderEditForm: false
                                              }));
                                          }}
                                          onUpdate={updateUsers}
                                      />
                                  </Collapse>
                              </React.Fragment>
                          );
                      })
                    : null}

                <TablePagination
                    classes={{ root: orderClasses.paginator }}
                    rowsPerPageOptions={[5, 10, 25]}
                    count={state.totalUsers}
                    component="div"
                    rowsPerPage={state.rowsPerPage}
                    page={state.page}
                    SelectProps={{ inputProps: { 'aria-label': 'rows per page' } }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Container>
        </Grid>
    );
};

export default ManageUser;
