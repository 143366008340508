import React from 'react';
import { B2BApiConfig } from '../../types/b2bApiConfig';
import {
    CircularProgress,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Box,
    Divider,
    Typography
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';

import TableComponent, { HeadCell, TableResultRow } from '../common/table-component';
import ConfirmationDialog from '../common/confirmation-dialog';
import storeContext from '../../contexts/store-context';
import { capitalizeWords } from '../../utilities';

type ModalState = { open: boolean; loading: boolean; type: 'RESET' | 'DEACTIVATE'; activeConfig: B2BApiConfig | null };

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    heading: {
        textAlign: 'left',
        fontSize: 20,
        fontWeight: 600,
        margin: '12px 0',
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    tableContainer: {
        paddingLeft: 16,
        paddingRight: 16
    },
    table: {
        minWidth: 750
    },

    tableRow: {
        position: 'relative'
    },
    text: {
        color: 'gray',
        fontSize: '15px'
    },
    iconRoot: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    iconText: {
        fontSize: 15,
        marginRight: '8px'
    },
    deactivateButton: {
        color: theme.palette.error.main
    },
    activateButton: {
        color: theme.palette.success.main
    }
}));

const ApiConfig = (props: {
    userId: number;
    hideDeactivateButton?: boolean;
    platform: string | undefined;
    companyName: string | undefined | null;
}) => {
    const { b2bApiConfigAction } = storeContext();

    const [showSecret, setShowSecret] = React.useState<boolean[]>([]);
    const [apiConfigs, setApiConfig] = React.useState<B2BApiConfig[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [modal, setModal] = React.useState<ModalState>({
        open: false,
        loading: false,
        type: 'RESET',
        activeConfig: null
    });
    const classes = useStyles();

    const handleToggleSecret = (index: number) => {
        const updatedShowSecret = [...showSecret];
        updatedShowSecret[index] = !updatedShowSecret[index];
        setShowSecret(updatedShowSecret);
    };

    const fetchApiConfig = async () => {
        setLoading(true);
        const response = await b2bApiConfigAction()?.getAPIConfig(props.userId);
        if (response) {
            setApiConfig(response);
            setShowSecret(Array(response.length).fill(false));
        }
        setLoading(false);
    };

    const handleToggleActivation = async (config: B2BApiConfig) => {
        const activeConfig = apiConfigs.find((item) => item.apiKey === config.apiKey);
        if (!activeConfig) {
            return;
        }

        // set modal to open, to confirm before deactivating the API config
        if (activeConfig?.isActive) {
            setModal((prevState) => ({ ...prevState, open: true, type: 'DEACTIVATE', activeConfig }));
            return;
        }

        // activate the API config directly without confirming
        await toggleAPIConfig(activeConfig.apiKey);
    };

    const toggleAPIConfig = async (apiKey: string) => {
        setLoading(true);
        const updatedConfig = await b2bApiConfigAction()?.toggleAPIConfigStatus(props.userId, apiKey);
        if (updatedConfig && updatedConfig.apiKey) {
            const updatedConfigs = apiConfigs.map((item) => {
                if (item.apiKey === updatedConfig.apiKey) {
                    return { ...updatedConfig };
                }
                return item;
            });
            setApiConfig(updatedConfigs);
        }
        setLoading(false);
    };

    const handleResetAPIKey = async (config: B2BApiConfig) => {
        const activeConfig = apiConfigs.find((item) => item.apiKey === config.apiKey);
        if (!activeConfig) {
            return;
        }

        setModal((prevState) => ({ ...prevState, open: true, type: 'RESET', activeConfig }));
    };

    const onCloseModal = () => setModal((prevState) => ({ ...prevState, open: false, activeApiKey: null }));

    const onConfirmModal = async () => {
        if (modal.type === 'DEACTIVATE' && modal.activeConfig?.apiKey) {
            await toggleAPIConfig(modal.activeConfig?.apiKey);
        }
        if (modal.type === 'RESET' && modal.activeConfig?.apiKey) {
            setLoading(true);
            const resetConfig = await b2bApiConfigAction()?.resetAPIConfig(props.userId, modal.activeConfig?.apiKey);
            if (resetConfig && resetConfig.apiKey) {
                await fetchApiConfig();
            }
            setLoading(false);
        }
        setModal((prevState) => ({ ...prevState, loading: false, open: false, activeApiKey: null }));
    };

    React.useEffect(() => {
        fetchApiConfig();
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    const headCells: Array<HeadCell> = [
        { id: 'platform', label: 'Platform' },
        { id: 'storeName', label: 'Company / Store' },
        { id: 'storeLink', label: 'Store Url' },
        { id: 'apiKey', label: 'API Key' },
        { id: 'apiSecret', label: 'API Secret' },
        { id: 'actions', label: '' }
    ];

    const tableRows: Array<TableResultRow> = apiConfigs
        .sort((a, b) => Number(b.isActive) - Number(a.isActive))
        .map((config, index) => {
            return {
                id: {
                    text: config.id.toString()
                },
                platform: {
                    align: 'left',
                    text: config.platform || 'CARDMOOLA'
                },
                storeName: {
                    align: 'left',
                    text: capitalizeWords(config.storeName || props.companyName || '-')
                },
                storeLink: {
                    align: 'left',
                    text: config.storeLink || '-'
                },
                apiKey: {
                    align: 'left',
                    text: config.apiKey || '-'
                },
                apiSecret: {
                    align: 'left',
                    text: '',
                    element: (
                        <div className={classes.iconRoot}>
                            <Typography className={classes.iconText}>
                                {showSecret[index] ? config.apiSecret : '********'}
                            </Typography>
                            <IconButton edge="end" onClick={() => handleToggleSecret(index)}>
                                {showSecret[index] ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </div>
                    )
                },
                actions: {
                    align: 'right',
                    text: '',
                    element: !props.hideDeactivateButton ? (
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="flex-end" mb={2} mt={2}>
                                <Button
                                    variant="outlined"
                                    color='inherit'
                                    className={config?.isActive ? classes.deactivateButton : classes.activateButton}
                                    onClick={() => handleToggleActivation(config)}
                                >
                                    {config?.isActive ? 'Deactivate' : 'Activate'} API Key
                                </Button>
                                <Box ml={2}>
                                    <Button variant="contained" onClick={() => handleResetAPIKey(config)}>
                                        RESET API Key
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    ) : undefined
                }
            };
        });

    const getSubHeader = (): string => {
        if (modal.type === 'DEACTIVATE') {
            return `Deactivate API Key ${modal.activeConfig?.apiKey} of platform ${
                modal.activeConfig?.platform || 'CARDMOOLA'
            }?`;
        } else {
            return `Reset API Config of platform ${modal.activeConfig?.platform || 'CARDMOOLA'} with key ${
                modal.activeConfig?.apiKey
            }?`;
        }
    };

    return (
        <>
            {apiConfigs.length ? (
                <Box className={classes.root} data-testid="dynamic-table">
                    <Typography className={classes.heading}>API Key</Typography>

                    <Paper className={clsx(classes.paper)}>
                        <TableComponent
                            rowHover={false}
                            keyField="id"
                            showSearch={false}
                            showCheckbox={false}
                            showPaginator={{ bottom: true }}
                            isLoading={loading}
                            rows={tableRows}
                            headCells={headCells}
                            fillEmptyRows={false}
                            showToolbar={false}
                        />

                        <ConfirmationDialog
                            header={`Are you sure?`}
                            subHeader={getSubHeader()}
                            open={modal.open}
                            isLoading={modal.loading}
                            maxWidth="md"
                            onClose={onCloseModal}
                            onConfirm={onConfirmModal}
                        />
                    </Paper>
                </Box>
            ) : null}
        </>
    );
};

export default ApiConfig;
