import React, { useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { RGBColor, SketchPicker } from "react-color";
import { getColorStringFromRGBColor } from "../../utilities";

const useColorSwatchStyles = makeStyles((theme) => ({
    main: {
        width: 'max-content',
        position: 'relative'
    },
    color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
    },
    swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
    },
    popover: {
        position: 'absolute',
        zIndex: 2,
    },
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    },
}));

type ColorSwatchState = {
    openPopover: boolean;
    color: RGBColor;
}
type ColorSwatchProps = {
    color: RGBColor;
    onChange?: (color: RGBColor) => void;
}

const ColorSwatch = (props: ColorSwatchProps) => {
    const classes = useColorSwatchStyles();
    const [state, setState] = useState<ColorSwatchState>({
        openPopover: false,
        color: props.color
    });

    useEffect(() => {
        if (props.onChange) {
            props.onChange(state.color);
        }
    }, [state.color]);

    return (
        <Grid item={true} className={classes.main}>
            <div
                className={classes.swatch}
                onClick={() => {
                    if (!props.onChange) {
                        return;
                    }
                    setState(prevState => ({ ...prevState, openPopover: !prevState.openPopover }));
                }}
            >
                <div className={classes.color} style={{ background: getColorStringFromRGBColor(state.color) }} />
            </div>
            {state.openPopover && props.onChange ? <div className={classes.popover}>
                <div className={classes.cover} onClick={() => setState(prevState => ({ ...prevState, openPopover: false }))} />
                <SketchPicker color={state.color} onChange={color => setState(prevState => ({ ...prevState, color: color.rgb }))} />
            </div> : null}
        </Grid>
    );
};

export default ColorSwatch;