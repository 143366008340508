/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import TableComponent, { HeadCell, TableResultRow } from '../../common/table-component';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import SimpleSearch from '../../common/simple-search';
import { SentIncentiveGiftCard } from '../../../actions/b2b-gift-card.action';
import { B2BCustomerGiftCardRedemption } from '../../../types/b2bCustomerGiftCardRedemption';
import storeContext from '../../../contexts/store-context';
import {
    DateFormatTypeEnum,
    capitalizeWords,
    formatDinero,
    formatTimeStamp,
    generateDinero,
    getDateRangeByOptionLabel,
    getDinero
} from '../../../utilities';
import { manageUserEditFormUseStyles } from '../../manage-user/editForm';
import moment from 'moment';
import GiftCardRedemptions from '../sold-gift-cards/gift-card-redemptions';
import { getBarcodeDetails } from '../../../utilities/giftCardUtil';
import { DATE_OPTIONS, useGiftCardStyles } from '../sold-gift-cards';

const headCells: Array<HeadCell> = [
    { id: 'incentiveId', label: '#' },
    { id: 'barCodeValue', label: 'Barcode' },
    { id: 'name', label: 'Name' },
    { id: 'price', label: 'Price' },
    { id: 'createdAt', label: 'Sent Date' },
    { id: 'balance', label: 'Current Balance' },
    { id: 'incentiveFee', label: 'Incentive Fee' },
    { id: 'feesDeductedOn', label: 'Fee Deducted On' },
    { id: 'customer', label: 'Customer' },
    { id: 'recipient', label: 'Recipient Email' },
    { id: 'status', label: 'Delivery Status' },
    {
        id: 'actions',
        label: ''
    }
];

const filterData = (arr: SentIncentiveGiftCard[], state: FilterState) => {
    const { rowsPerPage, page, search, dateRange } = state;
    let totalIncentiveRecords = arr.length;

    // filter the orders for matching search text
    arr =
        (search &&
            arr.filter((i) => {
                const { barcodeValue } = getBarcodeDetails(i.barcodeCodes);
                return (
                    i.b2bCardName?.toLowerCase().includes(search.toLowerCase()) ||
                    barcodeValue?.toLowerCase().includes(search.toLowerCase()) ||
                    i.customerName?.toLowerCase().includes(search.toLowerCase()) ||
                    i.customerEmail?.toLowerCase().includes(search.toLowerCase()) ||
                    i.cardPrice.toString().includes(search.toLowerCase()) ||
                    i.balance.toString().includes(search.toLowerCase())
                );
            })) ||
        arr;

    // filter by date range
    // eslint-disable-next-line prefer-const
    let [startDate, endDate] = dateRange;
    const startDateInUTC = (startDate && moment.utc(startDate).local()) || null;
    const endDateInUTC = (endDate && moment.utc(endDate).local()) || null;
    arr = arr.filter((o) => {
        const orderCreatedAtInUTC = moment.utc(o.createdAt);
        return startDateInUTC && endDateInUTC && orderCreatedAtInUTC.isBetween(startDateInUTC, endDateInUTC);
    });

    totalIncentiveRecords = arr.length;

    // if one of these filter props change, we adjust the total records
    if (startDate || endDate || search) {
        totalIncentiveRecords = arr.length;
    }

    // order page limit
    arr = (rowsPerPage > 0 && arr.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)) || arr;

    return { arr, totalIncentiveRecords };
};

type FilterState = {
    search: string;
    page: number;
    rowsPerPage: number;
    dateOptionSelected: string;
    dateRange: [string | null, string | null];
};

const IncentiveGiftCards = (props: { userId: number, isShopifyUser: boolean }) => {
    const { b2bGiftCardAction, manageBusinessUserAction } = storeContext();
    const userFormClasses = manageUserEditFormUseStyles();
    const classes = useGiftCardStyles();

    const [b2bIncentiveCards, setIncentiveCards] = React.useState<SentIncentiveGiftCard[]>([]);
    const [isLoading, setLoading] = React.useState(false);
    const [filter, setFilter] = React.useState<FilterState>({
        page: 0,
        rowsPerPage: 5,
        search: '',
        dateOptionSelected: 'Last 30 days',
        dateRange: getDateRangeByOptionLabel(DATE_OPTIONS, 'Last 30 days')
    });

    const [redemptionsLoading, setRedemptionsLoading] = React.useState(false);
    const [openRedemptionsDialog, setOpenRedemptionsDialog] = React.useState(false);
    const [redemptions, setRedemptions] = React.useState<B2BCustomerGiftCardRedemption[]>([]);
    const [totalIncentiveFee, setTotalFee] = React.useState(0);

    const { arr: filteredIncentiveCards, totalIncentiveRecords } = filterData(b2bIncentiveCards, filter);

    const showRedemptions = async (incentiveId: number) => {
        setOpenRedemptionsDialog(true);
        setRedemptionsLoading(true);
        const newRedemptions = await b2bGiftCardAction()?.getB2BIncentiveCardRedemptions(props.userId, incentiveId);
        setOpenRedemptionsDialog(!!newRedemptions);
        setRedemptionsLoading(false);
        setRedemptions(!newRedemptions ? [] : newRedemptions);
    };

    const rows: TableResultRow[] = filteredIncentiveCards.map((item) => {
        
        const { barcodeValue } = getBarcodeDetails(item.barcodeCodes);

        const data: TableResultRow = {
            id: {
                text: item.incentiveId!.toString(),
                align: 'left'
            },
            incentiveId: {
                text: '#' + item.incentiveId!.toString(),
                align: 'left'
            },
            barCodeValue: {
                text: barcodeValue || 'NA',
                align: 'left'
            },
            name: {
                text: capitalizeWords(item.b2bCardName || ''),
                align: 'left'
            },
            price: {
                text: formatDinero(getDinero(item.cardPrice, item.currencyCode)) || '',
                align: 'left'
            },
            createdAt: {
                text: (item.createdAt && formatTimeStamp(item.createdAt, DateFormatTypeEnum.MM_DD_YYYY_h_mm_a)) || '-',
                align: 'left',
                element: (
                    <Typography>
                        {formatTimeStamp(item.createdAt!, DateFormatTypeEnum.MM_DD_YYYY_h_mm_a)}
                    </Typography>
                )
            },
            balance: {
                text: formatDinero(generateDinero(item.balance, item.currencyCode)) || '',
                align: 'left'
            },
            incentiveFee: {
                text: formatDinero(generateDinero(item.incentiveFee ? item.incentiveFee / 100 : 0)),
                align: 'left'
            },
            feesDeductedOn: {
                text: item?.feesDeductedOn
                    ? formatTimeStamp(item?.feesDeductedOn, DateFormatTypeEnum.MMMM_D_YYYY) || '---'
                    : '---',
                align: 'left'
            },
            customer: {
                text: item.customerName || item.customerEmail,
                align: 'left'
            },
            recipient: {
                text: item.customerEmail || '',
                align: 'left'
            },
            status: {
                text: `${item.status}`,
                align: 'left'
            },
            actions: {
                text: '',
                align: 'left',
                element: (
                    <Box display="flex">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => showRedemptions(item.incentiveId!)}
                        >
                            View Redemptions
                        </Button>
                    </Box>
                )
            }
        };

        return data;
    });

    const init = () => {
        fetchIncentiveGiftCards();
        if (!props.isShopifyUser) {
            fetchTotalIncentiveFee();
        }
    };

    const fetchIncentiveGiftCards = async () => {
        setLoading(true);
        const giftCards = await b2bGiftCardAction()?.getIncentiveGiftCards(props.userId);
        setIncentiveCards(giftCards ? giftCards : []);
        setLoading(false);
    };

    const fetchTotalIncentiveFee = async () => {
        setLoading(true);
        const totalFee = await manageBusinessUserAction()?.getPendingIncentiveFeeByUserId(props.userId);
        setTotalFee(totalFee ? totalFee : 0);
        setLoading(false);
    };

    const onDialogClose = () => {
        setOpenRedemptionsDialog(false);
        setRedemptionsLoading(false);
        setRedemptions([]);
    };

    const handleChangePage = (page: number) => {
        setFilter((prevState) => ({ ...prevState, page }));
    };

    const handleChangeRowsPerPage = (rowsPerPage: number) => {
        setFilter((prevState) => ({ ...prevState, page: 0, rowsPerPage }));
    };

    React.useEffect(() => {
        init();
    }, []);

    return (
        <>
            <Box>
                <Box display="flex" justifyContent="space-between">
                    <Typography className={userFormClasses.heading}>Incentive Gift Cards</Typography>
                    <Box>
                    <Typography className={userFormClasses.heading}>
                        Total Incentive Fee:{' '}
                        <Typography component="span" color="primary" className={userFormClasses.heading}>
                            {formatDinero(generateDinero(totalIncentiveFee))}
                        </Typography>
                    </Typography>
                    </Box>
                </Box>

                <Box my={2} display="flex" justifyContent="space-between">
                    <SimpleSearch
                        value={filter.search}
                        onChange={(val) =>
                            setFilter((prevState) => ({
                                ...prevState,
                                search: val
                            }))
                        }
                    />

                    <FormControl>
                        <InputLabel id="sold-gift-cards-date-filter">Show:</InputLabel>
                        <Select
                            labelId="sold-gift-cards-date-filter"
                            value={filter.dateOptionSelected}
                            onChange={(e) => {
                                const dateOptionSelected = e.target.value as FilterState['dateOptionSelected'];
                                const dateRange = getDateRangeByOptionLabel(DATE_OPTIONS, dateOptionSelected);

                                setFilter((prevState) => ({
                                    ...prevState,
                                    dateOptionSelected,
                                    dateRange,
                                    page: 0
                                }));
                            }}
                        >
                            {DATE_OPTIONS.map((option) => (
                                <MenuItem key={option.label} value={option.label}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>

            <TableComponent
                rowHover={false}
                paperClass={classes.tablePaper}
                showPaginator={{ bottom: true }}
                rows={rows}
                headCells={headCells}
                keyField="id"
                showCheckbox={false}
                showToolbar={false}
                showSearch={false}
                isLoading={isLoading}
                totalRecords={totalIncentiveRecords}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <GiftCardRedemptions
                open={openRedemptionsDialog}
                loading={redemptionsLoading}
                redemptions={redemptions}
                onDialogClose={onDialogClose}
            />
        </>
    );
};

export default IncentiveGiftCards;
