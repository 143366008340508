import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Grid, Button, Container } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import CachedIcon from '@material-ui/icons/Cached';

import useAdminStyles from '../styles';

import { useUserService } from '../../contexts/user-context';
import storeContext from '../../contexts/store-context';
import HasPermission from '../../utilities/can';
import TableComponent, { HeadCell, TableResultRow } from '../common/table-component';
import UserAvatar from '../common/user-avatar';
import { joinNameParts } from '../../utilities';
import { ErrorType } from '../../types';
import { InfluencerDetails } from '../../types/influencer';
import StatusButton from '../common/status-button';

export const useStyles = makeStyles((theme) => ({
    userAvatarOuter: {
        display: 'flex',
        alignItems: 'center',
        '& $userAvatar': {
            marginRight: 16
        }
    },
    tableHeading: {
        fontWeight: 600,
        fontSize: 20,
        color: theme.palette.primary.main
    },
    toolbar: {
        textAlign: 'left'
    },
    headerComponent: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 16px'
    },
    userAvatar: {}
}));

type State = {
    isRefreshing: boolean;
    isLoading: boolean;
    menuAnchorEle: HTMLElement | null;
    selectedUserIds: Array<number>;
    users: Array<InfluencerDetails>;
    page: number;
};

const ManageInfluencer = () => {
    const classes = useStyles();
    const adminClasses = useAdminStyles();

    const history = useHistory();
    const userService = useUserService();
    const { appAction, manageInfluencerAction } = storeContext();

    const canRead = HasPermission(userService.user, 'MANAGE_INFLUENCER', 'READ');

    useEffect(() => {
        if (!canRead) {
            history.push('/dashboard');
            return;
        }
        appAction()?.renderFullHeader();
        init();
    }, []);

    const [state, setState] = useState<State>({
        users: [],
        isLoading: false,
        isRefreshing: false,
        menuAnchorEle: null,
        selectedUserIds: [],
        page: 0
    });

    const init = async () => {
        try {
            setState((prevState) => ({ ...prevState, isLoading: true, isRefreshing: false }));

            const users = await manageInfluencerAction()?.getAllInfluencers();

            setState((prevState) => ({
                ...prevState,
                users: users || [],
                isLoading: false,
                page: 0
            }));
        } catch (e: ErrorType) {
            setState((prevState) => ({ ...prevState, isLoading: false, isRefreshing: false, page: 0 }));
        }
    };

    const refreshUsers = async () => {
        setState((prevState) => ({ ...prevState, isLoading: false, isRefreshing: true }));
        const users = await manageInfluencerAction()?.getAllInfluencers();
        setState((prevState) => ({
            ...prevState,
            users: users || [],
            isRefreshing: false,
            page: 0
        }));
    };
    
    const handleChangePage = (page: number) => {
        setState({ ...state, page });
    };

    const headCells: Array<HeadCell> = [
        { id: 'userId', label: 'User Id', align: 'left' },
        { id: 'name', label: 'Name', align: 'left' },
        { id: 'profileName', label: 'Profile Name', align: 'left' },
        { id: 'email', label: 'Email', align: 'left' },
        { id: 'approvalStatus', label: 'Approval Status', align: 'left' }
    ];

    const tableRows: Array<TableResultRow> = state.users.map((user) => {
        const name = joinNameParts(user) || '-';

        return {
            id: {
                text: user.userId?.toString() || ''
            },
            userId: {
                text: `#${user.userId?.toString()}` || '',
                align: 'left'
            },
            name: {
                align: 'left',
                text: name,
                element: (
                    <Grid item={true} className={classes.userAvatarOuter}>
                        <UserAvatar className={classes.userAvatar} user={user} />
                        <Typography>{name}</Typography>
                    </Grid>
                )
            },
            profileName: {
                text: user.profileName || '',
                align: 'left'
            },
            email: {
                align: 'left',
                text: user.email || '-'
            },
            approvalStatus: {
                align: 'left',
                text: user.approvalStatus || '',
                element: (
                    <StatusButton
                        options={[
                            {
                                type: 'PENDING',
                                text: 'Pending',
                                color: 'warning'
                            },
                            {
                                type: 'REVOKED',
                                text: 'Revoked',
                                color: 'danger'
                            },
                            {
                                type: 'APPROVED',
                                text: 'APPROVED',
                                color: 'active'
                            }
                        ]}
                        type={user.approvalStatus}
                    />
                )
            }
        };
    });

    const headerComponent = (
        <Grid item={true} xs={12} className={classes.headerComponent}>
            <Typography className={classes.tableHeading}>Manage Influencers</Typography>
            <Button onClick={refreshUsers} size="small" variant="contained" disabled={state.isRefreshing}>
                <CachedIcon className={(state.isRefreshing && 'rotation-loading') || undefined} />
                &nbsp;Refresh
            </Button>
        </Grid>
    );

    const onCheckboxSelect = (selectedRows: TableResultRow[]) =>
        setState((prevState) => ({
            ...prevState,
            selectedUserIds: selectedRows.map(({ userId }) => +userId.text.replace(/\D/g, ''))
        }));

    const onRowClick = (userId: string) => {
        history.push('/manage-influencer/' + userId);
    };

    return (
        <Grid item={true} xs={12} className={adminClasses.root}>
            <Container className={adminClasses.container}>
                <TableComponent
                    rowHover={true}
                    headerComponent={headerComponent || undefined}
                    showToolbar={true}
                    showPaginator={{ bottom: true }}
                    showSearch={true}
                    isLoading={state.isLoading}
                    rows={tableRows}
                    page={state.page}
                    headCells={headCells}
                    fillEmptyRows={false}
                    keyField="id"
                    rowTooltip={'Click'}
                    showCheckbox={false}
                    onCheckboxSelect={onCheckboxSelect}
                    onRowClick={onRowClick}
                    onPageChange={handleChangePage}
                    overrideClasses={{
                        toolbarClass: classes.toolbar
                    }}
                />
            </Container>
        </Grid>
    );
};

export default ManageInfluencer;
