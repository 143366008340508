import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import AddIcon from '@material-ui/icons/Add';

import { ConstantType, ErrorType } from '../../types';

import TableComponent, { HeadCell, TableResultRow } from '../common/table-component';

import useAdminStyles from '../styles';
import storeContext from '../../contexts/store-context';

import { scrollTop } from '../../utilities';
import ConfirmationDialog from '../common/confirmation-dialog';
import HasPermission from '../../utilities/can';
import AddEditForm from '../fraud-score-slab/form';
import { useUserService } from '../../contexts/user-context';
import { useApiService } from '../../contexts/api-service-context';
import { useToasterData } from '../../contexts/toaster-context';
import ColorSwatch from '../fraud-score-slab/colorSwatch';
import { Box, Typography } from '@material-ui/core';
import { DEFAULT_COLOR, FraudScoreSlabObjectType } from '../fraud-score-slab';

const getData = (signinSignupScoreSlabConstant: FraudScoreSlabContextType, key: string) => {
    if (key === 'NEW') {
        return { accessStatus: null, riskScoreRange: [10, 10], color: DEFAULT_COLOR };
    }

    return signinSignupScoreSlabConstant[key];
};

type FraudScoreSlabContextType = Record<string, FraudScoreSlabObjectType>;
type State = {
    errorMessage: string | null;
    isLoading: boolean;
    isSaving: boolean;
    renderAddEditForm: boolean;
    isConfirmationDialogOpen: boolean;
    signinSignupScoreSlabConstant: ConstantType | null;
    activeFraudScoreSlabKey: string | null;
    menuAnchorEle: HTMLElement | null;
};

const ManageAuthScoreSlab = () => {
    const adminClasses = useAdminStyles();

    const history = useHistory();
    const apiService = useApiService();
    const toasterContext = useToasterData();
    const userService = useUserService();
    const { appAction } = storeContext();

    const canRead = HasPermission(userService.user, 'MANAGE_FRAUD_SCORE_SLAB', 'READ');
    const canCreate = HasPermission(userService.user, 'MANAGE_FRAUD_SCORE_SLAB', 'CREATE');
    const canUpdate = HasPermission(userService.user, 'MANAGE_FRAUD_SCORE_SLAB', 'UPDATE');
    const canDelete = HasPermission(userService.user, 'MANAGE_FRAUD_SCORE_SLAB', 'DELETE');

    useEffect(() => {
        if (!canRead) {
            history.push('/dashboard');
            return;
        }
        appAction()?.renderFullHeader();
        getAll();
    }, []);

    const [state, setState] = useState<State>({
        signinSignupScoreSlabConstant: null,
        activeFraudScoreSlabKey: null,
        isLoading: false,
        isSaving: false,
        isConfirmationDialogOpen: false,
        renderAddEditForm: false,
        menuAnchorEle: null,
        errorMessage: null
    });

    const signinSignupScoreSlab = (state.signinSignupScoreSlabConstant?.value || {}) as FraudScoreSlabContextType;

    const getAll = async () => {
        try {
            setState((prevState) => ({ ...prevState, isLoading: true }));

            const apiResponse = await apiService.get(`/api/admin/constants/get-all`);
            const response = apiResponse.parsedBody;

            setState((prevState) => ({ ...prevState, isLoading: false }));

            if (!response || !response.status) {
                toasterContext.setToaster({
                    isOpen: true,
                    message: response.message,
                    severity: 'error'
                });
                return;
            }
            const constantData: ConstantType = response.data.find(
                (c: ConstantType) => c.key === 'AUTH_SCORE_SLAB'
            );

            setState((prevState) => ({
                ...prevState,
                signinSignupScoreSlabConstant: constantData
            }));
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
    };

    const saveApi = async (constant: ConstantType) => {
        try {
            setState((prevState) => ({
                ...prevState,
                isSaving: true,
                menuAnchorEle: null,
                activeFraudScoreSlabKey: null
            }));
            const apiResponse = await apiService.post(`/api/admin/constants/update`, { constant });
            const response = apiResponse.parsedBody;
            setState((prevState) => ({ ...prevState, isSaving: false, isConfirmationDialogOpen: false }));

            if (!response || !response.status) {
                toasterContext.setToaster({
                    isOpen: true,
                    message: response.message,
                    severity: 'error'
                });
                return;
            }

            setState((prevState) => ({
                ...prevState,
                signinSignupScoreSlabConstant: response.data as ConstantType,
                renderAddEditForm: false
            }));
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
            setState((prevState) => ({ ...prevState, isSaving: false, menuAnchorEle: null }));
        }
    };

    const validateSlabs = (signinSignupScoreSlabConstant: Array<Array<number>>) => {
        let isValid = true;
        if (signinSignupScoreSlabConstant.length === 1) {
            return isValid;
        }

        for (let i = 0; i < signinSignupScoreSlabConstant.length; i++) {
            if (i === 0) {
                const [min, max] = signinSignupScoreSlabConstant[i];
                const [nextMin, nextMax] = signinSignupScoreSlabConstant[i + 1];
                if (max > nextMax) {
                    isValid = false;
                    break;
                }
                continue;
            }

            const [prevMin, prevMax] = signinSignupScoreSlabConstant[i - 1];
            const [min, max] = signinSignupScoreSlabConstant[i];
            if (min < prevMin || min === prevMin || min < prevMax || min === prevMax) {
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    const onSave = (fraudScoreSlab: FraudScoreSlabObjectType) => {
        const newKey = fraudScoreSlab.accessStatus!;
        const signinSignupScoreSlabConstant = {
            ...signinSignupScoreSlab,
            [newKey]: { ...fraudScoreSlab }
        };

        // sort slabs with respect to values.min
        const sortedScoreSlabs = Object.keys(signinSignupScoreSlabConstant)
            .map((key) => {
                const [min, max] = signinSignupScoreSlabConstant[key].riskScoreRange;
                return { min, key };
            })
            .sort((a, b) => a.min - b.min)
            .reduce((acc, ele) => {
                if (state.activeFraudScoreSlabKey === ele.key && state.activeFraudScoreSlabKey === newKey) {
                    return { ...acc, [ele.key]: signinSignupScoreSlabConstant[ele.key] };
                }
                if (state.activeFraudScoreSlabKey === ele.key) {
                    return acc;
                }
                return { ...acc, [ele.key]: signinSignupScoreSlabConstant[ele.key] };
            }, {} as FraudScoreSlabContextType);

        const sortedScoreValuesArray = Object.keys(sortedScoreSlabs).map((key) => sortedScoreSlabs[key].riskScoreRange);
        const isRiskScoreValid = validateSlabs(sortedScoreValuesArray);

        if (!isRiskScoreValid) {
            const errorMessage = `Fraud score slabs are not valid, min and max are overlapping!`;
            toasterContext.setToaster({
                isOpen: true,
                message: errorMessage,
                severity: 'error'
            });
            setState((prevState) => ({
                ...prevState,
                menuAnchorEle: null,
                isConfirmationDialogOpen: false,
                errorMessage
            }));
            setTimeout(() => setState((prevState) => ({ ...prevState, errorMessage: null })), 6000);
            return;
        }

        if (state.signinSignupScoreSlabConstant) {
            saveApi({
                ...state.signinSignupScoreSlabConstant,
                value: sortedScoreSlabs
            });
        }
    };

    const headCells: Array<HeadCell> = [
        { id: 'accessStatus', label: 'Access Status' },
        { id: 'color', label: 'Color' },
        { id: 'riskScoreRange', label: 'Risk Score Range' },
        { id: 'options', label: '', align: 'right' }
    ];

    const tableRows: Array<TableResultRow> = Object.keys(signinSignupScoreSlab).map((key) => {
        const data = signinSignupScoreSlab[key];
        const [riskMin, riskMax] = data.riskScoreRange;
        return {
            id: {
                text: key
            },
            accessStatus: {
                align: 'left',
                text: data.accessStatus || '-'
            },
            color: {
                align: 'left',
                text: '',
                element: <ColorSwatch color={data.color} />
            },
            riskScoreRange: {
                align: 'left',
                text: `${riskMin}_TO_${riskMax}`
            },
            options: {
                align: 'right',
                text: '',
                element:
                    ((canUpdate || canDelete) && (
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            disabled={!!state.activeFraudScoreSlabKey}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                if (state.activeFraudScoreSlabKey) {
                                    return;
                                }

                                setState((prevState) => ({
                                    ...prevState,
                                    menuAnchorEle: e.currentTarget,
                                    activeFraudScoreSlabKey: key
                                }));
                            }}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    )) ||
                    undefined
            }
        };
    });

    const headerComponent = (
        <Grid item={true} xs={12} className={adminClasses.headerComponent}>
            <Box display="flex" justifyContent="space-between" flex={1}>
                <Typography className={adminClasses.tableHeading}>Manage Auth Score Slab</Typography>
                <Button
                    onClick={(e) =>
                        setState((prevState) => ({ ...prevState, renderAddEditForm: true, activeFraudScoreSlabKey: 'NEW' }))
                    }
                    size="small"
                    color="primary"
                    variant="contained"
                >
                    <AddIcon />
                    &nbsp;Add New Fraud Score Slab
                </Button>
            </Box>
        </Grid>
    );

    const handleMenuClose = () => {
        setState((prevState) => ({
            ...prevState,
            menuAnchorEle: null,
            activeFraudScoreSlabKey: null
        }));
    };

    const openAddEditForm = (activeFraudScoreSlabKey: string) => {
        if (!canUpdate) {
            return;
        }

        if (state.activeFraudScoreSlabKey && state.activeFraudScoreSlabKey === 'NEW') {
            return;
        }

        setState((prevState) => ({
            ...prevState,
            activeFraudScoreSlabKey: activeFraudScoreSlabKey || null,
            renderAddEditForm: true,
            menuAnchorEle: null
        }));
        setTimeout(() => scrollTop());
    };

    const onDeleteConfirm = () => {
        if (!state.activeFraudScoreSlabKey) {
            setState((prevState) => ({
                ...prevState,
                isConfirmationDialogOpen: false,
                activeFraudScoreSlabKey: null,
                menuAnchorEle: null
            }));
            return;
        }

        const signinSignupScoreSlabConstant = state.signinSignupScoreSlabConstant;
        if (signinSignupScoreSlabConstant?.value) {
            delete (signinSignupScoreSlabConstant.value as FraudScoreSlabContextType)[state.activeFraudScoreSlabKey];
            saveApi(signinSignupScoreSlabConstant);
        }
    };

    const activeFraudScoreSlabKey =
        (state.activeFraudScoreSlabKey &&
            state.activeFraudScoreSlabKey !== 'NEW' &&
            signinSignupScoreSlab[state.activeFraudScoreSlabKey]) ||
        null;

    return (
        <Grid item={true} xs={12} className={adminClasses.root}>
            <Container className={adminClasses.container}>
                {state.renderAddEditForm && state.activeFraudScoreSlabKey && (
                    <AddEditForm
                        heading="Auth Fraud Score Slab"
                        errorMessage={state.errorMessage}
                        isSaving={state.isSaving}
                        id={state.activeFraudScoreSlabKey}
                        data={getData(signinSignupScoreSlab, state.activeFraudScoreSlabKey)}
                        onSave={onSave}
                        onCancel={() =>
                            setState((prevState) => ({
                                ...prevState,
                                renderAddEditForm: false,
                                activeFraudScoreSlabKey: null,
                                errorMessage: null
                            }))
                        }
                    />
                )}

                <Menu anchorEl={state.menuAnchorEle} open={!!state.menuAnchorEle} onClose={handleMenuClose}>
                    {canUpdate && (
                        <MenuItem
                            onClick={() =>
                                state.activeFraudScoreSlabKey && openAddEditForm(state.activeFraudScoreSlabKey)
                            }
                        >
                            Edit
                        </MenuItem>
                    )}
                    {canDelete && (
                        <MenuItem
                            onClick={() => setState((prevState) => ({ ...prevState, isConfirmationDialogOpen: true }))}
                        >
                            Delete
                        </MenuItem>
                    )}
                </Menu>

                <TableComponent
                    rowHover={Boolean(!state.activeFraudScoreSlabKey)}
                    keyField="id"
                    selectedRows={undefined}
                    showSearch={false}
                    showCheckbox={false}
                    showPaginator={{ bottom: true }}
                    headerComponent={(!state.renderAddEditForm && canCreate && headerComponent) || undefined}
                    isLoading={state.isLoading}
                    rows={tableRows}
                    headCells={headCells}
                    onDelete={() => setState((prevState) => ({ ...prevState, isConfirmationDialogOpen: true }))}
                    onCheckboxSelect={(selectedRows) => setState((prevState) => ({ ...prevState, selectedRows }))}
                    rowTooltip={(canUpdate && !state.activeFraudScoreSlabKey && 'Click to edit') || undefined}
                    onRowClick={(key) => openAddEditForm(key)}
                    fillEmptyRows={false}
                />
            </Container>

            <ConfirmationDialog
                header={'Are you sure?'}
                subHeader={`Click confirm to delete the ${
                    activeFraudScoreSlabKey || ''
                } fraud score slab. Changes can't be reverted.`}
                isLoading={state.isSaving}
                open={state.isConfirmationDialogOpen}
                onClose={() =>
                    setState((prevState) => ({
                        ...prevState,
                        isConfirmationDialogOpen: false,
                        activeFraudScoreSlabKey: null,
                        menuAnchorEle: null
                    }))
                }
                onConfirm={onDeleteConfirm}
            />
        </Grid>
    );
};

export default ManageAuthScoreSlab;
