import { StoreActionProps } from '.';

const appAction = (props?: StoreActionProps) => {
    if (!props) {
        return null;
    }

    return {
        hideHeader: () => props.dispatch({ type: 'SET_HEADER', isHidden: true, showMiniHeader: false }),
        renderMiniHeader: () => props.dispatch({ type: 'SET_HEADER', isHidden: false, showMiniHeader: true }),
        renderFullHeader: () => props.dispatch({ type: 'SET_HEADER', isHidden: false, showMiniHeader: false })
    };
};

export default appAction;

export interface AppAction {
    appAction: typeof appAction;
}
