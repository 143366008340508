import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import useAdminStyles from '../styles';
import { useApiService } from '../../contexts/api-service-context';
import { useToasterData } from '../../contexts/toaster-context';
import { ErrorType } from '../../types';
import {
    GetInfluencerReferralPaymentAttributes,
    GetPendingRealizedReferrals,
    UserInfluencerAccountAttributes
} from '../../types/influencer';

import { Button, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import ConfirmationDialog from '../common/confirmation-dialog';
import { CreatePaypalPayoutRequestType } from '../../types/paypal';
import { nanoid } from 'nanoid';
import PayoutDetails, { getAmountForRegistration, getAmountForTransaction } from './payout-details';
import { PayoutTransactionAttributes, PayoutTransactionReferralAttributes } from '../../types/influencerPayout';
import { formatDinero, getDinero } from '../../utilities';

type State = {
    isLoading: boolean;
    amount: string;
    isConfirmationDialogOpen: boolean;
    isPaymentLoading: boolean;
};

const useStyles = makeStyles({
    form: {
        margin: 'auto',
        marginBottom: 24
    },
    buttonContainer: {
        maxWidth: 600,
        marginLeft: 'auto'
    }
});

type Props = {
    paymentConstant: GetInfluencerReferralPaymentAttributes | null;
    activeUser: UserInfluencerAccountAttributes;
    pendingReferralsTransactions: Array<GetPendingRealizedReferrals>;
    pendingReferralsRegistrations: Array<GetPendingRealizedReferrals>;
    referralsSelectedForRegistrationPayment: Array<GetPendingRealizedReferrals>;
    referralsSelectedForTransactionPayment: Array<GetPendingRealizedReferrals>;
    paypalPayoutFee: number;
    onPaymentCompletion: () => void;
    onCheckboxChange: (
        referral: GetPendingRealizedReferrals,
        reason: PayoutTransactionAttributes['paymentReason'],
        isChecked: boolean
    ) => void;
};

const MakePayment = (props: Props) => {
    const classes = useStyles();
    const adminClasses = useAdminStyles();

    const apiService = useApiService();
    const toasterContext = useToasterData();

    const [state, setState] = useState<State>({
        isLoading: false,
        amount: '',
        isConfirmationDialogOpen: false,
        isPaymentLoading: false
    });

    const totalFromTransactions = props.referralsSelectedForTransactionPayment.reduce(
        (acc, cur) => acc + getAmountForTransaction(props.paymentConstant, cur.orders[0]?.baseTotal),
        0
    );
    const totalFromRegistrations = getAmountForRegistration(
        props.paymentConstant,
        props.referralsSelectedForRegistrationPayment.length
    );
    const total = totalFromTransactions + totalFromRegistrations;
    const grandTotal = total - props.paypalPayoutFee;

    const resetForm = () => {
        setState((prevState) => ({
            ...prevState,
            amount: '',
            isConfirmationDialogOpen: false
        }));
    };

    const onSubmit = () => {
        if (grandTotal === 0) {
            return;
        }
        setState((prevState) => ({ ...prevState, isConfirmationDialogOpen: true }));
    };

    const makePayment = async () => {
        if (!props.activeUser.paypalAccount) {
            toasterContext.setToaster({
                isOpen: true,
                message: 'Influencer does not have a PayPal account',
                severity: 'error'
            });
            return;
        }

        setState((prevState) => ({ ...prevState, isPaymentLoading: true }));

        const paymentDetails: (PayoutTransactionAttributes & { referrals: PayoutTransactionReferralAttributes[] })[] =
            [];

        let isFeeAdded = false;

        if (props.referralsSelectedForTransactionPayment.length > 0) {
            paymentDetails.push({
                amount: totalFromTransactions - props.paypalPayoutFee,
                paymentFee: props.paypalPayoutFee,
                influencerUserId: props.activeUser.userId!,
                paymentReason: 'SUCCESSFUL_TRANSACTION',
                paypalAccount: props.activeUser.paypalAccount,
                referrals: props.referralsSelectedForTransactionPayment.map((r) => ({
                    amount: getAmountForTransaction(props.paymentConstant, r.orders[0]?.baseTotal),
                    referralUserId: r.userId!
                }))
            });
            isFeeAdded = true;
        }

        if (totalFromRegistrations > 0 && props.referralsSelectedForRegistrationPayment.length > 0) {
            const amount = isFeeAdded ? totalFromRegistrations : totalFromRegistrations - props.paypalPayoutFee;
            paymentDetails.push({
                amount,
                paymentFee: isFeeAdded ? 0 : props.paypalPayoutFee,
                influencerUserId: props.activeUser.userId!,
                paymentReason: 'SUCCESSFUL_REGISTRATION',
                paypalAccount: props.activeUser.paypalAccount,
                referrals: props.referralsSelectedForRegistrationPayment.map((r) => ({
                    amount: +(amount / props.referralsSelectedForRegistrationPayment.length).toFixed(2),
                    referralUserId: r.userId!
                }))
            });
        }

        await paypalPaymentRequest({
            paypalTransaction: {
                senderBatchHeader: {
                    sender_batch_id: nanoid(),
                    email_message: 'You have received a payout! Thanks for using our service!',
                    email_subject: 'You have a payout!'
                },
                items: [
                    {
                        amount: {
                            value: grandTotal.toFixed(2),
                            currency: 'USD'
                        },
                        sender_item_id: nanoid(),
                        receiver: props.activeUser.paypalAccount!
                    }
                ]
            },
            paymentDetails
        });
        props.onPaymentCompletion();
        setState((prevState) => ({ ...prevState, isPaymentLoading: false }));
        resetForm();
    };

    const paypalPaymentRequest = async (payload: {
        paypalTransaction: CreatePaypalPayoutRequestType;
        paymentDetails: (PayoutTransactionAttributes & { referrals: PayoutTransactionReferralAttributes[] })[];
    }) => {
        try {
            const apiResponse = await apiService.post(`/api/admin/manage-payout/paypal-payment`, payload);
            const response = apiResponse.parsedBody;
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'success'
            });
            return response.data.payoutBatchId as string;
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
            return null;
        }
    };

    return (
        <Box className={clsx(classes.form, adminClasses.form)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography className={adminClasses.heading}>Make Payment to Influencer</Typography>
                </Grid>

                <Box marginX={2} width="100%">
                    <PayoutDetails
                        pendingReferralsTransactions={props.pendingReferralsTransactions}
                        pendingReferralsRegistrations={props.pendingReferralsRegistrations}
                        referralsSelectedForTransactionPayment={props.referralsSelectedForTransactionPayment}
                        referralsSelectedForRegistrationPayment={props.referralsSelectedForRegistrationPayment}
                        activePaymentConstant={props.paymentConstant}
                        paypalPayoutFee={props.paypalPayoutFee}
                        onCheckboxChange={props.onCheckboxChange}
                    />
                </Box>

                {grandTotal > 0 && (
                    <Grid item xs={12} container spacing={1} className={classes.buttonContainer}>
                        <Grid item xs={12} md={6}></Grid>
                        <Grid item xs={12} md={6}>
                            <Button
                                variant="contained"
                                type="button"
                                color="primary"
                                fullWidth
                                onClick={onSubmit}
                                disabled={state.isPaymentLoading}
                            >
                                {state.isPaymentLoading && <CircularProgress className="button-loader" />}
                                Pay
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Grid>

            <ConfirmationDialog
                header={'Confirm payment'}
                subHeader={
                    <>
                        You will pay <strong>{formatDinero(getDinero(grandTotal))}</strong> to{' '}
                        <strong>
                            {props.activeUser?.email} (User Id: {props.activeUser?.userId})
                        </strong>
                        .<br />
                        <br />
                        Click <strong>CONFIRM</strong> to complete the payment.
                    </>
                }
                isLoading={state.isPaymentLoading}
                open={state.isConfirmationDialogOpen}
                onClose={() => setState((prevState) => ({ ...prevState, isConfirmationDialogOpen: false }))}
                onConfirm={makePayment}
            />
        </Box>
    );
};

export default MakePayment;
