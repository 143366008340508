import React from 'react';
import { Box, Button, Divider, IconButton, Paper, Typography } from '@material-ui/core';
import TableComponent, { HeadCell, TableResultRow } from '../../common/table-component';
import { B2BGiftCardAttributes } from '../../../types/b2bGiftCard';
import useAdminStyles from '../../styles';
import storeContext from '../../../contexts/store-context';
import { UserAccount } from '../../../types';
import CategoryFilter, { useCategoryFilter } from '../../common/category-filter';
import { GifterCardTypeEnum } from '../../../types/savedProducts';
import { formatPriceDenomination } from '../../../utilities/giftCardUtil';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/Remove';
import SaveMerchantCardsToBuyCardsForm from './form';
import { scrollToElement } from '../../../utilities';

const SaveMerchantCardsToBuyCards = () => {
    const adminClasses = useAdminStyles();
    const { b2bGiftCardAction, manageBusinessUserAction } = storeContext();

    const categoryFilter = useCategoryFilter({
        hideCountry: true
    });

    const [isLoading, setIsLoading] = React.useState(false);
    const [giftCards, setGiftCards] = React.useState<B2BGiftCardAttributes[]>([]);
    const [merchants, setMerchants] = React.useState<UserAccount[]>([]);
    const [showForm, setShowForm] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);
    const [activeGiftCardId, setActiveGiftCardId] = React.useState<number | null>(null);
    const [activeMerchantUserId, setActiveMerchantUserId] = React.useState<number | null>(null);
    const [displayOnB2cBuyCardsPage, setDisplayOnB2cBuyCardsPage] = React.useState(false);
    const [displayOnB2bBuyCardsPage, setDisplayOnB2bBuyCardsPage] = React.useState(false);

    const activeGiftCard = giftCards.find((gc) => gc.id === activeGiftCardId);

    const headCells: Array<HeadCell> = [
        { id: 'name', label: 'Name' },
        { id: 'priceDenominations', label: 'Price' },
        { id: 'options', label: '' }
    ];

    const tableRows: Array<TableResultRow> = React.useMemo(
        () =>
            giftCards.map((obj) => {
                return {
                    id: {
                        text: obj.id?.toString() || ''
                    },
                    name: {
                        align: 'left',
                        text: obj.name || '-'
                    },
                    priceDenominations: {
                        align: 'left',
                        text: formatPriceDenomination(obj)
                    },
                    options: {
                        align: 'left',
                        text: '',
                        element: (
                            <Box>
                                <IconButton
                                    edge="end"
                                    aria-label="check"
                                    onClick={() => {
                                        setActiveGiftCardId(obj.id!);
                                        setDisplayOnB2bBuyCardsPage(obj.displayOnB2bBuyCardsPage);
                                        setDisplayOnB2cBuyCardsPage(obj.displayOnB2cBuyCardsPage);
                                        setActiveMerchantUserId(obj.userId); // TODO: should fetch the merchant User ID (not sub-user)
                                        setShowForm(true);
                                        scrollToElement('#save-merchant-cards-form');
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Box>
                        )
                    }
                };
            }),
        [giftCards]
    );

    const fetchGiftCards = async (giftCardType: typeof GifterCardTypeEnum[number]) => {
        setIsLoading(true);
        const cards = await b2bGiftCardAction()?.getCardMoolaGiftCardsShownOnBuyCardsPage({
            giftCardType
        });
        setGiftCards(cards || []);
        setIsLoading(false);
    };

    const fetchMerchants = async () => {
        const users = await manageBusinessUserAction()?.getAllMerchants();
        setMerchants(users || []);
    };

    const closeForm = () => {
        setShowForm(false);
        setActiveGiftCardId(null);
        setActiveMerchantUserId(null);
        setDisplayOnB2cBuyCardsPage(false);
        setDisplayOnB2bBuyCardsPage(false);
    };

    const onChangeMerchant = (merchantUserId: number) => {
        setActiveMerchantUserId(merchantUserId);
    };

    const onChangeDisplayOnB2cBuyCardsPage = (display: boolean) => {
        setDisplayOnB2cBuyCardsPage(display);
    };

    const onChangeDisplayOnB2bBuyCardsPage = (display: boolean) => {
        setDisplayOnB2bBuyCardsPage(display);
    };

    const onAdd = () => {
        setActiveGiftCardId(null);
        setActiveMerchantUserId(merchants.length > 0 ? merchants[0].userId! : null);
        setShowForm(true);
    };

    const onSubmit = async (b2bGiftCardId: number) => {
        setIsSaving(true);
        await b2bGiftCardAction()?.updateCardMoolaGiftCardsShownOnBuyCardsPage(b2bGiftCardId, displayOnB2cBuyCardsPage, displayOnB2bBuyCardsPage);
        await fetchGiftCards(categoryFilter.giftCardType);
        closeForm();
        setIsSaving(false);
    };

    React.useEffect(() => {
        fetchGiftCards(categoryFilter.giftCardType);
    }, [categoryFilter.giftCardType]);

    React.useEffect(() => {
        fetchMerchants();
    }, []);

    return (
        <Box my={5}>
            <Paper>
                <Box m={2} pt={3} textAlign="left">
                    <Typography align="left" className={adminClasses.heading}>
                        Save Merchant Cards To Buy Cards Page
                    </Typography>

                    {showForm && (
                        <Box mb={4}>
                            <SaveMerchantCardsToBuyCardsForm
                                merchants={merchants}
                                b2bGiftCard={activeGiftCard}
                                activeMerchantUserId={activeMerchantUserId}
                                displayOnB2bBuyCardsPage={displayOnB2bBuyCardsPage}
                                displayOnB2cBuyCardsPage={displayOnB2cBuyCardsPage}
                                isSaving={isSaving}
                                onCancel={closeForm}
                                onChangeMerchant={onChangeMerchant}
                                onChangeDisplayOnB2bBuyCardsPage={onChangeDisplayOnB2bBuyCardsPage}
                                onChangeDisplayOnB2cBuyCardsPage={onChangeDisplayOnB2cBuyCardsPage}
                                onSubmit={onSubmit}
                            />
                        </Box>
                    )}

                    {!showForm && (
                        <Box mb={4}>
                            <Button type="button" variant="contained" color="primary" onClick={onAdd}>
                                Add New Entry
                            </Button>
                        </Box>
                    )}

                    <Box mt={5} pb={2}>
                        <Divider />
                    </Box>

                    <CategoryFilter {...categoryFilter} />
                </Box>

                <TableComponent
                    showPaginator={{ bottom: true }}
                    showCheckbox={false}
                    showSearch={true}
                    showToolbar={false}
                    isLoading={isLoading}
                    rows={tableRows}
                    headCells={headCells}
                    keyField="id"
                    fillEmptyRows={false}
                />
            </Paper>
        </Box>
    );
};

export default SaveMerchantCardsToBuyCards;
