import React from 'react';
import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useApiService } from '../../../contexts/api-service-context';
import { useToasterData } from '../../../contexts/toaster-context';
import ConfirmationDialog from '../../common/confirmation-dialog';
import { SUPPORTED_TRANSLATION_LANGUAGES } from '../../../constants';
import { SyncB2BGiftCardTranslationsOptions } from '../../../types/savedProducts';

const DEFAULT_STATE_PARAMETERS: SyncB2BGiftCardTranslationsOptions = {
    parameters: Object.keys(SUPPORTED_TRANSLATION_LANGUAGES).map((lang) => ({
        lang,
        translateDescription: false,
        translateTerms: false
    })),
    productIds: []
};

type Props = {
    productIds?: string[];
    /**
     * @default false
     *
     * if true, it will execute the translations in the foreground, else the translation happens in the background
     */
    immediate?: boolean;
    open: boolean;
    onClose: () => void;
};

const SyncB2BGiftCardTranslationsModal: React.FC<Props> = (props) => {
    const apiService = useApiService();
    const toasterContext = useToasterData();

    const [isSaving, setIsSaving] = React.useState(false);
    const [payload, setPayload] = React.useState<SyncB2BGiftCardTranslationsOptions>(DEFAULT_STATE_PARAMETERS);

    const TRANSLATION_PRICING_LINK =
        'https://azure.microsoft.com/en-us/pricing/details/cognitive-services/translator/#pricing';

    const syncTranslationsInDB = async () => {
        setIsSaving(true);
        const apiResponse = await apiService.post(
            `/api/admin/manage-product/sync-b2b-translations${props.immediate ? '-immediate' : ''}`,
            {
                ...payload,
                productIds: props.productIds
            }
        );
        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            setIsSaving(false);
            closeDialog();
            return;
        }
        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });
        setIsSaving(false);
        closeDialog();
    };

    const closeDialog = () => {
        setPayload(DEFAULT_STATE_PARAMETERS);
        props.onClose();
    };

    const onConfirm = () => {
        syncTranslationsInDB();
    };

    const translateDescription = (lang: string) => {
        setPayload((prevState) => ({
            ...prevState,
            parameters: prevState.parameters.map((parameter) => {
                if (lang === parameter.lang) {
                    return {
                        ...parameter,
                        translateDescription: !parameter.translateDescription
                    };
                }
                return parameter;
            })
        }));
    };

    const translateTerms = (lang: string) => {
        setPayload((prevState) => ({
            ...prevState,
            parameters: prevState.parameters.map((parameter) => {
                if (lang === parameter.lang) {
                    return {
                        ...parameter,
                        translateTerms: !parameter.translateTerms
                    };
                }
                return parameter;
            })
        }));
    };

    return (
        <ConfirmationDialog
            header={'Select Parameters For Translation'}
            subHeader={
                <div>
                    <Box>
                        {Object.keys(SUPPORTED_TRANSLATION_LANGUAGES).map((lang) => (
                            <Box key={lang} mb={1}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <strong>{SUPPORTED_TRANSLATION_LANGUAGES[lang]}</strong>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <FormControlLabel
                                            checked={payload.parameters.some(
                                                (p) => p.lang === lang && p.translateDescription
                                            )}
                                            control={<Checkbox />}
                                            label="Translate Description"
                                            onClick={() => translateDescription(lang)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControlLabel
                                            checked={payload.parameters.some(
                                                (p) => p.lang === lang && p.translateTerms
                                            )}
                                            control={<Checkbox />}
                                            label="Translate Terms"
                                            onClick={() => translateTerms(lang)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        ))}
                    </Box>
                    <Alert severity="warning">
                        <AlertTitle>NOTE:</AlertTitle>
                        This API is chargeable, check{' '}
                        <a href={TRANSLATION_PRICING_LINK} target="_blank" rel="noreferrer">
                            {TRANSLATION_PRICING_LINK}
                        </a>{' '}
                        for the latest pricing.
                    </Alert>
                    <Box marginTop={1.5}>
                        <Typography>
                            {!props.immediate && (
                                <>
                                    A notification email will be sent upon the completion of the translations.
                                    <br /> <br />
                                </>
                            )}
                            Click CONFIRM to continue.
                        </Typography>
                    </Box>
                </div>
            }
            isLoading={isSaving}
            open={props.open}
            onClose={closeDialog}
            onConfirm={onConfirm}
        />
    );
};

export default SyncB2BGiftCardTranslationsModal;
