import React from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';

type AppTextFieldProps = TextFieldProps & {
    /**
     * Default 255
     */
    maxLength?: number;
};
export const DEFAULT_TEXT_FIELD_MAX_LENGTH = 255;
export const AppTextField = (props: AppTextFieldProps) => {
    const maxLength = props.maxLength || DEFAULT_TEXT_FIELD_MAX_LENGTH;

    return (
        <TextField
            {...props}
            value={props.value || ''}
            onChange={props.onChange}
            InputProps={{ ...props.InputProps, autoComplete: 'new-password' }}
            inputProps={{ ...props.inputProps, maxLength }}
        />
    );
};
