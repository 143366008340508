import { StoreActionProps } from '.';
import { ErrorType, GiftCardPaginator, GiftCardProduct } from '../types';
import { GifterCardTypeEnum } from '../types/savedProducts';

const generateVariableValueCards = (giftCard: GiftCardProduct): Array<number> => {
    const DENOMINATION = 25;
    const [min, max] = giftCard.denominations;
    const minCardValue = min < 5 ? 5 : min;

    const denominations: Array<number> = [];
    denominations.push(minCardValue);

    let price = DENOMINATION;
    if (minCardValue === price) {
        price = price + DENOMINATION;
    }

    if (price < minCardValue) {
        price = minCardValue + DENOMINATION;
    }

    while (price <= max) {
        denominations.push(price);
        price = price + DENOMINATION;
    }

    return denominations;
};

export const giftCardMapper = (giftCard: GiftCardProduct): GiftCardProduct => {
    return {
        ...giftCard,
        description: giftCard.longDescription || giftCard.shortDescription || giftCard.description,
        priceDenominations:
            (giftCard.denominationType === 'Fixed' && giftCard.denominations) ||
            (giftCard.denominationType === 'Variable' && generateVariableValueCards(giftCard)) ||
            []
    };
};

const setGiftCards = (
    props: StoreActionProps,
    cards: Array<GiftCardProduct> | null,
    paginator: GiftCardPaginator | null = null
) => {
    props.dispatch({ type: 'SET_GIFT_CARDS', cards: (cards || []).map((c) => giftCardMapper(c)), paginator });
    props.dispatch({ type: 'SET_GIFT_CARD_LOADING', isLoading: false });
};

export type GetGiftCardRequestParams = Partial<GiftCardPaginator> & {
    productName?: string;
    cultureCode?: string;
    /**
     * If all products, including hidden must be shown
     */
    showAll?: boolean;
    forceFetch?: boolean;
};
const getAllGiftCards = async (props: StoreActionProps, params?: GetGiftCardRequestParams) => {
    props.dispatch({ type: 'SET_GIFT_CARD_LOADING', isLoading: true });

    /**
     * no need to send params we'll fetch all the products
     * and do client side pagination
     *
     * const { state } = props;
     *
     * const page = params?.page || state.giftCardState.paginator.page || 1;
     * const pageSize = params?.pageSize || state.giftCardState.paginator.pageSize || 24;
     * const productName = params?.productName || undefined;
     */
    const { apiService, toasterContext } = props;

    try {
        const showAll = params?.showAll ? 1 : 0;
        const forceFetch = params?.forceFetch ? 1 : 0;
        const apiResponse = await apiService.post(`/public/products?show_all=${showAll}&force=${forceFetch}`, {}); // otherwise send params in request body
        const response = apiResponse.parsedBody;
        if (!response.status) {
            setGiftCards(props, null);
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        const { data, ...others } = response.data;
        setGiftCards(props, data, { ...others });
        return data;
    } catch (e: ErrorType) {
        setGiftCards(props, null);
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

const getAllB2BGiftCards = async (props: StoreActionProps, params?: GetGiftCardRequestParams) => {
    props.dispatch({ type: 'SET_GIFT_CARD_LOADING', isLoading: true });

    const { apiService, toasterContext } = props;

    try {
        const showAll = params?.showAll ? 1 : 0;
        const forceFetch = params?.forceFetch ? 1 : 0;
        const apiResponse = await apiService.get(
            `/public/business/products?show_all=${showAll}&force=${forceFetch}&cultureCode=${params?.cultureCode}`
        );
        const response = apiResponse.parsedBody;
        if (!response.status) {
            setGiftCards(props, null);
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        const { data, ...others } = response.data;
        setGiftCards(props, data, { ...others });
        return data;
    } catch (e: ErrorType) {
        setGiftCards(props, null);
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

const exportProducts = async (
    props: StoreActionProps,
    giftCardType: typeof GifterCardTypeEnum[number],
    countryCode: string,
    columns: string[]
) => {
    const { apiService, toasterContext } = props;

    try {
        await apiService.downloadFile(
            `/api/admin/manage-product/export-products?giftCardType=${giftCardType}&cultureCode=${countryCode}&columns=${columns.join(',')}`
        );
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
    }
};

const giftCardAction = (props?: StoreActionProps) => {
    if (!props) {
        return null;
    }

    return {
        getAllGiftCards: (params?: GetGiftCardRequestParams) => getAllGiftCards(props, params),
        getAllB2BGiftCards: (params?: GetGiftCardRequestParams) => getAllB2BGiftCards(props, params),
        exportProducts: (giftCardType: typeof GifterCardTypeEnum[number], cultureCode: string, columns: string[]) =>
            exportProducts(props, giftCardType, cultureCode, columns)
    };
};

export default giftCardAction;

export interface GiftCardAction {
    giftCardAction: typeof giftCardAction;
}
