import { StoreActionProps } from '.';
import { ConstantType, ErrorType, TransactionLimitSettingValue, TransactionLimitSettingView } from '../types';

const getUserTransactionLimitSettings = async (props: StoreActionProps) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/manage-transaction-limit-settings/get-all`);
        const response = apiResponse.parsedBody;

        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return [];
        }
        return response.data as TransactionLimitSettingView[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return [];
    }
};

const getUserTransactionLimitSettingsByUserId = async (props: StoreActionProps, userId: number) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(
            `/api/admin/manage-transaction-limit-settings/get-by-user-id/${userId}`
        );
        const response = apiResponse.parsedBody;

        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }
        return response.data as TransactionLimitSettingView;
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

const updateUserTransactionLimitSetting = async (
    props: StoreActionProps,
    transactionLimitSettingId: string | null,
    userId: number
) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.post(`/api/admin/manage-transaction-limit-settings/update`, {
            transactionLimitSettingId,
            userId
        });
        const response = apiResponse.parsedBody;

        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return [];
        }
        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });
        return response.data as TransactionLimitSettingView[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return [];
    }
};

const getTransactionLimitSettings = async (props: StoreActionProps) => {
    const { apiService } = props;
    try {
        const constantsApiResponse = await apiService.get(`/api/admin/constants/get-all`);
        const constantsResponse = constantsApiResponse.parsedBody;

        return (constantsResponse.data.find((c: ConstantType) => c.key === 'TRANSACTION_LIMIT_SETTINGS')?.value ||
            []) as TransactionLimitSettingValue[];
    } catch (e: ErrorType) {
        return [];
    }
};

const manageTransactionLimitSettingsAction = (props?: StoreActionProps) => {
    if (!props) {
        return null;
    }

    return {
        getTransactionLimitSettings: () => getTransactionLimitSettings(props),
        getUserTransactionLimitSettings: () => getUserTransactionLimitSettings(props),
        getUserTransactionLimitSettingsByUserId: (userId: number) =>
            getUserTransactionLimitSettingsByUserId(props, userId),
        updateUserTransactionLimitSetting: (transactionLimitSettingId: string | null, userId: number) =>
            updateUserTransactionLimitSetting(props, transactionLimitSettingId, userId)
    };
};

export default manageTransactionLimitSettingsAction;

export interface ManageTransactionLimitSettingsAction {
    manageTransactionLimitSettingsAction: typeof manageTransactionLimitSettingsAction;
}
