import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import TableComponent, { HeadCell, TableResultRow } from '../common/table-component';
import useAdminStyles from '../styles';
import storeContext from '../../contexts/store-context';
import HasPermission from '../../utilities/can';
import { useUserService } from '../../contexts/user-context';
import moment from 'moment';
import ConfirmationDialog from '../common/confirmation-dialog';

const useStyles = makeStyles((theme) => ({
    baseCurrencyHeading: {
        fontSize: 20,
        fontWeight: 600,
        textAlign: 'left',
        marginBottom: 12
    }
}));

type State = {
    isLoading: boolean;
    isSaving: boolean;
    lastUpdated: string;
    isConfirmationDialogOpen: boolean;
    rows: {
        currencyCode: string;
        exchangeRate: string;
    }[];
};

const ManageExchangeRates = () => {
    const adminClasses = useAdminStyles();
    const classes = useStyles();
    const history = useHistory();
    const userService = useUserService();

    const { exchangeRatesAction, appAction } = storeContext();

    const canRead = HasPermission(userService.user, 'MANAGE_EXCHANGE_RATES', 'READ');
    const canUpdate = HasPermission(userService.user, 'MANAGE_EXCHANGE_RATES', 'UPDATE');

    useEffect(() => {
        if (!canRead) {
            history.push('/dashboard');
            return;
        }
        appAction()?.renderFullHeader();
        getAllExchangeRates();
    }, []);

    const [state, setState] = useState<State>({
        isLoading: false,
        isSaving: false,
        isConfirmationDialogOpen: false,
        rows: [],
        lastUpdated: ''
    });

    const getAllExchangeRates = async () => {
        setState((prevState) => ({ ...prevState, isLoading: true }));

        const exchangeRatesResponse = await exchangeRatesAction()?.getAllExchangeRates();
        if (!exchangeRatesResponse) {
            return;
        }

        setState((prevState) => ({
            ...prevState,
            isLoading: false,
            isSaving: false,
            isConfirmationDialogOpen: false,
            lastUpdated: exchangeRatesResponse.updatedAt,
            rows: Object.keys(exchangeRatesResponse.exchangeRates).map((currencyCode) => ({
                currencyCode,
                exchangeRate: exchangeRatesResponse.exchangeRates[currencyCode]
            }))
        }));
    };

    const headCells: Array<HeadCell> = [
        { id: 'currencyCode', label: 'Currency' },
        { id: 'exchangeRate', label: 'Exchange Rate' }
    ];

    const tableRows: Array<TableResultRow> = state.rows.map((obj) => {
        return {
            id: {
                text: obj.currencyCode?.toString() || ''
            },
            currencyCode: {
                align: 'left',
                text: obj.currencyCode || '-'
            },
            exchangeRate: {
                align: 'left',
                text: obj.exchangeRate || '-'
            }
        };
    });

    const onConfirm = async () => {
        setState((prevState) => ({ ...prevState, isSaving: true }));
        const savedCategory = await exchangeRatesAction()?.updateExchangeRates();
        if (!savedCategory) {
            setState((prevState) => ({ ...prevState, isSaving: false }));
            return;
        }
        getAllExchangeRates();
    };

    const openModal = () => {
        setState((prevState) => ({
            ...prevState,
            isConfirmationDialogOpen: true
        }));
    };

    const closeModal = () => {
        setState((prevState) => ({
            ...prevState,
            isConfirmationDialogOpen: false
        }));
    };

    return (
        <Grid item={true} xs={12} className={adminClasses.root}>
            <Container className={adminClasses.container}>
                <Typography className={classes.baseCurrencyHeading}>Base Currency - USD</Typography>

                <Typography align="left" gutterBottom>
                    <strong>Last Updated:</strong><br />
                    {moment.utc(state.lastUpdated).format('MM/DD/YYYY hh:mm A')} UTC<br />
                    {moment.utc(state.lastUpdated).local().format('MM/DD/YYYY hh:mm A')} Local
                </Typography>

                {canUpdate && (
                    <>
                        <Typography align="left">
                            Click below to retrieve the latest exchange rates from the 3rd party API and update them in
                            the database.
                        </Typography>

                        <Box textAlign="left" marginBottom={3} marginTop={1}>
                            <Button type="button" variant="contained" onClick={openModal}>
                                Sync exchange rates in DB
                            </Button>
                        </Box>
                    </>
                )}

                <TableComponent
                    header="Exchange Rates"
                    showCheckbox={false}
                    showSearch={true}
                    isLoading={state.isLoading}
                    rows={tableRows}
                    headCells={headCells}
                    keyField="id"
                    fillEmptyRows={false}
                    rowsPerPage={25}
                    rowsOptions={[25, 50, 100, 150]}
                />
            </Container>
            <ConfirmationDialog
                header={'Are you sure?'}
                subHeader={'Click CONFIRM to continue.'}
                isLoading={state.isSaving}
                open={state.isConfirmationDialogOpen}
                onClose={closeModal}
                onConfirm={onConfirm}
            />
        </Grid>
    );
};

export default ManageExchangeRates;
