import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import TablePagination from '@material-ui/core/TablePagination';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

import { DateFormatTypeEnum, formatTimeStamp, scrollTop } from '../../utilities';
import { DataLoader } from '../common/data-loader';
import { ErrorType, UserRoleEnum } from '../../types';
import { useApiService } from '../../contexts/api-service-context';
import { useToasterData } from '../../contexts/toaster-context';
import storeContext from '../../contexts/store-context';
import { AppTextField } from '../common/app-textfield';

import moment from 'moment';
import UserRatingsFilter, { UserRatingEnum } from './ratings-filter';
import { UserRating } from '.';
import Button from '@material-ui/core/Button';

type TransactionRequest = {
    rating: typeof UserRatingEnum[number] | null;
    date: [string | null, string | null];
    userRoleType: typeof UserRoleEnum[number];
};

type State = {
    expandedAccordion: number | null;
    searchText: string | null;
    page: number;
    rowsPerPage: number;
    isLoading: boolean;
    isResendButtonLoading: boolean;
    ratings: UserRating[];
    userRoleType: typeof UserRoleEnum[number];
    ratingsFetched: boolean;
    showMoreWords: number;
};

const INITIAL_SHOW_MORE_WORDS = 40;

const DEFAULT_STATE: State = {
    expandedAccordion: null,
    searchText: null,
    page: 0,
    rowsPerPage: 5,
    isLoading: false,
    isResendButtonLoading: false,
    ratings: [],
    userRoleType: 'USER',
    ratingsFetched: false,
    showMoreWords: INITIAL_SHOW_MORE_WORDS
};

const useStyles = makeStyles((theme) => ({
    card: {
        background: theme.palette.common.white,
        boxShadow: theme.shadows['1']
    },
    cardRow: {
        marginTop: 16,
        marginBottom: 4,
        padding: 16,
        '&:first-child': {
            marginTop: 0,
            marginBottom: 6
        },
        '& $cardRowGrid': {
            '& > div': {
                textAlign: 'left',
                paddingBottom: 16,
                '&$cardRowGridPullRight': {
                    textAlign: 'right'
                },
                '&$cardRowGridPullCenter': {
                    textAlign: 'center'
                },
                [theme.breakpoints.up('sm')]: {
                    paddingBottom: 'unset',
                    '&$cardRowGridPullRight': {
                        textAlign: 'left'
                    },
                    '&$cardRowGridPullCenter': {
                        textAlign: 'left'
                    }
                }
            }
        },
        '& $actionButtonContainer': {
            textAlign: 'center',
            marginTop: 'unset',
            marginLeft: 'auto',
            width: '100%',
            maxWidth: 250,
            [theme.breakpoints.up('sm')]: {
                textAlign: 'right'
            },
            [theme.breakpoints.between('sm', 'md')]: {
                marginTop: 6
            },
            '& button': {
                width: 175,
                color: 'rgba(0, 0, 0, 0.54)',
                [theme.breakpoints.up('lg')]: {
                    width: '100%'
                }
            }
        },
        '& h6': {
            fontSize: 10,
            fontWeight: 'normal',
            letterSpacing: '1.5px',
            lineHeight: '12px',
            marginBottom: 4
        },
        '& p': {
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: '0.1px'
        }
    },
    reviewText: {
        fontSize: 10,
        maxWidth: 100,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        transition: 'max-height 0.6s ease-out',
        textAlign: 'initial',
        lineHeight: '1.75',
        '& a': {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline'
            }
        }
    },
    paginator: {
        marginLeft: 'auto !important',
        display: 'block !important',
        paddingTop: '24px !important',
        paddingBottom: '12px !important',
        border: 'none !important',
        overflow: 'unset !important',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '14px !important',
            paddingBottom: '14px !important'
        },
        '& .MuiTablePagination-selectRoot': {
            marginRight: 16
        },
        '& .MuiTablePagination-actions': {
            marginLeft: 0
        },
        '& > div': {
            flexWrap: 'wrap',
            paddingLeft: 0,
            justifyContent: 'flex-end'
        }
    },
    searchInput: {
        marginRight: 32,
        marginTop: 16,
        width: '100%',
        borderRadius: 2,
        background: '#fff',
        '& input': {
            padding: 10
        },
        '& svg': {
            color: 'rgba(0, 0, 0, 0.24)'
        }
    },
    showMoreButton: {
        marginBottom: 16,
        textTransform: 'capitalize',
        marginLeft: -8,
        marginRight: -8
    },
    cardRowGrid: {},
    cardRowGridPullRight: {},
    cardRowGridPullCenter: {},
    actionButtonContainer: {}
}));

const filterRatings = (ratings: UserRating[], state: State) => {
    // sort the ratings by createdAt date so that latest ratings are always on top
    ratings = ratings.sort((a, b) => new Date(b.createdAt || 0).getTime() - new Date(a.createdAt || 0).getTime());
    const { rowsPerPage, page, searchText } = state;
    let totalRecords = ratings.length;

    // filter the ratings for matching search text
    ratings =
        (searchText &&
            ratings.filter((r) => {
                const userIdMatch = `${r.userId}`.indexOf(searchText) !== -1;
                if (userIdMatch) {
                    return true;
                }
                const ratingMatch = `${r.userId}`.indexOf(searchText) !== -1;
                if (ratingMatch) {
                    return true;
                }
                const firstNameMatch = `${r.firstName}`.indexOf(searchText) !== -1;
                if (firstNameMatch) {
                    return true;
                }
                const accessStatusMatch = `${r.accessStatus}`.indexOf(searchText) !== -1;
                if (accessStatusMatch) {
                    return true;
                }

                return false;
            })) ||
        ratings;

    totalRecords = ratings.length;

    // page limit
    ratings = (rowsPerPage > 0 && ratings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)) || ratings;

    return { ratings, totalRecords };
};

const UserRatings = () => {
    const classes = useStyles();

    const apiService = useApiService();
    const toasterContext = useToasterData();

    const [state, setState] = useState<State>(DEFAULT_STATE);

    const clearAutomaticRefresh = React.useRef<unknown>();

    const { appAction } = storeContext();

    const getUserRatings = async (showLoader = true, requestBody?: TransactionRequest) => {
        try {
            const apiResponse = await apiService.post('/api/admin/manage-ratings/get-all-ratings', requestBody);
            const response = apiResponse.parsedBody;

            if (!response.status) {
                toasterContext.setToaster({
                    isOpen: true,
                    message: response.message,
                    severity: 'error'
                });
                setState({
                    ...state,
                    isLoading: showLoader,
                    page: 0,
                    ratingsFetched: true,
                    userRoleType: requestBody?.userRoleType || 'USER'
                });
                return;
            }

            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                page: 0,
                ratingsFetched: true,
                userRoleType: requestBody?.userRoleType || 'USER',
                ratings: response.data as UserRating[]
            }));
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
        }
    };

    useEffect(() => {
        appAction()?.renderFullHeader();
        getUserRatings(true);
        clearAutomaticRefresh.current = setInterval(() => getUserRatings(false), 1000 * 60 * 2);
        return () => clearInterval(clearAutomaticRefresh.current as number);
    }, []);

    const handleChangePage = (event: unknown, page: number) => {
        setState({ ...state, page });
        setTimeout(() => scrollTop());
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (state.rowsPerPage > +event.target.value) {
            setTimeout(() => scrollTop());
        }
        setState({ ...state, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        setState({ ...state, searchText: text });
    };

    const displayReviewText = (text: string) => {
        if (!text) {
            return '-';
        }
        if (text.length <= state.showMoreWords) {
            return text;
        }

        return text.substring(0, state.showMoreWords) + '...';
    };

    const renderShowMoreButton = (text: string) => {
        if (!text) {
            return false;
        }
        return text.length > INITIAL_SHOW_MORE_WORDS;
    };

    const onShowMoreClick = (text: string) => {
        if (!text) {
            return;
        }

        setState({
            ...state,
            showMoreWords: (state.showMoreWords >= text.length && INITIAL_SHOW_MORE_WORDS) || text.length
        });
    };

    const { ratings, totalRecords } = filterRatings(state.ratings, state);

    return (
        <Box>
            <Box className={classes.card}>
                <UserRatingsFilter
                    onFilter={(data) => {
                        const startDateUTC = data.dateRange[0] ? moment(data.dateRange[0]).utc().format() : null;
                        const endDateUTC = data.dateRange[1] ? moment(data.dateRange[1]).utc().format() : null;

                        getUserRatings(true, {
                            rating: data.rating === 'ALL' ? null : data.rating,
                            date: [startDateUTC, endDateUTC],
                            userRoleType: data.userRoleType ?? 'USER'
                        });
                    }}
                />
            </Box>

            <Box px={2} py={2} my={4} className={classes.card}>
                <Box display="flex" justifyContent="space-between">
                    <Typography align="left">Search Results</Typography>
                </Box>

                <AppTextField
                    variant="outlined"
                    placeholder="Search"
                    className={classes.searchInput}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                    value={state.searchText || ''}
                    onChange={handleSearch}
                />

                {ratings && !ratings.length ? <DataLoader isLoading={state.isLoading} items={ratings} /> : null}

                {ratings.map((r) => {
                    return (
                        <React.Fragment key={r.ratingId}>
                            <Card key={r.ratingId} className={classes.cardRow}>
                                <Grid container className={classes.cardRowGrid} justifyContent="space-between">
                                    <Grid item xs={6} sm={'auto'} lg={2}>
                                        <Typography component="h6">RATING ID #</Typography>
                                        <Typography>{r.ratingId}</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={'auto'} lg={2}>
                                        <Typography component="h6">USER ID #</Typography>
                                        <Typography>{r.userId}</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={'auto'} lg={2}>
                                        <Typography component="h6">RATING</Typography>
                                        <Typography>{r.rating}</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={'auto'} lg={2}>
                                        <Typography component="h6">REVIEW</Typography>
                                        <Box>
                                            <Typography className={classes.reviewText}>
                                                {displayReviewText(r.review)}
                                            </Typography>
                                            {renderShowMoreButton(r.review) && (
                                                <Box textAlign="left">
                                                    <Button
                                                        variant="text"
                                                        color="primary"
                                                        className={classes.showMoreButton}
                                                        onClick={() => onShowMoreClick(r.review)}
                                                    >
                                                        {state.showMoreWords === r.review.length
                                                            ? 'Show less'
                                                            : 'Show more...'}
                                                    </Button>
                                                </Box>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} sm={'auto'} lg={2}>
                                        <Typography component="h6">ACCESS STATUS</Typography>
                                        <Typography>{r.accessStatus}</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={'auto'} lg={2} className={classes.cardRowGridPullRight}>
                                        <Typography component="h6">CREATION DATE</Typography>
                                        <Typography>
                                            {formatTimeStamp(r.createdAt, DateFormatTypeEnum.MMMM_D_YYYY)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={'auto'} lg={2} className={classes.cardRowGridPullRight}>
                                        <Typography component="h6">UPDATED DATE</Typography>
                                        <Typography>
                                            {formatTimeStamp(r.updatedAt, DateFormatTypeEnum.MMMM_D_YYYY)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Card>
                        </React.Fragment>
                    );
                })}

                <TablePagination
                    classes={{ root: classes.paginator }}
                    rowsPerPageOptions={[5, 10, 25]}
                    count={totalRecords}
                    component="div"
                    rowsPerPage={state.rowsPerPage}
                    page={state.page}
                    SelectProps={{ inputProps: { 'aria-label': 'rows per page' } }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        </Box>
    );
};

export default UserRatings;
