import { makeStyles } from '@material-ui/core/styles';
import lightBlue from '@material-ui/core/colors/lightBlue';

export const useNotFoundStyles = makeStyles((theme) => ({
    root: {
        background: lightBlue[300],
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        flexDirection: 'column-reverse',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            justifyContent: 'center'
        },
        '& h1, h2': {
            fontWeight: 900,
            color: theme.palette.common.white
        },
        '& $label': {
            fontSize: 136,
            lineHeight: '1em',
            [theme.breakpoints.up('md')]: {
                fontSize: 182
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: 256
            }
        },
        '& $oops': {
            fontSize: 24,
            lineHeight: '1.25em',
            [theme.breakpoints.up('md')]: {
                fontSize: 42
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: 48
            }
        },
        '& $message': {
            fontSize: 14,
            lineHeight: '1.4em',
            marginTop: 12,
            fontWeight: 500,
            [theme.breakpoints.up('md')]: {
                marginTop: 0,
                fontWeight: 100,
                fontFamily: `'Work Sans', sans-serif !important`,
                fontSize: 20
            }
        },
        '& $inner': {
            margin: '0 auto',
            padding: '0 16px',
            [theme.breakpoints.up('md')]: {
                margin: 'auto 0',
                maxWidth: 520
            },
            [theme.breakpoints.up('lg')]: {
                maxWidth: 'none'
            },
            '& button': {
                margin: '32px auto'
            }
        }
    },
    svg: {
        height: 'auto',
        width: '90%',
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
            margin: 0,
            maxHeight: '90vh',
            width: '50%',
            padding: '0 16px'
        },
        [theme.breakpoints.up('lg')]: {
            width: '40%'
        }
    },
    label: {},
    oops: {},
    message: {},
    inner: {}
}));
