import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import useAdminStyles from '../styles';
import { useApiService } from '../../contexts/api-service-context';
import { useToasterData } from '../../contexts/toaster-context';
import { useUserService } from '../../contexts/user-context';
import HasPermission from '../../utilities/can';
import storeContext from '../../contexts/store-context';
import PayoutTransactions from './payout-transactions';
import { FetchPayoutTransactionResponse } from '../../types/influencerPayout';

type State = {
    isTransactionsLoading: boolean;
    transactions: FetchPayoutTransactionResponse;
};

const ManagePayment = () => {
    const adminClasses = useAdminStyles();

    const history = useHistory();
    const apiService = useApiService();
    const toasterContext = useToasterData();
    const userService = useUserService();
    const { appAction, influencerPayoutAction } = storeContext();

    const canRead = HasPermission(userService.user, 'MANAGE_PAYOUT', 'READ');

    useEffect(() => {
        if (!canRead) {
            history.push('/dashboard');
            return;
        }
        appAction()?.renderFullHeader();
        getAllTransactions();
    }, []);

    const [state, setState] = useState<State>({
        isTransactionsLoading: false,
        transactions: []
    });

    const getAllTransactions = async () => {
        setState((prevState) => ({ ...prevState, isTransactionsLoading: true }));
        const transactions = await influencerPayoutAction()?.getAllPayoutTransactions();
        setState((prevState) => ({
            ...prevState,
            isTransactionsLoading: false,
            transactions: transactions || []
        }));
    };

    return (
        <Grid item={true} xs={12} className={adminClasses.root}>
            <Container className={adminClasses.container}>
                <PayoutTransactions isLoading={state.isTransactionsLoading} transactions={state.transactions} />
            </Container>
        </Grid>
    );
};

export default ManagePayment;
