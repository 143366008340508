import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import useAdminStyles from '../styles';
import OrderHistory from './order-history';
import E2ETest from './e2e-test';
import OrderRestrictionContainer from './order-restriction';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto 14px',
        [theme.breakpoints.up('md')]: {
            margin: 'auto 24px'
        }
    },
    dashboardHeading: {
        fontSize: '2rem',
        marginBottom: 16,
        fontWeight: 400
    }
}));

const AdminDashboard = () => {
    const adminClasses = useAdminStyles();
    const classes = useStyles();

    return (
        <Grid item xs={12} className={clsx(adminClasses.root, classes.root)}>
            <Typography align="left" variant="h1" className={classes.dashboardHeading}>
                Dashboard
            </Typography>

            <OrderRestrictionContainer />

            {process.env.REACT_APP_ENV !== 'production' && <E2ETest />}

            <OrderHistory />
        </Grid>
    );
};

export default AdminDashboard;
