import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import TableComponent, { HeadCell, TableResultRow } from '../../common/table-component';
import { Box, Button, Chip, FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import SimpleSearch from '../../common/simple-search';
import GiftCardRedemptions from './gift-card-redemptions';
import { SoldB2BGiftCard, getFormattedOrderDeliveryType } from '../../../actions/b2b-gift-card.action';
import { B2BCustomerGiftCardRedemption } from '../../../types/b2bCustomerGiftCardRedemption';
import storeContext from '../../../contexts/store-context';
import {
    DateFormatTypeEnum,
    DateOption,
    capitalizeWords,
    formatDinero,
    formatTimeStamp,
    generateDinero,
    getDateRangeByOptionLabel,
    joinNameParts
} from '../../../utilities';
import { manageUserEditFormUseStyles } from '../../manage-user/editForm';
import moment from 'moment';
import { getBarcodeDetails } from '../../../utilities/giftCardUtil';

export const useGiftCardStyles = makeStyles({
    filterContainer: {
        background: '#fff',
        margin: 0,
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
        paddingTop: 16,
        paddingBottom: 18,
        paddingLeft: 24,
        paddingRight: 24,
        '& > div': {
            marginBottom: 8,
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'flex-start'
        }
    },
    tablePaper: {
        paddingTop: 8,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0
    }
});

const headCells: Array<HeadCell> = [
    { id: 'orderItemRecipientId', label: '#Recipient Id' },
    { id: 'barCodeValue', label: 'Barcode' },
    { id: 'name', label: 'Name' },
    { id: 'price', label: 'Price' },
    { id: 'createdAt', label: 'Purchase Date' },
    { id: 'balance', label: 'Current Balance' },
    { id: 'orderId', label: 'Order ID' },
    { id: 'customer', label: 'Customer' },
    { id: 'recipient', label: 'Recipient Email/Phone' },
    { id: 'deliveryType', label: 'Mode of Delivery' },
    {
        id: 'actions',
        label: ''
    }
];

export const DATE_OPTIONS: DateOption[] = [
    {
        offset: 0,
        startOf: 'day',
        label: 'Today'
    },
    {
        offset: 1,
        startOf: 'day',
        label: 'Yesterday'
    },
    {
        offset: 7,
        startOf: 'day',
        label: 'Last 7 days'
    },
    {
        offset: 30,
        startOf: 'day',
        label: 'Last 30 days'
    },
    {
        offset: 0,
        startOf: 'month',
        label: 'This month'
    },
    {
        offset: 1,
        startOf: 'month',
        label: 'Last month'
    },
    {
        offset: 0,
        startOf: 'year',
        label: 'This year'
    }
];

const filterData = (arr: SoldB2BGiftCard[], state: FilterState) => {
    // sort the orders by updated date so that latest orders are always on top
    const { rowsPerPage, page, search, dateRange } = state;
    let totalRecords = arr.length;

    // filter the orders for matching search text
    arr =
        (search &&
            arr.filter((o) => {
                const { barcodeValue } = getBarcodeDetails(o.recipient.barcodeCodes);
                return (
                    o.name?.toLowerCase().includes(search.toLowerCase()) ||
                    barcodeValue?.toLowerCase().includes(search.toLowerCase()) ||
                    o.description?.toLowerCase().includes(search.toLowerCase()) ||
                    o.price.toString().includes(search.toLowerCase()) ||
                    o.balance.toString().includes(search.toLowerCase())
                );
            })) ||
        arr;

    // filter by date range
    // eslint-disable-next-line prefer-const
    let [startDate, endDate] = dateRange;
    const startDateInUTC = (startDate && moment.utc(startDate).local()) || null;
    const endDateInUTC = (endDate && moment.utc(endDate).local()) || null;
    arr = arr.filter((o) => {
        const orderCreatedAtInUTC = moment.utc(o.createdAt);
        return startDateInUTC && endDateInUTC && orderCreatedAtInUTC.isBetween(startDateInUTC, endDateInUTC);
    });

    totalRecords = arr.length;

    // if one of these filter props change, we adjust the total records
    if (startDate || endDate || search) {
        totalRecords = arr.length;
    }

    const totalSalesByFilter = arr.reduce((acc, cur) => acc.add(generateDinero(cur.price)), generateDinero(0));

    // order page limit
    arr = (rowsPerPage > 0 && arr.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)) || arr;

    return { arr, totalRecords, totalSalesByFilter };
};

type FilterState = {
    search: string;
    page: number;
    rowsPerPage: number;
    dateOptionSelected: string;
    dateRange: [string | null, string | null];
};

const SoldGiftCards = (props: { userId: number }) => {
    const { b2bGiftCardAction } = storeContext();
    const userFormClasses = manageUserEditFormUseStyles();
    const classes = useGiftCardStyles();

    const [b2bGiftCards, setGiftCards] = React.useState<SoldB2BGiftCard[]>([]);
    const [isLoading, setLoading] = React.useState(false);
    const [filter, setFilter] = React.useState<FilterState>({
        page: 0,
        rowsPerPage: 5,
        search: '',
        dateOptionSelected: 'Last 30 days',
        dateRange: getDateRangeByOptionLabel(DATE_OPTIONS, 'Last 30 days')
    });

    const [redemptionsLoading, setRedemptionsLoading] = React.useState(false);
    const [openRedemptionsDialog, setOpenRedemptionsDialog] = React.useState(false);
    const [redemptions, setRedemptions] = React.useState<B2BCustomerGiftCardRedemption[]>([]);

    const { arr: filteredGiftCards, totalRecords, totalSalesByFilter } = filterData(b2bGiftCards, filter);

    const showRedemptions = async (orderItemRecipientId: number) => {
        setOpenRedemptionsDialog(true);
        setRedemptionsLoading(true);
        const newRedemptions = await b2bGiftCardAction()?.getB2BGiftCardRedemptions(props.userId, orderItemRecipientId);
        setOpenRedemptionsDialog(!!newRedemptions);
        setRedemptionsLoading(false);
        setRedemptions(!newRedemptions ? [] : newRedemptions);
    };

    const rows: TableResultRow[] = filteredGiftCards.map((item, index) => {
        const formattedDeliveryType = getFormattedOrderDeliveryType(item.recipient.deliveryType);
        const { barcodeValue } = getBarcodeDetails(item.recipient.barcodeCodes);

        const data: TableResultRow = {
            id: {
                text: item.recipient.recipientId.toString(),
                align: 'left'
            },
            orderItemRecipientId: {
                text: '#' + item.recipient.recipientId.toString(),
                align: 'left'
            },
            barCodeValue: {
                text: barcodeValue || 'NA',
                align: 'left'
            },
            name: {
                text: capitalizeWords(item.name || ''),
                align: 'left'
            },
            price: {
                text: formatDinero(generateDinero(item.price)) || '',
                align: 'left'
            },
            createdAt: {
                text: formatTimeStamp(item.createdAt, DateFormatTypeEnum.MM_DD_YYYY_h_mm_a) || '-',
                element: (
                    <Typography>{formatTimeStamp(item.createdAt, DateFormatTypeEnum.MM_DD_YYYY_h_mm_a)}</Typography>
                ),
                align: 'left'
            },
            balance: {
                text: formatDinero(generateDinero(item.balance)) || '',
                align: 'left'
            },
            orderId: {
                text: item.orderId.toString(),
                align: 'left'
            },
            customer: {
                text: joinNameParts(item.customer) || item.customer.email,
                align: 'left'
            },
            recipient: {
                text:
                    (item.recipient.deliveryType === 'EMAIL'
                        ? item.recipient.email
                        : item.recipient.deliveryType === 'SMS'
                        ? item.recipient.phone
                        : item.customer.email) || '',
                align: 'left'
            },
            deliveryType: {
                text: '',
                element: <Chip label={formattedDeliveryType.text} color={formattedDeliveryType.color} />,
                align: 'left'
            },
            actions: {
                text: '',
                align: 'left',
                element: (
                    <Box display="flex">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => showRedemptions(item.recipient.recipientId)}
                        >
                            View Redemptions
                        </Button>
                    </Box>
                )
            }
        };

        return data;
    });

    const init = () => {
        fetchSoldB2BGiftCards();
    };

    const fetchSoldB2BGiftCards = async () => {
        setLoading(true);
        const giftCards = await b2bGiftCardAction()?.getSoldGiftCards(props.userId);
        setGiftCards(giftCards ? giftCards : []);
        setLoading(false);
    };

    const onDialogClose = () => {
        setOpenRedemptionsDialog(false);
        setRedemptionsLoading(false);
        setRedemptions([]);
    };

    const handleChangePage = (page: number) => {
        setFilter((prevState) => ({ ...prevState, page }));
    };

    const handleChangeRowsPerPage = (rowsPerPage: number) => {
        setFilter((prevState) => ({ ...prevState, page: 0, rowsPerPage }));
    };

    React.useEffect(() => {
        init();
    }, []);

    return (
        <>
            <Box>
                <Box display="flex" justifyContent="space-between">
                    <Typography className={userFormClasses.heading}>Gift Card Sales</Typography>
                    <Typography className={userFormClasses.heading}>
                        TOTAL SALES AMOUNT:{' '}
                        <Typography component="span" color="primary" className={userFormClasses.heading}>
                            {formatDinero(totalSalesByFilter)}
                        </Typography>
                    </Typography>
                </Box>

                <Box my={2} display="flex" justifyContent="space-between">
                    <SimpleSearch
                        value={filter.search}
                        onChange={(val) =>
                            setFilter((prevState) => ({
                                ...prevState,
                                search: val
                            }))
                        }
                    />

                    <FormControl>
                        <InputLabel id="sold-gift-cards-date-filter">Show:</InputLabel>
                        <Select
                            labelId="sold-gift-cards-date-filter"
                            value={filter.dateOptionSelected}
                            onChange={(e) => {
                                const dateOptionSelected = e.target.value as FilterState['dateOptionSelected'];
                                const dateRange = getDateRangeByOptionLabel(DATE_OPTIONS, dateOptionSelected);

                                setFilter((prevState) => ({
                                    ...prevState,
                                    dateOptionSelected,
                                    dateRange,
                                    page: 0
                                }));
                            }}
                        >
                            {DATE_OPTIONS.map((option) => (
                                <MenuItem key={option.label} value={option.label}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>

            <TableComponent
                rowHover={false}
                paperClass={classes.tablePaper}
                showPaginator={{ bottom: true }}
                rows={rows}
                headCells={headCells}
                keyField="id"
                showCheckbox={false}
                showToolbar={false}
                showSearch={false}
                isLoading={isLoading}
                totalRecords={totalRecords}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <GiftCardRedemptions
                open={openRedemptionsDialog}
                loading={redemptionsLoading}
                redemptions={redemptions}
                onDialogClose={onDialogClose}
            />
        </>
    );
};

export default SoldGiftCards;
