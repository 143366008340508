import { StoreActionProps } from '.';
import { ErrorType, GeoLocationCountry } from '../types';

export const fetchAllGeoData = async (props: StoreActionProps) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get('/public/geo-location/get-countries');
        const response = apiResponse.parsedBody;

        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return [];
        }

        return response.data as GeoLocationCountry[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
    }
    return [];
};

export const fetchFilteredGeoData = async (props: StoreActionProps) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get('/public/business/countries-with-currencies');
        const response = apiResponse.parsedBody;

        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return [];
        }

        return response.data as GeoLocationCountry[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
    }
    return [];
};

const geoLocationAction = (props?: StoreActionProps) => {
    if (!props) {
        return null;
    }

    return {
        fetchAllGeoData: () => fetchAllGeoData(props),
        fetchFilteredGeoData: () => fetchFilteredGeoData(props)
    };
};

export default geoLocationAction;

export interface GeoLocationAction {
    geoLocationAction: typeof geoLocationAction;
}
