/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';

import SearchIcon from '@material-ui/icons/Search';

import { DataLoader } from './data-loader';
import { AppTextField } from './app-textfield';

function descendingComparator(
    a: Record<string, TableResultValue>,
    b: Record<string, TableResultValue>,
    orderBy: string
) {
    const recordA = a[orderBy]?.isNumeric ? Number(a[orderBy]?.text) : a[orderBy]?.text;
    const recordB = b[orderBy]?.isNumeric ? Number(b[orderBy]?.text) : b[orderBy]?.text;

    if (recordB < recordA) {
        return -1;
    }
    if (recordB > recordA) {
        return 1;
    }
    return 0;
}

function getComparator(order: 'asc' | 'desc', orderBy: string) {
    return order === 'desc'
        ? (a: Record<string, any>, b: Record<string, any>) => descendingComparator(a, b, orderBy)
        : (a: Record<string, any>, b: Record<string, any>) => -descendingComparator(a, b, orderBy);
}

function stableSort(
    array: TableResultRow[],
    comparator: (a: TableResultRow, b: TableResultRow) => any
): TableResultRow[] {
    const stabilizedThis: any[] = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableHeadProps {
    classes: Record<string, any>;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    order: 'asc' | 'desc';
    orderBy: string;
    numSelected: number;
    rowCount: number;
    headCells: HeadCell[];
    showCheckbox: boolean;
    onRequestSort: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, property: string) => void;
}

function EnhancedTableHead(props: EnhancedTableHeadProps) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, showCheckbox, headCells } =
        props;
    const createSortHandler = (property: string) => (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        onRequestSort(event, property);
    };
    const tableHeadClasses = makeStyles({
        tableHead: {
            '& th': {
                whiteSpace: 'nowrap',
                '& .MuiTableSortLabel-root': {
                    justifyContent: 'flex-end',
                    left: -26,
                    color: '#9E9E9E'
                }
            }
        }
    })();

    return (
        <TableHead className={tableHeadClasses.tableHead}>
            <TableRow>
                {showCheckbox && (
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>
                )}
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell?.align || 'right'}
                        padding="normal"
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <Box display="flex">
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                style={{ flexDirection: 'row-reverse' }}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                            {headCell.element && headCell.element}
                        </Box>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1)
    },
    highlight: {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85)
    },
    title: {
        flex: '1 1 100%',
        fontFamily: `'Work Sans', sans-serif`
    }
}));

interface EnhancedTableToolbarProps {
    overrideClasses?: ClassesType;
    numSelected: number;
    header?: string;
    headerComponent?: JSX.Element;
    onSelectedHeaderComponent?: JSX.Element;
    onDelete: () => void;
}

export const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { numSelected, header, onDelete, headerComponent, onSelectedHeaderComponent, overrideClasses } = props;

    const ToolbarHeading = () =>
        headerComponent ? (
            headerComponent
        ) : (
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div" align="left">
                {header}
            </Typography>
        );

    return (
        <Toolbar
            className={clsx(classes.root, overrideClasses?.toolbarClass, { [classes.highlight]: numSelected > 0 })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <ToolbarHeading />
            )}

            {(numSelected > 0 &&
                (onSelectedHeaderComponent || (
                    <Tooltip title="Delete">
                        <IconButton aria-label="delete" onClick={() => onDelete()}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                ))) ||
                null}
        </Toolbar>
    );
};

interface TableSearchProps {
    searchPlaceholder?: string;
    value: string;
    handleSearch: React.ChangeEventHandler<HTMLTextAreaElement>;
}

const TableSearch = (props: TableSearchProps) => {
    const classes = makeStyles({
        searchInput: {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            '& input': {
                padding: '11.5px 14px'
            }
        }
    })();

    const { value, searchPlaceholder, handleSearch } = props;

    return (
        <Box mx={2} mt={1} mb={2}>
            <FormControl fullWidth>
                <AppTextField
                    variant="outlined"
                    placeholder={searchPlaceholder || 'Search'}
                    className={classes.searchInput}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                    value={value || ''}
                    onChange={handleSearch}
                />
            </FormControl>
        </Box>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingTop: theme.spacing(2)
    },
    tableContainer: {
        paddingLeft: 16,
        paddingRight: 16
    },
    table: {
        minWidth: 750
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    helperText: {
        position: 'absolute',
        bottom: 0,
        maxWidth: 320,
        right: 36,
        fontStyle: 'italic',
        fontSize: 12,
        opacity: 0.8,
        letterSpacing: 0,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '0 2px'
    },
    tableRow: {
        position: 'relative'
    }
}));

export type TableResultValue = {
    text: string;
    isNumeric?: boolean;
    align?: TableCellProps['align'];
    size?: TableCellProps['size'];
    element?: JSX.Element | string;
    helperText?: string | null; // add helper text if any to last cell
};
export type TableResultRow = { [key: string]: TableResultValue };

type ClassesType = {
    toolbarClass?: string;
};

interface TableComponentProps {
    overrideClasses?: ClassesType;
    rowHover?: boolean;
    rowTooltip?: string;
    rows: TableResultRow[];
    headCells: HeadCell[];
    header?: string;
    /**
     * Default true
     */
    showCheckbox?: boolean;
    /**
     * Default true
     */
    showToolbar?: boolean;
    searchPlaceholder?: string;
    /**
     * Default true
     */
    showSearch?: boolean;
    showPaginator?: { top?: boolean; bottom?: boolean }; // default bottom true

    headerComponent?: JSX.Element;
    onSelectedHeaderComponent?: JSX.Element;

    page?: number;
    /**
     * @default 5
     */
    rowsPerPage?: number;

    selectedRows?: TableResultRow[];
    isLoading?: boolean;
    /**
     * Unique key: This field wont be displayed
     */
    keyField?: string;
    /**
     * Default 5
     */
    noOfRowsPerPage?: number;
    rowsOptions?: number[];
    /**
     * Default true
     */
    fillEmptyRows?: boolean;
    paperClass?: string;
    totalRecords?: number | null;
    onRowClick?: (key: string) => void;
    onDelete?: (rows: TableResultRow[]) => void;
    onCheckboxSelect?: (rows: TableResultRow[]) => void;
    onRowsPerPageChange?: (rows: number) => void;
    onPageChange?: (page: number) => void;
}

export type HeadCell = { id: string; label: string; element?: JSX.Element; align?: TableCellProps['align'] };

export default function TableComponent(props: TableComponentProps) {
    const classes = useStyles();
    const {
        overrideClasses,
        rowHover = true,
        rowTooltip,
        keyField,
        headCells = [],
        rows = [],
        selectedRows = [],
        header,
        isLoading = false,
        showCheckbox = true,
        showToolbar = true,
        showSearch = true,
        showPaginator = { bottom: true },
        headerComponent,
        onSelectedHeaderComponent,
        noOfRowsPerPage = 5,
        rowsOptions = [5, 10, 25],
        fillEmptyRows = true,
        paperClass = '',
        searchPlaceholder,
        onRowClick,
        onDelete,
        onCheckboxSelect,
        onRowsPerPageChange,
        onPageChange
    } = props;

    const [order, setOrder] = React.useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState<TableResultRow[]>(selectedRows);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(noOfRowsPerPage);
    const [search, setSearch] = React.useState('');
    const key = keyField || headCells[0]?.id;

    React.useEffect(() => {
        const rowExist = (sel: TableResultRow) => rows.some((row) => row[key].text === sel[key].text);
        const newSelected: TableResultRow[] = selected.filter(rowExist);
        setSelected([...newSelected]);
    }, [rows]);

    React.useEffect(() => {
        if ('page' in props) {
            setPage(props.page || 0);
        }
    }, [props.page]);

    React.useEffect(() => {
        if (props.rowsPerPage) {
            setRowsPerPage(props.rowsPerPage);
        }
    }, [props.rowsPerPage]);

    const handleRequestSort = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelected([...rows]);
            onCheckboxSelect && onCheckboxSelect([...rows]);
            return;
        }
        setSelected([]);
        onCheckboxSelect && onCheckboxSelect([]);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, dataSelected: TableResultRow) => {
        event.preventDefault();
        event.stopPropagation();
        const selectedIndex = selected.findIndex((sel) => sel[key].text === dataSelected[key].text);
        let newSelected: TableResultRow[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, dataSelected);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
        onCheckboxSelect && onCheckboxSelect(newSelected);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearch(event.target.value);
        setPage(0);
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
        onPageChange && onPageChange(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const noOfRows = parseInt(event.target.value, 10);
        setRowsPerPage(noOfRows);
        onRowsPerPageChange && onRowsPerPageChange(noOfRows);
        setPage(0);
    };

    const isSelected = (name: string) => selected.findIndex((sel) => sel[key].text === name) !== -1;

    const results = stableSort(rows, getComparator(order, orderBy))
        .filter((row) => {
            for (const key of Object.keys(row)) {
                if (typeof row[key].text === 'string' && !!row[key].text.match(new RegExp(`.*${search}.*`, 'i'))) {
                    return true;
                }
            }
            return false;
        })
        .slice(
            Object.prototype.hasOwnProperty.call(props, 'totalRecords') ? 0 : page * rowsPerPage,
            Object.prototype.hasOwnProperty.call(props, 'totalRecords') ? rows.length : page * rowsPerPage + rowsPerPage
        )
        .map((row, index) => {
            const isItemSelected = isSelected(row[key].text);
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
                <Tooltip title={rowTooltip || ''} placement="top" key={row[key].text}>
                    <TableRow
                        hover={rowHover}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row[key].text}
                        selected={isItemSelected}
                        className={clsx(
                            classes.tableRow,
                            (onRowClick && rowHover && classes.cursorPointer) || undefined
                        )}
                        onClick={(e) => {
                            if (onRowClick) {
                                onRowClick(row[key].text);
                            }
                        }}
                    >
                        {showCheckbox && (
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={isItemSelected}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                    onClick={(event) => handleClick(event, row)}
                                />
                            </TableCell>
                        )}

                        {Object.keys(row)
                            .filter((rowKey) => rowKey !== key)
                            .map((rowKey, cellIndex) => (
                                <TableCell
                                    align={row[rowKey].align || 'right'}
                                    key={rowKey}
                                    size={row[rowKey].size || 'medium'}
                                >
                                    {row[rowKey].element || row[rowKey].text}
                                    {row[rowKey]?.helperText && (
                                        <Typography variant="caption" className={classes.helperText}>
                                            {row[rowKey].helperText}
                                        </Typography>
                                    )}
                                </TableCell>
                            ))}
                    </TableRow>
                </Tooltip>
            );
        });

    const onDataDelete = () => {
        onDelete && onDelete(selected);
    };

    const emptyRows = Object.prototype.hasOwnProperty.call(props, 'totalRecords')
        ? 0
        : page > 0
        ? rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)
        : 0;
    const colSpan = headCells.length + 1;

    const paginator =
        rows.length === 0 || results.length === 0 ? null : (
            <TablePagination
                rowsPerPageOptions={rowsOptions}
                count={
                    Object.prototype.hasOwnProperty.call(props, 'totalRecords')
                        ? props.totalRecords || 0
                        : search
                        ? results.length
                        : rows.length
                }
                rowsPerPage={rowsPerPage}
                page={
                    Object.prototype.hasOwnProperty.call(props, 'totalRecords')
                        ? page
                        : page > 0 && rows.length < rowsPerPage
                        ? 0
                        : page
                }
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        );

    return (
        <div className={classes.root} data-testid="dynamic-table">
            <Paper className={clsx(paperClass, classes.paper)}>
                {showToolbar && (
                    <EnhancedTableToolbar
                        overrideClasses={overrideClasses}
                        numSelected={selected.length}
                        header={header}
                        headerComponent={headerComponent}
                        onSelectedHeaderComponent={onSelectedHeaderComponent}
                        onDelete={onDataDelete}
                    />
                )}

                {showSearch ? (
                    <TableSearch value={search} handleSearch={handleSearch} searchPlaceholder={searchPlaceholder} />
                ) : null}

                {(showPaginator?.top && paginator) || null}

                <TableContainer className={classes.tableContainer}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="medium"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            showCheckbox={showCheckbox}
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {isLoading || rows.length === 0 || results.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={colSpan}>
                                        <DataLoader isLoading={isLoading} items={results} />
                                    </TableCell>
                                </TableRow>
                            ) : null}
                            {!isLoading && results}
                            {fillEmptyRows && emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={colSpan} />
                                </TableRow>
                            )}
                            <TableRow>{(showPaginator?.bottom && paginator) || null}</TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}
