import React from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';

type Props = {
    /**
     * @default "Please wait..."
     */
    text?: string;
};

const LoadingBlock = (props: Props) => {
    return (
        <Box my={16} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <Box mb={12}>
                <Typography
                    className="fontFamilyWorkSans"
                    style={{
                        fontSize: 24,
                        fontStyle: 'normal',
                        fontWeight: 800,
                        lineHeight: '1.5em',
                        letterSpacing: 0,
                        textAlign: 'center',
                        margin: '20px 0'
                    }}
                >
                    {props.text || 'Please wait...'}
                </Typography>
            </Box>
            <Box>
                <CircularProgress />
            </Box>
        </Box>
    );
};

export default LoadingBlock;
