import React from 'react';
import { AppTextField } from './app-textfield';
import { makeStyles } from '@material-ui/core/styles';
import { Box, IconButton } from '@material-ui/core';
import SearchOutlined from '@material-ui/icons/SearchOutlined';

const useStyles = makeStyles((theme) => ({
    input: {
        maxWidth: 200,
        '& .MuiInputBase-root': {
            borderRadius: '4px 0px 0px 4px',
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)'
        },
        '& input': {
            height: 30
        }
    },
    button: {
        background: theme.palette.primary.main,
        color: 'white',
        height: 51,
        borderRadius: '0px 4px 4px 0px',
        width: 44,
        '&:hover': {
            color: theme.palette.primary.main
        }
    }
}));

type Props = { value: string; classes?: { root?: string }; onChange: (value: string) => void };

const SimpleSearch = (props: Props) => {
    const classes = useStyles();

    return (
        <Box display="flex" alignItems="center" className={props.classes?.root}>
            <AppTextField
                variant="outlined"
                placeholder="Search"
                value={props.value}
                size="small"
                className={classes.input}
                onChange={(e) => props.onChange(e.target.value)}
            />
            <IconButton aria-label="Search button" className={classes.button} size="small">
                <SearchOutlined />
            </IconButton>
        </Box>
    );
};

export default SimpleSearch;
