import React, { createContext, useContext } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.cjs.js';
import { AuthEventEnum } from './access-token-context';

export type IdleTimeoutContextProps = {
    idleTimer: {
        start: () => void;
        pause: () => void;
        getRemainingTime: () => number;
    };
    /**
     * called when user is idle
     */
    setIdleCallback: (callback: () => () => void) => void;
    /**
     * called when user makes an activity
     */
    setLastActivityCallback: (callback: () => () => void) => void;
};

type Props = { children?: React.ReactNode };
const IdleTimeoutContext = createContext<IdleTimeoutContextProps | undefined>(undefined);

const IDLE_TIMEOUT = 60_000 * 30; // 30 minute

export const isUserIdle = () => {
    const now = new Date().getTime();
    const lastActivity = localStorage.getItem(AuthEventEnum.LAST_ACTIVITY);
    return lastActivity && now - +lastActivity > IDLE_TIMEOUT;
}

export const IdleTimeoutContextProvider: React.FC = ({ children }: Props) => {
    const [idleCallback, setIdleCallback] = React.useState<() => void>(() => () => {});
    const [lastActivityCallback, setLastActivityCallback] = React.useState<() => void>(() => () => {});

    const onIdle = () => {
        idleCallback();
    };

    const onAction = () => {
        lastActivityCallback();
    };

    const idleTimer = useIdleTimer({
        startManually: true,
        crossTab: true,
        stopOnIdle: true,
        timeout: IDLE_TIMEOUT,
        onIdle,
        onAction,
    });

    return (
        <IdleTimeoutContext.Provider value={{ idleTimer, setIdleCallback, setLastActivityCallback }}>
            {children}
        </IdleTimeoutContext.Provider>
    );
};

export const useIdleTimeoutService = () => {
    const context = useContext(IdleTimeoutContext);
    if (context === undefined) {
        throw new Error('useIdleTimeoutService must be used with a IdleTimeoutContextProvider');
    }
    return context;
};
