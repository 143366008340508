import React from 'react';
import { GeoLocationCountry } from '../../../types';
import ConfirmationDialog from '../../common/confirmation-dialog';
import { Box, Typography } from '@material-ui/core';
import { SUPPORTED_TRANSLATION_LANGUAGES } from '../../../constants';
import { AppTextField } from '../../common/app-textfield';

type Props = {
    open: boolean;
    activeGeoData: GeoLocationCountry | null;
    isLoading: boolean;
    onNameChange: (cultureCode: string, name: string) => void;
    onConfirm: () => void;
    onClose: () => void;
};

const ManageTranslationsModal = (props: Props) => {
    return (
        <ConfirmationDialog
            header="Manage Translations"
            maxWidth="xl"
            isLoading={props.isLoading}
            open={props.open}
            additionalContent={
                <Box>
                    <Box display="flex" alignItems="center" mb={3}>
                        <Typography style={{ marginRight: 12 }}>English:</Typography>
                        <Typography>{props.activeGeoData?.name}</Typography>
                    </Box>
                    {Object.keys(SUPPORTED_TRANSLATION_LANGUAGES)
                        .filter((lang) => lang !== 'en')
                        .map((lang) => {
                            const cultureCode = `${lang}-${props.activeGeoData?.shortName}`;
                            const translation = props.activeGeoData?.translations?.find(
                                (t) => t.cultureCode === cultureCode
                            );

                            return (
                                <Box key={lang} display="flex" alignItems="center" mb={1}>
                                    <Typography style={{ marginRight: 12 }}>
                                        {SUPPORTED_TRANSLATION_LANGUAGES[lang]}:
                                    </Typography>
                                    <AppTextField
                                        variant="outlined"
                                        label="Name"
                                        value={translation?.name}
                                        onChange={(e) => props.onNameChange(cultureCode, e.target.value)}
                                    />
                                </Box>
                            );
                        })}
                </Box>
            }
            onClose={props.onClose}
            onConfirm={props.onConfirm}
        />
    );
};

export default ManageTranslationsModal;
