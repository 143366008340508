import { StoreActionProps } from '.';
import { ErrorType, OrderPayloadGiftCardProduct, PreferredLangType, UserAccount, UserBillingAddress } from '../types';
import { B2BBankAccountAttributes } from '../types/b2bBankAccount';
import { B2BOrderPayoutAttributes, B2BOrderPayoutPaymentMethodEnum, B2BOrderSummaryPayout } from '../types/b2bPayout';

const getCompanyDetails = async (props: StoreActionProps, userId: number) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/manage-business-user/${userId}/company-details`);
        const response = apiResponse.parsedBody;
        return response.data as {
            companyName: string;
            taxId?: null | string;
            address?: UserBillingAddress | null;
        };
    } catch (e: ErrorType) {
        toasterContext.setToaster({ isOpen: true, message: e.message, severity: 'error' });
        return null;
    }
};

export const B2BBankAccountTypeToLabel = (bankAccountType: B2BBankAccountAttributes['accountType']) => {
    switch (bankAccountType) {
        case 'SAVINGS':
            return 'Savings';
        case 'BUSINESS_CHECKING':
            return 'Business Checking';
        case 'CHECKING':
            return 'Checking';
        default:
            return 'NA';
    }
};

const getCompanyPrimaryBankAccount = async (props: StoreActionProps, userId: number) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/manage-business-user/${userId}/primary-bank-account`);
        const response = apiResponse.parsedBody;
        return (response.data as B2BBankAccountAttributes) || null;
    } catch (e: ErrorType) {
        toasterContext.setToaster({ isOpen: true, message: e.message, severity: 'error' });
        return null;
    }
};

const getFundBalance = async (props: StoreActionProps, userId: number) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/manage-business-user/${userId}/fund-balance`);
        const response = apiResponse.parsedBody;
        return response.data.balance || 0;
    } catch (e: ErrorType) {
        toasterContext.setToaster({ isOpen: true, message: e.message, severity: 'error' });
        return 0;
    }
};

const getSubscriptionPlan = async (props: StoreActionProps, userId: number) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/manage-business-user/${userId}/subscription-plan`);
        const response = apiResponse.parsedBody;
        return response.data;
    } catch (e: ErrorType) {
        toasterContext.setToaster({ isOpen: true, message: e.message, severity: 'error' });
        return 0;
    }
};

const getAllBusinessUsers = async (props: StoreActionProps) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/manage-business-user/get-all`);
        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return [];
        }

        return response.data.users as UserAccount[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({ isOpen: true, message: e.message, severity: 'error' });
        return [];
    }
};

const getBusinessUserByUserId = async (props: StoreActionProps, userId: number) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/manage-business-user/get-by-userId/${userId}`);
        const response = apiResponse.parsedBody;
        return response.data.user as UserAccount;
    } catch (e: ErrorType) {
        toasterContext.setToaster({ isOpen: true, message: e.message, severity: 'error' });
        return null;
    }
};

const getAllMerchants = async (props: StoreActionProps) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/manage-business-user/get-all-merchants`);
        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return [];
        }
        return response.data.users as UserAccount[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({ isOpen: true, message: e.message, severity: 'error' });
        return [];
    }
};

const getAllMerchantsByPlanId = async (props: StoreActionProps, planId: number) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/manage-business-user/${planId}/get-merchants`);
        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return [];
        }
        return response.data.users as UserAccount[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({ isOpen: true, message: e.message, severity: 'error' });
        return [];
    }
};

const getAllPendingPayoutOrders = async (props: StoreActionProps) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/manage-payout/business-order-payout/get-all-unpaid`);
        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return [];
        }

        return response.data as B2BOrderSummaryPayout[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({ isOpen: true, message: e.message, severity: 'error' });
        return [];
    }
};

const getAllPendingPayoutOrdersByUserId = async (props: StoreActionProps, userId: number) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/manage-business-user/${userId}/gift-card-payouts/get-all`);
        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return [];
        }

        return response.data as B2BOrderSummaryPayout[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({ isOpen: true, message: e.message, severity: 'error' });
        return [];
    }
};

const getPendingPayoutFeesByUserId = async (props: StoreActionProps, userId: number) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(
            `/api/admin/manage-payout/business-order-payout/get-pending-payout-fees/${userId}`
        );
        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            return null;
        }

        return response.data as {
            discountedTotal: number;
            totalPayoutAmount: number;
            totalOrderFees: number;
            totalGiftCardFees: number;
            totalPaymentGatewayFees: number;
            totalCardCreationFees: number;
            totalIncentiveFees: number;
        };
    } catch (e: ErrorType) {
        toasterContext.setToaster({ isOpen: true, message: e.message, severity: 'error' });
        return null;
    }
};

const getAllPayouts = async (props: StoreActionProps) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/manage-payout/business-order-payout/get-all-payouts`);
        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return [];
        }

        return response.data as B2BOrderPayoutAttributes[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({ isOpen: true, message: e.message, severity: 'error' });
        return [];
    }
};

const getAllPendingPayoutOrderItems = async (props: StoreActionProps, userId: number, orderId: string) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(
            `/api/admin/manage-business-user/${userId}/gift-card-payouts/get/${orderId}`
        );
        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return [];
        }

        return response.data as OrderPayloadGiftCardProduct[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({ isOpen: true, message: e.message, severity: 'error' });
        return [];
    }
};

const getAllPaidPayoutOrders = async (props: StoreActionProps) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/manage-payout/business-order-payout/get-all-paid`);
        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return [];
        }

        return response.data as B2BOrderSummaryPayout[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({ isOpen: true, message: e.message, severity: 'error' });
        return [];
    }
};

const getAllPaidPayoutOrderItems = async (props: StoreActionProps, orderId: number) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(
            `/api/admin/manage-payout/business-order-payout/get-all-paid-order-items/${orderId}`
        );
        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return [];
        }

        return response.data as OrderPayloadGiftCardProduct[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({ isOpen: true, message: e.message, severity: 'error' });
        return [];
    }
};

const makePayoutOrder = async (props: StoreActionProps, userId: number, payload: { amount: number }) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.post(
            `/api/admin/manage-business-user/${userId}/gift-card-payouts/pay`,
            payload
        );
        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return false;
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });

        return true;
    } catch (e: ErrorType) {
        toasterContext.setToaster({ isOpen: true, message: e.message, severity: 'error' });
        return false;
    }
};

const updateBusinessUsers = async (props: StoreActionProps, users: Array<Partial<UserAccount>>) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.post(`/api/admin/manage-business-user/update`, { users });
        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return [];
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });

        return response.data.users as UserAccount[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({ isOpen: true, message: e.message, severity: 'error' });
        return [];
    }
};

const getPendingIncentiveFeeByUserId = async (props: StoreActionProps, userId: number) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/manage-business-user/${userId}/get-pending-incentive-fee`);
        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            return null;
        }

        return response.data;
    } catch (e: ErrorType) {
        toasterContext.setToaster({ isOpen: true, message: e.message, severity: 'error' });
        return null;
    }
};

const getPendingCardCreationFeeByUserId = async (props: StoreActionProps, userId: number) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(
            `/api/admin/manage-business-user/${userId}/get-pending-card-creation-fee`
        );
        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            return null;
        }

        return response.data;
    } catch (e: ErrorType) {
        toasterContext.setToaster({ isOpen: true, message: e.message, severity: 'error' });
        return null;
    }
};

const updateLangPreference = async (props: StoreActionProps, userId: number, langPreference: PreferredLangType[] ) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.put(`/api/admin/manage-business-user/${userId}/lang`, langPreference);
        const response = apiResponse.parsedBody;
        if (!response) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }
        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'warning'
            });
            return null;
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });
    } catch (e: ErrorType) {
        toasterContext.setToaster({ isOpen: true, message: e.message, severity: 'error' });
    }
};


export const mapPayoutPaymentMethod = (paymentMethod: typeof B2BOrderPayoutPaymentMethodEnum[number]) => {
    switch (paymentMethod) {
        case 'BANK_TRANSFER':
            return 'Bank Transfer';
        case 'CARDMOOLA_FUNDS':
            return 'CardMoola Funds';
        default:
            return '-';
    }
};

const manageBusinessUserAction = (props?: StoreActionProps) => {
    if (!props) {
        return null;
    }

    return {
        getCompanyDetails: (userId: number) => getCompanyDetails(props, userId),
        getCompanyPrimaryBankAccount: (userId: number) => getCompanyPrimaryBankAccount(props, userId),
        getFundBalance: (userId: number) => getFundBalance(props, userId),
        getSubscriptionPlan: (userId: number) => getSubscriptionPlan(props, userId),
        getAllBusinessUsers: () => getAllBusinessUsers(props),
        getBusinessUserByUserId: (userId: number) => getBusinessUserByUserId(props, userId),
        getAllMerchants: () => getAllMerchants(props),
        getAllMerchantsByPlanId: (planId: number) => getAllMerchantsByPlanId(props, planId),
        updateBusinessUsers: (users: Array<Partial<UserAccount>>) => updateBusinessUsers(props, users),
        getAllPendingPayoutOrders: () => getAllPendingPayoutOrders(props),
        getAllPendingPayoutOrdersByUserId: (userId: number) => getAllPendingPayoutOrdersByUserId(props, userId),
        getPendingPayoutFeesByUserId: (userId: number) => getPendingPayoutFeesByUserId(props, userId),
        getAllPendingPayoutOrderItems: (userId: number, orderId: string) =>
            getAllPendingPayoutOrderItems(props, userId, orderId),
        getAllPayouts: () => getAllPayouts(props),
        getAllPaidPayoutOrders: () => getAllPaidPayoutOrders(props),
        getAllPaidPayoutOrderItems: (orderId: number) => getAllPaidPayoutOrderItems(props, orderId),
        makePayoutOrder: (userId: number, payload: { amount: number }) => makePayoutOrder(props, userId, payload),
        getPendingIncentiveFeeByUserId: (userId: number) => getPendingIncentiveFeeByUserId(props, userId),
        getPendingCardCreationFeeByUserId: (userId: number) => getPendingCardCreationFeeByUserId(props, userId),
        updateLangPreference: (userId: number, langPreference: PreferredLangType[]) => updateLangPreference(props, userId, langPreference)
    };
};

export default manageBusinessUserAction;

export interface ManageBusinessUserAction {
    manageBusinessUserAction: typeof manageBusinessUserAction;
}
