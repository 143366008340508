import React from 'react';

const WalletSvg = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64pt"
            height="64pt"
            viewBox="0 0 1024 1024"
            version="1.1"
            style={{ marginTop: -32 }}
            {...props}
        >
            <path
                d="M933.034667 494.933333h-17.066667v-119.466666a51.2 51.2 0 0 0-51.2-51.2h-38.4l-19.797333-74.069334a51.2 51.2 0 0 0-62.634667-36.181333l-18.773333 5.12L699.733333 165.717333a51.2 51.2 0 0 0-68.266666-24.746666L237.738667 324.266667h-21.504a51.2 51.2 0 0 0-51.2 51.2v191.829333a164.864 164.864 0 0 0 68.266666 314.88h631.466667a51.2 51.2 0 0 0 51.2-51.2v-119.466667h17.066667a17.066667 17.066667 0 0 0 17.066666-17.066666V512a17.066667 17.066667 0 0 0-17.066666-17.066667z m-286.72-324.266666a17.066667 17.066667 0 0 1 22.698666 8.192l22.528 48.469333-155.306666 41.642667a17.066667 17.066667 0 1 0 8.874666 33.109333l207.701334-55.637333a17.066667 17.066667 0 0 1 20.821333 11.946666l17.066667 65.194667H318.464zM102.4 716.8a130.901333 130.901333 0 1 1 130.901333 130.730667A131.072 131.072 0 0 1 102.4 716.8z m779.434667 51.2H570.709333a17.066667 17.066667 0 0 0 0 34.133333h311.125334v28.330667a17.066667 17.066667 0 0 1-17.066667 17.066667H333.312A161.962667 161.962667 0 0 0 374.101333 802.133333h46.933334a17.066667 17.066667 0 0 0 0-34.133333h-31.061334a164.864 164.864 0 0 0-156.672-216.234667 159.744 159.744 0 0 0-34.133333 3.584V375.466667a17.066667 17.066667 0 0 1 17.066667-17.066667h648.533333a17.066667 17.066667 0 0 1 17.066667 17.066667v119.466666H705.365333a62.464 62.464 0 0 0-62.464 62.464V648.533333a62.464 62.464 0 0 0 62.464 62.464h176.469334z m34.133333-91.136H705.365333A28.330667 28.330667 0 0 1 677.034667 648.533333v-90.624A28.330667 28.330667 0 0 1 705.365333 529.066667h210.602667z"
                fill="#592163"
            />
            <path
                d="M728.234667 620.544h25.6a17.066667 17.066667 0 0 0 0-34.133333h-25.6a17.066667 17.066667 0 0 0 0 34.133333zM500.565333 768H477.866667a17.066667 17.066667 0 0 0 0 34.133333h22.698666a17.066667 17.066667 0 0 0 0-34.133333zM242.517333 792.576l1.877334 5.290667a17.066667 17.066667 0 0 0 16.042666 11.264 17.066667 17.066667 0 0 0 5.802667-1.024 17.066667 17.066667 0 0 0 10.24-21.845334l-2.389333-6.826666A47.616 47.616 0 0 0 290.133333 761.173333a45.226667 45.226667 0 0 0 1.536-34.133333 45.738667 45.738667 0 0 0-58.88-27.306667l-10.922666 3.413334a11.434667 11.434667 0 0 1-7.68-20.48l21.504-7.850667a17.066667 17.066667 0 1 0 32.085333-11.605333 34.133333 34.133333 0 0 0-17.066667-19.285334 34.133333 34.133333 0 0 0-26.112-1.194666L221.866667 636.586667a17.066667 17.066667 0 1 0-32.085334 11.605333l2.56 6.997333a45.397333 45.397333 0 0 0 41.301334 80.042667l10.581333-3.925333a11.434667 11.434667 0 0 1 14.677333 6.826666 11.093333 11.093333 0 0 1 0 8.704 10.581333 10.581333 0 0 1-6.314666 5.802667l-21.504 7.850667a17.066667 17.066667 0 1 0-31.914667 11.776 34.133333 34.133333 0 0 0 31.914667 22.357333 34.133333 34.133333 0 0 0 11.776-2.048z"
                fill="#592163"
            />
        </svg>
    );
};

export default WalletSvg;
