import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, FormHelperText, Grid, Typography } from '@material-ui/core';
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';

const useStyles = makeStyles((theme) => ({
    footer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    resend: {
        textTransform: 'none',
        fontWeight: 400,
        fontSize: 14
    },
    helperText: {
        textAlign: 'center',
        color: theme.palette.error.main
    },
    submitBtnContainer: {
        marginBottom: 16,
        '& > div': {
            textAlign: 'left'
        }
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline'
        },
        display: 'block',
        textAlign: 'center',
        marginBottom: 6
    }
}));

type Props = {
    onResendOTP: () => void;
    isOTPConfirmLoading: boolean;
    isResendLoading: boolean;
    isResendDisabled: boolean;
    otp: string | null;
    resendTimer: number;
    isOtpInValid: boolean | null;
};

const OtpVerificationFooter = (props: Props) => {
    const {
        onResendOTP,
        isOTPConfirmLoading,
        isResendLoading,
        isResendDisabled,
        otp,
        isOtpInValid,
        resendTimer
    } = props;

    const classes = useStyles();
    const resendTimerFormatter = (value: number): string => {
        if (value < 10) {
            return '0' + value;
        }
        return '' + value;
    };

    const isNanError = isNaN(+(otp || ''));
    const isLengthError = !otp || otp.length !== 6;

    return (
        <Grid className={classes.footer}>
            <FormHelperText className={classes.helperText}>
                {isNanError && isOtpInValid && `one-time passcode must consist only digits`}
                {isLengthError && isOtpInValid && `Must be atleast 6 digits`}
            </FormHelperText>

            <Grid item={true} container className={classes.submitBtnContainer}>
                <Grid item xs={12}>
                    <Button
                        color="primary"
                        size="small"
                        startIcon={<RefreshOutlinedIcon />}
                        className={classes.resend}
                        onClick={onResendOTP}
                        disabled={isResendLoading || isResendDisabled}
                    >
                        Resend one-time passcode{' '}
                        {resendTimer > 0 && `in 0:${resendTimerFormatter(resendTimer)} seconds`}
                        {isResendLoading && <CircularProgress className="button-loader" />}
                    </Button>
                </Grid>
            </Grid>

            <Button
                color="primary"
                variant="contained"
                fullWidth={true}
                disabled={!otp || isOTPConfirmLoading}
                type="submit"
            >
                Continue
                {isOTPConfirmLoading && <CircularProgress className="button-loader" />}
            </Button>

            <Box textAlign="center" my={3} mb={5}>
                <RouterLink to={`/`} title="cardMoola" className={classes.link}>
                    Back to Home
                </RouterLink>
            </Box>
        </Grid>
    );
};

export default OtpVerificationFooter;
