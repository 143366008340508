/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

import { AccessStatusTypeEnum, B2BSubscriptionPlan, ErrorType, PreferredLangType, UserAccount, UserBillingAddress } from '../../types';
import { formatDinero, getDinero, joinNameParts } from '../../utilities';
import { AppTextField } from '../common/app-textfield';
import StatusButton from '../common/status-button';

import { FormattedPhoneNumber, manageUserEditFormUseStyles } from '../manage-user/editForm';
import ApiConfig from './apiConfig';
import storeContext from '../../contexts/store-context';
import { B2BBankAccountAttributes } from '../../types/b2bBankAccount';
import { B2BBankAccountTypeToLabel } from '../../actions/manage-business-user.action';
import SoldGiftCards from './sold-gift-cards';
import { Box } from '@material-ui/core';
import IncentiveGiftCards from './incentive-gift-cards';
import MerchantGiftCards from './merchant-gift-cards';
import AddFunds from './add-funds';
import { LANGUAGE_OPTIONS } from '.';

type AddressProps = {
    address: UserBillingAddress;
};
const Address = (props: AddressProps) => {
    const { address } = props;
    const classes = manageUserEditFormUseStyles();

    return (
        <Grid item={true} xs={12} className={clsx(classes.address, address.isPrimary && classes.primary)}>
            <Grid item={true} xs={12} className={classes.staticFields}>
                <Typography color="textSecondary" component="span">
                    Address:
                </Typography>
                <Typography>
                    {`${address?.address1 || NULL_TEXT} ${address?.address2 ? `, ${address?.address2}` : ''}`}
                </Typography>
            </Grid>
            <Grid item={true} xs={12} className={classes.staticFields}>
                <Typography color="textSecondary" component="span">
                    Country:
                </Typography>
                <Typography component="span">{address.country || NULL_TEXT}</Typography>
            </Grid>

            <Grid item={true} xs={12} className={classes.staticFields}>
                <Typography color="textSecondary" component="span">
                    City:
                </Typography>
                <Typography component="span">{address.city || NULL_TEXT}</Typography>
            </Grid>

            <Grid item={true} xs={12} className={classes.staticFields}>
                <Typography color="textSecondary" component="span">
                    ZIP:
                </Typography>
                <Typography component="span">{address.zip || NULL_TEXT}</Typography>
            </Grid>
        </Grid>
    );
};

export type UserAccountWithBillingAddress = Partial<UserAccount> & { billingAddresses?: Array<UserBillingAddress> };

type Props = {
    user: UserAccountWithBillingAddress;
    hideRevokingReasonFormField?: boolean;
    onCancel?: () => void;
    onUpdate?: (user: Array<UserAccountWithBillingAddress>) => Promise<null>;
};

type State = {
    isApproving: boolean;
    isRejecting: boolean;
    user: UserAccountWithBillingAddress | null;
    companyName: string;
    taxId?: string;
    companyAddress: UserBillingAddress;
    primaryBankAccount: B2BBankAccountAttributes | null;
    fundBalance: number;
    subscriptionPlan: B2BSubscriptionPlan | null;
};

export const ADDRESS_DEFAULT_STATE: Partial<UserBillingAddress> = {
    addressId: null,
    address1: null,
    address2: null,
    city: null,
    state: null,
    zip: null,
    country: 'United States',
    isPrimary: false,
    isDeleted: false
};

const DEFAULT_STATE: State = {
    isApproving: false,
    isRejecting: false,
    user: null,
    companyName: '',
    taxId: '',
    companyAddress: ADDRESS_DEFAULT_STATE,
    primaryBankAccount: null,
    fundBalance: 0,
    subscriptionPlan: null
};

const REASON_MAX_LENGTH = 500;
const NULL_TEXT = 'Information not available';

export const ManageBusinessUserEditForm = (props: Props) => {
    const classes = manageUserEditFormUseStyles();
    const { manageBusinessUserAction } = storeContext();
    const { hideRevokingReasonFormField, onCancel, onUpdate } = props;

    const [state, setState] = useState<State>({ ...DEFAULT_STATE, user: props.user });

    useEffect(() => {
        setState((prevState) => ({ ...prevState, user: props.user }));
        fetchCompanyDetails();
        fetchFundBalance();
        fetchCompanyPrimaryBankAccount();
        fetchSubscriptionPlan();
    }, [props.user]);

    const businessDosReasonLength = (state.user?.accessUpdateReason || '').length;

    const toBeApproved =
        state.user?.accessStatus === 'ON_WATCH' ||
        state.user?.accessStatus === 'APPROVAL_PENDING' ||
        state.user?.accessStatus === 'REVOKED';
    const toBeRejected =
        state.user?.accessStatus === 'ON_WATCH' ||
        state.user?.accessStatus === 'APPROVAL_PENDING' ||
        state.user?.accessStatus === 'APPROVED';

    const updateUser = async (accessStatus: typeof AccessStatusTypeEnum[number]) => {
        if (!state.user || businessDosReasonLength > REASON_MAX_LENGTH || !onUpdate || !onCancel) {
            return;
        }

        setState((prevState) => ({
            ...prevState,
            isApproving: !!(accessStatus === 'APPROVED'),
            isRejecting: !!(accessStatus === 'REVOKED')
        }));
        await onUpdate([
            {
                ...state.user,
                accessStatus,
                accessUpdateReason: state.user.accessUpdateReason
            }
        ]);
        setState((prevState) => ({ ...prevState, isApproving: false, isRejecting: false }));
        onCancel();
    };

    const fetchCompanyDetails = async () => {
        const userId = props.user.userId;
        if (!userId || props?.user?.registrationOrigin === 'SHOPIFY') {
            return;
        }

        const companyDetails = await manageBusinessUserAction()?.getCompanyDetails(userId);
        setState((prevState) => ({
            ...prevState,
            companyName: companyDetails?.companyName || '',
            taxId: companyDetails?.taxId || '',
            companyAddress: companyDetails?.address || ADDRESS_DEFAULT_STATE
        }));
    };

    const fetchCompanyPrimaryBankAccount = async () => {
        const userId = props.user.userId;
        if (!userId) {
            return;
        }

        const bankAccount = await manageBusinessUserAction()?.getCompanyPrimaryBankAccount(userId);
        setState((prevState) => ({
            ...prevState,
            primaryBankAccount: bankAccount || null
        }));
    };

    const fetchFundBalance = async () => {
        const userId = props.user.userId;
        if (!userId) {
            return;
        }

        const fundBalance = await manageBusinessUserAction()?.getFundBalance(userId);
        setState((prevState) => ({
            ...prevState,
            fundBalance: fundBalance || 0
        }));
    };

    const fetchSubscriptionPlan = async () => {
        const userId = props.user.userId;
        if (!userId) {
            return;
        }

        const plan = await manageBusinessUserAction()?.getSubscriptionPlan(userId);
        setState((prevState) => ({
            ...prevState,
            subscriptionPlan: plan || null
        }));
    };

    const getLanguageLabel = (lang: PreferredLangType[]): string => {
        const langString = lang.join(',');
        const option = LANGUAGE_OPTIONS.find(opt => opt.value === langString);
        return option ? option.label === 'Both' ? 'English, Arabic': option.label : '-';
      };

    const name = joinNameParts(state.user);

    return (
        <Container className={classes.mainContainer}>
            <Grid container={true} className={classes.inner}>
                <Grid item={true} xs={12} className={classes.form}>
                    <Grid container={true} alignItems="center" justifyContent="space-between">
                        <Typography className={classes.heading}>Business User Details</Typography>

                        <StatusButton
                            options={[
                                {
                                    type: 'APPROVAL_PENDING',
                                    text: 'Pending',
                                    color: 'warning'
                                },
                                {
                                    type: 'REVOKED',
                                    text: 'Access Revoked',
                                    color: 'danger'
                                },
                                {
                                    type: 'ON_WATCH',
                                    text: 'ON WATCH',
                                    color: 'warning'
                                }
                            ]}
                            type={state.user?.accessStatus}
                        />
                    </Grid>

                    <Grid item={true} xs={12} className={classes.details}>
                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                User Id:
                            </Typography>
                            <Typography component="span">#{state.user?.userId}</Typography>
                        </Grid>

                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Name:
                            </Typography>

                            <Typography component="span">{name || NULL_TEXT}</Typography>
                        </Grid>

                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Email:
                            </Typography>
                            <Typography component="span">{state.user?.email || NULL_TEXT}</Typography>
                        </Grid>

                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Mobile number:
                            </Typography>
                            <Typography component="span">
                                {state?.user?.phone && state?.user?.country ? (
                                    <FormattedPhoneNumber phone={state.user.phone} country={state.user.country} />
                                ) : (
                                    NULL_TEXT
                                )}
                            </Typography>
                        </Grid>

                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Registration Origin:
                            </Typography>
                            <Typography component="span">{state.user?.registrationOrigin || NULL_TEXT}</Typography>
                        </Grid>
                    </Grid>

                    {state?.companyName && (
                        <>
                            <Typography className={classes.heading}>Company Details</Typography>

                            <Grid item={true} xs={12} className={classes.details}>
                                <Grid item={true} xs={12} className={classes.staticFields}>
                                    <Typography color="textSecondary" component="span">
                                        Name:
                                    </Typography>
                                    <Typography component="span">{state.companyName || NULL_TEXT}</Typography>
                                </Grid>

                                {
                                    <Grid item={true} xs={12} className={classes.staticFields}>
                                        <Typography color="textSecondary" component="span">
                                            Position:
                                        </Typography>
                                        <Typography component="span">{state.user?.position || NULL_TEXT}</Typography>
                                    </Grid>
                                }

                                {state.taxId && (
                                    <Grid item={true} xs={12} className={classes.staticFields}>
                                        <Typography color="textSecondary" component="span">
                                            Tax Id:
                                        </Typography>
                                        <Typography component="span">{state.taxId || NULL_TEXT}</Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </>
                    )}

                    {state.primaryBankAccount && (
                        <>
                            <Typography className={classes.heading}>Company Primary Bank Account</Typography>

                            <Grid item={true} xs={12} className={classes.details}>
                                <Grid item={true} xs={12} className={classes.staticFields}>
                                    <Typography color="textSecondary" component="span">
                                        Account Holder Name:
                                    </Typography>
                                    <Typography component="span">
                                        {state.primaryBankAccount?.accountHolderName || NULL_TEXT}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item={true} xs={12} className={classes.details}>
                                <Grid item={true} xs={12} className={classes.staticFields}>
                                    <Typography color="textSecondary" component="span">
                                        Account Number:
                                    </Typography>
                                    <Typography component="span">
                                        {state.primaryBankAccount?.accountNumber || NULL_TEXT}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item={true} xs={12} className={classes.details}>
                                <Grid item={true} xs={12} className={classes.staticFields}>
                                    <Typography color="textSecondary" component="span">
                                        Routing Number:
                                    </Typography>
                                    <Typography component="span">
                                        {state.primaryBankAccount?.routingNumber || NULL_TEXT}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item={true} xs={12} className={classes.details}>
                                <Grid item={true} xs={12} className={classes.staticFields}>
                                    <Typography color="textSecondary" component="span">
                                        Account Type:
                                    </Typography>
                                    <Typography component="span">
                                        {B2BBankAccountTypeToLabel(state.primaryBankAccount?.accountType) || NULL_TEXT}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    )}

                    <Typography className={classes.heading}>Company Address</Typography>

                    <Grid item={true} xs={12} className={classes.details}>
                        {state.companyAddress && <Address address={state.companyAddress} />}
                    </Grid>

                    {state.subscriptionPlan && (
                        <>
                            <Typography className={classes.heading}>Subscription Plan</Typography>

                            <Grid item={true} xs={12} className={classes.details}>
                                <Grid item={true} xs={12} className={classes.staticFields}>
                                    <Typography color="textSecondary" component="span">
                                        Subscription Plan:
                                    </Typography>
                                    <Typography component="span">{state.subscriptionPlan.name}</Typography>
                                </Grid>

                                <Grid item={true} xs={12} className={classes.staticFields}>
                                    <Typography color="textSecondary" component="span">
                                        To pay:
                                    </Typography>
                                    <Typography component="span">
                                        {formatDinero(getDinero(state.subscriptionPlan.subscriptionFeePrice))} every{' '}
                                        {state.subscriptionPlan.subscriptionFeeInterval} month(s)
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} className={classes.staticFields}>
                                    <Typography color="textSecondary" component="span">
                                        Gift Card Fee:
                                    </Typography>
                                    <Typography component="span">
                                        {state.subscriptionPlan.giftCardFee}% per Gift Card.
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12} className={classes.staticFields}>
                                    <Typography color="textSecondary" component="span">
                                        Order Total Fee:
                                    </Typography>
                                    <Typography component="span">
                                        {state.subscriptionPlan.orderFee}% of the order total.
                                    </Typography>
                                </Grid>
                                {state.subscriptionPlan.cardCreationLimit && (
                                    <Grid item={true} xs={12} className={classes.staticFields}>
                                        <Typography color="textSecondary" component="span">
                                            Card Creation Quota:
                                        </Typography>
                                        <Typography component="span">
                                            {state.subscriptionPlan.cardCreationLimit} (without additional charges)
                                        </Typography>
                                    </Grid>
                                )}

                                {state.subscriptionPlan.cardCreationFee && (
                                    <Grid item={true} xs={12} className={classes.staticFields}>
                                        <Typography color="textSecondary" component="span">
                                            Over-Quota Card Fee:
                                        </Typography>
                                        <Typography component="span">
                                            {state.subscriptionPlan.cardCreationFee} per gift card
                                        </Typography>
                                    </Grid>
                                )}

                                {state.subscriptionPlan.sendIncentiveLimit && (
                                    <Grid item={true} xs={12} className={classes.staticFields}>
                                        <Typography color="textSecondary" component="span">
                                            Incentive Card Allowance:
                                        </Typography>
                                        <Typography component="span">
                                            {state.subscriptionPlan.sendIncentiveLimit} (without additional charges)
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </>
                    )}

                    <Typography className={classes.heading}>CardMoola Fund</Typography>
                    <Grid item={true} xs={12} className={classes.staticFields}>
                        <Typography color="textSecondary" component="span">
                            Current fund balance:
                        </Typography>
                        <Typography component="span">
                            <strong>{formatDinero(getDinero(state.fundBalance))}</strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box textAlign="left">
                            <AddFunds userId={props.user.userId!} fetchFundBalance={fetchFundBalance} />
                        </Box>
                    </Grid>

                    {/*         API Config      */}
                    <Grid item={true} xs={12} className={classes.staticFields}>
                        <ApiConfig
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            userId={props.user.userId!}
                            hideDeactivateButton={hideRevokingReasonFormField}
                            platform={props.user?.registrationOrigin}
                            companyName={props.user?.companyName}
                        />
                    </Grid>       

                    {state.user?.langPreference && (
                        <>
                            <Typography className={classes.heading}>API Details</Typography>
                            <Grid item={true} xs={12} className={classes.staticFields}>
                                <Typography color="textSecondary" component="span">
                                    Language Preference for API:
                                </Typography>
                                <Typography component="span">
                                    <strong>{getLanguageLabel(state.user.langPreference)}</strong>
                                </Typography>
                            </Grid>
                        </>
                        
                    )}

                    { !hideRevokingReasonFormField && (
                        <Grid item={true} xs={12} className={classes.innerForm}>
                            <AppTextField
                                className={classes.textField}
                                variant="outlined"
                                size="small"
                                label="Account Access Update reason"
                                placeholder="Reason for updating user's account access..."
                                multiline={true}
                                fullWidth={true}
                                maxLength={REASON_MAX_LENGTH}
                                value={state.user?.accessUpdateReason || ''}
                                helperText={
                                    (state.user?.accessUpdateReason &&
                                        businessDosReasonLength < REASON_MAX_LENGTH &&
                                        `${REASON_MAX_LENGTH - businessDosReasonLength} characters remaining`) ||
                                    `maximum 500 characters allowed`
                                }
                                error={!!(businessDosReasonLength >= REASON_MAX_LENGTH)}
                                onChange={(e) =>
                                    setState({
                                        ...state,
                                        user: { ...state.user, accessUpdateReason: e.target.value }
                                    })
                                }
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    )}

                    <Grid item={true} xs={12} className={classes.footer}>
                        {onCancel && (
                            <Button
                                variant="contained"
                                size="small"
                                onClick={(e) => {
                                    onCancel();
                                    setState(DEFAULT_STATE);
                                }}
                                disabled={state.isApproving || state.isRejecting}
                            >
                                Cancel
                            </Button>
                        )}

                        {toBeApproved && !hideRevokingReasonFormField && (
                            <Button
                                type="submit"
                                variant="contained"
                                color="inherit"
                                size="small"
                                disabled={state.isApproving || state.isRejecting}
                                className={classes.approveButton}
                                onClick={(e) => updateUser('APPROVED')}
                            >
                                {state.isApproving && <CircularProgress className="button-loader" />}
                                <ThumbUpIcon />
                                &nbsp;Approve
                            </Button>
                        )}

                        {toBeRejected && !hideRevokingReasonFormField && (
                            <Button
                                type="submit"
                                variant="contained"
                                color="inherit"
                                size="small"
                                disabled={state.isApproving || state.isRejecting}
                                className={classes.rejectButton}
                                onClick={(e) => updateUser('REVOKED')}
                            >
                                {state.isRejecting && <CircularProgress className="button-loader" />}
                                <ThumbDownIcon />
                                &nbsp;Revoke
                            </Button>
                        )}
                    </Grid>
                </Grid>

                {(state.subscriptionPlan || state.user?.registrationOrigin === 'SHOPIFY') && (
                    <Grid item xs={12}>
                        <Box width="100%" className={classes.form}>
                            <Grid item={true} xs={12}>
                                <MerchantGiftCards userId={props.user.userId!} />
                                {state.user?.registrationOrigin !== 'SHOPIFY' && (
                                    <SoldGiftCards userId={props.user.userId!} />
                                )}
                                <IncentiveGiftCards
                                    userId={props.user.userId!}
                                    isShopifyUser={state.user?.registrationOrigin === 'SHOPIFY'}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {onCancel && (
                                    <Box textAlign="right">
                                        <Button
                                            variant="contained"
                                            size="small"
                                            onClick={(e) => {
                                                onCancel();
                                                setState(DEFAULT_STATE);
                                            }}
                                            disabled={state.isApproving || state.isRejecting}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                )}
                            </Grid>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

export default ManageBusinessUserEditForm;
