export const CURRENCY_OPTIONS = [
    {
        value: 'USD',
        label: '$'
    },
    {
        value: 'EUR',
        label: '€'
    },
    {
        value: 'GBP',
        label: '£'
    },
    {
        value: 'CAD',
        label: 'CA$'
    }
];
