import React, { useState } from 'react';
import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@material-ui/lab/Alert';

import { GiftCardProduct, GiftCardCategory, AutocompleteValue } from '../../types';

import { AppTextField } from '../common/app-textfield';

import useAdminStyles from '../styles';

import ProductSortable from './product-sortable';
import { SavedProducts } from '../../types/savedProducts';

type KeyRecord = keyof GiftCardCategory;

type AddEditFormState = {
    category: GiftCardCategory;
    errors: {
        [key in KeyRecord]?: string | null;
    };
};

type AddEditFormProps = {
    onCancel: () => void;
    onSave: (category: GiftCardCategory, keepRenderAddEditFormOpen?: boolean) => void;
    category?: GiftCardCategory;
    products: GiftCardProduct[];
    productsSavedInDB: SavedProducts[];
    isSaving: boolean;
};

const DEFAULT_CATEGORY: GiftCardCategory = {
    name: '',
    isDeleted: false,
    isActive: true,
    productIds: [],
    countryCode: 'US',
    giftCardType: 'B2C'
};

const useStyles = makeStyles({
    autocomplete: {
        '& .MuiFilledInput-root': {
            paddingTop: 24
        },
        '& .MuiChip-root': {
            backgroundColor: '#fff'
        }
    },
    textField: {
        '& .MuiFilledInput-root': {
            paddingTop: 6
        }
    }
});

const AddEditForm = (props: AddEditFormProps) => {
    const adminClasses = useAdminStyles();
    const theme = useTheme();
    const classes = useStyles();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const { onCancel, onSave, category, products, productsSavedInDB, isSaving } = props;

    const [state, setState] = useState<AddEditFormState>({
        category: DEFAULT_CATEGORY,
        errors: {}
    });

    const selectedProducts: AutocompleteValue[] = state.category.productIds.map((productId) => {
        const eGifterProduct = products.find((p) => p.id === productId);
        const productInDB = productsSavedInDB.find((p) => p.productId === productId);

        return {
            id: eGifterProduct?.id || productInDB?.productId || productId,
            name: eGifterProduct?.name || productInDB?.name || productId,
            productNotInEGifter: !eGifterProduct
        };
    });

    React.useEffect(() => {
        setState((prevState) => ({ ...prevState, category: category || DEFAULT_CATEGORY }));
    }, [category]);

    const saveCategory = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // validations
        const errors: AddEditFormState['errors'] = {};
        if (!state.category.name) {
            errors.name = 'Category name is a required field';
        }
        const errorKeys = Object.keys(errors);
        if (errorKeys.length) {
            const input = document.querySelector(`input[name=category-${errorKeys[0]}]`);
            input?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'start'
            });
            setState((prevState) => ({ ...prevState, errors }));
            return;
        }

        onSave(state.category);
    };

    return (
        <Grid item={true} xs={12} className={adminClasses.form}>
            <Typography className={adminClasses.heading}>
                {' '}
                {(props.category && 'Update') || 'Create New'} ML Category{' '}
            </Typography>

            <Box mb={2}>
                <Alert severity="info">
                    <strong>NOTE:</strong> Products marked in red are either not saved in DB or have been removed by
                    eGifter
                </Alert>
            </Box>

            <form noValidate autoComplete="off" onSubmit={saveCategory}>
                <Grid container={true} spacing={(isLargeScreen && 2) || 0}>
                    <Grid item={true} xs={12} sm={3}>
                        <AppTextField
                            className={clsx(adminClasses.textField, classes.textField)}
                            required={true}
                            variant="filled"
                            label="Category name"
                            fullWidth={true}
                            value={state.category?.name || ''}
                            error={Boolean(state.errors.name)}
                            helperText={state.errors.name}
                            name="category-name"
                            onChange={(e) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    category: { ...prevState.category, name: e.target.value },
                                    errors: {
                                        ...prevState.errors,
                                        name: e.target.value ? undefined : 'Category name is a required field'
                                    }
                                }))
                            }
                        />
                    </Grid>

                    <Grid item={true} xs={12} sm={9}>
                        <Autocomplete
                            disableCloseOnSelect={true}
                            multiple={true}
                            id="tags-filled"
                            className={classes.autocomplete}
                            options={products.map((p) => ({ id: p.id, name: p.name, productNotInEGifter: false }))}
                            value={selectedProducts}
                            getOptionDisabled={(option) => selectedProducts.map(({ id }) => id).includes(option.id)}
                            getOptionLabel={(option) => option.name}
                            renderTags={(value: AutocompleteValue[], getTagProps) =>
                                value.map((option: AutocompleteValue, index: number) => (
                                    <Chip
                                        variant="outlined"
                                        key={index}
                                        style={
                                            option?.productNotInEGifter
                                                ? {
                                                      backgroundColor: theme.palette.error.main,
                                                      color: theme.palette.common.white
                                                  }
                                                : {}
                                        }
                                        label={option?.name}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            onChange={(event, value) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    category: {
                                        ...prevState.category,
                                        productIds: value.map((v) => v.id)
                                    },
                                }))
                            }
                            renderInput={(params) => (
                                <AppTextField
                                    {...params}
                                    variant="filled"
                                    name="category-productIds"
                                    label="Select products"
                                    error={Boolean(state.errors.productIds)}
                                    helperText={state.errors.productIds}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item={true} xs={12} sm={3}>
                        <Box textAlign="left">
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={state.category.isActive}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                category: { ...state.category, isActive: e.target.checked }
                                            })
                                        }
                                        color="primary"
                                    />
                                }
                                label="Is Active"
                            />
                        </Box>
                    </Grid>
                </Grid>

                <Grid item={true} xs={12}>
                    <Typography className={adminClasses.heading}> Sort Products </Typography>

                    <ProductSortable
                        onChange={(updatedProducts) => {
                            setState((prevState) => {
                                const updatedcategory: GiftCardCategory = {
                                    ...prevState.category,
                                    productIds: updatedProducts.map(({ id }) => id)
                                };
                                return { ...prevState, category: updatedcategory };
                            });
                        }}
                        products={selectedProducts}
                    />
                </Grid>

                <Grid item={true} xs={12} className={adminClasses.footer}>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={(e) => {
                            onCancel();
                            setState((prevState) => ({ ...prevState, category: DEFAULT_CATEGORY }));
                        }}
                        disabled={isSaving}
                    >
                        Cancel
                    </Button>

                    <Button variant="contained" color="primary" size="small" disabled={isSaving} type="submit">
                        save
                        {isSaving && <CircularProgress className="button-loader" />}
                    </Button>
                </Grid>
            </form>
        </Grid>
    );
};

export default AddEditForm;
