import React from 'react';
import { Box, Button, FormControl, InputLabel, makeStyles, MenuItem, Select, Paper } from '@material-ui/core';
import { GeoLocationCountry } from '../../types';
import ConfirmationDialog from '../common/confirmation-dialog';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 200,
        '& .MuiSelect-root': {
            textAlign: 'left'
        }
    }
}));

type Props = {
    allGeoData: GeoLocationCountry[];
    filteredGeoDataShortNames: Set<string>;
    isSaving: boolean;
    onCancel: () => void;
    onCountryAdd: (country: string) => void;
};

const AddWhitelistCountry = (props: Props) => {
    const classes = useStyles();

    const [countryCode, setCountryCode] = React.useState<string>('');
    const [isDialogOpen, setDialogOpen] = React.useState<boolean>(false);

    const selectedCountry = props.allGeoData.find((geoData) => geoData.shortName === countryCode);

    const handleGeoDataChange = (
        event: React.ChangeEvent<{
            name?: string;
            value: unknown;
        }>
    ) => {
        setCountryCode(event.target.value as string);
    };

    const openDialog = () => setDialogOpen(true);

    const closeDialog = () => setDialogOpen(false);

    return (
        <Paper>
            <Box display="flex" justifyContent="flex-start" alignItems="flex-end" gridGap={12} m={2} pb={2} >
                <FormControl className={classes.formControl}>
                    <InputLabel id="select-country-label">Choose Country</InputLabel>
                    <Select
                        labelId="select-country-label"
                        id="select-country"
                        value={countryCode}
                        onChange={handleGeoDataChange}
                    >
                        {props.allGeoData.map((geoData) => (
                            <MenuItem key={geoData.shortName} value={geoData.shortName}>
                                {geoData.name} ({geoData.shortName})
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    disabled={!countryCode}
                    onClick={() => {
                        if (countryCode) {
                            openDialog();
                        }
                    }}
                >
                    Add Country
                </Button>

                <Button
                    variant="contained"
                    color="secondary"
                    type="button"
                    onClick={() => {
                        setCountryCode('');
                        props.onCancel();
                    }}
                >
                    Cancel
                </Button>

                <ConfirmationDialog
                    header={'Are you sure?'}
                    subHeader={`Click CONFIRM to add ${selectedCountry?.name} to the allowed countries`}
                    isLoading={props.isSaving}
                    open={isDialogOpen}
                    onClose={closeDialog}
                    onConfirm={() => {
                        if (selectedCountry?.shortName) {
                            closeDialog();
                            props.onCountryAdd(selectedCountry?.shortName);
                            setCountryCode('');
                        }
                    }}
                />
            </Box>
        </Paper>
    );
};

export default AddWhitelistCountry;
