import { StoreActionProps } from '.';
import { ErrorType } from '../types';

const getAllExchangeRates = async (props: StoreActionProps) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/manage-exchange-rates/get-all`);
        const response = apiResponse.parsedBody;
        return response.data as {
            exchangeRates: Record<string, string>,
            updatedAt: string
        };
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

const updateExchangeRates = async (props: StoreActionProps) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.post(`/api/admin/manage-exchange-rates/update`, {});
        const response = apiResponse.parsedBody;

        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return false;
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });
        return true;
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return false;
    }
};

const exchangeRatesAction = (props?: StoreActionProps) => {
    if (!props) {
        return null;
    }
    return {
        getAllExchangeRates: () => getAllExchangeRates(props),
        updateExchangeRates: () => updateExchangeRates(props),
    };
};

export default exchangeRatesAction;

export interface ExchangeRatesAction {
    exchangeRatesAction: typeof exchangeRatesAction;
}
