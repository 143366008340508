import React from 'react';
import { Box, Button, Checkbox, CircularProgress, FormControlLabel } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import ConfirmationDialog from './confirmation-dialog';

type Props = {
    isLoading: boolean;
    columns: string[];
    open: boolean;
    label: string;
    onClose: () => void;
    openModal: () => void;
    onConfirm: (selectedColumns: string[]) => void;
};

const ExportCsvModal = (props: Props) => {
    const [selectedColumns, setSelectedColumns] = React.useState<string[]>([]);

    const handleChange = (selectedColumn: string) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (checked) {
            setSelectedColumns((prevState) => [...prevState, selectedColumn]);
            return;
        }

        setSelectedColumns((prevState) => prevState.filter((col) => col !== selectedColumn));
    };

    return (
        <Box>
            <Button
                type="button"
                variant="contained"
                color="secondary"
                startIcon={<GetAppIcon />}
                onClick={props.openModal}
            >
                {props.label}
            </Button>

            <ConfirmationDialog
                header="Select Columns to Export"
                isLoading={props.isLoading}
                additionalContent={
                    <>
                        {props.columns.map((column) => (
                            <FormControlLabel
                                key={column}
                                control={
                                    <Checkbox
                                        checked={selectedColumns.includes(column)}
                                        onChange={handleChange(column)}
                                        name={column}
                                    />
                                }
                                label={column}
                            />
                        ))}
                    </>
                }
                open={props.open}
                onClose={props.onClose}
                onConfirm={() => props.onConfirm(selectedColumns)}
            />
        </Box>
    );
};

export default ExportCsvModal;
