import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import sortBy from 'lodash/sortBy';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import useAdminStyles from '../styles';

import storeContext from '../../contexts/store-context';
import { useApiService } from '../../contexts/api-service-context';
import { useToasterData } from '../../contexts/toaster-context';
import { useUserService } from '../../contexts/user-context';

import HasPermission from '../../utilities/can';

import TableComponent, { HeadCell, TableResultRow, TableResultValue } from '../common/table-component';

import { BreakEvenCostType, SavedProducts } from '../../types/savedProducts';
import { CashbackSlab, CashbackSlabKeyType, EGifterProduct } from '../../types/eGifter';
import { ConstantType, ErrorType } from '../../types';
import ManagePricingSettings from './settings';

export const DEFAULT_BREAK_EVEN_COST: BreakEvenCostType = {
    BRAINTREE_VENMO: 0,
    PAYPAL: 0,
    AUTHORIZE_NET: 0
};

export const useStyles = makeStyles((theme) => ({
    toolbar: {
        textAlign: 'left'
    }
}));

type SavedProductsStateType = SavedProducts & {
    hasCashbackSlabs: boolean;
    hasBreakEvenCost: boolean;
};

type State = {
    activeProduct: SavedProducts | null;
    eGifterProducts: Array<EGifterProduct>;
    productsInDB: Array<SavedProductsStateType>;
    cashbackSlabs: CashbackSlab | null;
    isLoading: boolean;
};

const ManagePricing = () => {
    const classes = useStyles();
    const adminClasses = useAdminStyles();

    const history = useHistory();
    const apiService = useApiService();
    const toasterContext = useToasterData();
    const userService = useUserService();
    const { appAction, giftCardAction } = storeContext();

    const canRead = HasPermission(userService.user, 'MANAGE_PRICING', 'READ');
    const canUpdate = HasPermission(userService.user, 'MANAGE_PRICING', 'UPDATE');

    const [state, setState] = useState<State>({
        activeProduct: null,
        eGifterProducts: [],
        productsInDB: [],
        cashbackSlabs: null,
        isLoading: false
    });

    useEffect(() => {
        if (!canRead) {
            history.push('/dashboard');
            return;
        }
        appAction()?.renderFullHeader();
        init();
    }, []);

    const init = async () => {
        try {
            setState((prevState) => ({ ...prevState, isLoading: true }));
            const [productsInDBApiResponse, constantsApiResponse] = await Promise.all([
                apiService.get(`/api/admin/manage-product/get-all`),
                apiService.get(`/api/admin/constants/get-all`)
            ]);
            const productsInDBResponse = productsInDBApiResponse.parsedBody;
            const constantsResponse = constantsApiResponse.parsedBody;
            const cashbackSlabs = (constantsResponse.data.find((c: ConstantType) => c.key === 'CASHBACK_SLABS')
                ?.value || {}) as CashbackSlab;
            const sortedCashbackSlabs = Object.keys(cashbackSlabs)
                .sort((a, b) => (cashbackSlabs[a].order || 0) - (cashbackSlabs[b].order || 0))
                .reduce((acc, cur) => ({ ...acc, [cur]: acc[cur] }), {} as CashbackSlab);

            const eGifterProducts = await giftCardAction()?.getAllGiftCards({ showAll: true, forceFetch: true });

            setState((prevState) => ({ ...prevState, isLoading: false }));

            if (!productsInDBResponse || !productsInDBResponse.status) {
                toasterContext.setToaster({
                    isOpen: true,
                    message: productsInDBResponse.message,
                    severity: 'error'
                });
                return;
            }

            setState((prevState) => ({
                ...prevState,
                eGifterProducts: eGifterProducts || [],
                cashbackSlabs: sortedCashbackSlabs,
                productsInDB: productsInDBResponse.data.map((p: SavedProductsStateType) => ({
                    ...p,
                    breakEvenCost: p.breakEvenCost || DEFAULT_BREAK_EVEN_COST,
                    hasCashbackSlabs: Boolean(p.cashbackSlabs),
                    hasBreakEvenCost: Boolean(p.breakEvenCost)
                })) as Array<SavedProductsStateType>
            }));

        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
    };
    const cashbackSlabHeadCells = state.cashbackSlabs
        ? Object.keys(state.cashbackSlabs).map((key) => ({ id: key, label: `Cashback Percentage ${key}` }))
        : [];
    const headCells: Array<HeadCell> = [
        { id: 'name', label: 'Name' },
        { id: 'eGifterDiscount', label: 'E-gifter Discount' },
        ...cashbackSlabHeadCells,
        { id: 'isUpdated', label: 'Is Updated' },
        { id: 'option', label: '' }
    ];

    const updateProductInDB = async (updatedActiveProduct: SavedProducts | null) => {
        if (!updatedActiveProduct) {
            return null;
        }
        try {
            const updatedState = {
                ...state,
                activeProduct: updatedActiveProduct,
                productsInDB: state.productsInDB.map(
                    (p) =>
                        (p.id === updatedActiveProduct?.id && {
                            ...p,
                            ...updatedActiveProduct,
                            hasBreakEvenCost: true,
                            hasCashbackSlabs: true
                        }) ||
                        p
                )
            };
            setState(updatedState);
            await apiService.post(`/api/admin/manage-product/update`, { product: updatedActiveProduct });
            toasterContext.setToaster({
                isOpen: true,
                message: `${updatedActiveProduct.name} updated successfully!`,
                severity: 'success'
            });
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
        }
        return null;
    };

    const updateTableState = async () => {
        await init();
    }

    const rows: Array<TableResultRow> = state.productsInDB.map((p) => {
        const eGifterProduct = state.eGifterProducts.find((g) => g.id === p.productId);
        const cashbackSlabTableResultRow = state.cashbackSlabs
            ? Object.keys(state.cashbackSlabs).reduce((slabs, key) => {
                return (slabs = {
                    ...slabs,
                    [key as string]: {
                        align: 'left',
                        text:
                            (p.cashbackSlabs &&
                                p.hasCashbackSlabs &&
                                `${p.cashbackSlabs?.[key as CashbackSlabKeyType]?.cashbackPercentage}`) ||
                            '0',
                        element:
                            (p.cashbackSlabs &&
                                p.hasCashbackSlabs &&
                                p.cashbackSlabs?.[key as CashbackSlabKeyType] &&
                                `${p.cashbackSlabs?.[key as CashbackSlabKeyType]?.cashbackPercentage}%`) ||
                            '-',
                        isNumeric: true
                    } as TableResultValue
                });
            }, {} as TableResultRow)
            : ({} as TableResultRow);
        return {
            id: {
                align: 'left',
                text: `${p.id}` || ''
            },
            name: {
                align: 'left',
                text: p.name || '-'
            },
            eGifterDiscount: {
                align: 'left',
                text:
                    (eGifterProduct?.cost?.discountPercentage && `${eGifterProduct?.cost?.discountPercentage}`) || '0',
                element:
                    (eGifterProduct?.cost?.discountPercentage && `${eGifterProduct?.cost?.discountPercentage}%`) || '-',
                isNumeric: true
            },
            ...cashbackSlabTableResultRow,
            isUpdated: {
                align: 'left',
                text: '',
                element: (p.hasCashbackSlabs && p.hasBreakEvenCost && <CheckCircleIcon color="secondary" />) || (
                    <CancelIcon color="disabled" />
                )
            },
            option: {
                align: 'right',
                text: ''
            }
        };
    });

    const handleRowClick = (id: string) => {
        if (!canUpdate) {
            return;
        }

        setState((prevState) => ({
            ...prevState,
            activeProduct: prevState.productsInDB.find((p) => `${p.id}` === id) || null
        }));
    };

    return (
        <Grid item={true} xs={12} className={adminClasses.root}>
            <ManagePricingSettings
                eGifterProducts={state.eGifterProducts}
                productsInDB={state.productsInDB}
                activeProduct={state.activeProduct}
                isLoading={state.isLoading}
                updateProductInDB={updateProductInDB}
                updateTableState={updateTableState}
                setActiveProduct={(activeProduct) => setState(prevState => ({ ...prevState, activeProduct }))}
            />

            <Container className={adminClasses.container}>
                <TableComponent
                    header="Products"
                    showCheckbox={false}
                    rowHover={canUpdate}
                    rowTooltip={(canUpdate && 'Click to edit') || undefined}
                    showPaginator={{ bottom: true }}
                    showSearch={true}
                    isLoading={state.isLoading}
                    rows={sortBy(rows, [(item) => item.name.text])}
                    headCells={headCells}
                    fillEmptyRows={false}
                    keyField="id"
                    overrideClasses={{ toolbarClass: classes.toolbar }}
                    noOfRowsPerPage={50}
                    rowsOptions={[50, 100, 150]}
                    onRowClick={handleRowClick}
                />
            </Container>
        </Grid>
    );
};

export default ManagePricing;
