import React from 'react';
import clsx from 'clsx';

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 10,
        display: 'flex',
        justifyContent: 'center'
    },
    message: {
        fontSize: 20,
        fontFamily: `'Work Sans', sans-serif`,
        fontWeight: 900
    }
}));

export function DataLoader(props) {
    const classes = useStyles();
    const { dataLoaderRootClass, isLoading, items } = props;

    return (
        <span className={clsx(classes.root, dataLoaderRootClass)}>
            {(isLoading && <CircularProgress />) || null}
            {(!isLoading && (items || []).length === 0 && <span>No items available</span>) || null}
        </span>
    );
}
