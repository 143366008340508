import React, { useState } from 'react';

import { InfluencerReferralPaymentAttributes } from '../../types';
import { validateAmount, validatePercentage } from '../../utilities';

import { AppTextField } from '../common/app-textfield';

import useAdminStyles from '../styles';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

type KeyRecord = keyof InfluencerReferralPaymentAttributes;

type PaymentConstantFormState = {
    paymentConstant: Omit<
        InfluencerReferralPaymentAttributes,
        | 'influencerTransactionValue'
        | 'amount'
        | 'noOfRegistration'
        | 'referralTransactionValue'
        | 'influencerUserId'
        | 'id'
    > & {
        influencerTransactionValue: string | null;
        amount: string | null;
        noOfRegistration: string | null;
        referralTransactionValue: string | null;
    };
    isCustomNoOfRegistration: boolean;
    errors: {
        [key in KeyRecord]?: string | null;
    };
};

type PaymentConstantFormProps = {
    onSave: (paymentConstant: Omit<InfluencerReferralPaymentAttributes, 'influencerUserId'>) => void;
    paymentConstant?: InfluencerReferralPaymentAttributes | null;
    isSaving: boolean;
};

const DEFAULT_DATA: PaymentConstantFormState['paymentConstant'] = {
    influencerTransactionType: 'AMOUNT',
    influencerTransactionValue: '0',
    amount: '0',
    noOfRegistration: '1',
    referralTransactionType: 'AMOUNT',
    referralTransactionValue: '0'
};

const useStyles = makeStyles({});

const PaymentConstantForm = (props: PaymentConstantFormProps) => {
    const adminClasses = useAdminStyles();

    const { onSave, paymentConstant, isSaving } = props;

    const [state, setState] = useState<PaymentConstantFormState>({
        paymentConstant: DEFAULT_DATA,
        isCustomNoOfRegistration: true,
        errors: {}
    });

    React.useEffect(() => {
        paymentConstant &&
            setState((prevState) => ({
                ...prevState,
                paymentConstant:
                    {
                        ...paymentConstant,
                        influencerTransactionValue: paymentConstant?.influencerTransactionValue?.toString() || '0',
                        amount: paymentConstant?.amount?.toString() || '0',
                        noOfRegistration: paymentConstant?.noOfRegistration?.toString() || '1',
                        referralTransactionValue: paymentConstant?.referralTransactionValue?.toString() || '0'
                    } || DEFAULT_DATA,
                isCustomNoOfRegistration: paymentConstant?.noOfRegistration !== 1
            }));
    }, [paymentConstant]);

    const savePaymentConstant = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // validations
        const errors: PaymentConstantFormState['errors'] = {};

        const errorKeys = Object.keys(errors);
        if (errorKeys.length) {
            const input = document.querySelector(`input[name=payment-constant-${errorKeys[0]}]`);
            input?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'start'
            });
            setState((prevState) => ({ ...prevState, errors }));
            return;
        }

        onSave({
            ...state.paymentConstant,
            influencerTransactionValue: isNaN(parseFloat(state.paymentConstant?.influencerTransactionValue || '0'))
                ? 0
                : +state.paymentConstant.influencerTransactionValue!,
            amount: isNaN(parseFloat(state.paymentConstant?.amount || '0')) ? 0 : +state.paymentConstant.amount!,
            noOfRegistration: isNaN(parseFloat(state.paymentConstant?.noOfRegistration || '1'))
                ? 1
                : +state.paymentConstant.noOfRegistration!,
            referralTransactionValue: isNaN(parseFloat(state.paymentConstant?.referralTransactionValue || '0'))
                ? 0
                : +state.paymentConstant.referralTransactionValue!
        });
    };

    return (
        <Grid item={true} xs={12} className={adminClasses.form}>
            <Typography className={adminClasses.heading}>Save Payment Constant</Typography>

            <form noValidate autoComplete="off" onSubmit={savePaymentConstant}>
                <Grid container={true} spacing={4}>
                    <Grid item xs={12}>
                        <Typography align="left">
                            1. Influencer incentive on the first transaction of the fans.
                        </Typography>
                    </Grid>

                    <Grid item={true} xs={12} sm={6}>
                        <FormControl component="fieldset" fullWidth className="text-left">
                            <FormLabel component="legend">Influencer Transaction Type</FormLabel>
                            <RadioGroup
                                row
                                aria-label="type"
                                name="payment-constant-influencerTransactionType"
                                value={state.paymentConstant.influencerTransactionType}
                                onChange={(e) =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        paymentConstant: {
                                            ...prevState.paymentConstant,
                                            influencerTransactionType: e.target
                                                .value as InfluencerReferralPaymentAttributes['influencerTransactionType'],
                                            influencerTransactionValue: null
                                        },
                                        errors: {
                                            ...prevState.errors,
                                            influencerTransactionType: null
                                        }
                                    }))
                                }
                            >
                                <FormControlLabel value="AMOUNT" control={<Radio color="primary" />} label="Amount" />
                                <FormControlLabel
                                    value="PERCENTILE"
                                    control={<Radio color="primary" />}
                                    label="Percentile"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item={true} xs={12} sm={6}>
                        <AppTextField
                            className={adminClasses.textField}
                            variant="filled"
                            size="small"
                            label="Value"
                            fullWidth={true}
                            name="payment-constant-influencerTransactionValue"
                            value={state.paymentConstant?.influencerTransactionValue || ''}
                            error={Boolean(state.errors.influencerTransactionValue)}
                            helperText={state.errors.influencerTransactionValue}
                            onChange={(e) => {
                                const value = e.target.value;

                                if (
                                    state.paymentConstant.influencerTransactionType === 'AMOUNT' &&
                                    validateAmount(value)
                                ) {
                                    setState((prevState) => ({
                                        ...prevState,
                                        paymentConstant: {
                                            ...prevState.paymentConstant,
                                            influencerTransactionValue: value
                                        }
                                    }));
                                }

                                if (
                                    state.paymentConstant.influencerTransactionType === 'PERCENTILE' &&
                                    validatePercentage(value)
                                ) {
                                    setState((prevState) => ({
                                        ...prevState,
                                        paymentConstant: {
                                            ...prevState.paymentConstant,
                                            influencerTransactionValue: value
                                        }
                                    }));
                                }
                            }}
                        />
                    </Grid>
                </Grid>

                <br />

                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography align="left">
                            2. Amount an influencer will get for a number of registrations.
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl component="fieldset" fullWidth className="text-left">
                            <RadioGroup
                                row
                                aria-label="type"
                                name="payment-constant-influencerRegistrationType"
                                value={state.isCustomNoOfRegistration.toString()}
                                onChange={(e) =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        paymentConstant: {
                                            ...prevState.paymentConstant,
                                            noOfRegistration:
                                                e.target.value === 'true'
                                                    ? prevState.paymentConstant.noOfRegistration
                                                    : '1'
                                        },
                                        isCustomNoOfRegistration: e.target.value === 'true'
                                    }))
                                }
                            >
                                <FormControlLabel
                                    value={'false'}
                                    control={<Radio color="primary" />}
                                    label="Amount per registration"
                                />
                                <FormControlLabel
                                    value={'true'}
                                    control={<Radio color="primary" />}
                                    label="Amount per 'n' registrations"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    {state.isCustomNoOfRegistration && (
                        <>
                            <Grid item={true} xs={12} sm={6}>
                                <AppTextField
                                    className={adminClasses.textField}
                                    variant="filled"
                                    size="small"
                                    label="Amount"
                                    fullWidth={true}
                                    name="payment-constant-amount"
                                    value={state.paymentConstant?.amount || ''}
                                    error={Boolean(state.errors.amount)}
                                    helperText={state.errors.amount}
                                    onChange={(e) => {
                                        const value = e.target.value;

                                        if (validateAmount(value)) {
                                            setState((prevState) => ({
                                                ...prevState,
                                                paymentConstant: {
                                                    ...prevState.paymentConstant,
                                                    amount: value
                                                }
                                            }));
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item={true} xs={12} sm={6}>
                                <AppTextField
                                    className={adminClasses.textField}
                                    variant="filled"
                                    size="small"
                                    label="No. of registrations"
                                    fullWidth={true}
                                    name="payment-constant-noOfRegistration"
                                    value={state.paymentConstant?.noOfRegistration || ''}
                                    error={Boolean(state.errors.noOfRegistration)}
                                    helperText={state.errors.noOfRegistration}
                                    onChange={(e) => {
                                        const value = e.target.value;

                                        if (/^\d*$/.test(value)) {
                                            setState((prevState) => ({
                                                ...prevState,
                                                paymentConstant: {
                                                    ...prevState.paymentConstant,
                                                    noOfRegistration: value
                                                }
                                            }));
                                        }
                                    }}
                                />
                            </Grid>
                        </>
                    )}

                    {!state.isCustomNoOfRegistration && (
                        <Grid item={true} xs={12} sm={6}>
                            <AppTextField
                                className={adminClasses.textField}
                                variant="filled"
                                size="small"
                                label="Amount per registration"
                                fullWidth={true}
                                name="payment-constant-amount"
                                value={state.paymentConstant?.amount || ''}
                                onChange={(e) => {
                                    const value = e.target.value;

                                    if (/^\d*$/.test(value)) {
                                        setState((prevState) => ({
                                            ...prevState,
                                            paymentConstant: {
                                                ...prevState.paymentConstant,
                                                amount: value,
                                                noOfRegistration: '1'
                                            }
                                        }));
                                    }
                                }}
                            />
                        </Grid>
                    )}
                </Grid>

                <br />

                <Grid container={true} spacing={4}>
                    <Grid item xs={12}>
                        <Typography align="left">3. First transaction incentive to fans of influencer.</Typography>
                    </Grid>

                    <Grid item={true} xs={12} sm={6}>
                        <FormControl component="fieldset" fullWidth className="text-left">
                            <FormLabel component="legend">Referral Transaction Type</FormLabel>
                            <RadioGroup
                                row
                                aria-label="type"
                                name="payment-constant-referralTransactionType"
                                value={state.paymentConstant.referralTransactionType}
                                onChange={(e) =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        paymentConstant: {
                                            ...prevState.paymentConstant,
                                            referralTransactionType: e.target
                                                .value as InfluencerReferralPaymentAttributes['referralTransactionType'],
                                            referralTransactionValue: null
                                        },
                                        errors: {
                                            ...prevState.errors,
                                            referralTransactionType: null
                                        }
                                    }))
                                }
                            >
                                <FormControlLabel value="AMOUNT" control={<Radio color="primary" />} label="Amount" />
                                <FormControlLabel
                                    value="PERCENTILE"
                                    control={<Radio color="primary" />}
                                    label="Percentile"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item={true} xs={12} sm={6}>
                        <AppTextField
                            className={adminClasses.textField}
                            variant="filled"
                            size="small"
                            label="Value"
                            fullWidth={true}
                            name="payment-constant-referralTransactionValue"
                            value={state.paymentConstant?.referralTransactionValue || ''}
                            error={Boolean(state.errors.referralTransactionValue)}
                            helperText={state.errors.referralTransactionValue}
                            onChange={(e) => {
                                const value = e.target.value;

                                if (
                                    state.paymentConstant.referralTransactionType === 'AMOUNT' &&
                                    validateAmount(value)
                                ) {
                                    setState((prevState) => ({
                                        ...prevState,
                                        paymentConstant: {
                                            ...prevState.paymentConstant,
                                            referralTransactionValue: value
                                        }
                                    }));
                                }

                                if (
                                    state.paymentConstant.referralTransactionType === 'PERCENTILE' &&
                                    validatePercentage(value)
                                ) {
                                    setState((prevState) => ({
                                        ...prevState,
                                        paymentConstant: {
                                            ...prevState.paymentConstant,
                                            referralTransactionValue: value
                                        }
                                    }));
                                }
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid item={true} xs={12} className={adminClasses.footer}>
                    <Button variant="contained" color="primary" size="small" disabled={isSaving} type="submit">
                        save
                        {isSaving && <CircularProgress className="button-loader" />}
                    </Button>
                </Grid>
            </form>
        </Grid>
    );
};

export default PaymentConstantForm;
