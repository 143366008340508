import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import OtpInput from 'react-otp-input';
import { createBrowserHistory } from 'history';

import { HttpMessageResponse, UserAccount } from '../../../types';
import useOtpVerificationStyles from './otp-verification.styles';
import OtpVerificationFooter from './otp-verification-footer';
import ConfirmOTPComponent from './confirm-otp';
import LoadingBlock from '../../common/loading-block';
import { Box, Typography } from '@material-ui/core';
import storeContext from '../../../contexts/store-context';
import { useToasterData } from '../../../contexts/toaster-context';

type State = {
    isDialogOpen: boolean;
    isLoading: boolean;
    otp: string | null;
    isResendLoading: boolean;
    isResendDisabled: boolean;
    isLinkValidationLoading: boolean;
    isOtpInValid: null | boolean;
    user: Partial<UserAccount> | null;
};

type EmailOrTextConfirmState = {
    renderConfirmComponent: boolean;
    isLoading: boolean;
};

type ResponseType = {
    redirectUrl?: string;
    user?: Partial<UserAccount>;
};

const EMAIL_OR_TEXT_CONFIRM_INITIAL_STATE: EmailOrTextConfirmState = {
    renderConfirmComponent: false,
    isLoading: false,
};

const OtpVerification = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const timerRef = React.useRef<any>();
    const { token } = useParams<{ token: string }>();
    const [emailOrTextConfirmState, setEmailOrTextConfirmState] = React.useState<EmailOrTextConfirmState>(
        EMAIL_OR_TEXT_CONFIRM_INITIAL_STATE
    );

    const history = useHistory();
    const classes = useOtpVerificationStyles();
    const toasterContext = useToasterData();
    const { otpAction, userSessionAction } = storeContext();

    const [state, setState] = useState<State>({
        isLoading: false,
        isDialogOpen: false,
        isResendLoading: false,
        isResendDisabled: true,
        isLinkValidationLoading: true,
        otp: null,
        isOtpInValid: null,
        user: null,
    });

    const initialTimer = 60; // in seconds
    const [resendTimer, setResendTimer] = useState<number>(initialTimer);

    const isOTPVerificationScreen =
        !emailOrTextConfirmState.renderConfirmComponent;

    useEffect(() => {
        validateAccountToken();

        timerRef.current = setInterval(() => setResendTimer((resendTimer) => resendTimer - 1), 1000);
        return () => {
            clearInterval(timerRef.current);
        };
    }, []);

    useEffect(() => {
        if (resendTimer <= 0) {
            clearInterval(timerRef.current);
            setState({ ...state, isResendDisabled: false });
        }
    }, [resendTimer]);

    const formSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (state.isLoading) {
            return;
        }

        validateOTP();
    };

    const validateOTP = async () => {
        if (!state.otp || isNaN(+state.otp) || state.otp.length < 6) {
            setState({ ...state, isOtpInValid: true });
            return;
        }

        setState({ ...state, isLoading: true, isOtpInValid: null });
        const response: HttpMessageResponse<ResponseType | null> = await otpAction()?.validateOTP(
            token,
            +state.otp
        );

        if (!response || !response.status) {
            setState({ ...state, isResendDisabled: !!resendTimer, isLoading: false, isOtpInValid: null });
            return;
        }

        await userSessionAction()?.logout({ skipApiCall: true, skipFireEvent: true });
        await userSessionAction()?.login({ forceApiCall: true });
        setState({ ...state, isResendDisabled: !!resendTimer, isLoading: false, isOtpInValid: null });

        const historyBrowser = createBrowserHistory({
            forceRefresh: true
        });

        historyBrowser.push('/');
    };

    const validateAccountToken = async () => {
        try {
            const response: HttpMessageResponse<ResponseType | null> = await otpAction()?.validateAccountToken(
                token
            );
            if (!response) {
                setState((prevState) => ({ ...prevState, isLinkValidationLoading: false }));
                history.push('/');
                return;
            }

            setState((prevState) => ({
                ...prevState,
                isLinkValidationLoading: false,
                user: response?.data?.user || null,
            }));
        } catch (e) {
            setState((prevState) => ({ ...prevState, isLinkValidationLoading: false }));
            history.push('/');
        }
    };

    const resendOTP = async () => {
        setState((prevState) => ({ ...prevState, isResendLoading: true, isResendDisabled: true }));
        setEmailOrTextConfirmState((prevState) => ({ ...prevState, isLoading: true }));

        const response = await otpAction()?.resendOTP(token);

        setTimeout(() => {
            setState((prevState) => ({ ...prevState, isResendLoading: false, isResendDisabled: true }));
            setEmailOrTextConfirmState((prevState) => ({ ...prevState, isLoading: false }));

            /**
             * this hack is required to forcefully refresh the react-route
             */
            if (response.status && response?.data?.redirectUrl) {
                history.push(response?.data?.redirectUrl);
                window.location.reload();
            }
        }, 1500); // give some time for user to read the toaster
    };

    const handleChange = (otp: string) => setState({ ...state, otp, isOtpInValid: null });

    if (!token) {
        toasterContext.setToaster({
            isOpen: true,
            message: 'Error validating! Token missing.',
            severity: 'error'
        });
        return <Redirect to="/" />;
    }

    if (state.isLinkValidationLoading) {
        return <LoadingBlock />;
    }

    return (
        <Box>
            <form className={classes.root} onSubmit={formSubmitHandler}>
                <Typography className={clsx('fontFamilyWorkSans', classes.verifyAccount)} data-testid="heading">
                    Please Verify Account
                </Typography>

                <Typography gutterBottom>For your protection, we need to verify that this device is yours.</Typography>

                {emailOrTextConfirmState.renderConfirmComponent && (
                    <ConfirmOTPComponent
                        onConfirm={() => resendOTP()}
                        onCancel={() =>
                            setEmailOrTextConfirmState((prevState) => ({ ...prevState, renderConfirmComponent: false }))
                        }
                        isLoading={emailOrTextConfirmState.isLoading}
                    />
                )}

                {isOTPVerificationScreen && (
                    <React.Fragment>
                        <Typography>
                            Please enter code we have sent to{' '}
                            {state.user?.email}
                        </Typography>

                        <OtpInput
                            isInputNum={true}
                            value={state.otp || ''}
                            onChange={handleChange}
                            numInputs={6}
                            hasErrored={!!state.isOtpInValid}
                            shouldAutoFocus={true}
                            inputStyle={classes.otpInput}
                            containerStyle={classes.otpContainer}
                            errorStyle={classes.otpErrorStyle}
                            focusStyle={classes.otpFocusStyle}
                        />

                        <OtpVerificationFooter
                            onResendOTP={() => resendOTP()}
                            isOTPConfirmLoading={state.isLoading}
                            isResendLoading={state.isResendLoading}
                            isResendDisabled={state.isResendDisabled}
                            otp={state.otp}
                            isOtpInValid={state.isOtpInValid}
                            resendTimer={resendTimer}
                        />
                    </React.Fragment>
                )}
            </form>
        </Box>
    );
};

export default OtpVerification;
