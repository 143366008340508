import {
    userSessionReducer,
    initialState as userSessionState,
    UserSessionState,
    UserSessionActionType
} from './user-session.reducer';

import { giftCardReducer, initialState as giftCardState, GiftCardState, GiftCardActionType } from './gift-card.reducer';

import { appReducer, initialState as appState, AppState, AppActionType } from './app.reducer';
import { StoreActionType } from '../actions';

export interface StoreState {
    userSessionState: UserSessionState;
    giftCardState: GiftCardState;
    appState: AppState;
}

export const initialState: StoreState = {
    userSessionState: userSessionState,
    giftCardState: giftCardState,
    appState: appState
};

export const reducer = (state: StoreState = initialState, action: StoreActionType): StoreState => {
    const {
        userSessionState,
        giftCardState,
        appState
    } = state;

    // eslint-disable-next-line no-undef
    if (process.env.NODE_ENV === 'development') {
        console.log('previous state ==> ', state); // eslint-disable-line no-console
        console.log('action ==> ', action); // eslint-disable-line no-console
    }

    return {
        userSessionState: userSessionReducer(userSessionState, action as UserSessionActionType),
        giftCardState: giftCardReducer(giftCardState, action as GiftCardActionType),
        appState: appReducer(appState, action as AppActionType)
    };
};
