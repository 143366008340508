import React from 'react';
import { Box, Button, CircularProgress, Paper, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import useAdminStyles from '../../styles';
import { useApiService } from '../../../contexts/api-service-context';
import { ConstantType, TranslationSyncActionLimit } from '../../../types';
import moment from 'moment';
import { DateFormatTypeEnum } from '../../../utilities';
import SyncB2BGiftCardTranslationsModal from './sync-b2b-gift-card-translations-modal';

const SyncB2BGiftCardTranslations = () => {
    const adminClasses = useAdminStyles();
    const apiService = useApiService();

    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [isLoadingActionLimit, setIsLoadingActionLimit] = React.useState(false);
    const [actionLimit, setActionLimit] = React.useState<TranslationSyncActionLimit | null>(null);

    const openDialog = () => {
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    const fetchActionLimit = async () => {
        setIsLoadingActionLimit(true);
        const apiResponse = await apiService.get(`/api/admin/constants/get-all`);
        const response = apiResponse.parsedBody;
        const constantData: ConstantType = response.data.find(
            (c: ConstantType) => c.key === 'TRANSLATION_SYNC_ACTION_LIMIT'
        );
        setActionLimit(constantData.value as TranslationSyncActionLimit);
        setIsLoadingActionLimit(false);
    };

    React.useEffect(() => {
        fetchActionLimit();
    }, []);

    return (
        <Box my={5}>
            <Paper>
                <Box m={2} py={3} textAlign="left">
                    <Typography align="left" className={adminClasses.heading}>
                        Sync B2B Gift Card Translations
                    </Typography>
                    <Alert severity="info">
                        Click below to sync the translations for all B2B Gift Cards. This will fetch the latest
                        gift cards from eGifter, translate their descriptions and terms to the selected languages and save them to the
                        database.
                        <br /> <br />
                        <strong>Note:</strong> A maximum of 3 updates is permitted each month.
                    </Alert>

                    {isLoadingActionLimit && <CircularProgress />}
                    {!isLoadingActionLimit && actionLimit && (
                        <Box mt={2} sx={{ letterSpacing: '0.1em' }}>
                            <strong>Current month&apos;s updates:</strong> {actionLimit.count}
                            <br />
                            <strong>Last Updated: </strong>
                            {moment.utc(actionLimit.lastUpdated).format(DateFormatTypeEnum.MMMM_D_YYYY_h_mm_a)} UTC
                        </Box>
                    )}

                    <Box mt={2}>
                        <Button variant="contained" color="primary" type="button" onClick={openDialog}>
                            Sync translations
                        </Button>
                    </Box>
                </Box>
            </Paper>
            
            <SyncB2BGiftCardTranslationsModal open={isDialogOpen} onClose={closeDialog} />
        </Box>
    );
};

export default SyncB2BGiftCardTranslations;
