import { TransactionLimitSettingValue } from "../types";

export const getDefaultSetting = (transactionLimitSettings: TransactionLimitSettingValue[]) => {
    if (transactionLimitSettings) {
        const defaultSetting = transactionLimitSettings.find((setting) => setting.isDefault === true);

        if (!defaultSetting) {
            // If the 'isDefault' setting is not found, use the 'Moderate' key as the default
            const moderateSetting = transactionLimitSettings.find((setting) => setting.key === 'Moderate');
            if (moderateSetting) {
                return moderateSetting;
            }
        } else {
            return defaultSetting;
        }
    }
    return null;
};
