import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import { AppTextField } from '../common/app-textfield';

import { FraudStatusTypeEnum, FraudScoreSlabObjectType } from '.';
import useAdminStyles from '../styles';
import ColorSwatch from './colorSwatch';
import Alert from '@material-ui/lab/Alert';
import { FormHelperText } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    mainGrid: {
        display: 'flex',
        alignItems: 'center'
    },
    select: {
        width: 280
    },
    colorSwatch: {
        width: 280,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

type AddEditFormState = FraudScoreSlabObjectType & {
    errors: Record<string, string | null>;
}

type AddEditFormProps = {
    id: string;
    data: FraudScoreSlabObjectType;
    isSaving: boolean;
    errorMessage: string | null;
    heading: string;
    onSave: (data: FraudScoreSlabObjectType) => void;
    onCancel: () => void;
};

const AddEditForm = (props: AddEditFormProps) => {
    const regex = /^\d+(\.\d{0,2})?$/;
    const classes = useStyles();
    const adminClasses = useAdminStyles();

    const [state, setState] = React.useState<AddEditFormState>({
        ...props.data,
        errors: {}
    });

    const validate = (value: Array<number>, type: string) => {
        const [min, max] = value;
        if (min > max) {
            setState(prevState => ({ ...prevState, errors: { ...prevState.errors, [type]: 'LHS can never be greater than RHS' } }));
            return false;
        }

        if (min === max) {
            setState(prevState => ({ ...prevState, errors: { ...prevState.errors, [type]: 'LHS can never be same as RHS' } }));
            return false;
        }

        setState(prevState => ({ ...prevState, errors: {...prevState.errors, [type]: null} }));
        return true;
    }

    const validateStatus  = () => {
        if (!state.accessStatus) {
            setState(prevState => ({ ...prevState, errors: { ...prevState.errors, accessStatus: 'This field is required.' } }));
            return false;
        }
        return true;
    }

    const onSave = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const isRiskScoreValid = validate(state.riskScoreRange, 'riskScore');
        const isStatusValid = validateStatus();
        if (!isRiskScoreValid || !isStatusValid) {
            return;
        }
        const { errors, ...others } = state;
        props.onSave({ ...others });
        setState(prevState => ({ ...prevState, errors: {} }));
    }

    const [minRiskScore, maxRiskScore] = state.riskScoreRange;

    return (
        <Grid item={true} xs={12} className={adminClasses.form}>
            <Typography className={adminClasses.heading}> {props.id === 'NEW' && 'Create New' || 'Update'} {props.heading} </Typography>

            {props.errorMessage && <Box mb={2}> <Alert severity="error"> {props.errorMessage} </Alert> </Box>}

            <form noValidate autoComplete="off" onSubmit={onSave}>
                <Box display="flex" alignItems="center" gridGap={6} mb={2} className={classes.mainGrid}>
                    <Box mr={3} textAlign="left">Risk Score Range:</Box>
                    <AppTextField
                        value={minRiskScore || '0'}
                        onChange={(e) => {
                            const value = +e.target.value;
                            if (isNaN(value) || value < 0 || value > 120 || !regex.test(`${value}`)) {
                                console.log('rejected risk score value', value);
                                return;
                            }
                            setState(prevState => ({ ...prevState, riskScoreRange: [value, prevState.riskScoreRange[1]] }));
                        }}
                        error={Boolean(state.errors.riskScore)}
                        helperText={state.errors.riskScore}
                    />

                    <Box display="flex" alignItems="center">
                        <Box mr={1}>TO</Box>
                        <AppTextField
                            value={maxRiskScore || '0'}
                            onChange={(e) => {
                                const value = +e.target.value;
                                if (isNaN(value) || value < 0 || value > 120 || !regex.test(`${value}`)) {
                                    return;
                                }
                                setState(prevState => ({ ...prevState, riskScoreRange: [prevState.riskScoreRange[0], value] }));
                            }}
                            error={Boolean(state.errors.riskScore)}
                            helperText={state.errors.riskScore}
                        />
                    </Box>
                    <Grid item={true} className={classes.colorSwatch}>
                        <ColorSwatch color={state.color} onChange={color => setState(prevState => ({ ...prevState, color }))} />
                    </Grid>

                    <FormControl
                        size="small"
                        className={classes.select}
                        error={Boolean(state.errors.accessStatus)}
                    >
                        <InputLabel id="theme-typography-headingFont-label">Access Status</InputLabel>
                        <Select
                            value={state.accessStatus || ''}
                            onChange={(e) => {
                                const accessStatus = e.target.value as typeof FraudStatusTypeEnum[number];
                                setState((prevState) => ({ ...prevState, accessStatus }));
                            }}
                        >
                            {FraudStatusTypeEnum.map((as) => (<MenuItem value={as} key={as}> {as}</MenuItem>))}
                        </Select>
                        {state.errors.accessStatus && <FormHelperText>{state.errors.accessStatus}</FormHelperText>}
                    </FormControl>
                </Box>

                <Grid item={true} xs={12} className={adminClasses.footer}>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                            props.onCancel();
                            setState(prevState => ({ ...prevState, errors: {} }));
                        }}
                        disabled={props.isSaving}
                    >
                        Cancel
                    </Button>

                    <Button variant="contained" color="primary" size="small" disabled={props.isSaving} type="submit">
                        save
                        {props.isSaving && <CircularProgress className="button-loader" />}
                    </Button>
                </Grid>
            </form>
        </Grid >
    );
};

export default AddEditForm;
