import { StoreActionProps } from '.';
import { ErrorType, GetPromoCode, PromoCode } from '../types';

const getPromoAllCodes = async (props: StoreActionProps): Promise<GetPromoCode[] | null> => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/promo-codes/get-all`);
        const response = apiResponse.parsedBody;

        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }
        return response.data;
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

const updateAllPromoCodes = async (props: StoreActionProps, data: PromoCode[]) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.put(`/api/admin/promo-codes/update-all`, data);
        const response = apiResponse.parsedBody;

        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });
        return response.data as GetPromoCode[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

const updatePromoCode = async (props: StoreActionProps, data: PromoCode) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.post(`/api/admin/promo-codes/update`, data);
        const response = apiResponse.parsedBody;

        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });
        return response.data as GetPromoCode;
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
    }
};

const createPromoCode = async (props: StoreActionProps, data: PromoCode) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.post(`/api/admin/promo-codes/create`, data);
        const response = apiResponse.parsedBody;

        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });
        return response.data as GetPromoCode;
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
    }
};

const promoCodeAction = (props?: StoreActionProps) => {
    if (!props) {
        return null;
    }
    return {
        getPromoAllCodes: () => getPromoAllCodes(props),
        createPromoCode: (data: PromoCode) => createPromoCode(props, data),
        updatePromoCode: (data: PromoCode) => updatePromoCode(props, data),
        updateAllPromoCodes: (data: PromoCode[]) => updateAllPromoCodes(props, data)
    };
};

export default promoCodeAction;

export interface PromoCodeAction {
    promoCodeAction: typeof promoCodeAction;
}
