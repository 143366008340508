import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Grid, Button, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import useAdminStyles from '../styles';
import storeContext from '../../contexts/store-context';
import TableComponent, { HeadCell, TableResultRow } from '../common/table-component';
import { useStyles } from '../manage-user';
import HasPermission from '../../utilities/can';
import { useUserService } from '../../contexts/user-context';
import { GeoLocationCountry } from '../../types';
import ConfirmationDialog from '../common/confirmation-dialog';
import AddWhitelistCountry from './add-countries-form';

type State = {
    isLoading: boolean;
    allowedCountries: string[];
    renderForm: boolean;
    menuAnchorEle: HTMLElement | null;
    activeCountry: string | null;
    selectedRows: Array<TableResultRow>;
    isConfirmationDialogOpen: boolean;
    isSaving: boolean;
    isDeleting: boolean,
};

const ManageB2CWhitelistedCountries = () => {
    const adminClasses = useAdminStyles();
    const classes = useStyles();
    const userService = useUserService();
    const history = useHistory();

    const { appAction, manageB2CWhitelistedCountriesAction, geoLocationAction } = storeContext();
    
    const [state, setState] = useState<State>({
        isLoading: false,
        allowedCountries: [],
        isDeleting: false,
        renderForm: false,
        menuAnchorEle: null,
        activeCountry: null,
        selectedRows: [],
        isConfirmationDialogOpen: false,
        isSaving: false
    });
    const [allGeoData, setAllGeoData] = React.useState<GeoLocationCountry[]>([]);
    const [loadingAllGeoData, setLoadingAllGeoData] = React.useState(true);
    
    const canRead = HasPermission(userService.user, 'MANAGE_ALLOWED_REGISTRATION_COUNTRIES', 'READ');
    const canCreate = HasPermission(userService.user, 'MANAGE_ALLOWED_REGISTRATION_COUNTRIES', 'CREATE');
    const canDelete = HasPermission(userService.user, 'MANAGE_ALLOWED_REGISTRATION_COUNTRIES', 'DELETE');
    
    const filteredGeoDataShortNames = new Set(allGeoData.map((c) => c.shortName));

    const fetchAllWhitelistedCountries = async () => {
        setState({...state, isLoading: true});
        const result = await manageB2CWhitelistedCountriesAction()?.getAllB2CWhitelistedCountries();
        setState({...state, allowedCountries: result || [], isLoading: false});
    };
    
    const fetchAllGeoData = async () => {
        setLoadingAllGeoData(true);
        const newGeoData = await geoLocationAction()?.fetchAllGeoData();
        setAllGeoData(newGeoData || []);
        setLoadingAllGeoData(false);
    };
    
    useEffect(() => {
        if (!canRead) {
            history.push('/dashboard');
            return;
        }
        appAction()?.renderFullHeader();
        fetchAllGeoData();
        fetchAllWhitelistedCountries();
    }, []);

    const headCells: Array<HeadCell> = [
        { id: 'name', label: 'Name' },
        { id: 'country', label: 'Code' }
    ];

    const tableRows: Array<TableResultRow> = React.useMemo(
        () =>
            state.allowedCountries.map((c) => {
                const countryName = (allGeoData.find((g)=> g.shortName === c))?.name || null;
                return {
                    id: {
                        text: c
                    },
                    name: {
                        align: 'left',
                        text: countryName || '-'
                    },
                    country: {
                        align: 'left',
                        text: c
                    }
                };
            }),
        [state.allowedCountries]
    );

    const onCountryAdd = async (country: string) => {
        if(!canCreate) return;

        setState({...state, isSaving: true});
        const countries = await manageB2CWhitelistedCountriesAction()?.addB2CWhitelistedCountry(country);
        setState({...state, allowedCountries: countries || [], isSaving: false});
        closeForm();
    }

    const onDeleteConfirm = async () => {
        if (!canDelete) {
            return;
        }
        const countries: string[] = [];

        const codesToDelete = state.selectedRows.map((row) => row.id.text);
        Array.prototype.push.apply(countries, codesToDelete);

        setState((prevState) => ({ ...prevState, isDeleting: true, isConfirmationDialogOpen: false,
            activeCountry: null,
            menuAnchorEle: null  }));
        
        const countriesToDelete = state.allowedCountries.filter((p) => countries.includes(p));
        const result = await manageB2CWhitelistedCountriesAction()?.removeB2CWhitelistedCountries(countriesToDelete);

        setState((prevState) => ({ ...prevState, isDeleting: false, allowedCountries: result || [] }));
    };


    const closeForm = () => setState((prevState) => ({ ...prevState, renderForm: false, activeCountry: null }));

    const headerComponent = (
        <Grid item={true} xs={12} className={classes.headerComponent}>
            <Typography className={classes.tableHeading}>B2C Whitelisted Countries</Typography>
            <Button
                onClick={() =>
                    setState((prevState) => ({ ...prevState, renderForm: true, active: null }))
                }
                size="small"
                color="primary"
                variant="contained"
            >
                <AddIcon />
                &nbsp;Add Country
            </Button>
        </Grid>
    );

    return (
        <Grid item={true} xs={12} className={adminClasses.root}>
            <Container className={adminClasses.container}>

                {state.renderForm && !loadingAllGeoData && (
                        <AddWhitelistCountry
                            allGeoData={allGeoData.filter((geoData) => !state.allowedCountries.includes(geoData.shortName)).sort((a,b) => a.name.localeCompare(b.name))}
                            filteredGeoDataShortNames={filteredGeoDataShortNames}
                            isSaving={state.isSaving}
                            onCancel={closeForm}
                            onCountryAdd={onCountryAdd}
                        />
                )}

                <TableComponent
                    headerComponent={(!state.renderForm && canCreate && headerComponent) || undefined}
                    showPaginator={{ bottom: true }}
                    showCheckbox={true}
                    showSearch={true}
                    isLoading={state.isLoading}
                    rows={tableRows}
                    headCells={headCells}
                    selectedRows={state.selectedRows}
                    onDelete={() => setState((prevState) => ({ ...prevState, isConfirmationDialogOpen: true }))}
                    onCheckboxSelect={(selectedRows) => setState((prevState) => ({ ...prevState, selectedRows }))}
                    keyField="id"
                    fillEmptyRows={false}
                    noOfRowsPerPage={5}
                />
            </Container>
            <ConfirmationDialog
                header={'Are you sure?'}
                subHeader={'Click CONFIRM to remove the selected from the allowed list.'}
                isLoading={state.isDeleting}
                open={state.isConfirmationDialogOpen}
                onClose={() =>
                    setState({
                        ...state,
                        isConfirmationDialogOpen: false,
                        activeCountry: null,
                        menuAnchorEle: null
                    })
                }
                onConfirm={onDeleteConfirm}
            />
        </Grid>
    );
};

export default ManageB2CWhitelistedCountries;
