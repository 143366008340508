import React, { useEffect, useState } from 'react';

import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useApiService } from '../../contexts/api-service-context';
import { useToasterData } from '../../contexts/toaster-context';
import { ConstantType, ConstantTypeEnum, ErrorType } from '../../types';
import { CashbackSlab, CashbackSlabValue, EGifterProduct } from '../../types/eGifter';
import { SavedProducts } from '../../types/savedProducts';

import ManagePrcingCalculations from './calculations';
import ConfirmationDialog from '../common/confirmation-dialog';
import GlobalCashbackSlab, { slabKeyToObj } from './globalCashbackSlab';
import { nanoid } from 'nanoid';

export const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '12px 24px',
        [theme.breakpoints.up('lg')]: {
            display: 'flex'
        },
        '& $inner': {
            [theme.breakpoints.up('lg')]: {
                display: 'flex'
            }
        }
    },
    accordion: {
        boxShadow: theme.shadows[4],
        width: '100%',
        borderRadius: '6px !important',
        margin: '16px auto !important',
        padding: '0 8px',
        '&:before': {
            backgroundColor: 'transparent'
        }
    },
    form: {
        margin: 'auto',
        '& $footer': {
            width: '100%',
            display: 'flex',
            padding: '16px 0',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.up('sm')]: {
                justifyContent: 'flex-end'
            },
            '& button': {
                margin: '0 8px',
                [theme.breakpoints.up('sm')]: {
                    margin: '0 0 0 12px'
                }
            }
        }
    },
    textFieldGrid: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: 16,
        gap: 12,
        '& label': {
            textTransform: 'capitalize'
        },
        '& $second': {
            marginTop: 16,
            [theme.breakpoints.up(480)]: {
                marginTop: 0
            }
        },
        [theme.breakpoints.up(480)]: {
            flexWrap: 'nowrap'
        }
    },
    label: {
        width: 'max-content',
        textTransform: 'capitalize',
        margin: '16px 0',
        fontWeight: 600,
        color: theme.palette.primary.main
    },
    heading: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        fontSize: 20,
        fontWeight: 600,
        '& $circularProgress': {
            marginLeft: 12
        }
    },
    subHeading: {
        fontSize: 20,
        fontWeight: 600
    },
    helperText: {
        display: 'block',
        fontStyle: 'italic',
        fontSize: 14,
        color: 'gray',
        opacity: 0.8,
        '& span': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontStyle: 'italic',
            fontSize: 14,
            color: theme.palette.info.main,
            opacity: 0.8,
            '& svg': {
                fontSize: 16
            }
        }
    },
    rowContainer: {
        display: 'flex',
        alignItems: 'center',
        '& $dragIndicatorIcon': {
            fontSize: 48,
            marginRight: 32
        }
    },
    slabHelperText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        fontStyle: 'italic',
        fontSize: 14,
        color: theme.palette.info.main,
        opacity: 0.8,
        margin: '-8px 0 8px 0',
        '& svg': {
            marginRight: 6,
            fontSize: 16
        }
    },
    dragIndicatorIcon: {},
    circularProgress: {},
    footer: {},
    second: {},
    inner: {}
}));

type ConstantObjectType = {
    percentage: string;
    rate: string;
    fee: string;
    error?: boolean;
};
type ConstantContextType = Record<string, ConstantObjectType>;
type State = {
    isSavingPaymentGateways: boolean;
    isSavingCashbackSlabs: boolean;
    isSavingProduct: boolean;
    isLoading: boolean;
    rawConstants: Array<ConstantType>;
    constants: ConstantContextType;
    cashbackSlabs: CashbackSlabsRecord[];
};

export type CashbackSlabsRecord = CashbackSlabValue & {
    key: string;
    id: string;
};

export const cashbackSlabsToList = (cashslab: CashbackSlab): CashbackSlabsRecord[] => {
    return Object.keys(cashslab)
        .reduce(
            (acc, cur) => [
                ...acc,
                {
                    ...cashslab[cur],
                    key: cur,
                    id: nanoid(8)
                }
            ],
            [] as CashbackSlabsRecord[]
        )
        .sort((a, b) => (a.order || 0) - (b.order || 0));
};

export const listToCashbackSlab = (cashslab: CashbackSlabsRecord[]): CashbackSlab => {
    const cashbackSlab = cashslab.reduce((acc, cur, i) => {
        acc[cur.key] = {
            cashbackPercentage: cur.cashbackPercentage,
            order: i
        };
        return acc;
    }, {} as CashbackSlab);

    return cashbackSlab;
};

type Props = {
    isLoading: boolean;
    activeProduct: SavedProducts | null;
    eGifterProducts: Array<EGifterProduct>;
    productsInDB: Array<SavedProducts>;
    setActiveProduct: (activeProduct: SavedProducts | null) => void;
    updateProductInDB: (activeProduct: SavedProducts | null) => Promise<null>;
    updateTableState: () => Promise<void>;
};
const ManagePricingSettings = (props: Props) => {
    const classes = useStyles();
    const toasterContext = useToasterData();
    const apiService = useApiService();

    const [state, setState] = useState<State>({
        rawConstants: [],
        constants: {} as ConstantContextType,
        cashbackSlabs: [],
        isLoading: true,
        isSavingPaymentGateways: false,
        isSavingCashbackSlabs: false,
        isSavingProduct: false
    });

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            const apiResponse = await apiService.get(`/api/admin/constants/get-all`);
            const response = apiResponse.parsedBody;
            const cashbackSlabs = (response.data.find((c: ConstantType) => c.key === 'CASHBACK_SLABS')?.value ||
                {}) as CashbackSlab;

            const constants = response.data.reduce((acc: ConstantContextType, c: ConstantType) => {
                if (
                    // c.key === 'BRAINTREE_VENMO' ||
                    c.key === 'PAYPAL' ||
                    c.key === 'AUTHORIZE_NET' ||
                    c.key === 'PAYPAL_PAYOUT'
                ) {
                    return { ...acc, [c.key]: c.value };
                }
                return acc;
            }, {} as ConstantContextType);
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                constants,
                cashbackSlabs: cashbackSlabsToList(cashbackSlabs),
                rawConstants: response.data
            }));
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
    };

    const savePaymentGateways = async () => {
        setState((prevState) => ({ ...prevState, isSavingPaymentGateways: true }));
        const constants: Array<ConstantType> = state.rawConstants.map((c) => {
            if (
                c.key === 'BRAINTREE_VENMO' ||
                c.key === 'PAYPAL' ||
                c.key === 'AUTHORIZE_NET' ||
                c.key === 'PAYPAL_PAYOUT'
            ) {
                return { ...c, value: { ...(c.value as Record<string, unknown>), ...state.constants[c.key] } };
            }
            return c;
        });
        await updateConstants(constants);
        setState((prevState) => ({ ...prevState, isSavingPaymentGateways: false }));
    };

    const saveCashbackSlabs = async (cashbackSlabs: CashbackSlabsRecord[]) => {
        for (const slab of cashbackSlabs) {
            const slabObj = slabKeyToObj(slab.key);
            if (!slabObj.valueA) {
                return;
            }
            if (slabObj.type === 'BETWEEN' && !slabObj.valueB) {
                return;
            }
        }
        setState((prevState) => ({ ...prevState, isSavingCashbackSlabs: true }));
        const constants: Array<ConstantType> = state.rawConstants.map((c) => {
            if (c.key === 'CASHBACK_SLABS') {
                return { ...c, value: listToCashbackSlab(cashbackSlabs) };
            }
            return c;
        });
        await updateConstants(constants);
        await props.updateProductInDB(props.activeProduct);
        setState((prevState) => ({ ...prevState, isSavingCashbackSlabs: false }));
    };

    const updateConstants = async (constants: Array<ConstantType>) => {
        try {
            const apiResponse = await apiService.post(`/api/admin/constants/update-all`, { constants });
            const response = apiResponse.parsedBody;
            setState((prevState) => ({ ...prevState, rawConstants: response.data }));
            await props.updateTableState();
            toasterContext.setToaster({
                isOpen: true,
                message: `Settings updated successfully!`,
                severity: 'success'
            });
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
        }
        return null;
    };

    const updateConstantContext = (key: string, field: string, value: string | number) =>
        setState((prevState) => ({
            ...prevState,
            constants: { ...prevState.constants, [key]: { ...prevState.constants[key], [field]: value } }
        }));

    const updateProductInDB = async (updatedActiveProduct: SavedProducts | null) => {
        setState((prevState) => ({ ...prevState, isSavingProduct: true }));
        await props.updateProductInDB(updatedActiveProduct);
        setState((prevState) => ({ ...prevState, isSavingProduct: false }));
    };

    const paymentMethodConstants: Array<typeof ConstantTypeEnum[number]> = [
        // 'BRAINTREE_VENMO',
        'PAYPAL',
        'AUTHORIZE_NET',
        'PAYPAL_PAYOUT'
    ];

    const paymentGatewayContent = (
        <Grid item={true} xs={12}>
            <Typography color="secondary" className={classes.subHeading}>
                Payment Gateways
            </Typography>
            {Object.keys(state.constants).map((key) => {
                if (!paymentMethodConstants.includes(key as typeof ConstantTypeEnum[number])) {
                    return null;
                }
                const helperText = state.constants[key].error || undefined;
                const rate = state.constants[key].rate;
                const fee = state.constants[key].fee;
                const label = key.replace(/_/gi, ' ').toLowerCase();
                const regex = /^\d+(\.\d{0,2})?$/;

                return (
                    <React.Fragment key={key}>
                        <Typography className={classes.label}>{label}</Typography>

                        <Grid item={true} xs={12} className={classes.textFieldGrid}>
                            {rate && (
                                <TextField
                                    type="number"
                                    size="small"
                                    variant="outlined"
                                    label={`${label} rate`}
                                    value={rate}
                                    helperText={helperText}
                                    onChange={(e) => {
                                        const value = +e.target.value;
                                        if (isNaN(value) || value < 0 || !regex.test(`${value}`)) {
                                            return;
                                        }
                                        updateConstantContext(key, (rate && 'rate') || 'percentage', value.toString());
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>
                                    }}
                                />
                            )}

                            {fee && (
                                <TextField
                                    className={classes.second}
                                    type="number"
                                    size="small"
                                    variant="outlined"
                                    label={`${label} fixed fee`}
                                    value={fee}
                                    helperText={helperText}
                                    onChange={(e) => {
                                        const value = +e.target.value;
                                        if (isNaN(value) || value < 0 || !regex.test(`${value}`)) {
                                            return;
                                        }
                                        updateConstantContext(key, 'fee', value.toString());
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                    }}
                                />
                            )}
                        </Grid>
                    </React.Fragment>
                );
            })}

            <Grid item={true} xs={12} className={classes.footer}>
                <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={(e) => savePaymentGateways()}
                    disabled={state.isSavingPaymentGateways}
                >
                    save
                    {state.isSavingPaymentGateways && <CircularProgress className="button-loader" />}
                </Button>
            </Grid>
        </Grid>
    );

    return (
        <Container className={classes.mainContainer}>
            <Accordion className={classes.accordion}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="global-settings-content"
                    id="global-settings-header"
                >
                    <Typography className={classes.heading}>Global Settings</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container={true} className={classes.inner}>
                        <Grid item={true} xs={12} className={classes.form}>
                            {(state.isLoading && <CircularProgress />) || paymentGatewayContent}
                            {(state.isLoading && <CircularProgress />) || (
                                <GlobalCashbackSlab
                                    cashbackSlabs={state.cashbackSlabs}
                                    isSavingCashbackSlabs={state.isSavingCashbackSlabs}
                                    onCashbackSlabSave={(cashbackSlab) => saveCashbackSlabs(cashbackSlab)}
                                    onSlabUpdate={(updatedCashbackSlabs: CashbackSlabsRecord[]) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            cashbackSlabs: updatedCashbackSlabs
                                        }))
                                    }
                                />
                            )}
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>

            {props.activeProduct && (
                <ConfirmationDialog
                    isLoading={state.isSavingProduct}
                    maxWidth="xl"
                    header={`Calculation Playground - ${
                        ((props.isLoading || state.isLoading) && (
                            <CircularProgress className={classes.circularProgress} size={24} />
                        )) ||
                        props.activeProduct?.name + ' (' + props.activeProduct?.productId + ')'
                    }`}
                    open={!!props.activeProduct}
                    additionalContent={
                        <ManagePrcingCalculations
                            isLoading={state.isLoading}
                            constants={state.rawConstants}
                            activeProduct={props.activeProduct}
                            eGifterProducts={props.eGifterProducts}
                            setActiveProduct={props.setActiveProduct}
                        />
                    }
                    onClose={() => {
                        updateProductInDB(props.activeProduct);
                        props.setActiveProduct(null);
                    }}
                    onConfirm={() => updateProductInDB(props.activeProduct)}
                />
            )}
        </Container>
    );
};

export default ManagePricingSettings;
