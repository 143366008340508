import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import AddIcon from '@material-ui/icons/Add';

import { AdminUser, ErrorType } from '../../types';

import UserAvatar from '../common/user-avatar';
import TableComponent, { HeadCell, TableResultRow } from '../common/table-component';
import { UserAddEditForm } from '../my-account';

import HasPermission from '../../utilities/can';
import { joinNameParts } from '../../utilities';
import useAdminStyles from '../styles';
import StatusButton from '../common/status-button';
import { useApiService } from '../../contexts/api-service-context';
import { useToasterData } from '../../contexts/toaster-context';
import { useUserService } from '../../contexts/user-context';
import storeContext from '../../contexts/store-context';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    userAvatarOuter: {
        display: 'flex',
        alignItems: 'center',
        '& $userAvatar': {
            marginRight: 16
        }
    },
    headerComponent: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '0 16px'
    },
    userAvatar: {}
}));

type State = {
    isLoading: boolean;
    renderAddEditForm: boolean;
    menuAnchorEle: HTMLElement | null;
    activeUser: AdminUser | null;
    userToBeEdited: AdminUser | null;
    users: Array<AdminUser>;
};

const Users = () => {
    const classes = useStyles();
    const adminClasses = useAdminStyles();

    const history = useHistory();
    const apiService = useApiService();
    const toasterContext = useToasterData();
    const userService = useUserService();
    const { appAction } = storeContext();

    const canReadUser = HasPermission(userService.user, 'MANAGE_ADMIN_USER', 'READ');
    const canCreateUser = HasPermission(userService.user, 'MANAGE_ADMIN_USER', 'CREATE');
    const canUpdateUser = HasPermission(userService.user, 'MANAGE_ADMIN_USER', 'UPDATE');

    useEffect(() => {
        if (!canReadUser) {
            history.push('/dashboard');
            return;
        }
        appAction()?.renderFullHeader();
        getAllAdminUsers();
    }, []);

    const [state, setState] = useState<State>({
        users: [],
        isLoading: false,
        renderAddEditForm: false,
        menuAnchorEle: null,
        activeUser: null,
        userToBeEdited: null
    });

    const getAllAdminUsers = async () => {
        try {
            setState((prevState) => ({ ...prevState, isLoading: true }));

            const apiResponse = await apiService.get(`/api/admin/user/get-all`);
            const response = apiResponse.parsedBody;
            setState((prevState) => ({ ...prevState, isLoading: false }));

            if (!response || !response.status) {
                toasterContext.setToaster({
                    isOpen: true,
                    message: response.message,
                    severity: 'error'
                });
                return;
            }
            setState((prevState) => ({ ...prevState, users: response.data.users || [] }));
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
    };

    const headCells: Array<HeadCell> = [
        { id: 'name', label: 'Name' },
        { id: 'email', label: 'Email' },
        { id: 'status', label: 'Status', align: 'left' },
        { id: 'options', label: '', align: 'right' }
    ];

    const tableRows: Array<TableResultRow> = state.users.map((user) => {
        const name = joinNameParts(user) || '-';

        return {
            userId: {
                text: user.userId?.toString() || ''
            },
            name: {
                align: 'left',
                text: name,
                element: (
                    <Grid item={true} className={classes.userAvatarOuter}>
                        <UserAvatar className={classes.userAvatar} user={user} />
                        <Typography>{name}</Typography>
                    </Grid>
                )
            },
            email: {
                align: 'left',
                text: user.email || '-'
            },
            status: {
                align: 'left',
                text: '-',
                element: (
                    <StatusButton
                        options={[
                            {
                                type: 'INVITATION_PENDING',
                                text: 'Invited',
                                color: 'warning'
                            },
                            {
                                type: 'ACCESS_REVOKED',
                                text: 'Access revoked',
                                color: 'danger'
                            }
                        ]}
                        type={user.inActiveReason}
                    />
                )
            },
            options: {
                align: 'right',
                text: '',
                helperText: user.updatedBy?.userId && `updated by - ${user.updatedBy?.firstName ? joinNameParts(user.updatedBy) : user.updatedBy?.email || ''
                    }${user.updatedBy?.updatedAt && ' - '}${user.updatedBy?.updatedAt && moment(new Date(user.updatedBy?.updatedAt)).fromNow()
                    }` || undefined,
                element:
                    (canUpdateUser && (
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setState((prevState) => ({
                                    ...prevState,
                                    menuAnchorEle: e.currentTarget,
                                    activeUser: user
                                }));
                            }}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    )) ||
                    undefined
            }
        };
    });

    const headerComponent = (
        <Grid item={true} xs={12} className={adminClasses.headerComponent}>
            <Button
                onClick={(e) => setState((prevState) => ({ ...prevState, renderAddEditForm: true }))}
                size="small"
                color="primary"
                variant="contained"
            >
                <AddIcon />
                &nbsp;Create New User
            </Button>
        </Grid>
    );

    const handleMenuClose = () => {
        setState((prevState) => ({
            ...prevState,
            menuAnchorEle: null,
            activeUser: null
        }));
    };

    const handleRowClick = (userId: string) => {
        if (!canUpdateUser || state.renderAddEditForm) {
            return;
        }

        setState((prevState) => ({
            ...prevState,
            renderAddEditForm: true,
            userToBeEdited: prevState.users.find((u) => u.userId === +userId) || null
        }));
    };

    const handleRevokeUserAccess = async () => {
        handleMenuClose();

        const userId = state.activeUser?.userId || null;
        if (!userId) {
            return;
        }

        try {
            const apiResponse = await apiService.post(`/api/admin/user/revoke-access/${userId}`, {});
            const response = apiResponse.parsedBody;
            if (!response || !response.status) {
                toasterContext.setToaster({
                    isOpen: true,
                    message: response.message,
                    severity: 'error'
                });
                return;
            }

            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'success'
            });

            setState((prevState) => ({
                ...prevState,
                users: prevState.users.map(
                    (u) => (u.userId === response.data.user?.userId && { ...response.data.user }) || { ...u }
                )
            }));
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
        }
    };

    const handleGrantUserAccess = async () => {
        handleMenuClose();

        const userId = state.activeUser?.userId || null;
        if (!userId) {
            return;
        }

        try {
            const apiResponse = await apiService.post(`/api/admin/user/grant-access/${userId}`, {});
            const response = apiResponse.parsedBody;
            if (!response || !response.status) {
                toasterContext.setToaster({
                    isOpen: true,
                    message: response.message,
                    severity: 'error'
                });
                return;
            }

            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'success'
            });

            setState((prevState) => ({
                ...prevState,
                users: prevState.users.map(
                    (u) => (u.userId === response.data.user?.userId && { ...response.data.user }) || { ...u }
                )
            }));
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
        }
    };

    const renderMenuItems = () => {
        let menuItems: Array<JSX.Element> = [];
        const grantAccessMenuItem = (
            <MenuItem key="1" onClick={handleGrantUserAccess}>
                Grant Access
            </MenuItem>
        );
        const resendinvitationMenuItem = (
            <MenuItem key="2" onClick={handleGrantUserAccess}>
                Resend Invitation
            </MenuItem>
        );
        const revokeAccessMenuItem = (
            <MenuItem key="3" onClick={handleRevokeUserAccess}>
                Revoke Access
            </MenuItem>
        );

        if (state.activeUser?.inActiveReason === 'ACCESS_REVOKED') {
            menuItems = [...menuItems, grantAccessMenuItem];
        }

        if (state.activeUser?.inActiveReason === 'INVITATION_PENDING') {
            menuItems = [...menuItems, resendinvitationMenuItem, revokeAccessMenuItem];
        }

        if (!state.activeUser?.inActiveReason || state.activeUser?.inActiveReason === 'ACCOUNT_TEMPORARILY_LOCKED') {
            menuItems = [...menuItems, revokeAccessMenuItem];
        }

        return menuItems;
    };

    return (
        <Grid item={true} xs={12} className={adminClasses.root}>
            <Container className={adminClasses.container}>
                {(state.renderAddEditForm && (
                    <UserAddEditForm
                        user={state.userToBeEdited}
                        onCancel={() =>
                            setState((prevState) => ({ ...prevState, renderAddEditForm: false, userToBeEdited: null }))
                        }
                        onCreate={(user) =>
                            setState((prevState) => ({
                                ...prevState,
                                renderAddEditForm: false,
                                userToBeEdited: null,
                                users: [{ ...user }, ...state.users]
                            }))
                        }
                        onUpdate={(user) =>
                            setState((prevState) => ({
                                ...prevState,
                                renderAddEditForm: false,
                                userToBeEdited: null,
                                users: prevState.users.map((u) => (u.userId === user.userId && { ...user }) || { ...u })
                            }))
                        }
                    />
                )) ||
                    null}

                <Menu anchorEl={state.menuAnchorEle} open={!!state.menuAnchorEle} onClose={handleMenuClose}>
                    {renderMenuItems()}
                </Menu>

                <TableComponent
                    rowHover={canUpdateUser && !state.renderAddEditForm}
                    headerComponent={(!state.renderAddEditForm && canCreateUser && headerComponent) || undefined}
                    showPaginator={{ bottom: true }}
                    showCheckbox={false}
                    showSearch={true}
                    isLoading={state.isLoading}
                    rows={tableRows}
                    headCells={headCells}
                    fillEmptyRows={false}
                    keyField="userId"
                    onRowClick={handleRowClick}
                    rowTooltip={(canUpdateUser && !state.renderAddEditForm && 'Click to edit') || undefined}
                />
            </Container>
        </Grid>
    );
};

export default Users;
