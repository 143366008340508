import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import { ConstantType } from '../../types';
import { BreakEvenCostType, SavedProducts } from '../../types/savedProducts';
import { InputAdornment, TextField } from '@material-ui/core';
import { CashbackSlab, CashbackSlabKeyType, EGifterProduct } from '../../types/eGifter';
import { DEFAULT_BREAK_EVEN_COST } from '.';
import { formatDinero, getDinero } from '../../utilities';
import TableComponent, { HeadCell, TableResultRow } from '../common/table-component';
import { CashbackSlabsRecord, cashbackSlabsToList, listToCashbackSlab } from './settings';
import GlobalCashbackSlab from './globalCashbackSlab';

export const useStyles = makeStyles((theme) => ({
    mainContainer: {
        padding: '12px 24px',
        [theme.breakpoints.up('lg')]: {
            display: 'flex'
        },
        '& $inner': {
            [theme.breakpoints.up('lg')]: {
                display: 'flex'
            }
        }
    },
    accordion: {
        boxShadow: theme.shadows[4],
        width: '100%',
        borderRadius: 8,
        margin: '16px auto !important',
        padding: '0 8px'
    },
    form: {
        margin: 'auto',
        '& $footer': {
            width: '100%',
            display: 'flex',
            padding: '16px 0',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.up('sm')]: {
                justifyContent: 'flex-end'
            },
            '& button': {
                margin: '0 8px',
                [theme.breakpoints.up('sm')]: {
                    margin: '0 0 0 12px'
                }
            }
        }
    },
    textFieldGrid: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: 16,
        '& label': {
            textTransform: 'capitalize'
        },
        '& $second': {
            marginTop: 16,
            [theme.breakpoints.up(480)]: {
                marginLeft: 16,
                marginTop: 0
            }
        },
        [theme.breakpoints.up(480)]: {
            flexWrap: 'nowrap'
        }
    },
    label: {
        width: 'max-content',
        textTransform: 'capitalize',
        margin: '16px 0',
        fontWeight: 600,
        color: theme.palette.primary.main
    },
    heading: {
        textAlign: 'left',
        fontSize: 20,
        fontWeight: 600
    },
    details: {
        textAlign: 'left'
    },
    staticFields: {
        display: 'flex',
        textAlign: 'left',
        '& span': {
            textTransform: 'capitalize',
            '&:first-child': {
                fontWeight: 900,
                marginRight: 12
            }
        },
        '& input': {
            padding: 0,
            textAlign: 'left',
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: '1.5',
            letterSpacing: '0.00938em'
        }
    },
    textField: {
        margin: '16px 0 24px 0'
    },
    success: {
        color: theme.palette.success.main,
        fontWeight: 'bold'
    },
    error: {
        color: theme.palette.error.main,
        fontWeight: 'bold'
    },
    content: {
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.up(1080)]: {
            minWidth: 960,
            flexWrap: 'nowrap'
        }
    },
    topGrid: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    rightPane: {
        '& $label': {
            margin: '0 0 16px 0'
        }
    },
    footer: {},
    second: {},
    inner: {}
}));

export const fetchCashbackPercentageForGivenCardValue = (cashbackSlabs: CashbackSlab, cardValue: number) => {
    let maxCashbackPercentage = 0;
    Object.keys(cashbackSlabs).forEach((key) => {
        if (key.startsWith('BELOW')) {
            const valueA = +(key.match(/\d/g)?.join('') || 0);
            if (cardValue <= valueA) {
                maxCashbackPercentage = cashbackSlabs[key as CashbackSlabKeyType].cashbackPercentage;
            }
        }

        if (key.startsWith('ABOVE')) {
            const valueA = +(key.match(/\d/g)?.join('') || 0);
            if (cardValue > valueA) {
                maxCashbackPercentage = cashbackSlabs[key as CashbackSlabKeyType].cashbackPercentage;
            }
        }

        if (key.includes('TO')) {
            const [valueA, valueB] = key.split('_TO_');
            if (cardValue > +valueA && cardValue <= +valueB) {
                maxCashbackPercentage = cashbackSlabs[key as CashbackSlabKeyType].cashbackPercentage;
            }
        }
    });
    return maxCashbackPercentage;
};

const calculateBreakEvenCost = (
    eGifterFee: number,
    eGifterDiscountPercentage: number,
    PaymentGatewayFee: number,
    PaymentGatewayRate: number
) => {
    const fixedFee = Number(eGifterFee || 0) + Number(PaymentGatewayFee);
    const eGifterDiscount = (eGifterDiscountPercentage || 0) / 100;
    const transactionRate = PaymentGatewayRate / 100;
    const breakEvenCostRaw = fixedFee / (eGifterDiscount - transactionRate);
    return +(breakEvenCostRaw < 0 ? 0 : breakEvenCostRaw).toFixed(2);
};

type State = {
    cardValue: number;
    cashbackSlabs: CashbackSlabsRecord[];
    breakEvenCost: BreakEvenCostType;
};

type Props = {
    eGifterProducts: Array<EGifterProduct>;
    activeProduct: SavedProducts | null;
    constants: Array<ConstantType>;
    isLoading: boolean;
    setActiveProduct: (activeProduct: SavedProducts | null) => void;
};
const ManagePrcingCalculations = (props: Props) => {
    const classes = useStyles();
    const defaultCashbackSlabs = (props.constants.find((c) => c.key === 'CASHBACK_SLABS')?.value || {}) as CashbackSlab;

    const [state, setState] = useState<State>({
        cardValue: 44,
        cashbackSlabs: cashbackSlabsToList({ ...(props.activeProduct?.cashbackSlabs || defaultCashbackSlabs) }),
        breakEvenCost: props.activeProduct?.breakEvenCost || DEFAULT_BREAK_EVEN_COST
    });

    const eGifterProduct = props.eGifterProducts.find((p) => p.id === props.activeProduct?.productId) || null;
    const eGifterDiscount = eGifterProduct?.cost?.discountPercentage || 0;
    const maxDiscountValue = +((eGifterDiscount * state.cardValue) / 100).toFixed(2);

    useEffect(() => {
        if (props.activeProduct) {
            // const venmo = props.constants.find(c => c.key === 'BRAINTREE_VENMO');
            const paypal = props.constants.find(c => c.key === 'PAYPAL');
            const authorizeNet = props.constants.find(c => c.key === 'AUTHORIZE_NET');

            props.setActiveProduct({
                ...props.activeProduct,
                breakEvenCost: {
                    ...props.activeProduct.breakEvenCost,
                    AUTHORIZE_NET: calculateBreakEvenCost(
                        eGifterProduct?.cost?.fee || 0,
                        eGifterProduct?.cost?.discountPercentage || 0,
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        ((authorizeNet?.value as any).fee || 0) as number,
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        ((authorizeNet?.value as any).rate || 0) as number
                    ),
                    // BRAINTREE_VENMO: calculateBreakEvenCost(
                    //     eGifterProduct?.cost?.fee || 0,
                    //     eGifterProduct?.cost?.discountPercentage || 0,
                    //     (venmo?.value.fee || 0) as number,
                    //     (venmo?.value.rate || 0) as number
                    // ),
                    PAYPAL: calculateBreakEvenCost(
                        eGifterProduct?.cost?.fee || 0,
                        eGifterProduct?.cost?.discountPercentage || 0,
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        ((paypal?.value as any).fee || 0) as number,
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        ((paypal?.value as any).rate || 0) as number
                    ),
                }
            });
        }
    }, [state.breakEvenCost]);

    const paymentGatewayConstants = props.constants.filter((c) =>
        // c.key === 'BRAINTREE_VENMO' ||
        c.key === 'PAYPAL' ||
        c.key === 'AUTHORIZE_NET');
    const headCells: Array<HeadCell> = [
        { id: 'option', label: '' },
        { id: 'transactionCost', label: 'Transaction Cost' },
        { id: 'fixedFee', label: 'Fixed Fee' },
        { id: 'totalCharges', label: 'Total Charges' },
        { id: 'breakEvenCost', label: 'BreakEven Cost' },
        { id: 'profitMargin', label: 'Profit Margin' },
        { id: 'profitMarginPercentage', label: 'Profit Margin Percentage' },
        { id: 'cashback', label: 'cashback' },
        { id: 'cashbackPercentage', label: 'Cashback Percentage' }
    ];

    const rows: Array<TableResultRow> = paymentGatewayConstants.map((c) => {
        const label = c.key.replace(/_/gi, ' ').toUpperCase();
        const constant = props.constants.find((cp) => cp.key === c.key);

        const eGifterFee = (eGifterProduct?.cost?.fee || 0) as number;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const rate = ((constant?.value as any).rate || 0) as number;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fee = ((constant?.value as any).fee || 0) as number;

        const fixedFee = Number(eGifterFee || 0) + Number(fee);
        const eGifterDiscount = (eGifterProduct?.cost?.discountPercentage || 0) / 100;

        const transactionRate = rate / 100;
        const breakEvenCostRaw = fixedFee / (eGifterDiscount - transactionRate);
        const breakEvenCost = breakEvenCostRaw < 0 ? 0 : breakEvenCostRaw;

        const rateValue = (rate * state.cardValue) / 100;
        const total = Number(rateValue) + Number(fee) + Number(eGifterFee);

        const margin = maxDiscountValue - +total;
        const marginPercentage = (margin / state.cardValue) * 100;

        const maxCashbackPercentage = fetchCashbackPercentageForGivenCardValue(
            listToCashbackSlab(state.cashbackSlabs),
            state.cardValue
        );

        const cashback = (margin * maxCashbackPercentage) / 100;
        const cashbackPercentage = (cashback / state.cardValue) * 100;

        return {
            id: {
                align: 'left',
                text: c.key
            },
            option: {
                align: 'left',
                text: label
            },
            transactionCost: {
                align: 'left',
                text: `${rateValue}`,
                element: (
                    <React.Fragment>
                        <Typography component="span">
                            {rate}% of ${state.cardValue}
                        </Typography>
                        <br />
                        <Typography component="span" color="primary">
                            {formatDinero(getDinero(rateValue))}
                        </Typography>
                    </React.Fragment>
                ),
                isNumeric: true
            },
            fixedFee: {
                align: 'left',
                text: `${fee}`,
                element: <Typography component="span">${fee}</Typography>,
                isNumeric: true
            },
            totalCharges: {
                align: 'left',
                text: `${total}`,
                element: (
                    <React.Fragment>
                        <Typography component="span" color="primary" style={{ marginRight: 0, fontWeight: 400 }}>
                            {formatDinero(getDinero(rateValue))}&nbsp;+
                        </Typography>
                        <br />
                        <Typography component="span">${fee}&nbsp;+</Typography>
                        <br />
                        <Typography component="span">${eGifterFee}&nbsp;=</Typography>
                        <br />
                        <Typography component="span" color="primary">
                            {formatDinero(getDinero(total))}
                        </Typography>
                    </React.Fragment>
                ),
                isNumeric: true
            },
            breakEvenCost: {
                align: 'left',
                text: `${breakEvenCost}`,
                element: (
                    <React.Fragment>
                        <Typography
                            className={(breakEvenCost === 0 && classes.error) || classes.success}
                            component="span"
                        >
                            {formatDinero(getDinero(breakEvenCost))}
                        </Typography>
                    </React.Fragment>
                ),
                isNumeric: true
            },
            profitMargin: {
                align: 'left',
                text: `${margin}`,
                element: (
                    <React.Fragment>
                        <Typography component="span" className={(margin < 0 && classes.error) || classes.success}>
                            {formatDinero(getDinero(margin))}
                        </Typography>
                    </React.Fragment>
                ),
                isNumeric: true
            },
            profitMarginPercentage: {
                align: 'left',
                text: `${marginPercentage}`,
                element: (
                    <React.Fragment>
                        <Typography component="span" className={(margin < 0 && classes.error) || classes.success}>
                            {marginPercentage.toFixed(2)}%
                        </Typography>
                    </React.Fragment>
                ),
                isNumeric: true
            },
            cashback: {
                align: 'left',
                text: `${cashback}`,
                element: (
                    <React.Fragment>
                        {(cashback > 0 && (
                            <Typography component="span" className={classes.success}>
                                {formatDinero(getDinero(cashback))}
                            </Typography>
                        )) || (
                                <Typography component="span" className={classes.error}>
                                    NA
                                </Typography>
                            )}
                    </React.Fragment>
                ),
                isNumeric: true
            },
            cashbackPercentage: {
                align: 'left',
                text: `${cashbackPercentage}`,
                element: (
                    <React.Fragment>
                        {(cashback > 0 && (
                            <Typography component="span" className={classes.success}>
                                {cashbackPercentage.toFixed(2)}%
                            </Typography>
                        )) || (
                                <Typography component="span" className={classes.error}>
                                    NA
                                </Typography>
                            )}
                    </React.Fragment>
                ),
                isNumeric: true
            }
        };
    });

    const leftPane = (
        <Grid item={true} xs={12} className={classes.details}>
            <Grid item={true} xs={12}>
                <TextField
                    size="small"
                    variant="outlined"
                    label="card value"
                    type="number"
                    margin="normal"
                    className={classes.textField}
                    value={state.cardValue}
                    onChange={(e) => setState((prevState) => ({ ...prevState, cardValue: +e.target.value }))}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                />
            </Grid>

            <Typography className={classes.label}>E-Gifter</Typography>
            <Grid item={true} xs={12} className={classes.staticFields}>
                <Typography color="textSecondary" component="span">
                    {' '}
                    E-Gifter Discount:{' '}
                </Typography>
                <Typography component="span">
                    {eGifterProduct?.cost?.discountPercentage}% of ${state.cardValue}
                </Typography>
                <Typography component="span" color="primary">
                    &nbsp;=&nbsp;${maxDiscountValue}
                </Typography>
            </Grid>

            <Grid item={true} xs={12} className={classes.staticFields}>
                <Typography color="textSecondary" component="span">
                    E-Gifter Fixed Fee:
                </Typography>
                <Typography component="span">${eGifterProduct?.cost?.fee}</Typography>
            </Grid>
        </Grid>
    );

    const rightPane = (
        <Grid item={true} xs={12} className={clsx(classes.details, classes.rightPane)}>
            <GlobalCashbackSlab
                cashbackSlabs={state.cashbackSlabs}
                isSavingCashbackSlabs={props.isLoading}
                hideSaveButton={true}
                onSlabUpdate={(updatedCashbackSlabs: CashbackSlabsRecord[]) => {
                    console.log('onSlabUpdate', props.activeProduct);

                    const updatedState = {
                        ...state,
                        cashbackSlabs: updatedCashbackSlabs
                    };
                    setState(updatedState);

                    if (props.activeProduct) {
                        props.setActiveProduct({
                            ...props.activeProduct,
                            cashbackSlabs: listToCashbackSlab(updatedCashbackSlabs)
                        });
                    }
                }}
                onSlabDelete={(updatedCashbackSlabs: CashbackSlabsRecord[]) => {
                    if (props.activeProduct) {
                        const updatedActiveProduct = {
                            ...props.activeProduct,
                            cashbackSlabs: listToCashbackSlab(updatedCashbackSlabs)
                        };
                        props.setActiveProduct(updatedActiveProduct);
                    }
                }}
            />
        </Grid>
    );

    const content = (
        <React.Fragment>
            <Grid item={true} xs={12} className={classes.content}>
                {leftPane}
                {rightPane}
            </Grid>
            <Grid item={true} xs={12}>
                <TableComponent
                    keyField="id"
                    showSearch={false}
                    showCheckbox={false}
                    rowHover={false}
                    fillEmptyRows={false}
                    rows={rows}
                    headCells={headCells}
                    showPaginator={{ bottom: false, top: false }}
                />
            </Grid>
        </React.Fragment>
    );

    return (
        <Grid container={true} className={classes.inner}>
            <Grid item={true} xs={12} className={classes.form}>
                {(props.isLoading && <CircularProgress />) || content}
            </Grid>
        </Grid>
    );
};

export default ManagePrcingCalculations;
