import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';

import CachedIcon from '@material-ui/icons/Cached';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import useAdminStyles from '../styles';
import { useUserService } from '../../contexts/user-context';
import { OrderPayloadGiftCardProduct, UserAccount } from '../../types';
import {
    DateFormatTypeEnum,
    formatDinero,
    formatTimeStamp,
    generateDinero,
    getDinero,
    joinNameParts,
    scrollTop
} from '../../utilities';
import HasPermission from '../../utilities/can';
import TableComponent, { HeadCell, TableResultRow } from '../common/table-component';
import UserAvatar from '../common/user-avatar';
import PendingOrderPayoutDetails from './pending-order-payout-history';
import StatusButton from '../common/status-button';
import storeContext from '../../contexts/store-context';
import { useStyles } from '../manage-user';
import { B2BOrderPayoutAttributes, B2BOrderSummaryPayout } from '../../types/b2bPayout';
import { mapPayoutPaymentMethod } from '../../actions/manage-business-user.action';
import OrderDetails, { PayoutTypeEnum } from './order-details';

type State = {
    isRefreshing: boolean;
    isLoading: boolean;
    page: number;
    renderEditForm: boolean;
    menuAnchorEle: HTMLElement | null;
    activeUser: UserAccount | null;
    users: Array<UserAccount>;
    isRefreshingPayouts: boolean;
    isRefreshingOrder: boolean;
    paidPayouts: B2BOrderPayoutAttributes[];
    orderPayouts: B2BOrderSummaryPayout[];
    renderPaidOrderForm: boolean;
    activeOrder: B2BOrderSummaryPayout | null;
    orderMenuAnchorEle: HTMLElement | null;
};

const ManageBusinessOrderPayout = () => {
    const classes = useStyles();
    const adminClasses = useAdminStyles();

    const history = useHistory();
    const userService = useUserService();
    const { appAction, manageBusinessUserAction } = storeContext();

    const canRead = HasPermission(userService.user, 'APPROVE_BUSINESS_USER', 'READ');

    useEffect(() => {
        if (!canRead) {
            history.push('/dashboard');
            return;
        }
        appAction()?.renderFullHeader();
        getAllUnpaidPayouts();
        getAllPaidPayouts();
        getAllOrderPayouts();
    }, []);

    const [state, setState] = useState<State>({
        users: [],
        isLoading: false,
        isRefreshing: false,
        page: 0,
        renderEditForm: false,
        menuAnchorEle: null,
        activeUser: null,
        isRefreshingPayouts: false,
        isRefreshingOrder: false,
        paidPayouts: [],
        orderPayouts: [],
        renderPaidOrderForm: false,
        activeOrder: null,
        orderMenuAnchorEle: null
    });
    const [activeOrderItems, setActiveOrderItems] = useState<OrderPayloadGiftCardProduct[]>([]);

    const getAllUnpaidPayouts = async (isRefreshing?: boolean) => {
        setState((prevState) => ({ ...prevState, isLoading: !isRefreshing, isRefreshing: !!isRefreshing }));
       const payoutOrders = (await manageBusinessUserAction()?.getAllPendingPayoutOrders())?.map(
            (p) => p.businessUserId
        );
        const merchants = await manageBusinessUserAction()?.getAllMerchants();
        const payoutUsers = merchants?.filter((u) => u?.userId && payoutOrders?.includes(u.userId));
        setState((prevState) => ({ ...prevState, isLoading: false, isRefreshing: false, users: payoutUsers || [], page: 0 }));
    };

    const getAllPaidPayouts = async (isRefreshingPayouts?: boolean) => {
        setState((prevState) => ({
            ...prevState,
            isLoading: !isRefreshingPayouts,
            isRefreshingPayouts: !!isRefreshingPayouts
        }));
        const paidPayouts = await manageBusinessUserAction()?.getAllPayouts();
        setState((prevState) => ({
            ...prevState,
            isLoading: false,
            isRefreshingPayouts: false,
            paidPayouts: paidPayouts || [],
            page: 0
        }));
    };

    const getAllOrderPayouts = async (isRefreshingOrder?: boolean) => {
        setState((prevState) => ({
            ...prevState,
            isLoading: !isRefreshingOrder,
            isRefreshingOrder: !!isRefreshingOrder
        }));
        const paidOrders = await manageBusinessUserAction()?.getAllPaidPayoutOrders();
        setState((prevState) => ({
            ...prevState,
            isLoading: false,
            isRefreshingOrder: false,
            orderPayouts: paidOrders || [],
            page: 0
        }));
    };

    const handleChangePage = (page: number) => {
        setState({ ...state, page });
    };

    const headCells: Array<HeadCell> = [
        { id: 'userId', label: 'Merchant User Id' },
        { id: 'name', label: 'Name' },
        { id: 'email', label: 'Email' },
        { id: 'companyName', label: 'Company Name' },
        { id: 'status', label: 'Status', align: 'left' },
        { id: 'options', label: '', align: 'right' }
    ];

    const tableRows: Array<TableResultRow> = state.users.map((user) => {
        const name = joinNameParts(user) || '-';

        return {
            id: {
                text: user.userId?.toString() || ''
            },
            userId: {
                text: `#${user.userId?.toString()}` || '',
                align: 'left'
            },
            name: {
                align: 'left',
                text: name,
                element: (
                    <Grid item={true} className={classes.userAvatarOuter}>
                        <UserAvatar className={classes.userAvatar} user={user} />
                        <Typography>{name}</Typography>
                    </Grid>
                )
            },
            email: {
                align: 'left',
                text: user.email || '-'
            },
            companyName: {
                align: 'left',
                text: user.companyName || '-'
            },
            status: {
                align: 'left',
                text: '',
                element: (
                    <StatusButton
                        options={[
                            {
                                type: 'APPROVAL_PENDING',
                                text: 'Pending',
                                color: 'warning'
                            },
                            {
                                type: 'REVOKED',
                                text: 'Revoked',
                                color: 'danger'
                            },
                            {
                                type: 'ON_WATCH',
                                text: 'ON WATCH',
                                color: 'warning'
                            }
                        ]}
                        type={user.accessStatus}
                    />
                )
            },
            options: {
                align: 'right',
                text: '',
                helperText:
                    (user.updatedBy?.userId &&
                        `updated by - ${
                            user.updatedBy?.firstName ? joinNameParts(user.updatedBy) : user.updatedBy?.email || ''
                        }${user.updatedBy?.updatedAt && ' - '}${
                            user.updatedBy?.updatedAt && moment(new Date(user.updatedBy?.updatedAt)).fromNow()
                        }`) ||
                    undefined,
                element:
                    (canRead && (
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setState((prevState) => ({
                                    ...prevState,
                                    menuAnchorEle: e.currentTarget,
                                    activeUser: user
                                }));
                            }}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    )) ||
                    undefined
            }
        };
    });

    const paidPayoutHeadCells: Array<HeadCell> = [
        { id: 'payoutId', label: 'Payout Id' },
        { id: 'businessUserId', label: 'Merchant User Id' },
        { id: 'amount', label: 'Paid Amount' },
        { id: 'totalPayout', label: 'Total Payout Amount' },
        { id: 'incentiveDeduction', label: 'Incentive Fee Deduction' },
        { id: 'cardCreationFeeDeduction', label: 'Card Creation Fee Deduction' },
        { id: 'orderIds', label: 'Order Ids' },
        { id: 'paidDate', label: 'Paid Date' },
        { id: 'paymentMethod', label: 'Payment Method' },
        { id: 'status', label: 'Status' }
    ];

    const paidPayoutTableRows: Array<TableResultRow> = state.paidPayouts
        .sort((a, b) => new Date(b.createdAt || 0).getTime() - new Date(a.createdAt || 0).getTime())
        .map((item) => {
            return {
                id: {
                    text: item.id?.toString() || ''
                },
                payoutId: {
                    text: `#${item.id!}`,
                    align: 'left'
                },
                businessUserId: {
                    text: `${item.b2bUserId}`,
                    align: 'left'
                },
                amount: {
                    text: formatDinero(generateDinero(item.paidAmount * 100 || 0)),
                    align: 'left'
                },
                totalPayout: {
                    text: formatDinero(generateDinero(item.totalPayoutAmount * 100 || 0)),
                    align: 'left'
                },
                incentiveDeduction: {
                    text: formatDinero(generateDinero(item.incentiveDeduction * 100 || 0)),
                    align: 'left'
                },
                cardCreationFeeDeduction: {
                    text: formatDinero(generateDinero(item.cardFeeDeduction * 100 || 0)),
                    align: 'left'
                },
                orderIds: {
                    text: [...(item.orderIds || []), ...(item.b2bOrderIds || [])].toString(),
                    align: 'left'
                },
                paidDate: {
                    text:
                        (item?.createdAt && formatTimeStamp(item?.createdAt, DateFormatTypeEnum.MMMM_D_YYYY_h_mm_a)) ||
                        '-',
                    align: 'left'
                },
                paymentMethod: {
                    text: mapPayoutPaymentMethod(item.paymentMethod),
                    align: 'left'
                },
                status: {
                    text: `${item.payoutStatus}`,
                    align: 'left'
                }
            };
        });

    const orderPayoutHeadCells: Array<HeadCell> = [
        { id: 'orderId', label: 'Order ID' },
        { id: 'orderDate', label: 'Order Date' },
        { id: 'giftCardType', label: 'Gift Card Type' },
        { id: 'businessUserId', label: 'Merchant User Id' },
        { id: 'orderTotal', label: 'Order Total' },
        { id: 'amount', label: 'Payout Amount' },
        { id: 'payoutId', label: 'Payout ID' },
        { id: 'paidDate', label: 'Paid Date' },
        { id: 'paymentMethod', label: 'Payment Method' },
        { id: 'status', label: 'Status' },
        { id: 'options', label: '', align: 'right' }
    ];

    const orderPayoutTableRows: Array<TableResultRow> = state.orderPayouts.map((item) => {
        return {
            id: {
                text: item.orderId.toString(),
                align: 'left'
            },
            orderId: {
                text: `${item.orderId}`,
                align: 'left'
            },
            orderDate: {
                text:
                    (item?.createdAt && formatTimeStamp(item?.createdAt, DateFormatTypeEnum.MMMM_D_YYYY_h_mm_a)) || '-',
                align: 'left'
            },
            giftCardType: {
                text: `${item.giftCardType}`,
                align: 'left'
            },
            businessUserId: {
                text: `#${item.businessUserId}`,
                align: 'left'
            },
            orderTotal: {
                text: formatDinero(generateDinero(item?.total || item?.baseTotal || 0)),
                align: 'left'
            },
            amount: {
                text: formatDinero(getDinero(item?.payoutAmount || 0)),
                align: 'left'
            },
            payoutId: {
                text: `#${item.payout.id}`,
                align: 'left'
            },
            paidDate: {
                text:
                    (item?.payout?.createdAt &&
                        formatTimeStamp(item?.payout?.createdAt, DateFormatTypeEnum.MMMM_D_YYYY_h_mm_a)) ||
                    '-',
                align: 'left'
            },
            paymentMethod: {
                text: mapPayoutPaymentMethod(item.payout.paymentMethod),
                align: 'left'
            },
            status: {
                text: `${item.payout.payoutStatus}`,
                align: 'left'
            },
            actions: {
                align: 'right',
                text: '',
                element:
                    (canRead && (
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setState((prevState) => ({
                                    ...prevState,
                                    orderMenuAnchorEle: e.currentTarget,
                                    activeOrder: item
                                }));
                            }}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    )) ||
                    undefined
            }
        };
    });

    const headerComponent = (
        <Grid item={true} xs={12} className={classes.headerComponent}>
            <Typography className={classes.tableHeading}>Pending Payouts by Merchant</Typography>
            <Button
                onClick={(e) => getAllUnpaidPayouts(true)}
                size="small"
                variant="contained"
                disabled={state.isRefreshing}
            >
                <CachedIcon className={(state.isRefreshing && 'rotation-loading') || undefined} />
                &nbsp;Refresh
            </Button>
        </Grid>
    );

    const handleRowClick = (userId: string) => {
        if (!canRead) {
            return;
        }

        setState((prevState) => ({
            ...prevState,
            renderEditForm: true,
            activeUser: prevState.users.find((u) => u.userId === +userId) || null
        }));
        scrollTop();
    };

    const payoutHeaderComponent = (
        <Grid item={true} xs={12} className={classes.headerComponent}>
            <Typography className={classes.tableHeading}>Payouts by Merchant</Typography>
            <Button
                onClick={(e) => getAllPaidPayouts(true)}
                size="small"
                variant="contained"
                disabled={state.isRefreshingPayouts}
            >
                <CachedIcon className={(state.isRefreshingPayouts && 'rotation-loading') || undefined} />
                &nbsp;Refresh
            </Button>
        </Grid>
    );

    const paidOrderHeaderComponent = (
        <Grid item={true} xs={12} className={classes.headerComponent}>
            <Typography className={classes.tableHeading}>Payouts by Order</Typography>
            <Button
                onClick={(e) => getAllOrderPayouts(true)}
                size="small"
                variant="contained"
                disabled={state.isRefreshingOrder}
            >
                <CachedIcon className={(state.isRefreshingOrder && 'rotation-loading') || undefined} />
                &nbsp;Refresh
            </Button>
        </Grid>
    );

    const fetchPayoutOrderItems = async (activeOrderId: number) => {
        const activeOrderItems = await manageBusinessUserAction()?.getAllPaidPayoutOrderItems(activeOrderId);
        setActiveOrderItems(activeOrderItems || []);
    };

    const handlePaidRowClick = async (orderId: string) => {
        if (!canRead) {
            return;
        }

        await fetchPayoutOrderItems(+orderId);
        setState((prevState) => ({
            ...prevState,
            renderPaidOrderForm: true,
            activeOrder: prevState.orderPayouts.find((p) => p.orderId === +orderId) || null
        }));
    };

    const handleMenuClose = () => {
        setState((prevState) => ({ ...prevState, menuAnchorEle: null, activeUser: null }));
    };

    const handleOrderMenuClose = () => {
        setState((prevState) => ({ ...prevState, orderMenuAnchorEle: null, activeOrder: null }));
    };

    const onPaymentComplete = () => {
        getAllUnpaidPayouts(true);
        getAllPaidPayouts(true);
        getAllOrderPayouts(true);
        setState((prevState) => ({ ...prevState, renderEditForm: false, activeUser: null }));
    };

    return (
        <>
            {/* Pending Merchant Giftcard order Payouts */}
            <Grid item={true} xs={12} className={adminClasses.root}>
                <Container className={adminClasses.container}>
                    {(state.activeUser && state.renderEditForm && (
                        <PendingOrderPayoutDetails
                            user={state.activeUser}
                            onPaymentComplete={onPaymentComplete}
                            onCancel={() =>
                                setState((prevState) => ({ ...prevState, renderEditForm: false, activeUser: null }))
                            }
                        />
                    )) ||
                        null}

                    <Menu anchorEl={state.menuAnchorEle} open={!!state.menuAnchorEle} onClose={handleMenuClose}>
                        <MenuItem
                            disabled={!canRead}
                            onClick={() => {
                                if (state.activeUser) {
                                    handleRowClick((state.activeUser?.userId || -1).toString());
                                }
                                setState((prevState) => ({ ...prevState, menuAnchorEle: null }));
                            }}
                        >
                            View
                        </MenuItem>
                    </Menu>

                    <TableComponent
                        rowHover={canRead}
                        headerComponent={headerComponent}
                        showCheckbox={false}
                        showPaginator={{ bottom: true }}
                        showSearch={true}
                        isLoading={state.isLoading}
                        rows={tableRows}
                        page={state.page}
                        headCells={headCells}
                        fillEmptyRows={false}
                        keyField="id"
                        rowTooltip={(canRead && 'Click to view') || undefined}
                        onRowClick={handleRowClick}
                        onPageChange={handleChangePage}
                        overrideClasses={{
                            toolbarClass: classes.toolbar
                        }}
                    />
                </Container>
            </Grid>

            {/*  All Successful Payout transactions */}

            <Grid item={true} xs={12} className={adminClasses.root}>
                <Container className={adminClasses.container}>
                    <TableComponent
                        rowHover={canRead}
                        headerComponent={payoutHeaderComponent}
                        showCheckbox={false}
                        showPaginator={{ bottom: true }}
                        showSearch={true}
                        isLoading={state.isLoading}
                        rows={paidPayoutTableRows}
                        page={state.page}
                        headCells={paidPayoutHeadCells}
                        fillEmptyRows={false}
                        keyField="id"
                        onPageChange={handleChangePage}
                        overrideClasses={{
                            toolbarClass: classes.toolbar
                        }}
                    />
                </Container>
            </Grid>

            {/*  All Order Payout transactions */}

            <Grid item={true} xs={12} className={adminClasses.root}>
                <Container className={adminClasses.container}>
                    {(state.activeOrder && state.renderPaidOrderForm && (
                        <Box sx={{ marginBottom: 16 }}>
                            <OrderDetails
                                order={state.activeOrder}
                                orderItems={activeOrderItems}
                                payoutType={PayoutTypeEnum.PAID}
                                payout={state.activeOrder.payout}
                                onCancel={() =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        renderPaidOrderForm: false,
                                        activeOrder: null
                                    }))
                                }
                            />
                        </Box>
                    )) ||
                        null}

                    <Menu
                        anchorEl={state.orderMenuAnchorEle}
                        open={!!state.orderMenuAnchorEle}
                        onClose={handleOrderMenuClose}
                    >
                        <MenuItem
                            disabled={!canRead}
                            onClick={async () => {
                                if (state.activeOrder) {
                                    handlePaidRowClick((state.activeOrder?.orderId || -1).toString());
                                }
                                setState((prevState) => ({ ...prevState, orderMenuAnchorEle: null }));
                            }}
                        >
                            View
                        </MenuItem>
                    </Menu>

                    <TableComponent
                        rowHover={canRead}
                        headerComponent={paidOrderHeaderComponent}
                        showCheckbox={false}
                        showPaginator={{ bottom: true }}
                        showSearch={true}
                        isLoading={state.isLoading}
                        rows={orderPayoutTableRows}
                        page={state.page}
                        headCells={orderPayoutHeadCells}
                        fillEmptyRows={false}
                        keyField="id"
                        rowTooltip={(canRead && 'Click to view') || undefined}
                        onRowClick={handlePaidRowClick}
                        onPageChange={handleChangePage}
                        overrideClasses={{
                            toolbarClass: classes.toolbar
                        }}
                    />
                </Container>
            </Grid>
        </>
    );
};

export default ManageBusinessOrderPayout;
