import { StoreActionProps } from '.';
import { ErrorType, GetGiftCardCategoryAdmin, GiftCardCategory } from '../types';
import { GifterCardTypeEnum } from '../types/savedProducts';

const getAllCategories = async (
    props: StoreActionProps,
    giftCardType: typeof GifterCardTypeEnum[number],
    countryCode: string
) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(
            `/api/admin/ml-categories/get-all?giftCardType=${giftCardType}&countryCode=${countryCode}`
        );
        const response = apiResponse.parsedBody;
        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        return response.data as GetGiftCardCategoryAdmin[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

const getProductsByCategoryKeys = async (
    props: StoreActionProps,
    options: { keys?: string[]; giftCardType: typeof GifterCardTypeEnum[number]; countryCode: string }
) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.post(`/api/admin/ml-categories/get-products`, options);
        const response = apiResponse.parsedBody;
        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        return response.data as GetGiftCardCategoryAdmin[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

const saveCategory = async (props: StoreActionProps, data: GiftCardCategory) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.post(
            `/api/admin/ml-categories/${(data?.id && 'update') || 'create'}`,
            data
        );
        const response = apiResponse.parsedBody;
        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });

        return response.data as GetGiftCardCategoryAdmin;
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

const updateAllCategories = async (props: StoreActionProps, data: GiftCardCategory[]) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.put(`/api/admin/ml-categories/update-all`, data);
        const response = apiResponse.parsedBody;
        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });

        return response.data as GetGiftCardCategoryAdmin[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

const mlCategoryAction = (props?: StoreActionProps) => {
    if (!props) {
        return null;
    }

    return {
        getAllCategories: (giftCardType: typeof GifterCardTypeEnum[number], countryCode: string) =>
            getAllCategories(props, giftCardType, countryCode),
        getProductsByCategoryKeys: (options: {
            keys?: string[];
            giftCardType: typeof GifterCardTypeEnum[number];
            countryCode: string;
        }) => getProductsByCategoryKeys(props, options),
        saveCategory: (data: GiftCardCategory) => saveCategory(props, data),
        updateAllCategories: (data: GiftCardCategory[]) => updateAllCategories(props, data)
    };
};

export default mlCategoryAction;

export interface MlCategoryAction {
    mlCategoryAction: typeof mlCategoryAction;
}
