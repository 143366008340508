import { StoreActionProps } from '.';
import {
    ErrorType,
    GetPendingRealizedReferrals,
    InfluencerDetails,
    OrderSummary,
    UserAccount,
    UserInfluencerAccountAttributes
} from '../types';
import { PayoutTransactionAttributes } from '../types/influencerPayout';

const getAllInfluencers = async (props: StoreActionProps): Promise<InfluencerDetails[]> => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get('/api/admin/manage-influencer/get-all');
        const response = apiResponse.parsedBody;
        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return [];
        }
        return response.data as InfluencerDetails[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return [];
    }
};

const getInfluencerByUserId = async (props: StoreActionProps, userId: number): Promise<InfluencerDetails | null> => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/manage-influencer/get/${userId}`);
        const response = apiResponse.parsedBody;
        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }
        return response.data as InfluencerDetails;
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

const getReferralList = async (
    props: StoreActionProps,
    influencerUserId: number | null | undefined,
    realized: boolean | null
): Promise<(UserAccount & { orders: OrderSummary[] })[] | []> => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(
            `/api/admin/manage-influencer/referrals/${influencerUserId}?realized=${realized}`
        );
        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return [];
        }
        return response.data;
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return [];
    }
};

const saveInfluencerAccessStatus = async (
    props: StoreActionProps,
    influencerUserId: number | null | undefined,
    approvalStatus: UserInfluencerAccountAttributes['approvalStatus'],
    rejectionReason: UserInfluencerAccountAttributes['rejectionReason']
): Promise<UserInfluencerAccountAttributes | null> => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.post(
            `/api/admin/manage-influencer/save-approval-status/${influencerUserId}`,
            {
                approvalStatus,
                rejectionReason
            }
        );
        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }
        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });
        return response.data;
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

const getPendingRealizedReferrals = async (
    props: StoreActionProps,
    influencerUserId: number,
    paymentReason: PayoutTransactionAttributes['paymentReason']
) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(
            `/api/admin/manage-influencer/pending-realized-referrals/${influencerUserId}?paymentReason=${paymentReason}`
        );
        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return [];
        }
        return response.data as GetPendingRealizedReferrals[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return [];
    }
};

const manageInfluencerAction = (props?: StoreActionProps) => {
    if (!props) {
        return null;
    }

    return {
        getAllInfluencers: () => getAllInfluencers(props),
        getInfluencerByUserId: (influencerUserId: number) => getInfluencerByUserId(props, influencerUserId),
        getReferralList: (influencerUserId: number, realized: boolean | null) =>
            getReferralList(props, influencerUserId, realized),
        getPendingRealizedReferrals: (
            influencerUserId: number,
            paymentReason: PayoutTransactionAttributes['paymentReason']
        ) => getPendingRealizedReferrals(props, influencerUserId, paymentReason),
        saveInfluencerAccessStatus: (
            influencerUserId: number | null | undefined,
            approvalStatus: UserInfluencerAccountAttributes['approvalStatus'],
            rejectionReason: UserInfluencerAccountAttributes['rejectionReason'] | null
        ) => saveInfluencerAccessStatus(props, influencerUserId, approvalStatus, rejectionReason)
    };
};

export default manageInfluencerAction;

export interface ManageInfluencerAction {
    manageInfluencerAction: typeof manageInfluencerAction;
}
