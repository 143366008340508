import { makeStyles } from "@material-ui/core";

export const influencerReferralListUseStyles = makeStyles((theme) => ({
    searchBar: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        marginTop: 12,
        marginBottom: 24,
        '& button': {
            width: 120,
            minWidth: 120
        },
        '& $searchInput': {
            marginRight: 32,
            flex: 1,
            minWidth: 280,
            borderRadius: 2,
            background: '#fff',
            '& input': {
                padding: 10
            },
            '& svg': {
                color: 'rgba(0, 0, 0, 0.24)'
            }
        }
    },
    cardRow: {
        marginTop: 20,
        marginBottom: 4,
        padding: 16,
        '&:first-child': {
            marginTop: 0,
            marginBottom: 6
        },
        '& $cardRowGrid': {
            '& > div': {
                textAlign: 'left',
                paddingBottom: 16,
                '&$cardRowGridPullRight': {
                    textAlign: 'right'
                },
                '&$cardRowGridPullCenter': {
                    textAlign: 'center'
                },
                [theme.breakpoints.up('sm')]: {
                    '&$cardRowGridPullRight': {
                        textAlign: 'left'
                    },
                    '&$cardRowGridPullCenter': {
                        textAlign: 'left'
                    }
                }
            }
        },
        '& $actionButtonContainer': {
            textAlign: 'center',
            marginTop: 'unset',
            [theme.breakpoints.up('sm')]: {
                textAlign: 'right'
            },
            [theme.breakpoints.between('sm', 'lg')]: {
                marginTop: 6
            },
            '& button': {
                width: 175,
                color: 'rgba(0, 0, 0, 0.54)',
                [theme.breakpoints.up('lg')]: {
                    width: '100%'
                }
            }
        },
        '& h6': {
            fontSize: 10,
            fontWeight: 'normal',
            letterSpacing: '1.5px',
            lineHeight: '12px',
            marginBottom: 4
        },
        '& p': {
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: '0.1px'
        }
    },
    alignSelfCenter: {
        alignSelf: 'center'
    },
    accordionCollapsed: {
        '& td': {
            padding: `24px 16px`
        }
    },
    paginator: {
        marginLeft: 'auto !important',
        display: 'block !important',
        paddingTop: '24px !important',
        paddingBottom: '24px !important',
        border: 'none !important',
        overflow: 'unset !important',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '14px !important',
            paddingBottom: '14px !important'
        },
        '& .MuiTablePagination-select': {
            marginRight: 16
        },
        '& .MuiTablePagination-actions': {
            marginLeft: 0
        },
        '& > div': {
            flexWrap: 'wrap',
            paddingLeft: 0
        }
    },
    picker: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        marginRight: 32,
        '& label': {
            transform: 'none !important',
            position: 'unset !important'
        },
        '& $pickerSelect': {
            backgroundColor: 'transparent !important',
            marginTop: 0,
            marginLeft: 8,
            fontWeight: 900,
            color: theme.palette.primary.main,
            '& > div:focus': {
                backgroundColor: 'transparent !important'
            },
            '&:after, &:before': {
                content: `''`,
                position: 'unset !important',
                borderBottom: 'none !important'
            }
        }
    },
    cancel: {
        width: 280
    },
    pickerSelect: {},
    cardRowGrid: {},
    cardRowGridPullRight: {},
    cardRowGridPullCenter: {},
    actionButtonContainer: {},
    divider: {},
    searchInput: {}
}));
