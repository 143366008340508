import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import useAdminStyles from '../styles';
import TableComponent, { HeadCell, TableResultRow } from '../common/table-component';
import ConfirmationDialog from '../common/confirmation-dialog';
import { FetchPayoutTransactionResponse, PayoutTransactionAttributes } from '../../types/influencerPayout';
import { DateFormatTypeEnum, formatDinero, formatTimeStamp, getDinero } from '../../utilities';
import { TabPanelProps } from '@material-ui/lab/TabPanel';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { influencerReferralListUseStyles } from './referral-list.styles';
import moment from 'moment';
import StatusButton from '../common/status-button';
import { nanoid } from 'nanoid';
import { ErrorType, InfluencerDetails } from '../../types';
import { CreatePaypalPayoutRequestType } from '../../types/paypal';
import { useApiService } from '../../contexts/api-service-context';
import { useToasterData } from '../../contexts/toaster-context';

function TabPanel(props: Omit<TabPanelProps, 'value'> & { index: number; value: number }) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index}>
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    toolbar: {
        textAlign: 'left'
    },
    total: {
        fontSize: 18,
        fontWeight: 400,
        textAlign: 'left',
        '& span': {
            fontWeight: 600,
            fontSize: 20
        }
    },
    grandTotal: {
        backgroundColor: theme.palette.secondary.dark,
        color: 'white',
        paddingLeft: 24,
        fontSize: 24,
        fontWeight: 600,
        textAlign: 'left',
        '& span': {
            fontWeight: 600,
            fontSize: 28
        }
    },
    tabpanel: {
        '& > div': {
            padding: '24px 0px 24px 0'
        }
    }
}));

const filterTransactionList = (transactions: FetchPayoutTransactionResponse, state: State) => {
    const { dateRange } = state;
    let newTransactions: FetchPayoutTransactionResponse = transactions.slice();
    let totalRecords = transactions.length;

    // eslint-disable-next-line prefer-const
    let [startDate, endDate] = dateRange;
    endDate =
        (endDate && moment(endDate).add(1, 'day').toDate()) ||
        (startDate && moment(startDate).endOf('day').toDate()) ||
        null;

    // filter the users for matching date range
    newTransactions =
        (startDate && newTransactions.filter((o) => moment(o.paidOn).isAfter(startDate))) || newTransactions;
    newTransactions = (endDate && newTransactions.filter((o) => moment(o.paidOn).isBefore(endDate))) || newTransactions;

    // if one of these filter props change, we adjust the total records
    if (startDate || endDate) {
        totalRecords = newTransactions.length;
    }

    return { transactions: newTransactions, totalRecords };
};

type TransactionProps = {
    isLoading: boolean;
    influencer: InfluencerDetails;
    transactions: FetchPayoutTransactionResponse;
    transactionReason: PayoutTransactionAttributes['paymentReason'];
    heading: string;
    footerText: string;
    onRetryPayment: () => void;
};

type State = {
    selectedDateRange: number;
    dateRange: Array<Date | null>;
    isConfirmationDialogOpen: boolean;
    isPaymentLoading: boolean;
    activePaymentTransactionId: number | null;
};

const Transaction = (props: TransactionProps) => {
    const classes = useStyles();
    const adminClasses = useAdminStyles();
    const referralClasses = influencerReferralListUseStyles();
    const apiService = useApiService();
    const toasterContext = useToasterData();

    const [state, setState] = React.useState<State>({
        selectedDateRange: 0,
        dateRange: [null, null],
        isConfirmationDialogOpen: false,
        isPaymentLoading: false,
        activePaymentTransactionId: null
    });

    const headCells: Array<HeadCell> = [
        { id: 'amount', label: '$ Amount Paid' },
        { id: 'paymentFee', label: 'Payment Fee' },
        { id: 'paidOn', label: 'Paid On' },
        { id: 'paymentMethodType', label: 'Payment Method' },
        {
            id: 'referralUserId',
            label: 'Referral User ID(s)'
        },
        {
            id: 'paymentStatus',
            label: 'Payment Status'
        },
        { id: 'paypalAccount', label: 'PayPal Account' },
    ];

    const dateRanges = [
        { key: 0, label: 'All' },
        { key: 1, label: 'Last month' },
        { key: 3, label: 'Last 3 months' },
        { key: 6, label: 'Last 6 months' },
        { key: 12, label: 'Last year' }
    ];

    const { transactions } = filterTransactionList(props.transactions, state);
    const amount = transactions.find((t) => t.id === state.activePaymentTransactionId)?.amount || 0;

    const rows: Array<TableResultRow> = transactions.map((p) => {
        const referralUserIds = p.payoutTransactionReferrals
            .sort((a, b) => ((a?.referralUserId || 0) > (b?.referralUserId || 0) ? 1 : -1))
            .map((r) => `#${r.referralUserId}`)
            .join(', ');

        const row: TableResultRow = {
            id: {
                align: 'left',
                text: p.id?.toString() || ''
            },
            amount: {
                align: 'left',
                text: formatDinero(getDinero(p.amount))
            },
            paymentFee: {
                align: 'left',
                text: formatDinero(getDinero(p.paymentFee || 0))
            },
            paidOn: {
                align: 'left',
                text: formatTimeStamp(p.paidOn, DateFormatTypeEnum.MMMM_D_YYYY_h_mm_a) || ''
            },
            paymentMethodType: {
                align: 'left',
                text: p.paymentMethodType || ''
            },
            referralUserId: {
                align: 'left',
                text: referralUserIds
            },
            paymentStatus: {
                align: 'left',
                text: p.paymentStatus || 'PENDING',
                element: (
                    <Box display="flex" gridColumnGap={12}>
                        <StatusButton
                            options={[
                                {
                                    color: 'active',
                                    text: 'SUCCESS',
                                    type: 'SUCCESS'
                                },
                                {
                                    color: 'danger',
                                    text: 'FAIL',
                                    type: 'FAIL'
                                },
                                {
                                    color: 'warning',
                                    text: 'PENDING',
                                    type: 'PENDING'
                                }
                            ]}
                            type={p.paymentStatus}
                        />
                        {p.paymentStatus === 'FAIL' && (
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    if (p.id) {
                                        setState((prevState) => ({
                                            ...prevState,
                                            isConfirmationDialogOpen: true,
                                            activePaymentTransactionId: p.id!
                                        }));
                                    }
                                }}
                            >
                                Retry Payment
                            </Button>
                        )}
                    </Box>
                )
            },
            paypalAccount: {
                align: 'left',
                text: p.paypalAccount || ''
            }
        };

        return row;
    });
    const totalAmount = transactions.reduce((acc, cur) => acc + cur.amount, 0);

    const headerComponent = (
        <Grid item={true} xs={12} className={adminClasses.headerComponent}>
            <Box display="flex" justifyContent="space-between" width="100%">
                <Typography className={adminClasses.tableHeading}>{props.heading}</Typography>
                <FormControl variant="filled" className={referralClasses.picker}>
                    <InputLabel id="referral-list-date-filter">
                        <Typography color="textSecondary" component="span">
                            Date:
                        </Typography>
                    </InputLabel>
                    <Select
                        className={referralClasses.pickerSelect}
                        variant="standard"
                        labelId="referral-list-date-filter"
                        value={state.selectedDateRange}
                        onChange={(e) => {
                            const selectedDateRange = Number(e.target.value);
                            const currentMonth = new Date().getMonth();
                            const dateRange = [
                                new Date(new Date().setMonth(currentMonth - selectedDateRange)),
                                new Date()
                            ];
                            setState((prevState: State) => ({
                                ...prevState,
                                selectedDateRange,
                                dateRange: selectedDateRange ? dateRange : [null, null]
                            }));
                        }}
                    >
                        {dateRanges.map((d) => (
                            <MenuItem key={d.key} value={d.key}>
                                {d.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </Grid>
    );

    const retryPaypalPaymentRequest = async () => {
        try {
            setState((prevState) => ({ ...prevState, isPaymentLoading: true }));
            const apiResponse = await apiService.post(`/api/admin/manage-payout/retry-paypal-payment`, {
                paypalTransaction: {
                    senderBatchHeader: {
                        sender_batch_id: nanoid(),
                        email_message: 'You have received a payout! Thanks for using our service!',
                        email_subject: 'You have a payout!'
                    },
                    items: [
                        {
                            amount: {
                                value: amount.toFixed(2),
                                currency: 'USD'
                            },
                            sender_item_id: nanoid(),
                            receiver: props.influencer.paypalAccount!
                        }
                    ]
                },
                payoutTransactionId: state.activePaymentTransactionId
            });
            const response = apiResponse.parsedBody;
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'success'
            });
            setState((prevState) => ({
                ...prevState,
                isPaymentLoading: false,
                isConfirmationDialogOpen: false,
                activePaymentTransactionId: null
            }));
            props.onRetryPayment();
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
            setState((prevState) => ({
                ...prevState,
                isPaymentLoading: false,
                isConfirmationDialogOpen: false,
                activePaymentTransactionId: null
            }));
            return null;
        }
    };

    return (
        <Box>
            <TableComponent
                headerComponent={headerComponent}
                rowHover={false}
                showPaginator={{ bottom: true }}
                showSearch={true}
                isLoading={props.isLoading}
                rows={rows}
                headCells={headCells}
                fillEmptyRows={false}
                keyField="id"
                showCheckbox={false}
                overrideClasses={{
                    toolbarClass: classes.toolbar
                }}
            />

            <Typography className={classes.total}>
                {props.footerText}: <span>{formatDinero(getDinero(totalAmount))}</span>
            </Typography>

            <ConfirmationDialog
                header={'Confirm payment'}
                subHeader={
                    <>
                        You will pay <strong>{formatDinero(getDinero(amount || 0))}</strong> to{' '}
                        <strong>
                            {props.influencer.email} (User Id: {props.influencer.userId})
                        </strong>
                        .<br />
                        <br />
                        Click <strong>CONFIRM</strong> to complete the payment.
                    </>
                }
                isLoading={state.isPaymentLoading}
                open={state.isConfirmationDialogOpen}
                onClose={() => setState((prevState) => ({ ...prevState, isConfirmationDialogOpen: false }))}
                onConfirm={() => retryPaypalPaymentRequest()}
            />
        </Box>
    );
};

type Props = {
    influencer: InfluencerDetails;
    earnings: FetchPayoutTransactionResponse;
    isLoading: boolean;
    onRetryPayment: () => void;
};

const InfluencerEarnings = (props: Props) => {
    const adminClasses = useAdminStyles();
    const classes = useStyles();

    const [value, setValue] = React.useState(0);

    const transactionPayouts = props.earnings.filter((p) => p.paymentReason === 'SUCCESSFUL_TRANSACTION');
    const registrationPayouts = props.earnings.filter((p) => p.paymentReason === 'SUCCESSFUL_REGISTRATION');

    const grandTotal = props.earnings.reduce((acc, cur) => acc + cur.amount, 0);

    const handleTabChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Grid item={true} xs={12} className={adminClasses.root}>
            <Box justifyContent="center">
                <Tabs value={value} onChange={handleTabChange}>
                    <Tab label="Payments for transactions" />
                    <Tab label="Payments for registrations" />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0} className={classes.tabpanel}>
                <Transaction
                    isLoading={props.isLoading}
                    influencer={props.influencer}
                    onRetryPayment={props.onRetryPayment}
                    heading="Transaction payments"
                    transactions={transactionPayouts}
                    footerText="Total paid for Transactions"
                    transactionReason="SUCCESSFUL_TRANSACTION"
                />
            </TabPanel>

            <TabPanel value={value} index={1} className={classes.tabpanel}>
                <Transaction
                    isLoading={props.isLoading}
                    influencer={props.influencer}
                    onRetryPayment={props.onRetryPayment}
                    heading="Registration payments"
                    transactions={registrationPayouts}
                    footerText="Total paid for Registrations"
                    transactionReason="SUCCESSFUL_REGISTRATION"
                />
            </TabPanel>

            <Typography className={classes.grandTotal}>
                Grand Total: <span>{formatDinero(getDinero(grandTotal))}</span>
            </Typography>
        </Grid>
    );
};

export default InfluencerEarnings;
