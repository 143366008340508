import React, { Suspense, useEffect } from 'react';

import { Switch, withRouter, useHistory } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import Header from './header';

import storeContext from '../contexts/store-context';

import AppLoader from './common/app-loader';

import { LIGHT_GREY } from '../constants/colors';
import ROUTES from '../app-routes';
import { useToasterData } from '../contexts/toaster-context';
import PrivateRoute from './private-route';

export const useAppStyles = makeStyles((theme) => ({
    appRoot: {
        backgroundColor: LIGHT_GREY,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        width: '100%'
    },
    appMain: {
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        marginTop: 64
    }
}));

const ScrollToTopWithRouter: React.FC<unknown> = (props: React.PropsWithChildren<unknown>) => {
    const history = useHistory();
    useEffect(() => {
        const unlisten = history.listen(() => window.scrollTo(0, 0));
        return () => unlisten();
    }, []);

    return <React.Fragment>{props.children}</React.Fragment>;
};

const ScrollToTop = withRouter(ScrollToTopWithRouter);

const MainWrapper: React.FC<unknown> = (props: React.PropsWithChildren<unknown>): JSX.Element => {
    const classes = useAppStyles();

    return (
        <Box textAlign="center" className={classes.appRoot}>
            <Header />
            <Box className={classes.appMain}>
                <ScrollToTop>{props.children}</ScrollToTop>
            </Box>
        </Box>
    );
};

const App: React.FC = (props): JSX.Element => {
    const toasterContext = useToasterData();
    const { userSessionState } = storeContext();
    const { isLoading } = userSessionState;
    const { Toasters } = toasterContext;

    if (isLoading) {
        return <AppLoader />;
    }

    return (
        <React.Fragment>
            <Toasters />
            <Suspense fallback={<AppLoader />}>
                <Switch>
                    {renderRoutes(
                        ROUTES.map((r) => {
                            const Component = r.component;
                            return {
                                ...r,
                                render: (props) => (
                                    <MainWrapper>
                                        {(r.privateRoute && <PrivateRoute {...r} />) || <Component {...props} />}
                                    </MainWrapper>
                                )
                            };
                        })
                    )}
                </Switch>
            </Suspense>
        </React.Fragment>
    );
};

export default App;
