import { StoreActionProps } from '.';
import { B2BSubscriptionPlan, ErrorType, GetB2BSubscriptionPlanAdmin } from '../types';

const getAllSubscriptionPlans = async (props: StoreActionProps) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/manage-b2b-subscription-plan/get-all`);
        const response = apiResponse.parsedBody;
        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return [];
        }

        return response.data as GetB2BSubscriptionPlanAdmin[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return [];
    }
};

const saveSubscription = async (props: StoreActionProps, data: B2BSubscriptionPlan): Promise<boolean> => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.post(`/api/admin/manage-b2b-subscription-plan/${(data?.id && 'update') || 'create'}`, data);
        const response = apiResponse.parsedBody;
        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return false;
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });

        return true;
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return false;
    }

};

const updateAllSubscriptions = async (props: StoreActionProps, data: B2BSubscriptionPlan[]) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.put(`/api/admin/manage-b2b-subscription-plan/update-all`, data);
        const response = apiResponse.parsedBody;
        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
    }

    return null;
};

const subscriptionAction = (props?: StoreActionProps) => {
    if (!props) {
        return null;
    }

    return {
        getAllSubscriptionPlans: () => getAllSubscriptionPlans(props),
        saveSubscription: (data: B2BSubscriptionPlan) => saveSubscription(props, data),
        updateAllSubscriptions: (data: B2BSubscriptionPlan[]) => updateAllSubscriptions(props, data),
    };
};

export default subscriptionAction;

export interface SubscriptionAction {
    subscriptionAction: typeof subscriptionAction;
}
