import React, { useState } from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import RedeemIcon from '@material-ui/icons/Redeem';

import CircularProgress from '@material-ui/core/CircularProgress';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';

import {
    DateFormatTypeEnum,
    formatDinero,
    generateDinero,
    getDinero,
    timeZones,
    formatTimeStamp,
    formatPhone,
    getMerchantCardImageUrl
} from '../../../utilities';
import {
    OrderAttributes,
    OrderItemRecipient,
    OrderItemRecipientStatusEnum,
    OrderPayloadGiftCardProduct,
    OrderSummary,
    PaymentMethod,
    PromoCode
} from '../../../types';

import moment from 'moment-timezone';
import { LIGHT_GREEN } from '../../../constants/colors';

import PayPalSvg from '../../../svg/PayPalSvg';
import ApplePaySvg from '../../../svg/ApplePaySvg';
import VenmoSvg from '../../../svg/VenmoSvg';
import VisaCardSvg from '../../../svg/VisaCardSvg';
import MasterCardSvg from '../../../svg/MasterCardSvg';
import DiscoverCardSvg from '../../../svg/DiscoverSvg';
import AmericanExpressSvg from '../../../svg/AmericanExpressSvg';
import WalletSvg from '../../../svg/WalletSvg';

import jcbSvg from '../../../assets/images/JCB_logo.svg';
import googlePaySvg from '../../../assets/images/google-pay-mark_800.svg';
import { OrderUserTypeEnum } from '.';
import { B2C_USER } from './order-history-filter';
import { CardContent, Link } from '@material-ui/core';
import { WalletAttributes } from '../../../types/wallet';
import { RefundList } from './';
import { getCurrencySymbol } from '../../../actions/b2b-gift-card.action';

export type RenderBrandLogoOptions = {
    style?: Record<string, string | number>;
};

type Props = {
    paymentDetails?: OrderSummary['payment'];
    subscription?: OrderSummary['subscription'] | null;
    redemptionDetails?: OrderSummary['redemptionDetails'] | null;
    orderStatus: OrderSummary['status'];
    orderType: OrderSummary['orderType'];
    items: Array<Omit<OrderPayloadGiftCardProduct, 'recipients'> & { 
        recipients: OrderItemRecipient[];
        basePrice?: number; 
        convertedPrice?: number;  
    }>;
    cashback: number;
    walletFundsUsed: number;
    promoCode?: Pick<PromoCode, 'promoCodeId' | 'key' | 'type' | 'value' | 'maxAmount'> | null;
    total: number;
    convertedTotal?: number;
    convertedCurrency?: string;
    title: string;
    orderId: number;
    cardmoolaFundsUsed: number;
    cardmoolaFundsAdded: number;
    orderUserType: typeof OrderUserTypeEnum[number];
    ordersFetched: boolean;
    resendEmail: (recipientId: number) => Promise<void>;
    voidMethod: (orderId: number) => Promise<OrderSummary[] | null>;
    refundMethod: (
        orderId: number,
        total: number,
        amount: number,
        orderItemRecipientId: number
    ) => Promise<WalletAttributes[] | null>;
    eGifterFee: number;
    paymentGatewayFee: number;
    resendSuccessfulGiftCardMethod: (recipientId: number) => Promise<void>;
    refund: RefundList[];
    getOrderHistory: (showLoader?: boolean) => Promise<void>;
    getRefundCashbackHistory: (orderId: number) => Promise<void>;
};

export const renderBrandLogo = (brand?: string | null, options?: RenderBrandLogoOptions | null) => {
    const style = {
        width: '100%',
        maxWidth: 32,
        height: 36,
        filter: 'drop-shadow(0 2px 2px rgba(0,0,0,0.2))',
        ...(options?.style || {})
    };

    switch (brand) {
        case 'visa':
            return <VisaCardSvg style={style} />;
        case 'mastercard':
            return <MasterCardSvg style={style} />;
        case 'discover':
            return <DiscoverCardSvg style={style} />;
        case 'americanexpress':
        case 'amex':
            return <AmericanExpressSvg style={style} />;
        case 'jcb':
            return <img src={jcbSvg} alt="JCB" style={style} />;
        case 'paypal':
            return <PayPalSvg style={style} />;
        case 'apple_pay':
            return <ApplePaySvg style={style} />;
        case 'google_pay':
            return <img src={googlePaySvg} alt="Google Pay" style={style} />;
        case 'venmo':
            return <VenmoSvg style={{ width: 'auto', maxWidth: 85, height: 36 }} />;
        default:
            return <CreditCardIcon style={style} />;
    }
};

export const getRecipientStatus = (
    orderStatus: OrderSummary['status'],
    recipientStatus: typeof OrderItemRecipientStatusEnum[number] | undefined
) => {
    return (
        ((orderStatus === 'FAIL' || orderStatus === 'CANCELED') && 'CANCELED') ||
        (orderStatus === 'ON_HOLD' && 'PENDING') ||
        recipientStatus
    );
};

type State = {
    isRefundingAmount: boolean;
    isLoading: boolean;
    isValidEmail: boolean;
    isResendingEmail: boolean;
    isResendSuccessfulEmail: boolean;
    isVoidMethod: boolean;
    deliveryField: OrderItemRecipient['deliveryType'];
    email: {
        currentString: string;
        confirmedString: string;
    };
};

type RecipientItemStatusProps = {
    paymentDetails?: OrderSummary['payment'] | null;
    recipient: OrderItemRecipient;
    itemId?: number;
    orderId: number;
    total: number;
    resendEmail: (recipientId: number) => Promise<void>;
    voidMethod: (orderId: number) => Promise<OrderSummary[] | null>;
    refundMethod: (
        orderId: number,
        total: number,
        amount: number,
        orderItemRecipientId: number
    ) => Promise<WalletAttributes[] | null>;
    eGifterFee: number;
    paymentGatewayFee: number;
    orderType: OrderSummary['orderType'];
    resendSuccessfulGiftCardMethod: (recipientId: number) => Promise<void>;
    refund: RefundList[];
    getOrderHistory: (showLoader?: boolean) => Promise<void>;
    getRefundCashbackHistory: (orderId: number) => Promise<void>;
};

export const RecipientItemStatus = (props: RecipientItemStatusProps) => {
    const {
        recipient,
        resendEmail,
        voidMethod,
        orderId,
        refundMethod,
        total,
        itemId,
        eGifterFee,
        paymentGatewayFee,
        resendSuccessfulGiftCardMethod,
        refund,
        getOrderHistory,
        getRefundCashbackHistory
    } = props;
    const classes = useStyles();
    const [state, setState] = React.useState<State>({
        isRefundingAmount: false,
        isLoading: false,
        isValidEmail: true,
        isResendingEmail: false,
        isResendSuccessfulEmail: false,
        isVoidMethod: false,
        deliveryField: 'EMAIL',
        email: {
            currentString: recipient.email,
            confirmedString: recipient.email
        }
    });

    const resendRecipientGiftCard = async (recipientId: number) => {
        setState((prevState) => ({ ...prevState, isResendingEmail: true }));
        await resendEmail(recipientId);
        setState((prevState) => ({ ...prevState, isResendingEmail: false }));
    };

    const resendSuccessfulGiftCard = async (recipientId: number) => {
        setState((prevState) => ({ ...prevState, isResendSuccessfulEmail: true }));
        await resendSuccessfulGiftCardMethod(recipientId);
        setState((prevState) => ({ ...prevState, isResendSuccessfulEmail: false }));
    };

    React.useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            email: {
                currentString: recipient.email,
                confirmedString: recipient.email
            },
            deliveryField: recipient.deliveryType
        }));
    }, [recipient]);

    const recipientEmailFailureReason = (recipient: OrderItemRecipient) => {
        // handle bounce failure
        if (recipient.emailFailureReason?.bounce) {
            if (recipient.emailFailureReason?.bounce.type === 'Permanent') {
                return {
                    description:
                        'Your email was rejected by our email server, please try again by changing your email and we will resend you the Gift card(s)',
                    renderResendBtn: false
                };
            }

            if (recipient.emailFailureReason?.bounce.type === 'Undetermined') {
                return {
                    description:
                        'Failure delivering email to recipient. Please review or modify your email address and resend.',
                    renderResendBtn: true
                };
            }

            if (recipient.emailFailureReason?.bounce.type === 'Transient') {
                switch (recipient.emailFailureReason?.bounce.subType) {
                    case 'General':
                    case 'MessageTooLarge':
                    case 'ContentRejected':
                    case 'AttachmentRejected':
                        return {
                            description:
                                'Failure delivering email to recipient. Please review or modify your email address and resend.',
                            renderResendBtn: true
                        };
                    case 'MailboxFull':
                        return {
                            description:
                                'Your mailbox is full. Delete any emails you do not need from your mailbox and hit resend.',
                            renderResendBtn: true
                        };
                }
            }
        }

        // handle complaint failure
        if (recipient.emailFailureReason?.complaint) {
            return {
                description:
                    'Your email was not accepted by our server, please try again by changing your email and we will resend you the Gift card(s)',
                renderResendBtn: false
            };
        }
    };

    const recipientFailureDescription = (() => {
        if (
            recipient.status === 'SUCCESS' ||
            recipient.status === 'PENDING' ||
            recipient.status === 'SCHEDULED' ||
            recipient.status === 'CANCELED'
        ) {
            return {
                description: '',
                renderResendBtn: false
            };
        }

        // handle bounce failure
        const emailFailure = recipientEmailFailureReason(recipient);
        if (emailFailure) {
            return emailFailure;
        }

        if (recipient.smsFailureReason) {
            return {
                description: recipient.smsFailureReason,
                renderResendBtn: true
            };
        }

        // this happens when email was not sent from email worker, so we will allow the ability to resend
        return {
            description: 'Failed to deliver the gift card. Please try again by resending or changing the email.',
            renderResendBtn: true
        };
    })();

    const tz = timeZones.find((tz) => tz.type === recipient?.deliveryScheduledAtTimezone);

    const statusIconMap = {
        SUCCESS: {
            icon: <CheckCircleIcon color="primary" className={classes.statusIcon} />,
            text: (
                <Typography color="primary" variant="overline" className={classes.text}>
                    DELIVERED TO:
                </Typography>
            )
        },
        PENDING: {
            icon: <WarningIcon className={clsx(classes.warning, classes.statusIcon)} />,
            text: (
                <Typography className={clsx(classes.warning, classes.text)} variant="overline">
                    DELIVERY PENDING:
                </Typography>
            )
        },
        SCHEDULED: {
            icon: <ScheduleIcon className={clsx(classes.warning, classes.statusIcon)} />,
            text: (
                <Typography className={clsx(classes.warning, classes.text, classes.scheduled)} variant="overline">
                    DELIVERY SCHEDULED:
                    <Typography component="span" color="textSecondary">
                        at{' '}
                        {recipient?.deliveryScheduledAtTimestamp &&
                            tz &&
                            moment
                                .utc(recipient?.deliveryScheduledAtTimestamp)
                                .tz(tz.timezone)
                                .format(DateFormatTypeEnum.MMMM_D_YYYY_h_mm_a)}{' '}
                        ({recipient?.deliveryScheduledAtTimezone})
                    </Typography>
                </Typography>
            )
        },
        FAIL: {
            icon: <ErrorIcon color="error" className={classes.statusIcon} />,
            text: (
                <Typography color="error" variant="overline" className={classes.text}>
                    DELIVERY FAILED:
                </Typography>
            )
        },
        CANCELED: {
            icon: <ErrorIcon color="error" className={classes.statusIcon} />,
            text: (
                <Typography color="error" variant="overline" className={classes.text}>
                    DELIVERY CANCELED:
                </Typography>
            )
        }
    };

    if (!recipient.status) {
        return null;
    }

    // Function to get the gift card saved message based on status
    const getGiftCardSavedMessage = (status: typeof OrderItemRecipientStatusEnum[number]) => {
        switch (status) {
            case 'SUCCESS':
                return 'Gift card saved to My Cards section';
            case 'PENDING':
                return 'Gift card delivery is pending and will be saved to My Cards section';
            case 'FAIL':
                return 'Gift card was not saved to My Cards section due to an issue';
            case 'CANCELED':
                return 'Gift card delivery was canceled and not saved to My Cards section';
            default:
                return '';
        }
    };

    const { icon, text } = statusIconMap[recipient.status];
    const phoneFormat = formatPhone(recipient.phone, recipient.country);

    return (
        <>
            <Box display="flex" alignItems="center" className={clsx(classes.justifyResponsive, classes.statusGrid)}>
                {icon}
                <Box ml="5px">{text}</Box>
            </Box>

            <Box
                display="flex"
                alignItems="center"
                mb="4px"
                flexWrap="wrap"
                className={clsx(classes.justifyResponsive, classes.emailContainer)}
            >
                {(state.deliveryField === 'EMAIL' || !state.deliveryField) && (
                    <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        className={classes.email}
                        title={recipient.email}
                    >
                        {recipient.email}
                    </Typography>
                )}

                {state.deliveryField === 'SMS' && (
                    <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        className={classes.email}
                        title={recipient.email}
                    >
                        {phoneFormat}
                    </Typography>
                )}

                {state.deliveryField === 'CODES' && (
                    <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        className={classes.myCards}
                        title={recipient.email}
                    >
                        {getGiftCardSavedMessage(recipient.status)}
                    </Typography>
                )}

                <Box className={classes.deliverBox}>
                    {/* Gift card delivery not handled for Merchant API orders */}
                    {recipient.status === 'SUCCESS' && state.deliveryField !== 'CODES' && props.orderType !== 'GIFT_CARD_PURCHASE_WITH_API' && (
                        <Typography color="textSecondary" variant="overline" className={classes.deliveryDetails}>
                            Delivered time:{' '}
                            {formatTimeStamp(recipient.deliveryAt, DateFormatTypeEnum.MMMM_D_YYYY_h_mm_a)}
                        </Typography>
                    )}

                    {recipient.deliveryType === 'EMAIL' && recipient.openedAt && recipient.openedAt.length > 0 && (
                        <Typography color="textSecondary" variant="overline" className={classes.deliveryDetails}>
                            Email opened at:{' '}
                            {formatTimeStamp(recipient.openedAt, DateFormatTypeEnum.MMMM_D_YYYY_h_mm_a)}
                        </Typography>
                    )}

                    {recipient.clickedAt && recipient.clickedAt.length > 0 && (
                        <Typography color="textSecondary" variant="overline" className={classes.deliveryDetails}>
                            Claimlink clicked at:{' '}
                            {formatTimeStamp(recipient.clickedAt, DateFormatTypeEnum.MMMM_D_YYYY_h_mm_a)}
                        </Typography>
                    )}
                </Box>

                {(recipient.status === 'FAIL' || recipient.status === 'PENDING') && props.orderType !== 'GIFT_CARD_PURCHASE_WITH_API' && (
                    <Box className={classes.ResendGiftCardBox}>
                        <Button
                            size="small"
                            disabled={state.isResendingEmail}
                            variant="contained"
                            className={classes.button}
                            onClick={() => resendRecipientGiftCard(recipient.recipientId)}
                        >
                            Resend GiftCard(s)
                            {state.isResendingEmail && <CircularProgress className="button-loader" />}
                        </Button>
                    </Box>
                )}

                {/* Resend option not available for Merchant API orders */}
                {recipient.deliveryType !== 'CODES' && recipient.status === 'SUCCESS' && props.orderType !== 'GIFT_CARD_PURCHASE_WITH_API' && (
                    <Box className={classes.boxStyle}>
                        <Typography className={classes.headingText}> Resend GiftCard</Typography>
                        <Typography className={classes.checkBalance}>
                            Description: If the card was missed in E-Mail, you can resend the card to the user by
                            pressing the button below.
                        </Typography>
                        <Button
                            size="small"
                            variant="contained"
                            className={classes.button}
                            onClick={() => resendSuccessfulGiftCard(recipient.recipientId)}
                            disabled={state.isLoading || state.isResendSuccessfulEmail}
                        >
                            Resend GiftCard
                            {state.isResendSuccessfulEmail && <CircularProgress className="button-loader" />}
                        </Button>
                    </Box>
                )}

                {(recipientFailureDescription.description && (
                    <Box my={2}>
                        <Alert severity="error">{recipientFailureDescription.description}</Alert>{' '}
                    </Box>
                )) ||
                    null}
            </Box>
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    cardRowDetail: {
        padding: 16,
        '& $downloadReceiptContainer': {
            textAlign: 'left',
            marginLeft: -6,
            [theme.breakpoints.up('sm')]: {
                marginLeft: 'auto',
                textAlign: 'right'
            }
        }
    },
    rowItem: {
        '& $emailContainer': {
            '&:last-child': {
                marginBottom: 0
            }
        },
        '& $itemRow': {
            '&:last-child': {
                borderBottom: 'none',
                paddingBottom: 0,
                marginBottom: 0
            }
        },
        '& img': {
            borderRadius: 4,
            height: 'auto',
            width: '100%',
            maxWidth: 140
        },
        '& $imageContainer': {
            textAlign: 'center',
            paddingRight: 0,
            [theme.breakpoints.up(960)]: {
                textAlign: 'left',
                paddingRight: 16
            }
        },
        '& $upperItemTitleHead': {
            fontSize: 10,
            fontWeight: 400,
            letterSpacing: '1.5px',
            marginBottom: 2,
            lineHeight: '12px'
        },
        '& $upperItemTitleBody': {
            fontSize: 20,
            fontWeight: 400,
            '&$discountText': {
                fontSize: 10,
                [theme.breakpoints.up('sm')]: {
                    fontSize: 20
                }
            },
            '&$fontWeight900': {
                fontWeight: 900
            },
            '&$cardValueText': {
                marginBottom: 16,
                [theme.breakpoints.up('md')]: {
                    marginBottom: 'auto'
                }
            }
        },
        '& $lowerItemText': {
            fontSize: 14,
            letterSpacing: '0.25px'
        }
    },
    cashbackContainer: {
        background: LIGHT_GREEN,
        height: 36,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 4,
        padding: '0 8px'
    },
    fontWeight500: {
        fontWeight: 500
    },
    itemRow: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        marginBottom: 24,
        paddingBottom: 24,
        [theme.breakpoints.up(960)]: {
            flexDirection: 'row',
            alignItems: 'flex-start'
        },
        '& $inner': {
            textAlign: 'center',
            marginTop: 16,
            [theme.breakpoints.up(960)]: {
                textAlign: 'left',
                marginTop: 0,
                paddingRight: 40
            },
            '&:last-child': {
                paddingRight: 0
            }
        }
    },
    valueContainer: {
        minWidth: 124,
        display: 'flex',
        flexDirection: 'column',
        '& $lowerItemText': {
            marginTop: 12
        }
    },
    brandName: {
        textTransform: 'capitalize'
    },
    emailStatus: {},
    statusIcon: {
        height: 14,
        width: 14
    },
    warning: {
        color: theme.palette.warning.main
    },
    text: {
        fontSize: 10,
        lineHeight: '12.5px'
    },
    button: {
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column'
    },
    resendBtn: {
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column'
    },
    boxStyle: {
        background: theme.palette.common.white,
        boxShadow: theme.shadows['1'],
        padding: 4,
        marginBottom: 8,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '90%'
    },
    buttonBox: {
        marginBottom: 8,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center'
    },
    buttonVoidBox: {
        marginBottom: 10,
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        display: 'flex',
        flexDirection: 'column',
        marginRight: 10
    },
    ResendGiftCardBox: {
        marginBottom: 8,
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        paddingRight: 8
    },
    deliverBox: {
        width: '90%'
    },
    sendBox: {
        marginTop: 8
    },
    headingText: { fontSize: '18px', fontWeight: 'bold' },
    refundText: { fontSize: '14px', fontWeight: 600 },
    email: {
        width: '100%',
        maxWidth: 150,
        whiteSpace: 'nowrap',
        display: 'block',
        [theme.breakpoints.only('sm')]: {
            maxWidth: '100%'
        }
    },
    myCards: {
        width: '100%',
        maxWidth: 240,
        flexWrap: 'wrap',
        display: 'block',
        [theme.breakpoints.only('sm')]: {
            maxWidth: '100%'
        }
    },
    editInputContainer: {
        flexWrap: 'nowrap'
    },
    editBtn: {
        visibility: 'visible'
    },
    statusGrid: {
        marginTop: -6
    },
    emailContainer: {
        marginBottom: 8
    },
    justifyResponsive: {
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-start'
        }
    },
    checkBalance: {
        fontSize: 12,
        '&$default': {
            padding: 0
        }
    },
    scheduled: {
        display: 'flex',
        alignItems: 'center',
        '& span': {
            fontStyle: 'italic',
            fontSize: 12,
            lineHeight: '1em',
            textTransform: 'none'
        },
        '& svg': {
            color: theme.palette.grey['600'],
            fontSize: 16,
            marginLeft: 4,
            cursor: 'pointer'
        }
    },
    deliveryDetails: {
        display: 'block',
        fontSize: 10,
        lineHeight: '12.5px',
        marginTop: 4,
        marginBottom: 16
    },
    cardmoolaFundContainer: {
        background: LIGHT_GREEN,
        height: 36,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 4,
        padding: '0 8px'
    },
    default: {},
    inner: {},
    fontWeight900: {},
    downloadReceiptContainer: {},
    imageContainer: {},
    upperItemTitleHead: {},
    upperItemTitleBody: {},
    lowerItemText: {},
    discountText: {},
    cardValueText: {}
}));

export const renderPaymentMethod = (paymentMethodType?: PaymentMethod['paymentMethodType']) => {
    switch (paymentMethodType) {
        case 'WALLET':
            return <WalletSvg style={{ width: 'auto', maxWidth: 85, height: 36 }} />;

        case 'CARDMOOLA_GIFT_CARD':
            return <RedeemIcon style={{ width: 'auto', maxWidth: 85, height: 36 }} />;
        case 'CARD':
            // No render action for CARD, so default case handles all non-WALLET/GIFT_CARD and non-CARD payment methods
            return null;

        default:
            return renderBrandLogo(paymentMethodType?.toLowerCase(), { style: { maxWidth: 50 } });
    }
};

const ItemsOrdered: React.FC<Props> = (props: Props) => {
    const classes = useStyles();
    const {
        items,
        cashback,
        promoCode,
        total,
        convertedTotal = 0,
        convertedCurrency = 'USD',
        title,
        orderId,
        walletFundsUsed,
        redemptionDetails,
        orderUserType,
        ordersFetched,
        cardmoolaFundsUsed,
        cardmoolaFundsAdded,
        resendEmail,
        voidMethod,
        refundMethod,
        eGifterFee,
        paymentGatewayFee,
        resendSuccessfulGiftCardMethod,
        refund,
        getOrderHistory,
        getRefundCashbackHistory
    } = props;

    const filteredRefunds = props.refund.filter((refundItem) => refundItem.orderId === props.orderId);
    const sumOfValues = filteredRefunds.reduce((total, refund) => total + (refund.value as number), 0);
    const value = sumOfValues * 100;
    const recipientsId = items && items.length > 0 && items[0].recipients ? items[0].recipients[0]?.recipientId : null;
    const recipientsStatus = items && items.length > 0 && items[0].recipients ? items[0].recipients[0]?.status : null;

    type State = {
        isLoading: boolean;
    };

    const [state, setState] = React.useState<State>({
        isLoading: false
    });
    const [amount, setAmount] = React.useState<number>(0);
    const [isRefundingAmountLoading, setIsRefundingAmountLoading] = useState(false);

    const handleRefundAmount = async () => {
        setIsRefundingAmountLoading(true);

        try {
            if (recipientsId !== null) {
                await refundMethod(orderId, total, amount, recipientsId);
            }
            await getOrderHistory(true);
            await getRefundCashbackHistory(orderId);
        } catch (error) {
            console.error('Refund Amount failed', error);
        } finally {
            setIsRefundingAmountLoading(false);
        }
    };

    const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAmount(Number(event.target.value));
    };

    const promoCodeDiscount =
        (promoCode?.type === 'AMOUNT' && promoCode?.value) ||
        (promoCode?.type === 'PERCENTILE' && (total * promoCode?.value) / 10000) ||
        0;

    const maskedCardNumber =
        props.paymentDetails?.paymentMethodType === 'CARD' ? `**** **** **** ${props.paymentDetails?.last4}` : '';

    const expirationDate =
        props.paymentDetails?.paymentMethodType === 'CARD'
            ? `${props.paymentDetails?.expiryMonth} / ${props.paymentDetails?.expiryYear?.toString()?.slice(2)}`
            : '';

    const voidPayment = async (orderId: number) => {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await voidMethod(orderId);
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };

    return (
        <Card className={classes.cardRowDetail}>
            <Grid container justifyContent="space-between">
                <Grid item xs={12} sm={6}>
                    <Typography component="h6" variant="h6" className="fontFamilyWorkSans" align="left">
                        {title}
                    </Typography>
                </Grid>
            </Grid>
            {(props.orderType === 'GIFT_CARD_PURCHASE' || (props.orderType === 'Codes' || props.orderType === 'Links')) && (
                <>
                    <Box display="flex" justifyContent="space-between" mb={1} mt={2}>
                        <Typography component="strong" variant="subtitle2">
                            Subtotal
                        </Typography>
                        <Typography component="strong" variant="subtitle2">
                            {formatDinero(generateDinero(total))}
                            {convertedTotal ? ` ~ ${formatDinero(generateDinero(convertedTotal, convertedCurrency))}` : null}
                        </Typography>
                    </Box>
                    {(promoCode && (
                        <Box display="flex" justifyContent="space-between" mb={1}>
                            <Typography variant="subtitle2" color="textSecondary">
                                Promo Code ({promoCode.key})
                            </Typography>
                            <Typography variant="subtitle2" color="textSecondary">
                                -{formatDinero(getDinero(promoCodeDiscount))}
                            </Typography>
                        </Box>
                    )) ||
                        null}
                    {(redemptionDetails && (
                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                <Typography variant="subtitle2" color="textSecondary">
                                    <RedeemIcon style={{ width: 'auto', height: 20, verticalAlign: 'middle' }} />{' '}
                                    {redemptionDetails.giftCardName} Gift Card ({redemptionDetails.giftCardNumber})
                                </Typography>
                                <Typography variant="subtitle2" color="textSecondary">
                                    -
                                    {formatDinero(
                                        generateDinero(
                                            redemptionDetails.amountUsed || 0,
                                            redemptionDetails.currencyCode || 'USD'
                                        )
                                    )}
                                </Typography>
                            </Box>
                        )) ||
                            null}
                        {(cardmoolaFundsUsed && (
                            <Box display="flex" justifyContent="space-between" mb={1}>
                                <Typography variant="subtitle2" color="textSecondary">
                                    CardMoola funds
                                </Typography>
                                <Typography variant="subtitle2" color="textSecondary">
                                    -{formatDinero(getDinero(cardmoolaFundsUsed))}
                                </Typography>
                            </Box>
                        )) ||
                            null}
                    {(walletFundsUsed && (
                        <Box display="flex" justifyContent="space-between" mb={1}>
                            <Typography variant="subtitle2" color="textSecondary">
                                Wallet
                            </Typography>
                            <Typography variant="subtitle2" color="textSecondary">
                                -{formatDinero(getDinero(walletFundsUsed))}
                            </Typography>
                        </Box>
                    )) ||
                        null}
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="h6" className="fontFamilyWorkSans">
                            Total
                        </Typography>
                        <Typography variant="h6" className="fontFamilyWorkSans">
                            {formatDinero(
                                generateDinero(total)
                                    .subtract(getDinero(promoCodeDiscount))
                                    .subtract(generateDinero(+(redemptionDetails?.baseAmountUsed || 0)))
                                    .subtract(getDinero(walletFundsUsed))
                                    .subtract(getDinero(cardmoolaFundsUsed))
                            )}
                        </Typography>
                    </Box>

                    {(cashback && (
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            mb={1}
                            mt={1}
                            className={classes.cashbackContainer}
                        >
                            <Typography component="strong" variant="subtitle2">
                                Cashback credited to wallet
                            </Typography>
                            <Typography component="strong" variant="subtitle2">
                                {formatDinero(generateDinero(cashback))}
                            </Typography>
                        </Box>
                    )) ||
                        null}

                    <Box my={2}>
                        <Divider />
                    </Box>

                    <Typography component="h6" variant="h6" className="fontFamilyWorkSans" align="left">
                        Items Ordered
                    </Typography>

                    <Box textAlign="left" my={2}>
                        <Typography className={classes.fontWeight500}>
                            {items.length} {items.length === 1 ? 'Item' : 'Items'}
                        </Typography>
                    </Box>

                    <Box my={2}>
                        <Divider />
                    </Box>
                </>
            )}
            {props.orderType === 'CARDMOOLA_FUNDS' && (
                <Box
                    display="flex"
                    justifyContent="space-between"
                    mb={3}
                    mt={1}
                    className={classes.cardmoolaFundContainer}
                >
                    <Typography component="strong" variant="subtitle2">
                        {props.orderStatus === 'COMPLETE'
                            ? 'CardMoola funds credited'
                            : 'CardMoola fund to be credited'}
                    </Typography>
                    <Typography component="strong" variant="subtitle2">
                        {formatDinero(getDinero(cardmoolaFundsAdded))}
                    </Typography>
                </Box>
            )}

            {props.orderType === 'CARDMOOLA_FUNDS' && props.paymentDetails?.status === 'PENDING' && (
                <Box className={classes.buttonVoidBox}>
                    <Button
                        size="small"
                        variant="contained"
                        className={classes.button}
                        onClick={() => voidPayment(orderId)}
                        disabled={state.isLoading}
                    >
                        Void Payment
                        {state.isLoading && <CircularProgress className="button-loader" />}
                    </Button>
                </Box>
            )}

            {props.orderType === 'SUBSCRIPTION' && props.subscription && (
                <Card>
                    <CardContent>
                        <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                            <Typography component="strong" variant="subtitle2" gutterBottom>
                                Subscription Plan: {props.subscription.name}
                            </Typography>

                            <Typography component="strong" variant="subtitle2">
                                {formatDinero(getDinero(props.subscription.subscriptionFeePrice))} every{' '}
                                {props.subscription.subscriptionFeeInterval} month(s)
                            </Typography>

                            <Typography component="strong" variant="subtitle2">
                                Gift Card Fee: {props.subscription.giftCardFee}% per Gift Card.
                            </Typography>

                            <Typography component="strong" variant="subtitle2">
                                Order Total Fee: {props.subscription.orderFee}% of the order total.
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            )}
            {props.orderType === 'SUBSCRIPTION' && !props.subscription && (
                <Card>
                    <CardContent>
                        <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                            <Typography component="strong" variant="subtitle2" gutterBottom>
                                Subscription Order Failed
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            )}

            {props.orderType === 'SUBSCRIPTION' && props.paymentDetails?.status === 'PENDING' && (
                <Box className={classes.buttonVoidBox}>
                    <Button
                        size="small"
                        variant="contained"
                        className={classes.button}
                        onClick={() => voidPayment(orderId)}
                        disabled={state.isLoading}
                    >
                        Void Payment
                        {state.isLoading && <CircularProgress className="button-loader" />}
                    </Button>
                </Box>
            )}

            <Grid container className={classes.rowItem}>
                {items.map((item, i) => {
                    const calculateProfit = (o: Props['items'][number]) => {
                        const price = item.price || item.basePrice || 0;
                        const eGifterDiscountAmount = generateDinero(price).percentage(
                            o.eGifterDiscount ? o.eGifterDiscount / 100 : 0
                        );
                        const eGifterFee = generateDinero(o.baseEGifterFee || o.baseEGifterFee || 0);
                        const cashback = generateDinero(o.cashback || 0);

                        // egifter fee + cashback amount
                        const cost = eGifterFee.add(cashback);
                        const profit = o.eGifterDiscount ? eGifterDiscountAmount.subtract(cost) : null;

                        return profit ? profit.multiply(o.quantity) : generateDinero(0);
                    };

                    const profit = calculateProfit(item);
                    const itemPhoto = item.photo;
                    return (
                        <Grid item={true} xs={12} key={i} className={classes.itemRow}>
                            <img
                                src={itemPhoto || ''}
                                alt={`gift-card-${item.name}`}
                                className={classes.imageContainer}
                            />

                            <Grid item={true} className={clsx(classes.inner, classes.valueContainer)}>
                                <Grid item={true}>
                                    <Typography className={classes.upperItemTitleHead}>CARD VALUE</Typography>
                                    <Typography className={clsx(classes.upperItemTitleBody, classes.cardValueText)}>
                                        {formatDinero(generateDinero(item.price || item.basePrice || 0))}
                                    </Typography>
                                </Grid>

                                <Grid item={true}>
                                    <Typography color="textSecondary" className={classes.lowerItemText}>
                                        Quantity: {item.quantity}
                                    </Typography>
                                </Grid>
                            </Grid>

                            {orderUserType === B2C_USER && ordersFetched && (
                                <>
                                    <Grid item={true} className={classes.inner}>
                                        <Typography className={classes.upperItemTitleHead}>PROFIT</Typography>
                                        <Typography
                                            color={profit.isNegative() ? 'error' : 'secondary'}
                                            className={clsx(classes.fontWeight900, classes.upperItemTitleBody)}
                                        >
                                            {formatDinero(profit)}
                                        </Typography>
                                    </Grid>

                                    <Grid item={true} className={classes.inner}>
                                        <Typography className={classes.upperItemTitleHead}>CASHBACK</Typography>
                                        <Typography
                                            color="primary"
                                            className={clsx(classes.fontWeight900, classes.upperItemTitleBody)}
                                        >
                                            {formatDinero(generateDinero(item.cashback || 0).multiply(item.quantity))}
                                        </Typography>
                                    </Grid>
                                </>
                            )}

                            <Grid item={true} className={classes.inner}>
                                <Typography className={classes.upperItemTitleHead}>BRAND</Typography>
                                <Typography className={clsx(classes.upperItemTitleBody, classes.brandName)}>
                                    {item.name}
                                </Typography>
                            </Grid>

                            <Grid item={true} className={clsx(classes.emailStatus, classes.inner)}>
                                {item.recipients.map((r) => (
                                    <RecipientItemStatus
                                        recipient={r}
                                        key={r.recipientId}
                                        orderId={orderId}
                                        total={total}
                                        itemId={item.itemId}
                                        resendEmail={resendEmail}
                                        voidMethod={(orderId) => voidMethod(orderId)}
                                        refundMethod={(orderId, total, amount, orderItemRecipientId) =>
                                            refundMethod(orderId, total, amount, orderItemRecipientId)
                                        }
                                        refund={refund}
                                        eGifterFee={eGifterFee}
                                        paymentGatewayFee={paymentGatewayFee}
                                        resendSuccessfulGiftCardMethod={resendSuccessfulGiftCardMethod}
                                        paymentDetails={props.paymentDetails}
                                        orderType={props.orderType}
                                        getOrderHistory={getOrderHistory}
                                        getRefundCashbackHistory={(orderId) => getRefundCashbackHistory(orderId)}
                                    />
                                ))}

                                {recipientsStatus === 'SUCCESS' && props.paymentDetails?.status === 'SUCCESS' && (
                                    <Box className={classes.boxStyle}>
                                        {sumOfValues !== 0 ? (
                                            <Typography className={classes.refundText}>
                                                This order has already been refunded with an amount of{' '}
                                                {formatDinero(generateDinero(value))} to the Wallet
                                            </Typography>
                                        ) : (
                                            <>
                                                <Typography className={classes.headingText}>Refund Amount</Typography>
                                                <Typography className={classes.checkBalance}>
                                                    EGifter Fee : {formatDinero(generateDinero(eGifterFee))}
                                                </Typography>
                                                <Typography className={classes.checkBalance}>
                                                    PaymentGateway Fee :{' '}
                                                    {formatDinero(generateDinero(paymentGatewayFee))}
                                                </Typography>
                                                <Typography className={classes.checkBalance}>
                                                    To check the balance of your gift card, visit the{' '}
                                                    <Link
                                                        href="https://www.egifter.com/gift-card-balance-check"
                                                        target="_blank"
                                                    >
                                                        gift card balance check page
                                                    </Link>
                                                    .
                                                </Typography>
                                                <TextField
                                                    id="filled-basic"
                                                    label="Amount"
                                                    variant="filled"
                                                    value={amount}
                                                    onChange={handleAmountChange}
                                                />
                                                {amount > total / 100 && (
                                                    <Typography style={{ color: 'red' }}>
                                                        Please enter an amount below or equal to the total amount.
                                                    </Typography>
                                                )}

                                                {amount > 0 && amount <= total / 100 && (
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        className={classes.button}
                                                        onClick={handleRefundAmount}
                                                        disabled={state.isLoading || isRefundingAmountLoading}
                                                    >
                                                        Refund Amount
                                                        {isRefundingAmountLoading && (
                                                            <CircularProgress className="button-loader" />
                                                        )}
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                    </Box>
                                )}
                            </Grid>
                            {props.paymentDetails?.status === 'PENDING' && (
                                <Box className={classes.buttonVoidBox}>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        className={classes.button}
                                        onClick={() => voidPayment(orderId)}
                                        disabled={state.isLoading}
                                    >
                                        Void Payment
                                        {state.isLoading && <CircularProgress className="button-loader" />}
                                    </Button>
                                </Box>
                            )}
                        </Grid>
                    );
                })}
            </Grid>

            {props.paymentDetails && (
                <React.Fragment>
                    <Box my={2}>
                        <Divider />
                    </Box>

                    <Typography component="h6" variant="h6" className="fontFamilyWorkSans" align="left">
                        Payment details
                    </Typography>

                    <Box mb={2}>
                        <Box display="flex" justifyContent="space-between"> 
                            {renderPaymentMethod(props.paymentDetails?.paymentMethodType)}
                        </Box>
                        {props.paymentDetails?.paymentMethodType === 'CARD' && (
                            <>
                                <Box display="flex" justifyContent="space-between" mb={1} mt={2}>
                                    {renderBrandLogo(props.paymentDetails?.brand)}
                                    <Typography variant="subtitle2">{maskedCardNumber}</Typography>
                                </Box>
                                <Box display="flex" justifyContent="space-between" mb={1}>
                                    <Typography variant="subtitle2">Expiration date</Typography>
                                    <Typography variant="subtitle2">{expirationDate}</Typography>
                                </Box>
                            </>
                        )}
                    </Box>
                </React.Fragment>
            )}
        </Card>
    );
};

export default ItemsOrdered;
