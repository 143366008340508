/* eslint-disable no-undef */

import React from 'react';

import { CacheProvider } from '@emotion/react';

import CssBaseline from '@material-ui/core/CssBaseline';
import PickerAdapter from '@material-ui/pickers/adapter/moment';
import { LocalizationProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/core/styles';
import DEFAULT_THEME from './theme';

import cache from './cache';
import { AccessTokenProvider } from './contexts/access-token-context';
import { ApiServiceProvider } from './contexts/api-service-context';
import { StoreProvider } from './contexts/store-context';
import { ToasterProvider } from './contexts/toaster-context';
import { UserProvider } from './contexts/user-context';
import { IdleTimeoutContextProvider } from './contexts/idle-timeout-context';

const AppProvider: React.FC = (props: React.PropsWithChildren<unknown>) => {
    return (
        <CacheProvider value={cache}>
            <IdleTimeoutContextProvider>
                <UserProvider>
                    <AccessTokenProvider>
                        <ApiServiceProvider>
                            <ThemeProvider theme={DEFAULT_THEME}>
                                <LocalizationProvider dateAdapter={PickerAdapter}>
                                    <ToasterProvider>
                                        <StoreProvider>
                                            <CssBaseline />
                                            {props.children}
                                        </StoreProvider>
                                    </ToasterProvider>
                                </LocalizationProvider>
                            </ThemeProvider>
                        </ApiServiceProvider>
                    </AccessTokenProvider>
                </UserProvider>
            </IdleTimeoutContextProvider>
        </CacheProvider>
    );
};

export default AppProvider;
