import React from 'react';
import { arrayMoveImmutable } from 'array-move';
import { SortableContainer, SortableElement, SortEnd } from 'react-sortable-hoc';

import { makeStyles } from '@material-ui/core/styles';
import DragHandleIcon from '@material-ui/icons/DragHandle';

import { AutocompleteValue } from '../../types';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    sortableUl: {
        listStyleType: 'none',
        padding: 0,
        maxWidth: 480,
        margin: 'auto'
    },
    sortableLi: {
        background: '#35baf633',
        margin: '8px 0',
        padding: '3px 16px',
        cursor: 'pointer',
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center'
    },
    sortableLiSpan: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));

type Props = {
    products: Array<AutocompleteValue>;
    onChange: (products: Array<AutocompleteValue>) => void;
}

const SortableItem = SortableElement(({ name }: { name: string }) => {
    const classes = useStyles();
    return (
        <li className={classes.sortableLi}>
            <DragHandleIcon color="primary" />
            <span className={classes.sortableLiSpan}>
                <Typography>{name}</Typography>
            </span>
        </li>
    );
});

const SortableList = SortableContainer(({ products }: { products: Array<AutocompleteValue> }) => {
    const classes = useStyles();

    return (
        <ul className={classes.sortableUl}>
            {products.map((p, index) => <SortableItem key={`item-${index}`} index={index} name={p?.name} />)}
        </ul>
    );
});

const ProductSortable = (props: Props) => {
    const { products, onChange } = props;
    const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => onChange(arrayMoveImmutable(products, oldIndex, newIndex));
    return (
        <SortableList
            lockAxis="y"
            products={products}
            onSortEnd={onSortEnd}

        />
    );
}

export default ProductSortable;
