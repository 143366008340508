import { makeStyles } from '@material-ui/core/styles';

const useAdminStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 24,
        paddingBottom: 24
    },
    container: {
        maxWidth: 1600,
        paddingLeft: 16,
        paddingRight: 16,
        '@media (min-width: 700px)': {
            paddingLeft: 24,
            paddingRight: 24
        },
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    form: {
        maxWidth: 1200,
        padding: 16,
        boxShadow: theme.shadows[4],
        borderRadius: 8,
        margin: '24px auto',
        '& $footer': {
            display: 'flex',
            padding: '16px 0',
            justifyContent: 'center',
            [theme.breakpoints.up('sm')]: {
                justifyContent: 'flex-end'
            },
            '& button': {
                margin: '0 8px',
                [theme.breakpoints.up('sm')]: {
                    margin: '0 0 0 12px'
                }
            }
        }
    },
    headerComponent: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '0 16px'
    },
    spaceBetweenHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 16px'
    },
    formControl: {
        width: '30%'
    },
    heading: {
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 600,
        margin: '12px auto',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'left'
        }
    },
    footer: {},
    tableHeading: {
        fontWeight: 600,
        fontSize: 20,
        color: theme.palette.primary.main,
    },
    textField: {
        marginBottom: 24,
        width: '100%'
    }
}));

export default useAdminStyles;
