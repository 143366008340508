import React, { useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { useToasterData } from '../contexts/toaster-context';
import { useUserService } from '../contexts/user-context';
import { IsAdminUser } from '../utilities/userRoles';
import { DRAWER_WIDTH } from './header';

export const useStyles = makeStyles((theme) => ({
    adminWrapper: {
        [theme.breakpoints.up('md')]: {
            marginLeft: DRAWER_WIDTH // Left margin equivalnt to DRAWER_WIDTH
        }
    }
}));

const PrivateRoute = ({ component: Component, ...routeParams }: RouteProps) => {
    const classes = useStyles();
    const userService = useUserService();
    const toasterContext = useToasterData();

    const { user } = userService;
    const isAdminUser = IsAdminUser(user);

    useEffect(() => {
        if (!user || !isAdminUser) {
            toasterContext.setToaster({
                isOpen: true,
                message:
                    'Unauthorized Access! You have attempted to access a page that you are not authorized to view.',
                severity: 'error'
            });
        }
    }, [user]);

    return (
        <Route
            {...routeParams}
            render={(props) =>
                (user && Component && (
                    <Grid item={true} xs={12} className={classes.adminWrapper}>
                        <Component {...props} />
                    </Grid>
                )) || <Redirect to="/login" />
            }
        />
    );
};

export default PrivateRoute;
