import { StoreActionProps } from '.';
import { ErrorType } from '../types';

export const validateOTP = async (props: StoreActionProps, token: string | null, otp: number) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.post('/api/admin/auth/otp/validate-otp', {
            token,
            otp
        });
        const response = apiResponse.parsedBody;

        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });

        return response;
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
    }
    return null;
};

export const resendOTP = async (props: StoreActionProps, token: string | null) => {
    const { apiService, toasterContext } = props;
    try {
        const apiResponse = await apiService.post('/api/admin/auth/otp/resend', {
            token
        });
        const response = apiResponse.parsedBody;

        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });

        return response;
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
    }

    return null;
};

export const validateAccountToken = async (props: StoreActionProps, token: string) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.post('/api/admin/auth/otp/validate-token', { token });
        const response = apiResponse.parsedBody;
        if (!response.status) {
            console.error(response.message);
            return null;
        }
        return response;
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
    }
    return null;
};

const otpAction = (props?: StoreActionProps) => {
    if (!props) {
        return null;
    }

    return {
        validateOTP: (token: string | null, otp: number) => validateOTP(props, token, otp),
        resendOTP: (token: string | null) => resendOTP(props, token),
        validateAccountToken: (token: string) => validateAccountToken(props, token),
    };
};

export default otpAction;

export interface OtpAction {
    otpAction: typeof otpAction;
}

