import { formatDinero, getDinero } from '.';
import { BarcodeCode } from '../types';
import { B2BGiftCardAttributes } from '../types/b2bGiftCard';

export const getBarcodeDetails = (bardcodeCodes: BarcodeCode[] | null | undefined) => {
    if (!bardcodeCodes?.length) {
        return {};
    }
    const barcodeValue = bardcodeCodes[0]?.value;
    const barcodePin = bardcodeCodes[1]?.value;

    return {
        barcodeValue,
        barcodePin
    };
};

export const formatPriceDenomination = (giftCard: B2BGiftCardAttributes): string => {
    if (giftCard.type === 'FIXED') {
        return giftCard.priceDenominations.map((gc) => formatDinero(getDinero(gc))).join(', ');
    }

    const min = formatDinero(getDinero(giftCard.priceDenominations[0], giftCard.currencyCode));
    const max = formatDinero(
        getDinero(giftCard.priceDenominations[giftCard.priceDenominations.length - 1], giftCard.currencyCode)
    );

    return `${min} - ${max}`;
};
