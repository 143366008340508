import { makeStyles } from "@material-ui/core/styles";

const useOtpVerificationStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '45px 10px 0',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 500
        }
    },
    verifyAccount: {
        fontSize: 24,
        fontStyle: 'normal',
        fontWeight: 800,
        lineHeight: '1.5em',
        letterSpacing: 0,
        textAlign: 'center',
        marginBottom: 24
    },
    textfield: {
        margin: '30px 0',
        width: '100%',
        '& input': {
            '-moz-appearance': 'textfield',
            padding: '15px 12px 15px'
        },
        '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    otpInput: {
        height: 36,
        width: '36px !important',
        margin: '0 8px',
        borderRadius: 4,
        fontSize: 18,
        border: '1px solid rgba(0, 0, 0, 0.3)',
        [theme.breakpoints.up('sm')]: {
            fontSize: 32,
            height: 48,
            width: '54px !important'
        }
    },
    otpContainer: {
        padding: '48px 0 56px 0'
    },
    otpErrorStyle: {
        border: `1px solid ${theme.palette.error.main}`
    },
    otpFocusStyle: {
        outline: `${theme.palette.secondary.main} auto 1px`
    }
}));

export default useOtpVerificationStyles;
