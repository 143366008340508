import React from 'react';

import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { HttpRequest, useApiService } from '../../../contexts/api-service-context';
import { ErrorType } from '../../../types';

const B2C_APP = process.env.REACT_APP_B2C_BASE_URL as string;
const B2B_APP = process.env.REACT_APP_B2B_BASE_URL as string;

const useStyles = makeStyles((theme) => ({
    clientDropdown: {
        width: 300,
        marginBottom: 16
    }
}));

const E2ETest = () => {
    const apiService = useApiService();
    const classes = useStyles();

    const [isTestLoading, setIsTestLoading] = React.useState(false);
    const [testLogs, setTestLogs] = React.useState('');
    const [clientBaseUrl, setClientBaseUrl] = React.useState(B2C_APP);

    const testContainerRef = React.useRef<HTMLDivElement | null>(null);
    const abortControllerRef = React.useRef<AbortController>(new AbortController());

    const runTests = async () => {
        try {
            setIsTestLoading(true);
            setTestLogs('');

            await apiService.get(`api/admin/e2e/clear-test-data`);

            const apiResponse = await apiService.get(`/api/admin/e2e/token`);
            const response = apiResponse.parsedBody;
            const accessToken = response.data;

            const headers = new Headers();
            headers.append('Access-Control-Allow-Credentials', 'true');
            if (accessToken) {
                headers.append('authorization', `Bearer ${accessToken}`);
            }
            const request: HttpRequest = {
                credentials: 'same-origin',
                method: 'GET',
                headers,
                signal: abortControllerRef.current.signal
            };
            const res = await fetch(`${clientBaseUrl}/e2e`, request);
            const reader = res.body?.getReader();
            let result;
            const decoder = new TextDecoder('utf8');
            while (!result?.done) {
                result = await reader?.read();
                const chunk = decoder.decode(result?.value);
                setTestLogs((prevState) => `${prevState}\n${chunk}`);
                scrollSmoothToBottom();
            }

            setIsTestLoading(false);
        } catch (error: ErrorType) {
            if (error.name === 'AbortError') return;
            console.error('Error ', error);
            setIsTestLoading(false);
            setTestLogs('');
        }
    };

    const scrollSmoothToBottom = () => {
        if (!testContainerRef.current) {
            return;
        }
        testContainerRef.current.scrollTo({
            top: testContainerRef.current.scrollHeight,
            behavior: 'smooth'
        });
    };

    const clearTestLogs = () => {
        setTestLogs('');
    };

    const abortTests = () => {
        const controller = abortControllerRef.current;
        controller.abort();
        setIsTestLoading(false);
        setTestLogs('');
        abortControllerRef.current = new AbortController();
    };

    React.useEffect(() => {
        const controller = abortControllerRef.current;

        return () => {
            controller.abort();
        };
    }, []);

    return (
        <Box textAlign="right" py={4}>
            <Box textAlign="left">
                <FormControl className={classes.clientDropdown}>
                    <InputLabel id="select-e2e-client-app">Select client to test</InputLabel>
                    <Select
                        labelId="select-e2e-client-app"
                        id="select-e2e-client-app-helper"
                        value={clientBaseUrl}
                        label="Age"
                        onChange={(e) => setClientBaseUrl(e.target.value as string)}
                    >
                        <MenuItem value={B2C_APP}>B2C App</MenuItem>
                        <MenuItem value={B2B_APP}>B2B App</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            <Box
                bgcolor="black"
                color="white"
                textAlign="left"
                p={2}
                mb={2}
                minHeight={100}
                width="100%"
                maxHeight={300}
                overflow="auto"
                {...{ ref: testContainerRef }}
            >
                <pre>{testLogs || 'Click the button below to run e2e tests'}</pre>
            </Box>

            <Box display="flex" justifyContent="flex-end">
                {testLogs && !isTestLoading && (
                    <Box marginRight={2}>
                        <Button type="button" variant="contained" size="small" onClick={clearTestLogs}>
                            Clear
                        </Button>
                    </Box>
                )}
                {isTestLoading && (
                    <Box marginRight={2}>
                        <Button type="button" variant="contained" size="small" color="secondary" onClick={abortTests}>
                            Abort
                        </Button>
                    </Box>
                )}
                <Button
                    disabled={isTestLoading}
                    onClick={runTests}
                    type="button"
                    variant="contained"
                    size="small"
                    color="primary"
                >
                    Run tests {isTestLoading && <CircularProgress className="button-loader" />}
                </Button>
            </Box>
        </Box>
    );
};

export default E2ETest;
