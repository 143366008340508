import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Alert from '@material-ui/lab/Alert';

import { ThemeAttributes } from '../../types';

import { AppTextField } from '../common/app-textfield';

import useAdminStyles from '../styles';

import { SketchPicker } from 'react-color';

import 'react-linear-gradient-picker/dist/index.css';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ThemePreview from './theme-preview';
import { colorObjToCss } from '.';
import { GradientPicker } from 'react-linear-gradient-picker';

export type ThemeFormState = Omit<ThemeAttributes, 'bgColor'> & {
    homePageBanner?: File | null;
    searchGiftCard?: File | null;
    shareGiftCard?: File | null;
    buyGiftCard?: File | null;
    bgColor: {
        offset: string;
        opacity: number;
        color: string;
    }[];
};
type ThemeErrorState = Omit<
    ThemeFormState,
    | 'isActive'
    | 'isDeleted'
    | 'bgColor'
    | 'images'
    | 'homePageBanner'
    | 'searchGiftCard'
    | 'shareGiftCard'
    | 'buyGiftCard'
> & {
    bgColor: string;
    homePageBanner: string;
    searchGiftCard: string;
    shareGiftCard: string;
    buyGiftCard: string;
};

type AddEditFormState = {
    theme: ThemeFormState;
    errors: ThemeErrorState;
};

type AddEditFormProps = {
    onCancel: () => void;
    onSave: (theme: ThemeFormState) => void;
    theme?: ThemeFormState;
    isSaving: boolean;
};

const DEFAULT_THEME: ThemeFormState = {
    type: '',
    primaryColor: 'rgba(104, 159, 56, 1)',
    secondaryColor: 'rgba(3, 169, 244, 1)',
    bgColor: [
        { offset: '0.00', color: 'rgb(104, 159, 56)', opacity: 1 },
        { offset: '1.00', color: 'rgb(3, 169, 244)', opacity: 1 }
    ],
    button: {
        textColor: 'rgba(255, 255, 255, 1)'
    },
    typography: {
        headingFont: ''
    },
    homePageBanner: undefined,
    searchGiftCard: undefined,
    shareGiftCard: undefined,
    buyGiftCard: undefined,
    isActive: false,
    isDeleted: false,
    images: {
        homepage: {
            homePageBanner: '',
            shareGiftCard: '',
            searchGiftCard: '',
            buyGiftCard: ''
        }
    }
};

const DEFAULT_ERROR: ThemeErrorState = {
    type: '',
    primaryColor: '',
    secondaryColor: '',
    bgColor: '',
    button: {
        textColor: ''
    },
    typography: {
        headingFont: ''
    },
    homePageBanner: '',
    searchGiftCard: '',
    shareGiftCard: '',
    buyGiftCard: ''
};

const rgbToRgba = (rgb: string, a = 1) => rgb.replace('rgb(', 'rgba(').replace(')', `, ${a})`);

const WrappedColorPicker = ({
    onSelect,
    color = '',
    opacity = 1,
    ...rest
}: {
    onSelect?: (hex: string, alpha: number) => void;
    color?: string;
    opacity?: number;
}) => {
    return (
        <SketchPicker
            {...rest}
            color={rgbToRgba(color, opacity)}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(c: any) => {
                const { r, g, b, a } = c.rgb;
                onSelect && onSelect(`rgb(${r}, ${g}, ${b})`, a);
            }}
        />
    );
};

const imgComponentList: (Pick<ImgComponentProps, 'label' | 'width' | 'height'> & {
    field: keyof ThemeFormState['images']['homepage'];
})[] = [
    {
        label: 'Home page banner',
        field: 'homePageBanner',
        width: 300,
        height: 'auto'
    },
    {
        label: 'Search gift card',
        field: 'searchGiftCard'
    },
    {
        label: 'Share gift card',
        field: 'shareGiftCard'
    },
    {
        label: 'Buy gift card',
        field: 'buyGiftCard'
    }
];

type ImgComponentProps = {
    label: string;
    errorField: string;
    image?: string | null | false;
    width?: number;
    height?: number | string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
const ImgComponent = (props: ImgComponentProps) => {
    const { label, errorField, image, width = 100, height = 100, onChange } = props;

    return (
        <Grid item={true} xs={12} sm={4}>
            <Box textAlign="left" ml={-2}>
                <FormControlLabel
                    label={label}
                    labelPlacement="start"
                    control={
                        <Box ml={1}>
                            <input type="file" onChange={onChange} />
                        </Box>
                    }
                />
                <Box ml={2} mt={2}>
                    {errorField && <Alert severity="error">{errorField}</Alert>}
                    {image && <img src={image} alt={label} width={width} height={height} />}
                </Box>
            </Box>
        </Grid>
    );
};

const availableFonts = [
    'Roboto',
    'Helvetica',
    'Arial',
    'sans-serif',
    'serif',
    '"Dancing Script", cursive',
    '"Work Sans", sans-serif',
    '"Open Sans", sans-serif'
];

const AddEditForm = (props: AddEditFormProps) => {
    const adminClasses = useAdminStyles();

    const { onCancel, onSave, theme, isSaving } = props;

    const [state, setState] = useState<AddEditFormState>({
        theme: DEFAULT_THEME,
        errors: DEFAULT_ERROR
    });

    React.useEffect(() => {
        theme &&
            setState((prevState) => ({
                ...prevState,
                theme:
                    {
                        ...theme
                    } || DEFAULT_THEME
            }));
    }, [theme]);

    const saveThemeAttributes = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // validations.
        const errors: AddEditFormState['errors'] = {
            ...state.errors
        };
        if (!state.theme.type) {
            errors.type = 'Type is a required field';
        }

        if (!state.theme.typography.headingFont) {
            errors.typography.headingFont = 'Heading font is required';
        }

        if (!state.theme.homePageBanner && !state.theme.images.homepage.homePageBanner) {
            errors.homePageBanner = 'Banner is required';
        }

        if (!state.theme.searchGiftCard && !state.theme.images.homepage.searchGiftCard) {
            errors.searchGiftCard = 'Search gift card image is required';
        }

        if (!state.theme.shareGiftCard && !state.theme.images.homepage.shareGiftCard) {
            errors.shareGiftCard = 'Share gift card image is required';
        }

        if (!state.theme.buyGiftCard && !state.theme.images.homepage.buyGiftCard) {
            errors.buyGiftCard = 'Buy gift card image is required';
        }

        function isErrored(
            arg: Record<string, unknown> | string,
            key: string | null = null,
            err: Record<string, number> = {}
        ) {
            if (typeof arg === 'object') {
                for (const k in arg) isErrored(arg[k] as string, k, err);
            } else if (typeof arg === 'string' && key && arg.length) {
                err[key] = 1;
            }
            return err;
        }
        const errorKeys = Object.keys(isErrored(errors));

        if (errorKeys.length) {
            setState((prevState) => ({ ...prevState, errors }));
            return;
        }
        onSave(state.theme);
    };

    return (
        <Grid item={true} xs={12} className={adminClasses.form}>
            <Typography className={adminClasses.heading}>
                {' '}
                {(props.theme && 'Update') || 'Create New'} Theme{' '}
            </Typography>

            <form noValidate autoComplete="off" onSubmit={saveThemeAttributes}>
                <Grid container={true} spacing={4}>
                    <Grid item={true} container={true} spacing={1}>
                        <Grid item={true} container={true} xs={12} sm={6} spacing={2} alignContent="flex-start">
                            <Grid item={true} xs={12} sm={12}>
                                <AppTextField
                                    className={adminClasses.textField}
                                    required={true}
                                    variant="filled"
                                    size="small"
                                    label="Type"
                                    fullWidth={true}
                                    value={state.theme?.type || ''}
                                    error={Boolean(state.errors.type)}
                                    helperText={state.errors.type}
                                    name="theme-type"
                                    onChange={(e) => {
                                        const type = e.target.value.toUpperCase();
                                        setState((prevState) => ({
                                            ...prevState,
                                            theme: { ...prevState.theme, type },
                                            errors: {
                                                ...prevState.errors,
                                                type: !type ? 'Type is a required field' : ''
                                            }
                                        }));
                                    }}
                                />
                            </Grid>

                            <Grid item={true} xs={12} sm={12}>
                                <Box textAlign="left">
                                    <FormControl variant="filled" size="small" fullWidth>
                                        <InputLabel id="theme-typography-headingFont-label">Heading Font</InputLabel>
                                        <Select
                                            labelId="theme-typography-headingFont-label"
                                            id="theme-typography-headingFont"
                                            value={state.theme?.typography.headingFont}
                                            onChange={(e) => {
                                                const headingFont = e.target.value as string;
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    theme: { ...prevState.theme, typography: { headingFont } },
                                                    errors: {
                                                        ...prevState.errors,
                                                        typography: {
                                                            headingFont: !headingFont
                                                                ? 'Heading Font is a required field'
                                                                : ''
                                                        }
                                                    }
                                                }));
                                            }}
                                        >
                                            {availableFonts.map((font) => (
                                                <MenuItem value={font} key={font}>
                                                    {font}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item={true} xs={12} sm={6}>
                                <Box textAlign="center" ml={-2}>
                                    <FormControlLabel
                                        label="Primary color"
                                        labelPlacement="top"
                                        control={
                                            <SketchPicker
                                                color={state.theme.primaryColor}
                                                onChangeComplete={(color) => {
                                                    const { r, g, b, a } = color.rgb;
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        theme: {
                                                            ...prevState.theme,
                                                            primaryColor: `rgba(${r}, ${g}, ${b}, ${a})`
                                                        }
                                                    }));
                                                }}
                                            />
                                        }
                                    />
                                </Box>
                            </Grid>

                            <Grid item={true} xs={12} sm={6}>
                                <Box textAlign="center" ml={-2}>
                                    <FormControlLabel
                                        label="Secondary color"
                                        labelPlacement="top"
                                        control={
                                            <SketchPicker
                                                color={state.theme.secondaryColor}
                                                onChangeComplete={(color) => {
                                                    const { r, g, b, a } = color.rgb;
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        theme: {
                                                            ...prevState.theme,
                                                            secondaryColor: `rgba(${r}, ${g}, ${b}, ${a})`
                                                        }
                                                    }));
                                                }}
                                            />
                                        }
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6} container={true}>
                            <Grid item={true} xs={12}>
                                <ThemePreview
                                    headingFont={state.theme.typography.headingFont}
                                    primaryColor={state.theme.primaryColor}
                                    secondaryColor={state.theme.secondaryColor}
                                    buttonTextColor={state.theme.button.textColor}
                                    backgroundColor={colorObjToCss(state.theme.bgColor)}
                                />
                            </Grid>

                            <Grid item={true} xs={12} sm={'auto'} className="align-self-end">
                                <Box textAlign="left" ml={-2}>
                                    <FormControlLabel
                                        label="Button text color"
                                        labelPlacement="top"
                                        control={
                                            <SketchPicker
                                                color={state.theme.button.textColor}
                                                onChangeComplete={(color) => {
                                                    const { r, g, b, a } = color.rgb;
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        theme: {
                                                            ...prevState.theme,
                                                            button: { textColor: `rgba(${r}, ${g}, ${b}, ${a})` }
                                                        }
                                                    }));
                                                }}
                                            />
                                        }
                                    />
                                </Box>
                            </Grid>

                            <Grid item={true} xs={12} sm={'auto'}>
                                <Box textAlign="left" ml={-2}>
                                    <FormControlLabel
                                        label="Background color"
                                        labelPlacement="top"
                                        control={
                                            <GradientPicker
                                                {...{
                                                    width: 320,
                                                    paletteHeight: 32,
                                                    palette: state.theme.bgColor,
                                                    onPaletteChange: (color: ThemeFormState['bgColor']) => {
                                                        setState((prevState) => ({
                                                            ...prevState,
                                                            theme: {
                                                                ...prevState.theme,
                                                                bgColor: color
                                                            }
                                                        }));
                                                    }
                                                }}
                                            >
                                                <WrappedColorPicker />
                                            </GradientPicker>
                                        }
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    {imgComponentList.map((item) => (
                        <ImgComponent
                            key={item.field}
                            label={item.label}
                            errorField={state.errors[item.field]}
                            image={!state.theme[item.field] && state.theme.images.homepage[item.field]}
                            width={item.width}
                            height={item.height}
                            onChange={(e) => {
                                const file = e.target.files && e.target.files[0];
                                let error = '';
                                if (!file) {
                                    error = 'Failed to upload file';
                                }
                                setState((prevState) => ({
                                    ...prevState,
                                    theme: {
                                        ...prevState.theme,
                                        [item.field]: file
                                    },
                                    errors: {
                                        ...prevState.errors,
                                        [item.field]: error
                                    }
                                }));
                            }}
                        />
                    ))}

                    <Grid item={true} xs={12}>
                        <Box textAlign="left">
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={state.theme.isActive}
                                        onChange={(e) =>
                                            setState((prevState) => ({
                                                ...prevState,
                                                theme: { ...prevState.theme, isActive: e.target.checked }
                                            }))
                                        }
                                        color="primary"
                                    />
                                }
                                label="Is Active"
                            />
                        </Box>
                    </Grid>
                </Grid>

                <Grid item={true} xs={12} className={adminClasses.footer}>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={(e) => {
                            onCancel();
                            setState({ ...state, theme: DEFAULT_THEME });
                        }}
                        disabled={isSaving}
                    >
                        Cancel
                    </Button>

                    <Button variant="contained" color="primary" size="small" disabled={isSaving} type="submit">
                        save
                        {isSaving && <CircularProgress className="button-loader" />}
                    </Button>
                </Grid>
            </form>
        </Grid>
    );
};

export default AddEditForm;
