import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Typography, FormControl, Box, Select, InputLabel } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

import { GetPromoCode, PromoCode } from '../../types';

import TableComponent, { HeadCell, TableResultRow } from '../common/table-component';

import useAdminStyles from '../styles';
import storeContext from '../../contexts/store-context';
import Switch from '@material-ui/core/Switch';

import moment from 'moment';
import { formatDinero, generateDinero, getDinero, joinNameParts, scrollTop } from '../../utilities';
import ConfirmationDialog from '../common/confirmation-dialog';
import HasPermission from '../../utilities/can';
import AddEditForm from './form';
import StatusButton from '../common/status-button';
import { useUserService } from '../../contexts/user-context';

const ALL_CODES = 'All';
const ADMINS = 'Admin';
const MERCHANTS = 'Merchants';
const PromoCodeTypeEnum = [ADMINS, MERCHANTS, ALL_CODES] as const;

type State = {
    isLoading: boolean;
    isDeleting: boolean;
    isSaving: boolean;
    renderAddEditForm: boolean;
    menuAnchorEle: HTMLElement | null;
    activePromoCodeId: number | null;
    promoCodes: GetPromoCode[];
    selectedRows: TableResultRow[];
    isConfirmationDialogOpen: boolean;
    promoCodeFilter: typeof PromoCodeTypeEnum[number];
};

const PromoCodeComponent = () => {
    const adminClasses = useAdminStyles();
    const history = useHistory();
    const { promoCodeAction, appAction } = storeContext();

    const userService = useUserService();
    const canReadPromoCode = HasPermission(userService.user, 'PROMO_CODE', 'READ');
    const canCreatePromoCode = HasPermission(userService.user, 'PROMO_CODE', 'CREATE');
    const canUpdatePromoCode = HasPermission(userService.user, 'PROMO_CODE', 'UPDATE');

    const [state, setState] = useState<State>({
        isLoading: false,
        isDeleting: false,
        isSaving: false,
        renderAddEditForm: false,
        menuAnchorEle: null,
        activePromoCodeId: null,
        promoCodes: [],
        selectedRows: [],
        isConfirmationDialogOpen: false,
        promoCodeFilter: ALL_CODES
    });

    useEffect(() => {
        if (!canReadPromoCode) {
            history.push('/dashboard');
            return;
        }
        appAction()?.renderFullHeader();
        getAllPromoCodes();
    }, [state.isSaving]);

    const activePromoCode = state.promoCodes.find((p) => p.promoCodeId === state.activePromoCodeId);

    const mapDineroPromoCodeToValues = (promocode?: GetPromoCode): GetPromoCode | undefined => {
        if (!promocode) {
            return promocode;
        }
        const { value, type, minOrderValue, maxAmount, currencyCode } = promocode;

        return {
            ...promocode,
            value: type === 'AMOUNT' ? generateDinero(value, currencyCode).getAmount() / 100 : value,
            minOrderValue: minOrderValue ? generateDinero(minOrderValue, currencyCode).getAmount() / 100 : null,
            maxAmount: maxAmount ? generateDinero(maxAmount, currencyCode).getAmount() / 100 : null,
        }
    };

    const mapValuesToDineroPromoCode = (promocode: PromoCode): PromoCode => {
        const { value, type, minOrderValue, maxAmount, currencyCode } = promocode;

        return {
            ...promocode,
            value: type === 'AMOUNT' ? getDinero(value, currencyCode).getAmount() : value,
            minOrderValue: minOrderValue ? getDinero(minOrderValue, currencyCode).getAmount() : null,
            maxAmount: maxAmount ? getDinero(maxAmount, currencyCode).getAmount() : null,
        }
    };

    const getAllPromoCodes = async () => {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        const promoCodes = await promoCodeAction()?.getPromoAllCodes();
        setState((prevState) => ({ ...prevState, promoCodes: promoCodes || [], isLoading: false }));
    };

    const onIsActiveChange = async (isActive: boolean, promoCodeId: number) => {
        const activePromoCode = state.promoCodes.find((p) => p.promoCodeId === promoCodeId);
        if (!activePromoCode) {
            return;
        }
        const savedPromoCode = await promoCodeAction()?.updatePromoCode({
            ...activePromoCode,
            isActive
        });
        setState((prevState) => {
            const newPromoCodes = prevState.promoCodes.map((p) => {
                if (p.promoCodeId === promoCodeId) {
                    return { ...p, ...savedPromoCode };
                }
                return p;
            });
            return { ...prevState, promoCodes: newPromoCodes };
        });
    };

    const headCells: Array<HeadCell> = [
        { id: 'key', label: 'Key' },
        { id: 'value', label: 'Value' },
        { id: 'type', label: 'Type' },
        { id: 'adminUserId', label: 'Admin User ID' },
        { id: 'b2bUserId', label: 'Merchant User ID' },
        { id: 'isActive', label: 'Is Active' },
        { id: 'maxAmount', label: 'Max waive off amount' },
        { id: 'minAmount', label: 'Min order amount' },
        { id: 'options', label: '' }
    ];

    const tableRows: Array<TableResultRow> = state.promoCodes
        .filter((code) => {
            if (state.promoCodeFilter === MERCHANTS) {
                return Boolean(code.b2bUserId) || Boolean(code.updatedByB2bUserId);
            } else if (state.promoCodeFilter === ADMINS) {
                return Boolean(code.adminUserId);
            } else {
                return true;
            }
        })
        .map((promocode) => {
            return {
                promoCodeId: {
                    text: promocode.promoCodeId?.toString() || ''
                },
                key: {
                    align: 'left',
                    text: promocode.key || '-'
                },
                value: {
                    align: 'left',
                    text:
                        promocode.type === 'AMOUNT'
                            ? formatDinero(generateDinero(promocode.value, promocode.currencyCode))
                            : promocode.value.toString() + '%'
                },
                type: {
                    align: 'left',
                    text: promocode.type || '-'
                },
                adminUserId: {
                    align: 'left',
                    text: promocode.adminUserId?.toString() || '-'
                },
                b2bUserId: {
                    align: 'left',
                    text: promocode.b2bUserId?.toString() || '-'
                },
                isActive: {
                    align: 'left',
                    text: '',
                    element: canUpdatePromoCode ? (
                        <Switch
                            checked={promocode.isActive}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onIsActiveChange(!promocode.isActive, promocode.promoCodeId);
                            }}
                            color="primary"
                        />
                    ) : (
                        <StatusButton
                            options={[
                                { type: 'ACTIVE', text: 'Active', color: 'active' },
                                { type: 'INACTIVE', text: 'Inactive', color: 'warning' }
                            ]}
                            type={promocode.isActive ? 'ACTIVE' : 'INACTIVE'}
                        />
                    )
                },
                maxAmount: {
                    align: 'left',
                    text: promocode.maxAmount ? formatDinero(generateDinero(promocode.maxAmount, promocode.currencyCode)) : '-'
                },
                minAmount: {
                    align: 'left',
                    text: promocode.minOrderValue ? formatDinero(generateDinero(promocode.minOrderValue, promocode.currencyCode)) : '-'
                },
                options: {
                    align: 'right',
                    text: '',
                    helperText:
                        (promocode.updatedByAdminUser &&
                            `updated by - ${
                                promocode.updatedByAdminUser?.firstName
                                    ? joinNameParts(promocode.updatedByAdminUser)
                                    : promocode.updatedByAdminUser?.email || ''
                            }${promocode?.updatedAt && ' - '}${
                                promocode?.updatedAt && moment(new Date(promocode?.updatedAt)).fromNow()
                            }`) ||
                        undefined,
                    element:
                        (canUpdatePromoCode && (
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setState((prevState) => ({
                                        ...prevState,
                                        menuAnchorEle: e.currentTarget,
                                        activePromoCodeId: promocode.promoCodeId
                                    }));
                                }}
                            >
                                <MoreVertIcon />
                            </IconButton>
                        )) ||
                        undefined
                }
            };
        });

    const headerComponent = (
        <Grid item={true} xs={12} className={adminClasses.spaceBetweenHeader}>
            <Typography className={adminClasses.tableHeading}>Promo Codes</Typography>
            <Box display="flex" justifyContent="flex-end" gridGap="4rem">
                <FormControl className={adminClasses.formControl}>
                    <InputLabel id="promo-code-filter">Created by :</InputLabel>
                    <Select
                        labelId="select-promo-code-filter"
                        value={state.promoCodeFilter}
                        label="Created by :"
                        onChange={(e) => {
                            const codeOrigin = e.target.value as typeof PromoCodeTypeEnum[number];
                            setState((prevState) => ({ ...prevState, promoCodeFilter: codeOrigin }));
                        }}
                    >
                        {PromoCodeTypeEnum.map((code) => (
                            <MenuItem key={code} value={code}>
                                {code}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button
                    onClick={() =>
                        setState((prevState) => ({ ...prevState, renderAddEditForm: true, activePromoCodeId: null }))
                    }
                    size="small"
                    color="primary"
                    variant="contained"
                >
                    <AddIcon />
                    &nbsp;Create New Promo Code
                </Button>
            </Box>
        </Grid>
    );

    const handleMenuClose = () => {
        setState((prevState) => ({
            ...prevState,
            menuAnchorEle: null,
            activePromoCodeId: null
        }));
    };

    const savePromoCode = async (promoCode: PromoCode) => {
        const promoCodeToSave: PromoCode = {
            ...mapValuesToDineroPromoCode(promoCode),
            promoCodeId: promoCode.promoCodeId === -1 ? null : promoCode.promoCodeId
        };
        setState((prevState) => ({ ...prevState, isSaving: true }));
        const savedPromoCode = await (promoCodeToSave.promoCodeId
            ? promoCodeAction()?.updatePromoCode(promoCodeToSave)
            : promoCodeAction()?.createPromoCode(promoCodeToSave));

        setState((prevState) => {
            let newPromoCodes: GetPromoCode[] = state.promoCodes;

            if (promoCode.promoCodeId) {
                // update
                newPromoCodes = prevState.promoCodes.map((p) => {
                    if (p.promoCodeId === promoCode.promoCodeId) {
                        return {
                            ...p,
                            ...savedPromoCode
                        };
                    }
                    return p;
                });
            } else {
                // add
                if (savedPromoCode) {
                    newPromoCodes = [...prevState.promoCodes, savedPromoCode];
                }
            }

            return { ...prevState, isSaving: false, promoCodes: newPromoCodes, renderAddEditForm: !savedPromoCode };
        });
    };

    const openFormForEdit = (promoCodeId: number) => {
        if (!canUpdatePromoCode) {
            return;
        }

        setState((prevState) => ({
            ...prevState,
            renderAddEditForm: true,
            activePromoCodeId: promoCodeId,
            menuAnchorEle: null
        }));
        setTimeout(() => scrollTop());
    };

    const onDeleteConfirm = async () => {
        const ids: number[] = [];

        if (activePromoCode?.promoCodeId) {
            ids.push(activePromoCode.promoCodeId);
        } else if (state.selectedRows) {
            const idsToDelete = state.selectedRows.map((row) => +row.promoCodeId.text);
            Array.prototype.push.apply(ids, idsToDelete);
        }

        setState((prevState) => ({ ...prevState, isDeleting: true }));
        const promoCodesToDelete = state.promoCodes
            .filter((p) => ids.includes(p.promoCodeId))
            .map((p) => ({ ...p, isDeleted: true }));
        await promoCodeAction()?.updateAllPromoCodes(promoCodesToDelete);
        setState((prevState) => {
            const newPromoCodes = prevState.promoCodes.filter((p) => !ids.includes(p.promoCodeId));
            return {
                ...prevState,
                promoCodes: newPromoCodes,
                isDeleting: false,
                isConfirmationDialogOpen: false,
                menuAnchorEle: null
            };
        });
    };

    return (
        <Grid item={true} xs={12} className={adminClasses.root}>
            <Container className={adminClasses.container}>
                {state.renderAddEditForm && (
                    <AddEditForm
                        isSaving={state.isSaving}
                        promocode={mapDineroPromoCodeToValues(activePromoCode)}
                        onCancel={() => setState((prevState) => ({ ...prevState, renderAddEditForm: false }))}
                        onSave={savePromoCode}
                    />
                )}

                <Menu anchorEl={state.menuAnchorEle} open={!!state.menuAnchorEle} onClose={handleMenuClose}>
                    <MenuItem onClick={() => state.activePromoCodeId && openFormForEdit(state.activePromoCodeId)}>
                        Edit
                    </MenuItem>
                    <MenuItem
                        onClick={() => setState((prevState) => ({ ...prevState, isConfirmationDialogOpen: true }))}
                    >
                        Delete
                    </MenuItem>
                </Menu>

                <TableComponent
                    headerComponent={(!state.renderAddEditForm && canCreatePromoCode && headerComponent) || undefined}
                    showPaginator={{ bottom: true }}
                    showCheckbox={true}
                    showSearch={true}
                    isLoading={state.isLoading}
                    rows={tableRows}
                    headCells={headCells}
                    selectedRows={state.selectedRows}
                    onDelete={() => setState((prevState) => ({ ...prevState, isConfirmationDialogOpen: true }))}
                    onCheckboxSelect={(selectedRows) => setState((prevState) => ({ ...prevState, selectedRows }))}
                    keyField="promoCodeId"
                    rowTooltip={(canUpdatePromoCode && 'Click to edit') || undefined}
                    onRowClick={(promoCodeId) => openFormForEdit(+promoCodeId)}
                    fillEmptyRows={false}
                />
            </Container>
            <ConfirmationDialog
                header={'Are you sure?'}
                subHeader={'Click CONFIRM to delete the promo code(s)'}
                isLoading={state.isDeleting}
                open={state.isConfirmationDialogOpen}
                onClose={() =>
                    setState({
                        ...state,
                        isConfirmationDialogOpen: false,
                        activePromoCodeId: null,
                        menuAnchorEle: null
                    })
                }
                onConfirm={onDeleteConfirm}
            />
        </Grid>
    );
};

export default PromoCodeComponent;
