import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import { AdminUser, ErrorType } from '../types';
import { HttpRequest, HttpResponse, prepareHeaders } from './api-service-context';
import { useUserService } from './user-context';

export interface AccessToken {
    token: string | null;
    expiry: number | null;
}

interface State {
    accessToken: AccessToken;
}

type RefreshTokenResponse = { accessToken: AccessToken['token'] | null; user: AdminUser | null };
export type AccessTokenContextProps = State & {
    setAccessToken: (accessToken: AccessToken | null) => void;
    refreshToken: (forceFetch?: boolean) => Promise<RefreshTokenResponse>;
    isAccessTokenValid: () => boolean;
    fireLoginEvent: () => void;
    fireLogoutEvent: () => void;
    clearAutomaticRefreshTokenInterval: () => void;
};

export const AuthEventEnum = {
    LOGOUT: 'ADMIN_LOGOUT',
    LOGIN: 'ADMIN_LOGIN',
    LAST_ACTIVITY: 'ADMIN_LAST_ACTIVITY',
};

type Props = { children?: React.ReactNode };
export const AccessTokenContext = createContext<AccessTokenContextProps | undefined>(undefined);
export const AccessTokenProvider: React.FC = ({ children }: Props) => {
    const userService = useUserService();

    const user = userService.user;

    const clearAutomaticRefresh = useRef<any>(); // eslint-disable-line @typescript-eslint/no-explicit-any
    const [state, setState] = useState<State>({
        accessToken: {
            token: null,
            expiry: null
        }
    });

    useEffect(() => {
        // get a new access token with the refresh token when it expires
        if (state.accessToken.expiry && !isNaN(state.accessToken.expiry)) {
            const now = new Date();
            const triggerAfterMs = state.accessToken.expiry - now.getTime();
            const buffer = 10 * 1000;
            clearAutomaticRefresh.current = setInterval(() => refreshToken(true), triggerAfterMs - buffer);
        }

        return () => clearInterval(clearAutomaticRefresh.current);
    }, [state.accessToken.expiry]);

    const clearAutomaticRefreshTokenInterval = () => {
        userService.setUser(null);
        setAccessToken(null);
        clearInterval(clearAutomaticRefresh.current);
    };

    const isAccessTokenValid = (): boolean => {
        const now = new Date();
        return (
            Boolean(state.accessToken.token && state.accessToken.expiry && now.getTime() < state.accessToken.expiry) &&
            !!user
        );
    };

    const isUserLoggedOut = (): boolean => {
        const LOGIN = localStorage.getItem(AuthEventEnum.LOGIN);
        const LOGOUT = localStorage.getItem(AuthEventEnum.LOGOUT);

        const loginTime = LOGIN && new Date(LOGIN).getTime();
        const loggedOutTime = LOGOUT && new Date(LOGOUT).getTime();

        return Boolean(loggedOutTime && loginTime && loggedOutTime > loginTime);
    };

    const refreshToken = async (forceFetch?: boolean): Promise<RefreshTokenResponse> => {
        if (isAccessTokenValid() && !forceFetch) {
            return { accessToken: state.accessToken.token, user: user };
        }

        if (isUserLoggedOut() && !forceFetch) {
            clearAutomaticRefreshTokenInterval();
            return { accessToken: null, user: null };
        }

        try {
            const headers = prepareHeaders();
            const request: HttpRequest = {
                credentials: 'same-origin',
                method: 'GET',
                headers
            };

            const endpoint = `/api/admin/auth/refresh-token`;
            const apiResponse: HttpResponse = await fetch(endpoint, request);
            const response = await apiResponse.json();

            if (!response.status || !response.data) {
                clearAutomaticRefreshTokenInterval();
                return { accessToken: null, user: null };
            }

            userService.setUser(response.data.user);
            setAccessToken({
                token: response.data.access_token,
                expiry: response.data.token_expiration
            });
            return { accessToken: response.data.access_token, user: response.data.user };
        } catch (e: ErrorType) {
            console.log(e); // eslint-disable-line no-console
            clearAutomaticRefreshTokenInterval();
            return { accessToken: null, user: null };
        }
    };

    const setAccessToken = (accessToken: AccessToken | null) => {
        if (accessToken && accessToken.token && accessToken.expiry) {
            setState({ accessToken });
            return;
        }
        setState({ accessToken: { token: null, expiry: null } });
    };

    // fire an event to let all tabs know they should login
    const fireLoginEvent = () => {
        localStorage.setItem(AuthEventEnum.LOGIN, new Date().toISOString());
    };

    // fire an event to logout from all tabs
    const fireLogoutEvent = () => {
        localStorage.setItem(AuthEventEnum.LOGOUT, new Date().toISOString());
    };

    return (
        <AccessTokenContext.Provider
            value={{
                accessToken: state.accessToken,
                setAccessToken,
                refreshToken,
                isAccessTokenValid,
                fireLoginEvent,
                fireLogoutEvent,
                clearAutomaticRefreshTokenInterval
            }}
        >
            {children}
        </AccessTokenContext.Provider>
    );
};

export const useAccessTokenService = () => {
    const context = useContext(AccessTokenContext);
    if (context === undefined) {
        throw new Error('useAccessTokenService must be used with a AccessTokenProvider');
    }
    return context;
};
