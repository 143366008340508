import { StoreActionProps } from '.';
import { ErrorType, GetPromoCode, PromoCode } from '../types';
import { BlackListedEmailAttributes, GetBlackListedEmail } from '../types/blackListedEmail';

const getAllBlackListedEmails = async (props: StoreActionProps): Promise<GetBlackListedEmail[]> => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/manage-black-listed-email/get-all`);
        const response = apiResponse.parsedBody;
        return response.data;
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return [];
    }
};

const deleteMultipleBlackListedEmails = async (props: StoreActionProps, ids: number[]) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.post(`/api/admin/manage-black-listed-email/delete`, ids);
        const response = apiResponse.parsedBody;

        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

const updateBlackListedEmail = async (props: StoreActionProps, data: BlackListedEmailAttributes) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.post(`/api/admin/manage-black-listed-email/update/${data.id}`, data);
        const response = apiResponse.parsedBody;

        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return false;
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });
        return true;
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return false;
    }
};

const createBlackListedEmail = async (props: StoreActionProps, data: BlackListedEmailAttributes) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.post(`/api/admin/manage-black-listed-email/create`, data);
        const response = apiResponse.parsedBody;

        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return false;
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });
        return true;
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return false;
    }
};

const blackListedEmailsAction = (props?: StoreActionProps) => {
    if (!props) {
        return null;
    }
    return {
        getAllBlackListedEmails: () => getAllBlackListedEmails(props),
        createBlackListedEmail: (data: BlackListedEmailAttributes) => createBlackListedEmail(props, data),
        updateBlackListedEmail: (data: BlackListedEmailAttributes) => updateBlackListedEmail(props, data),
        deleteMultipleBlackListedEmails: (data: number[]) => deleteMultipleBlackListedEmails(props, data)
    };
};

export default blackListedEmailsAction;

export interface BlackListedEmailsAction {
    blackListedEmailsAction: typeof blackListedEmailsAction;
}
