import React from 'react';
import clsx from 'clsx';

import Button, { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

export type StatusButtonOptions = {
    color: 'warning' | 'danger' | 'active';
    text: string;
    type: string;
};

type Props = {
    options: StatusButtonOptions[];
    type?: string | null;
    onClick?: () => void;
};

const useStyles = makeStyles((theme) => ({
    status: {
        pointerEvents: 'none'
    },
    danger: {
        color: theme.palette.error.main
    },
    active: {
        color: theme.palette.success.main
    },
    warning: {
        color: theme.palette.warning.main
    }
}));

const StatusButton = (props: Props) => {
    const buttonProps: ButtonProps = {
        variant: 'outlined',
        color: 'inherit',
        size: 'small'
    };
    const { options, type } = props;
    const classes = useStyles();

    const requiredType = options.find((option) => option.type === type);

    if (requiredType) {
        return (
            <Button
                {...buttonProps}
                className={clsx(classes.status, classes[requiredType.color])}
                onClick={props.onClick}
            >
                {requiredType.text}
            </Button>
        );
    }

    return (
        <Button {...buttonProps} className={clsx(classes.status, classes.active)} onClick={props.onClick}>
            Active
        </Button>
    );
};

export default StatusButton;
