import { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Box, FormControlLabel, InputAdornment, Switch, Tooltip, makeStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { B2BSubscriptionPlan } from '../../types';
import { formatDinero, getDinero, validateNumber } from '../../utilities';
import { AppTextField } from '../common/app-textfield';
import { manageUserEditFormUseStyles } from '../manage-user/editForm';
import { Alert } from '@material-ui/lab';
import useAdminStyles from '../styles';

const useSubscriptionStyles = makeStyles({
    datepicker: {
        width: '100%',
        '& label': {
            top: -2
        },
        '& input': {
            paddingTop: 23,
            paddingBottom: 7
        }
    },
    planToolTip: {
        marginLeft: 32
    },
    infoIcon: {
            marginLeft: 4,
            fontSize: 16
    },
});

type Props = {
    plan: B2BSubscriptionPlan | null;
    onCancel: () => void;
    onSave: (plan: B2BSubscriptionPlan) => Promise<void>;
};

type State = {
    isSaving: boolean;
    plan: Pick<B2BSubscriptionPlan, 'name' | 'subscriptionFeeInterval' | 'isActive'> & {
        subscriptionFeePrice: string;
        orderFee: string;
        giftCardFee: string;
        cardCreationLimit: string | null;
        cardCreationFee: string | null;
        sendIncentiveLimit: string | null;
        incentiveFee: string | null;
    };
    errors: {
        [key in keyof B2BSubscriptionPlan]?: string | null;
    };
};

const DEFAULT_PLAN: State['plan'] = {
    name: '',
    subscriptionFeeInterval: 0,
    subscriptionFeePrice: '',
    orderFee: '',
    giftCardFee: '',
    cardCreationLimit: null,
    cardCreationFee: null,
    sendIncentiveLimit: '100',
    incentiveFee: null,
    isActive: true
};

const DEFAULT_STATE: State = {
    isSaving: false,
    plan: DEFAULT_PLAN,
    errors: {
        name: null,
        subscriptionFeeInterval: null,
        subscriptionFeePrice: null,
        orderFee: null,
        giftCardFee: null
    }
};

export const ManageB2BSubcriptionEditForm = (props: Props) => {
    const classes = manageUserEditFormUseStyles();
    const planStyleClasses = useSubscriptionStyles();
    const adminClasses = useAdminStyles();
    const { onCancel, onSave } = props;

    const [state, setState] = useState<State>(DEFAULT_STATE);

    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            plan: {
                name: props.plan?.name || '',
                subscriptionFeeInterval: props.plan?.subscriptionFeeInterval ?? DEFAULT_PLAN.subscriptionFeeInterval,
                subscriptionFeePrice: props.plan?.subscriptionFeePrice?.toString() || DEFAULT_PLAN.subscriptionFeePrice,
                orderFee: props.plan?.orderFee?.toString() || DEFAULT_PLAN.orderFee,
                giftCardFee: props.plan?.giftCardFee?.toString() || DEFAULT_PLAN.giftCardFee,
                cardCreationFee: props.plan?.cardCreationFee?.toString() || DEFAULT_PLAN.cardCreationFee,
                cardCreationLimit: props.plan?.cardCreationLimit?.toString() || DEFAULT_PLAN.cardCreationLimit,
                sendIncentiveLimit: props.plan?.sendIncentiveLimit?.toString() || DEFAULT_PLAN.sendIncentiveLimit,
                incentiveFee: props.plan?.incentiveFee?.toString() || DEFAULT_PLAN.incentiveFee,
                isActive: props.plan?.isActive ?? true
            }
        }));
    }, [props.plan]);

    const formErrors = (plan: Partial<State['plan']>) => {
        const errors: State['errors'] = {
            name: null,
            subscriptionFeeInterval: null,
            subscriptionFeePrice: null,
            orderFee: null,
            giftCardFee: null
        };

        if (!plan?.name || plan.name.length <= 0) {
            errors.name = 'Subscription name is required.';
        }

        if (!plan.subscriptionFeeInterval || plan.subscriptionFeeInterval < 1) {
            errors.subscriptionFeeInterval = 'Subscription interval must be greater than 0';
        }

        if (!plan.subscriptionFeePrice || !validateNumber(plan.subscriptionFeePrice)) {
            errors.subscriptionFeePrice = 'Enter a valid number';
        }

        if (!plan.orderFee || !validateNumber(plan.orderFee)) {
            errors.orderFee = 'Enter a valid number';
        }

        if (!plan.giftCardFee || !validateNumber(plan.giftCardFee)) {
            errors.giftCardFee = 'Enter a valid number';
        }

        return {
            isInvalid: Object.values(errors).some((e) => Boolean(e)),
            errors
        };
    };

    const savePlan = async () => {
        const { isInvalid, errors } = formErrors(state.plan);
        if (isInvalid) {
            setState((prevState) => ({ ...prevState, errors }));
            return;
        }

        setState((prevState) => ({
            ...prevState,
            isSaving: true
        }));
        const data: B2BSubscriptionPlan = {
            ...state.plan,
            subscriptionFeePrice: Number(state.plan.subscriptionFeePrice),
            orderFee: Number(state.plan.orderFee),
            giftCardFee: Number(state.plan.giftCardFee),
            cardCreationLimit: Number(state.plan.cardCreationLimit),
            cardCreationFee: Number(state.plan.cardCreationFee),
            sendIncentiveLimit: Number(state.plan.sendIncentiveLimit),
            incentiveFee: Number(state.plan.incentiveFee)
        };
        await onSave(data);
        setState((prevState) => ({ ...prevState, isSaving: false }));
    };
    
    const cardCreationTooltipInfo =
        'Initial number of cards the merchant can create with this plan without incurring Over-Quota Card Fee.';

    const cardCreationFeeTooltipInfo =
            'The cost incurred by the merchant for each additional card created after surpassing the initial limit.';
    
    const incentiveLimitTooltipInfo = 'The maximum number of incentive cards that merchants can send without incurring an incentive fee.';
    
    return (
        <Grid item={true} xs={12} className={adminClasses.form}>
            <Typography className={adminClasses.heading}>
                {(props.plan && 'Update') || 'Create New'} Subscription Plan
            </Typography>

            <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                    <AppTextField
                        className={classes.textField}
                        variant="outlined"
                        size="small"
                        label="Subscription Name"
                        placeholder="Subscription Name"
                        fullWidth={true}
                        value={state.plan?.name}
                        helperText={state.errors.name}
                        error={Boolean(state.errors.name)}
                        onChange={(e) => {
                            const name = e.target.value;
                            const { errors } = formErrors({ name });

                            setState((prevState) => ({
                                ...prevState,
                                plan: { ...prevState.plan, name: e.target.value },
                                errors: {
                                    ...prevState.errors,
                                    name: errors.name
                                }
                            }));
                        }}
                    />
                </Grid>

                <Grid item={true} xs={12} md={6}>
                    <AppTextField
                        className={adminClasses.textField}
                        variant="outlined"
                        size="small"
                        label="Subscription Interval"
                        placeholder="Subscription Interval"
                        fullWidth={true}
                        value={state.plan?.subscriptionFeeInterval}
                        helperText={state.errors.subscriptionFeeInterval}
                        error={Boolean(state.errors.subscriptionFeeInterval)}
                        onChange={(e) => {
                            const subscriptionFeeInterval = parseInt(e.target.value || '0', 10);
                            const { errors } = formErrors({ subscriptionFeeInterval });

                            setState((prevState) => ({
                                ...prevState,
                                plan: { ...prevState.plan, subscriptionFeeInterval },
                                errors: {
                                    ...prevState.errors,
                                    subscriptionFeeInterval: errors.subscriptionFeeInterval
                                }
                            }));
                        }}
                    />
                </Grid>

                <Grid item={true} xs={12} md={6}>
                    <AppTextField
                        className={classes.textField}
                        variant="outlined"
                        size="small"
                        label="Subscription Price"
                        placeholder="Subscription Price"
                        fullWidth={true}
                        value={state.plan?.subscriptionFeePrice}
                        helperText={state.errors.subscriptionFeePrice}
                        error={Boolean(state.errors.subscriptionFeePrice)}
                        onChange={(e) => {
                            const subscriptionFeePrice = e.target.value;
                            const { errors } = formErrors({ subscriptionFeePrice });

                            setState((prevState) => ({
                                ...prevState,
                                plan: { ...prevState.plan, subscriptionFeePrice },
                                errors: {
                                    ...prevState.errors,
                                    subscriptionFeePrice: errors.subscriptionFeePrice
                                }
                            }));
                        }}
                    />
                </Grid>

                {Boolean(state.plan.subscriptionFeeInterval) &&
                    Boolean(state.plan.subscriptionFeePrice) &&
                    !state.errors.subscriptionFeeInterval &&
                    !state.errors.subscriptionFeePrice && (
                        <Grid item xs={12} style={{ marginBottom: 16 }}>
                            <Alert severity="info">
                                User will pay {formatDinero(getDinero(+state.plan.subscriptionFeePrice))} every{' '}
                                {state.plan.subscriptionFeeInterval} month(s)
                            </Alert>
                        </Grid>
                    )}

                <Grid item={true} xs={12} md={6}>
                    <AppTextField
                        className={classes.textField}
                        variant="outlined"
                        size="small"
                        label="Order Fee $"
                        placeholder="Order Fee"
                        fullWidth={true}
                        value={state.plan?.orderFee}
                        helperText={state.errors.orderFee}
                        error={Boolean(state.errors.orderFee)}
                        onChange={(e) => {
                            const orderFee = e.target.value;
                            const { errors } = formErrors({ orderFee });

                            setState((prevState) => ({
                                ...prevState,
                                plan: { ...prevState.plan, orderFee },
                                errors: {
                                    ...prevState.errors,
                                    orderFee: errors.orderFee
                                }
                            }));
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                    />
                </Grid>

                <Grid item={true} xs={12} md={6}>
                    <AppTextField
                        className={classes.textField}
                        variant="outlined"
                        size="small"
                        label="Gift Card Fee %"
                        placeholder="Gift Card Fee"
                        fullWidth={true}
                        value={state.plan?.giftCardFee}
                        helperText={state.errors.giftCardFee}
                        error={Boolean(state.errors.giftCardFee)}
                        onChange={(e) => {
                            const giftCardFee = e.target.value;
                            const { errors } = formErrors({ giftCardFee });

                            setState((prevState) => ({
                                ...prevState,
                                plan: { ...prevState.plan, giftCardFee },
                                errors: {
                                    ...prevState.errors,
                                    giftCardFee: errors.giftCardFee
                                }
                            }));
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">%</InputAdornment>
                        }}
                    />
                </Grid>

                <Grid item={true} xs={12} md={6}>
                    <AppTextField
                        className={classes.textField}
                        variant="outlined"
                        size="small"
                        label="Card Creation Quota"
                        placeholder="Card Creation Quota"
                        fullWidth={true}
                        value={state.plan?.cardCreationLimit}
                        helperText={state.errors.cardCreationLimit}
                        error={Boolean(state.errors.cardCreationLimit)}
                        onChange={(e) => {
                            const cardCreationLimit = e.target.value;
                            const { errors } = formErrors({ cardCreationLimit });

                            setState((prevState) => ({
                                ...prevState,
                                plan: { ...prevState.plan, cardCreationLimit },
                                errors: {
                                    ...prevState.errors,
                                    cardCreationLimit: errors.cardCreationLimit
                                }
                            }));
                        }}
                        InputProps={{
                            endAdornment: (
                                <Tooltip
                                    leaveTouchDelay={3000}
                                    enterTouchDelay={50}
                                    placement="top"
                                    title={cardCreationTooltipInfo}
                                    classes={{ tooltipPlacementTop: planStyleClasses.planToolTip }}
                                >
                                    <InfoIcon className={planStyleClasses.infoIcon} />
                                </Tooltip>
                            )
                        }}
                    />
                </Grid>

                <Grid item={true} xs={12} md={6}>
                    <AppTextField
                        className={classes.textField}
                        variant="outlined"
                        size="small"
                        label="Over-Quota Card Fee $"
                        placeholder="Over-Quota Card Fee"
                        fullWidth={true}
                        value={state.plan?.cardCreationFee}
                        helperText={state.errors.cardCreationFee}
                        error={Boolean(state.errors.cardCreationFee)}
                        onChange={(e) => {
                            const cardCreationFee = e.target.value;
                            const { errors } = formErrors({ cardCreationFee });

                            setState((prevState) => ({
                                ...prevState,
                                plan: { ...prevState.plan, cardCreationFee },
                                errors: {
                                    ...prevState.errors,
                                    cardCreationFee: errors.cardCreationFee
                                }
                            }));
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            endAdornment: (
                                <Tooltip
                                    leaveTouchDelay={3000}
                                    enterTouchDelay={50}
                                    placement="top"
                                    title={cardCreationFeeTooltipInfo}
                                    classes={{ tooltipPlacementTop: planStyleClasses.planToolTip }}
                                >
                                    <InfoIcon className={planStyleClasses.infoIcon} />
                                </Tooltip>
                            )
                        }}
                    />
                </Grid>

                {state.plan?.cardCreationLimit && state.plan?.cardCreationFee &&
                    !state.errors.cardCreationLimit &&
                    !state.errors.cardCreationFee && (
                        <Grid item xs={12} style={{ marginBottom: 16 }}>
                            <Alert severity="info">
                             Additional card(s) created beyond the limit of {state.plan.cardCreationLimit} will incur {formatDinero(getDinero(+state.plan.cardCreationFee))} fee per card
                            </Alert>
                        </Grid>
                    )}

                <Grid item={true} xs={12} md={6}>
                    <AppTextField
                        className={classes.textField}
                        variant="outlined"
                        size="small"
                        label="Incentive Card Allowance"
                        placeholder="Incentive Card Allowance"
                        fullWidth={true}
                        value={state.plan?.sendIncentiveLimit}
                        helperText={state.errors.sendIncentiveLimit}
                        error={Boolean(state.errors.sendIncentiveLimit)}
                        onChange={(e) => {
                            const sendIncentiveLimit = e.target.value;
                            const { errors } = formErrors({ sendIncentiveLimit });

                            setState((prevState) => ({
                                ...prevState,
                                plan: { ...prevState.plan, sendIncentiveLimit },
                                errors: {
                                    ...prevState.errors,
                                    sendIncentiveLimit: errors.sendIncentiveLimit
                                }
                            }));
                        }}
                        InputProps={{
                            endAdornment: (
                                <Tooltip
                                    leaveTouchDelay={3000}
                                    enterTouchDelay={50}
                                    placement="top"
                                    title={incentiveLimitTooltipInfo}
                                    classes={{ tooltipPlacementTop: planStyleClasses.planToolTip }}
                                >
                                    <InfoIcon className={planStyleClasses.infoIcon} />
                                </Tooltip>
                            )
                        }}
                    />
                </Grid>

                <Grid item={true} xs={12} md={6}>
                    <AppTextField
                        className={classes.textField}
                        variant="outlined"
                        size="small"
                        label="Incentive Fee %"
                        placeholder="Incentive Fee"
                        fullWidth={true}
                        value={state.plan?.incentiveFee}
                        helperText={state.errors.incentiveFee}
                        error={Boolean(state.errors.incentiveFee)}
                        onChange={(e) => {
                            const incentiveFee = e.target.value;
                            const { errors } = formErrors({ incentiveFee });

                            setState((prevState) => ({
                                ...prevState,
                                plan: { ...prevState.plan, incentiveFee },
                                errors: {
                                    ...prevState.errors,
                                    incentiveFee: errors.incentiveFee
                                }
                            }));
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            endAdornment: (
                                <Tooltip
                                    leaveTouchDelay={3000}
                                    enterTouchDelay={50}
                                    placement="top"
                                    title={cardCreationFeeTooltipInfo}
                                    classes={{ tooltipPlacementTop: planStyleClasses.planToolTip }}
                                >
                                    <InfoIcon className={planStyleClasses.infoIcon} />
                                </Tooltip>
                            )
                        }}
                    />
                </Grid>

                {state.plan?.sendIncentiveLimit && state.plan?.incentiveFee &&
                    !state.errors.sendIncentiveLimit &&
                    !state.errors.incentiveFee && (
                        <Grid item xs={12} style={{ marginBottom: 16 }}>
                            <Alert severity="info">
                             Additional incentive card(s) sent beyond the limit of {state.plan.sendIncentiveLimit} will incur a fee of {state.plan.incentiveFee}% of card price per card
                            </Alert>
                        </Grid>
                    )}

                <Grid item={true} xs={12} sm={3}>
                    <Box textAlign="left">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={state.plan.isActive}
                                    onChange={(e) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            plan: { ...prevState.plan, isActive: e.target.checked }
                                        }))
                                    }
                                    color="primary"
                                />
                            }
                            label="Is Active"
                        />
                    </Box>
                </Grid>

                <Grid item={true} xs={12} className={adminClasses.footer}>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                            onCancel();
                            setState(DEFAULT_STATE);
                        }}
                        disabled={state.isSaving}
                    >
                        Cancel
                    </Button>

                    <Button
                        type="submit"
                        variant="contained"
                        color="inherit"
                        size="small"
                        disabled={state.isSaving}
                        className={classes.approveButton}
                        onClick={savePlan}
                    >
                        Save
                        {state.isSaving && <CircularProgress className="button-loader" />}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ManageB2BSubcriptionEditForm;
