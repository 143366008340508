import { StoreActionProps } from '.';
import { ErrorType, B2BApiConfig } from '../types';

const getAPIConfig = async (props: StoreActionProps, userId: number) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/manage-business-user/${userId}/api-config`);
        const response = apiResponse.parsedBody;
        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        return response.data as B2BApiConfig[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

const toggleAPIConfigStatus = async (props: StoreActionProps, userId: number, apiKey: string) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.post(
            `/api/admin/manage-business-user/${userId}/api-config/toggle-active-status`,
            {apiKey}
        );
        const response = apiResponse.parsedBody;
        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });

        return response.data as B2BApiConfig;
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

const resetAPIConfig = async (props: StoreActionProps, userId: number, apiKey: string) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.post(`/api/admin/manage-business-user/${userId}/api-config/reset`, {apiKey});
        const response = apiResponse.parsedBody;
        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });

        return response.data as B2BApiConfig;
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

const b2bApiConfigAction = (props?: StoreActionProps) => {
    if (!props) {
        return null;
    }

    return {
        getAPIConfig: (userId: number) => getAPIConfig(props, userId),
        toggleAPIConfigStatus: (userId: number, apiKey: string) => toggleAPIConfigStatus(props, userId, apiKey),
        resetAPIConfig: (userId: number, apiKey: string) => resetAPIConfig(props, userId, apiKey)
    };
};

export default b2bApiConfigAction;

export interface B2BApiConfigAction {
    b2bApiConfigAction: typeof b2bApiConfigAction;
}
