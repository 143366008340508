import { StoreActionProps } from '.';
import { ErrorType, PaginationData, UserAccount, UserInfluencerAccountAttributes } from '../types';

export type PaginationUserDataResponse = {
    users: UserInfluencerAccountAttributes[];
    totalRecords: number;
};

const getAllUsers = async (props: StoreActionProps, queryData: PaginationData) => {
    const { apiService, toasterContext } = props;
    const { page, rowsPerPage, searchText } = queryData;

    try {
        const apiResponse = await apiService.get(
            `/api/admin/manage-user/get-all?page=${page}&rowsPerPage=${rowsPerPage}&searchText=${searchText}`
        );
        const response = apiResponse.parsedBody;
        return {
            users: response.data.users as UserInfluencerAccountAttributes[],
            totalRecords: response.data.count
        } as PaginationUserDataResponse;
    } catch (e: ErrorType) {
        toasterContext.setToaster({ isOpen: true, message: e.message, severity: 'error' });
        return { users: [], totalRecords: 0 } as PaginationUserDataResponse;
    }
};

const getUserByUserId = async (props: StoreActionProps, userId: number) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/manage-user/${userId}`);
        const response = apiResponse.parsedBody;
        return response.data.user as UserInfluencerAccountAttributes;
    } catch (e: ErrorType) {
        toasterContext.setToaster({ isOpen: true, message: e.message, severity: 'error' });
        return null;
    }
};

const updateUsers = async (props: StoreActionProps, users: Partial<UserAccount>[]) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.post(`/api/admin/manage-user/update`, { users });
        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });

        return response.data.users as UserInfluencerAccountAttributes[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({ isOpen: true, message: e.message, severity: 'error' });
        return null;
    }
};

const updateUserFraudCheck = async (props: StoreActionProps, userId: number) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.put(`/api/admin/manage-user/${userId}/check-fraud`, {});
        const response = apiResponse.parsedBody;
        if (!response) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }
        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'warning'
            });
            return null;
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });
    } catch (e: ErrorType) {
        toasterContext.setToaster({ isOpen: true, message: e.message, severity: 'error' });
    }
};

const manageUserAction = (props?: StoreActionProps) => {
    if (!props) {
        return null;
    }

    return {
        getAllUsers: (queryData: PaginationData) => getAllUsers(props, queryData),
        getUserByUserId: (userId: number) => getUserByUserId(props, userId),
        updateUsers: (users: Partial<UserAccount>[]) => updateUsers(props, users),
        updateUserFraudCheck: (userId: number) => updateUserFraudCheck(props, userId)
    };
};

export default manageUserAction;

export interface ManageUserAction {
    manageUserAction: typeof manageUserAction;
}
