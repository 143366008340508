import { PaymentGatewayFeeConstantTypeEnum } from "./constants";
import { CashbackSlab } from "./eGifter";

export type BreakEvenCostType = Record<typeof PaymentGatewayFeeConstantTypeEnum[number], number>;
export const GifterCardTypeEnum = ['B2C', 'B2B'] as const;
export type SavedProducts = {
    id: number;
    productId: string;
    name: string;
    giftCardType: typeof GifterCardTypeEnum[number];
    countryCode: string;
    breakEvenCost: BreakEvenCostType;
    cashbackSlabs?: CashbackSlab;
    createdAt: Date;
    updatedAt: Date;
}

export type SyncB2BGiftCardTranslationParameters = {
    translateDescription: boolean;
    translateTerms: boolean;
    lang: string;
};
export type SyncB2BGiftCardTranslationsOptions = {
    parameters: SyncB2BGiftCardTranslationParameters[];
    /**
     * If the productIds is null or empty, all the gift cards will be translated
     */
    productIds?: string[] | null;
};
