import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { useNotFoundStyles } from './not-found.styles';

import storeContext from '../../contexts/store-context';

import NotFoundSvg from '../../svg/NotFoundSvg';

export const NotFound = (props) => {
    const classes = useNotFoundStyles();
    const history = useHistory();
    const { appAction } = storeContext();

    useEffect(() => {
        appAction()?.hideHeader();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Grid item={true} xs={12} className={classes.root}>
            <Grid className={classes.inner}>
                <Typography variant="h2" className={clsx(classes.label, 'fontFamilyWorkSans')}>
                    404
                </Typography>
                <Typography variant="h1" className={clsx(classes.oops, 'fontFamilyWorkSans')}>
                    oops! page not found
                </Typography>
                <Typography variant="h2" className={classes.message}>
                    Perhaps you can try to refresh the page, sometimes it works
                </Typography>
                <Button variant="outlined" onClick={(e) => history.push('/')}>
                    back to home
                </Button>
            </Grid>
            <NotFoundSvg className={classes.svg} />
        </Grid>
    );
};

NotFound.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
};
