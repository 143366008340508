import React, { createContext, useContext } from 'react';
import Snackbar, { SnackbarOrigin, SnackbarCloseReason } from '@material-ui/core/Snackbar';
import MuiAlert, { Color } from '@material-ui/lab/Alert';

interface State {
    isOpen: boolean;
    message: string | null;
    severity: Color;
    autoHideDuration: number;
    anchorOrigin: SnackbarOrigin;
}

export type ToasterContextProps = {
    Toasters: () => JSX.Element;
    setToaster: (newState: Partial<State>) => void;
    closeToaster: () => void;
};

const DEFAULT_STATE: State = {
    isOpen: false,
    message: '',
    severity: 'success',
    autoHideDuration: 3000,
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
    }
};

type Props = { children?: React.ReactNode };
export const ToasterContext = createContext<ToasterContextProps | undefined>(undefined);
export const ToasterProvider: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
    const [state, setState] = React.useState<State>(DEFAULT_STATE);

    const handleClose = (reason: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }
        setState(DEFAULT_STATE);
    };

    const closeToaster = () => {
        setState(DEFAULT_STATE);
    };

    const Toasters = () => {
        const {
            isOpen,
            message,
            severity,
            autoHideDuration = DEFAULT_STATE.autoHideDuration,
            anchorOrigin = DEFAULT_STATE.anchorOrigin
        } = state;

        return (
            <Snackbar
                open={isOpen}
                autoHideDuration={autoHideDuration}
                onClose={(e, reason) => handleClose(reason)}
                anchorOrigin={anchorOrigin}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={(e) => closeToaster()}
                    severity={severity}
                    style={{ textAlign: 'center' }}
                >
                    {message}
                </MuiAlert>
            </Snackbar>
        );
    };

    const setToaster = (newState: Partial<State>) => {
        setState({ ...state, ...newState });
    };

    return (
        <ToasterContext.Provider value={{ setToaster, Toasters, closeToaster }}>
            {props.children}
        </ToasterContext.Provider>
    );
};

export const useToasterData = () => {
    const context = useContext(ToasterContext);
    if (context === undefined) {
        throw new Error('useToasterData must be used with a ToasterProvider');
    }
    return context;
};
