import { StoreActionProps } from '.';
import { ErrorType } from '../types';
import { GetGiftCardCategoryAdmin, GiftCardCategory } from '../types/category';
import { GifterCardTypeEnum } from '../types/savedProducts';

const getAllCategories = async (
    props: StoreActionProps,
    giftCardType: typeof GifterCardTypeEnum[number],
    countryCode: string
) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(
            `/api/admin/categories/get-all?giftCardType=${giftCardType}&countryCode=${countryCode}`
        );
        const response = apiResponse.parsedBody;
        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        return response.data as GetGiftCardCategoryAdmin[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

const exportCategories = async (
    props: StoreActionProps,
    giftCardType: typeof GifterCardTypeEnum[number],
    countryCode: string,
    columns: string[]
) => {
    const { apiService, toasterContext } = props;

    try {
        await apiService.downloadFile(
            `/api/admin/categories/export?giftCardType=${giftCardType}&countryCode=${countryCode}&columns=${columns.join(',')}`
        );
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
    }
};

const updateProductsForCategoryViaCSV = async (
    props: StoreActionProps,
    giftCardType: typeof GifterCardTypeEnum[number],
    countryCode: string,
    formData: FormData
) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.postFormData(
            `/api/admin/categories/update-by-csv?giftCardType=${giftCardType}&countryCode=${countryCode}`,
            formData
        );
        const response = apiResponse.parsedBody;

        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
    }
};

const getProductsByCategoryKeys = async (
    props: StoreActionProps,
    options: { keys?: string[]; giftCardType: typeof GifterCardTypeEnum[number]; countryCode: string }
) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.post(`/api/admin/categories/get-products`, options);
        const response = apiResponse.parsedBody;
        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        return response.data as GetGiftCardCategoryAdmin[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

const saveCategory = async (props: StoreActionProps, data: GiftCardCategory) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.post(`/api/admin/categories/${(data?.id && 'update') || 'create'}`, data);
        const response = apiResponse.parsedBody;
        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });

        return response.data as GetGiftCardCategoryAdmin;
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

const updateAllCategories = async (props: StoreActionProps, data: GiftCardCategory[]) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.put(`/api/admin/categories/update-all`, data);
        const response = apiResponse.parsedBody;
        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });

        return response.data as GetGiftCardCategoryAdmin[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

const categoryAction = (props?: StoreActionProps) => {
    if (!props) {
        return null;
    }

    return {
        getAllCategories: (giftCardType: typeof GifterCardTypeEnum[number], countryCode: string) =>
            getAllCategories(props, giftCardType, countryCode),
        getProductsByCategoryKeys: (options: {
            keys?: string[];
            giftCardType: typeof GifterCardTypeEnum[number];
            countryCode: string;
        }) => getProductsByCategoryKeys(props, options),
        saveCategory: (data: GiftCardCategory) => saveCategory(props, data),
        updateAllCategories: (data: GiftCardCategory[]) => updateAllCategories(props, data),
        exportCategories: (giftCardType: typeof GifterCardTypeEnum[number], countryCode: string, columns: string[]) =>
            exportCategories(props, giftCardType, countryCode, columns),
        updateProductsForCategoryViaCSV: (giftCardType: typeof GifterCardTypeEnum[number], countryCode: string, formData: FormData) =>
            updateProductsForCategoryViaCSV(props, giftCardType, countryCode, formData)
    };
};

export default categoryAction;

export interface CategoryAction {
    categoryAction: typeof categoryAction;
}
