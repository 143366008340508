import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, Grid } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        width: 272,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: '16px 0',
        '& button:last-child': {
            marginTop: 16
        }
    }
});

type Props = {
    onConfirm: () => void;
    onCancel: () => void;
    isLoading: boolean;
};

const ConfirmOTPComponent = (props: Props) => {
    const { onCancel, isLoading } = props;
    const classes = useStyles();

    const onConfirmClick = () => {
        props.onConfirm();
    };

    return (
        <Grid className={classes.root}>
            <Button fullWidth={true} variant="contained" color="primary" onClick={onConfirmClick} disabled={isLoading}>
                CONFIRM
                {isLoading && <CircularProgress className="button-loader" />}
            </Button>
            <Button fullWidth={true} variant="outlined" onClick={onCancel}>
                BACK
            </Button>
        </Grid>
    );
};

export default ConfirmOTPComponent;
