import React from 'react';
import { JsonEditor } from 'json-edit-react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export const fraudDataStyles = makeStyles((theme) => ({
    form: {
        padding: 16,
        boxShadow: theme.shadows[4],
        borderRadius: 8,
        margin: '24px auto',
        '& $heading': {
            textAlign: 'left',
            fontSize: 20,
            fontWeight: 600,
            margin: '12px 0'
        }
    },
    mainContainer: {
        [theme.breakpoints.up('lg')]: {
            display: 'flex'
        },
        '& $inner': {
            [theme.breakpoints.up('lg')]: {
                display: 'flex'
            }
        }
    },
    details: {
        textAlign: 'left',
        '& > div': {
            marginBottom: 8
        }
    },
    staticFields: {
        display: 'flex',
        flexFlow: 'column',
        textAlign: 'left',
        '& > span': {
            '&:first-child': {
                fontWeight: 900,
                marginRight: 12
            }
        },
        '& > input': {
            padding: 0,
            textAlign: 'left',
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: '1.5',
            letterSpacing: '0.00938em'
        }
    },
    inner: {},
    heading: {}
}));

type Props = {
    emailDataJson?: Record<string, unknown> | null;
    fraudDataJson?: Record<string, unknown> | null;
    email?: string | null;
    ipAddress?: string | null;
};

export const FraudDetails = (props: Props) => {
    const classes = fraudDataStyles();
    const { emailDataJson, email, fraudDataJson, ipAddress } = props;

    // eslint-disable-next-line @typescript-eslint/ban-types
    const emailResponse = emailDataJson as object;
    // eslint-disable-next-line @typescript-eslint/ban-types
    const ipResponse = fraudDataJson as object;

    return (
        <Grid item={true} xs={12} className={classes.form}>
            <Grid container={true} alignItems="center" justifyContent="space-between">
                <Typography className={classes.heading}>Fraud Validation Details:</Typography>
            </Grid>

            {(email || emailResponse) && (
                <Grid item={true} xs={12} className={classes.details}>
                    {email && (
                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Email:
                            </Typography>
                            <Typography component="span">{email}</Typography>
                        </Grid>
                    )}

                    {emailResponse && (
                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Email Validation Response:
                            </Typography>
                            <JsonEditor
                                data={emailResponse}
                                collapse
                                restrictAdd
                                restrictDelete
                                restrictEdit
                                restrictTypeSelection
                            />
                        </Grid>
                    )}
                </Grid>
            )}

            {(ipAddress || ipResponse) && (
                <Grid item={true} xs={12} className={classes.details}>
                    {ipAddress && (
                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                IP Address:
                            </Typography>
                            <Typography component="span">{ipAddress}</Typography>
                        </Grid>
                    )}

                    {ipResponse && (
                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                IP Validation Response:
                            </Typography>
                            <JsonEditor
                                data={ipResponse}
                                collapse
                                restrictAdd
                                restrictDelete
                                restrictEdit
                                restrictTypeSelection
                            />
                        </Grid>
                    )}
                </Grid>
            )}
        </Grid>
    );
};

export default FraudDetails;
