import { PrivilegeTypeEnum, PrivilegeAccessTypeEnum, AdminUser } from '../types';
import { IsAdminUser } from './userRoles';

const HasPermission = (
    user: AdminUser | null,
    privilege: typeof PrivilegeTypeEnum[number],
    access: typeof PrivilegeAccessTypeEnum[number]
): boolean => {
    const isAdminUser = IsAdminUser(user);
    const canAccess = !!user?.privileges?.[privilege]?.includes(access);
    return !!(isAdminUser && canAccess);
};

export default HasPermission;
