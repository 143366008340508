import { StoreActionProps } from '.';
import { ErrorType, GetInfluencerReferralPaymentAttributes, InfluencerReferralPaymentAttributes } from '../types';

const getAll = async (props: StoreActionProps): Promise<GetInfluencerReferralPaymentAttributes[]> => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/manage-influencer-referral-payment/get-all`);
        const response = apiResponse.parsedBody;

        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return [];
        }
        return response.data as GetInfluencerReferralPaymentAttributes[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return [];
    }
};

const updateAll = async (props: StoreActionProps, data: InfluencerReferralPaymentAttributes[]) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.put(`/api/admin/manage-influencer-referral-payment/update-all`, data);
        const response = apiResponse.parsedBody;

        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });
        return response.data as GetInfluencerReferralPaymentAttributes[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

const influencerReferralPaymentAction = (props?: StoreActionProps) => {
    if (!props) {
        return null;
    }
    return {
        getAll: () => getAll(props),
        updateAll: (data: InfluencerReferralPaymentAttributes[]) => updateAll(props, data)
    };
};

export default influencerReferralPaymentAction;

export interface InfluencerReferralPaymentAction {
    influencerReferralPaymentAction: typeof influencerReferralPaymentAction;
}
