/* eslint-disable */

import React, { createContext, useContext } from 'react';
import { AccessTokenContextProps, useAccessTokenService } from './access-token-context';
import { ErrorType } from '../types';

// TODO remove any MOOLA-276
export interface HttpResponse<T = any> extends Response {
    parsedBody?: T;
}

export type HttpRequest = Partial<Omit<RequestInfo, string>>;

export interface ApiServiceContextProps {
    post: <T>(url: string, body: T, options?: HttpRequest) => Promise<any>;
    postFormData: (url: string, body: FormData, options?: HttpRequest) => Promise<any>;
    put: <T>(url: string, body: T, options?: HttpRequest) => Promise<any>;
    get: (url: string, options?: HttpRequest) => Promise<any>;
    downloadFile: (url: string, filename?: string | null, options?: HttpRequest) => Promise<any>;
}

export const ApiServiceContext = createContext<ApiServiceContextProps | undefined>(undefined);

export const prepareHeaders = (accessToken?: string | null) => {
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Credentials', 'true');

    if (accessToken) {
        headers.append('authorization', accessToken);
    }
    return headers;
};

export const prepareFormDataHeaders = (accessToken?: string | null) => {
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Access-Control-Allow-Credentials', 'true');

    if (accessToken) {
        headers.append('authorization', accessToken);
    }
    return headers;
};

const checkStatus = (response: HttpResponse, accessTokenService: AccessTokenContextProps) => {
    if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response);
    }

    /**
     * jwt access_token authentication failed
     * or
     * refresh_token has been cleared from cookies, or verification failed
     */
    if (response.status === 401) {
        accessTokenService.fireLogoutEvent();
        accessTokenService.clearAutomaticRefreshTokenInterval();
        return Promise.reject({ message: `Authentication Failed! Please sign-in again.` });
    }

    return Promise.reject({
        message: response.parsedBody?.message || 'Internal server error occurred! Please contact administrator.'
    });
};

const apiService = async (url: string, request: HttpRequest, accessTokenService: AccessTokenContextProps) => {
    try {
        const response: HttpResponse = await fetch(url, request);
        response.parsedBody = await response.json();
        return checkStatus(response, accessTokenService);
    } catch (e: ErrorType) {
        console.error(e, 'apiService');
    }
};

const apiDownloadService = async (
    url: string,
    request: HttpRequest,
    accessTokenService: AccessTokenContextProps,
    filename?: string | null
) => {
    const response: HttpResponse = await fetch(url, request);
    const blob = await response.blob();
    const objectUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = objectUrl;
    if (!filename) {
        const contentDisposition = response.headers.get('Content-Disposition');
        if (contentDisposition && contentDisposition.includes('filename=')) {
            filename = contentDisposition.split('filename=')[1].split(';')[0].replace(/['"]/g, ''); // Remove quotes if present
        }
    }
    a.download = filename || 'download.csv';
    document.body.appendChild(a);
    a.click();
    a.remove();
    return checkStatus(response, accessTokenService);
};

type Props = { children?: React.ReactNode };
export const ApiServiceProvider: React.FC = (props: React.PropsWithChildren<Props>) => {
    const accessTokenService = useAccessTokenService();

    const post = async <T,>(url: string, body: T, options?: HttpRequest) => {
        const { accessToken } = await accessTokenService.refreshToken();

        const headers = prepareHeaders((accessToken && `Bearer ${accessToken}`) || null);
        const request: HttpRequest = {
            ...options,
            credentials: 'same-origin',
            method: 'POST',
            headers,
            body: JSON.stringify(body)
        };
        return apiService(url, request, accessTokenService);
    };

    const postFormData = async <T,>(url: string, body: T, options?: HttpRequest) => {
        const { accessToken } = await accessTokenService.refreshToken();

        const headers = prepareFormDataHeaders((accessToken && `Bearer ${accessToken}`) || null);
        const request: HttpRequest = {
            ...options,
            credentials: 'same-origin',
            method: 'POST',
            headers,
            body
        };
        return apiService(url, request, accessTokenService);
    };

    const put = async <T,>(url: string, body: T, options?: HttpRequest) => {
        const { accessToken } = await accessTokenService.refreshToken();

        const headers = prepareHeaders((accessToken && `Bearer ${accessToken}`) || null);
        const request: HttpRequest = {
            ...options,
            credentials: 'same-origin',
            method: 'PUT',
            headers,
            body: JSON.stringify(body)
        };
        return apiService(url, request, accessTokenService);
    };

    const get = async (url: string, options?: HttpRequest) => {
        const { accessToken } = await accessTokenService.refreshToken();

        const headers = prepareHeaders((accessToken && `Bearer ${accessToken}`) || null);
        const request: HttpRequest = {
            ...options,
            credentials: 'same-origin',
            method: 'GET',
            headers
        };
        return apiService(url, request, accessTokenService);
    };

    const downloadFile = async (url: string, filename?: string | null, options?: HttpRequest) => {
        const { accessToken } = await accessTokenService.refreshToken();

        const headers = prepareHeaders((accessToken && `Bearer ${accessToken}`) || null);
        const request: HttpRequest = {
            ...options,
            credentials: 'same-origin',
            method: 'GET',
            headers
        };
        return apiDownloadService(url, request, accessTokenService, filename);
    };

    return (
        <ApiServiceContext.Provider value={{ post, get, put, postFormData, downloadFile }}>
            {props.children}
        </ApiServiceContext.Provider>
    );
};

export const useApiService = () => {
    const context = useContext(ApiServiceContext);
    if (context === undefined) {
        throw new Error('useApiService must be used with a ApiServiceProvider');
    }
    return context;
};
