import React, { useState } from 'react';
import { Box, Button } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import ConfirmationDialog from '../common/confirmation-dialog';

type Props = {
    open: boolean;
    isLoading: boolean;
    onUpload: (file: File) => void;
    onConfirm: () => void;
    onClose: () => void;
};

const CsvUploadModal = (props: Props) => {
    const [file, setFile] = React.useState<File | null>(null);
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (!file) {
            return;
        }
        setFile(file);
        props.onUpload(file);
    };

    const onClose = () => {
        setFile(null);
        props.onClose();
    };

    const onConfirm = () => {
        setFile(null);
        props.onConfirm();
    };

    return (
        <ConfirmationDialog
            header="Update Products For Each Category using CSV"
            subHeader={
                <>
                    The CSV should have the following format:
                    <br />
                    <strong>CATEGORY_ID, PRODUCT_IDS (comma separated)</strong>
                    <Box mt={1} mb={4}>
                        <Button href="/csv/sample-category-product.csv" variant="outlined" size="small">
                            Downlod Sample CSV
                        </Button>
                    </Box>
                </>
            }
            isLoading={props.isLoading}
            additionalContent={
                <>
                    <input
                        type="file"
                        accept=".csv"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        id="upload-csv"
                    />
                    <label htmlFor="upload-csv">
                        <Button variant="contained" color="secondary" component="span" startIcon={<PublishIcon />}>
                            Upload CSV
                        </Button>
                    </label>
                    {file ? (
                        <>
                            <br />
                            {file.name}
                        </>
                    ) : null}
                </>
            }
            open={props.open}
            onClose={onClose}
            onConfirm={onConfirm}
        />
    );
};

export default CsvUploadModal;
