import { StoreActionProps } from '.';
import { ErrorType, GetTheme, ThemeAttributes, ThemeCreateUpdateRequest } from '../types';
import { jsonToFormData } from '../utilities';

const getAllThemes = async (props: StoreActionProps): Promise<GetTheme[] | null> => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/manage-theme/get-all`);
        const response = apiResponse.parsedBody;

        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }
        return response.data;
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

const updateAllThemes = async (props: StoreActionProps, data: ThemeAttributes[]) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.put(`/api/admin/manage-theme/update-all`, data);
        const response = apiResponse.parsedBody;

        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });
        return response.data as GetTheme[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

const updateTheme = async (props: StoreActionProps, data: ThemeCreateUpdateRequest) => {
    const { apiService, toasterContext } = props;

    const formData = jsonToFormData(data);

    try {
        const apiResponse = await apiService.postFormData(`/api/admin/manage-theme/update`, formData);
        const response = apiResponse.parsedBody;

        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });
        return response.data as GetTheme[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
    }
};

const createTheme = async (props: StoreActionProps, data: ThemeCreateUpdateRequest) => {
    const { apiService, toasterContext } = props;

    const formData = jsonToFormData(data);

    try {
        const apiResponse = await apiService.postFormData(`/api/admin/manage-theme/create`, formData);
        const response = apiResponse.parsedBody;

        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });
        return response.data as GetTheme[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
    }
};

const themeAction = (props?: StoreActionProps) => {
    if (!props) {
        return null;
    }
    return {
        getAllThemes: () => getAllThemes(props),
        createTheme: (data: ThemeCreateUpdateRequest) => createTheme(props, data),
        updateTheme: (data: ThemeCreateUpdateRequest) => updateTheme(props, data),
        updateAllThemes: (data: ThemeAttributes[]) => updateAllThemes(props, data)
    };
};

export default themeAction;

export interface ThemeAction {
    themeAction: typeof themeAction;
}
