import { StoreActionProps } from '.';
import { ConstantType, ErrorType } from '../types';


const addB2CWhitelistedCountry = async (props: StoreActionProps, country: string) => {
    const { apiService, toasterContext } = props;
    try {
        const apiResponse = await apiService.post(`/api/admin/manage-allowed-registration-countries/add`, {
            country
        });
        const response = apiResponse.parsedBody;

        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return [];
        }
        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });
        return response.data as string[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return [];
    }
};

const getAllB2CWhitelistedCountries = async (props: StoreActionProps) => {
    const { apiService, toasterContext } = props;
    try {
        const apiResponse = await apiService.get(`/api/admin/manage-allowed-registration-countries/get-all`);
        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return [];
        }
        return response.data as string[];;
    } catch (e: ErrorType) {
        return [];
    }
};

const removeB2CWhitelistedCountries = async (
    props: StoreActionProps,
    countries: string[]
) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.post(`/api/admin/manage-allowed-registration-countries/delete`, {
            countries
        });
        const response = apiResponse.parsedBody;

        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return [];
        }
        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });
        return response.data as string[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return [];
    }
};

const manageB2CWhitelistedCountriesAction = (props?: StoreActionProps) => {
    if (!props) {
        return null;
    }

    return {
        getAllB2CWhitelistedCountries: () => getAllB2CWhitelistedCountries(props),
        removeB2CWhitelistedCountries: (countries: string[]) =>
            removeB2CWhitelistedCountries(props, countries),
        addB2CWhitelistedCountry: (country: string) =>
            addB2CWhitelistedCountry(props, country)
    };
};

export default manageB2CWhitelistedCountriesAction;

export interface ManageB2CWhitelistedCountriesAction {
    manageB2CWhitelistedCountriesAction: typeof manageB2CWhitelistedCountriesAction;
}
