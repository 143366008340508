import React from 'react';
import { Box, Button, InputAdornment, TextField } from '@material-ui/core';
import ConfirmationDialog from '../common/confirmation-dialog';
import { useApiService } from '../../contexts/api-service-context';
import { useToasterData } from '../../contexts/toaster-context';
import { formatDinero, getDinero, validateAmount } from '../../utilities';

type Props = {
    userId: number;
    fetchFundBalance: () => Promise<void>;
};

const AddFunds = (props: Props) => {
    const apiService = useApiService();
    const toasterContext = useToasterData();

    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);
    const [amount, setAmount] = React.useState('');

    const isValidAmount = validateAmount(amount);

    const openDialog = () => {
        if (!isValidAmount || !+amount) {
            return;
        }
        setIsDialogOpen(true);
    };

    const closeDialog = () => setIsDialogOpen(false);

    const onConfirm = async () => {
        setIsSaving(true);
        const apiResponse = await apiService.post(
            `/api/admin/manage-fund-transactions/user/${props.userId}/admin-credit`,
            { amount: +amount }
        );
        const response = apiResponse.parsedBody;
        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            closeDialog();
            setIsSaving(false);
            return;
        }
        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });
        await props.fetchFundBalance();
        setAmount('');
        closeDialog();
        setIsSaving(false);
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setAmount(value);
    };

    React.useEffect(() => {
        setAmount('');
    }, [props.userId]);

    return (
        <Box display="flex" alignItems="center" gridGap={8} my={2}>
            <TextField
                label="Add Funds"
                InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                value={amount}
                error={!isValidAmount}
                helperText={!isValidAmount && 'Please enter a valid amount.'}
                onChange={onChange}
            />

            <div>
                <Button variant="contained" type="button" color="primary" onClick={openDialog}>
                    Add Funds
                </Button>
            </div>

            <ConfirmationDialog
                header={'Are you sure?'}
                subHeader={`Click CONFIRM to credit ${formatDinero(getDinero(+amount))}`}
                isLoading={isSaving}
                open={isDialogOpen}
                onClose={closeDialog}
                onConfirm={onConfirm}
            />
        </Box>
    );
};

export default AddFunds;
