import React from 'react';

const DiscoverCardSvg = (props) => {
    return (
        <svg {...props} viewBox="0 0 33 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32 12.3369C32 12.3369 23.5179 18.318 7.9852 21H32V12.3369Z" fill="#F48120" />
            <path
                d="M3.6907 7.34306H2.39905V11.8679H3.6907C4.37354 11.8679 4.86717 11.7034 5.3032 11.3496C5.82151 10.9218 6.12591 10.2801 6.12591 9.61373C6.11768 8.27272 5.12221 7.34306 3.6907 7.34306ZM4.72731 10.7491C4.44759 10.9959 4.09382 11.1111 3.51793 11.1111H3.27934V8.11641H3.51793C4.0856 8.11641 4.43113 8.21513 4.72731 8.4784C5.03171 8.74989 5.2127 9.16947 5.2127 9.60551C5.2127 10.0415 5.03171 10.4776 4.72731 10.7491Z"
                fill="#231F20"
            />
            <path d="M7.40929 7.34306H6.52899V11.8679H7.40929V7.34306Z" fill="#231F20" />
            <path
                d="M9.5648 9.07896C9.03827 8.88151 8.88195 8.74988 8.88195 8.5113C8.88195 8.22335 9.16167 8.00945 9.54012 8.00945C9.80338 8.00945 10.0255 8.1164 10.2476 8.37966L10.7084 7.77909C10.3299 7.45001 9.87743 7.27724 9.37558 7.27724C8.57755 7.27724 7.96052 7.83668 7.96052 8.57711C7.96052 9.20237 8.24847 9.52323 9.07118 9.8194C9.41671 9.94281 9.58948 10.0251 9.67998 10.0744C9.85275 10.1896 9.94324 10.3542 9.94324 10.5434C9.94324 10.9136 9.6553 11.1851 9.2604 11.1851C8.84082 11.1851 8.50351 10.9712 8.29783 10.5845L7.73016 11.1357C8.13329 11.7363 8.62691 11.9996 9.29331 11.9996C10.2065 11.9996 10.8564 11.3908 10.8564 10.5105C10.8729 9.77827 10.5685 9.44918 9.5648 9.07896Z"
                fill="#231F20"
            />
            <path
                d="M11.1444 9.61373C11.1444 10.9465 12.1892 11.9749 13.5303 11.9749C13.9087 11.9749 14.2378 11.9009 14.6327 11.7116V10.675C14.2789 11.0288 13.9663 11.1686 13.5714 11.1686C12.6829 11.1686 12.0494 10.5269 12.0494 9.60551C12.0494 8.73344 12.6993 8.05059 13.5303 8.05059C13.9498 8.05059 14.2707 8.19868 14.6327 8.56067V7.52406C14.246 7.32661 13.9252 7.24434 13.5467 7.24434C12.2222 7.24434 11.1444 8.2974 11.1444 9.61373Z"
                fill="#231F20"
            />
            <path
                d="M21.6421 10.3871L20.4328 7.34306H19.4702L21.3871 11.9831H21.8643L23.8141 7.34306H22.8597L21.6421 10.3871Z"
                fill="#231F20"
            />
            <path
                d="M24.2172 11.8679H26.7183V11.1028H25.0975V9.88523H26.6607V9.12011H25.0975V8.11641H26.7183V7.34306H24.2172V11.8679Z"
                fill="#231F20"
            />
            <path
                d="M30.2147 8.68407C30.2147 7.83669 29.6306 7.35129 28.6105 7.35129H27.3024V11.8762H28.1827V10.058H28.2978L29.5154 11.8762H30.6014L29.1781 9.9675C29.8445 9.82764 30.2147 9.37515 30.2147 8.68407ZM28.4377 9.43274H28.1827V8.05882H28.4542C29.0054 8.05882 29.3015 8.28917 29.3015 8.73344C29.3015 9.18592 29.0054 9.43274 28.4377 9.43274Z"
                fill="#231F20"
            />
            <path
                d="M17.3394 12.0325C18.6707 12.0325 19.7499 10.9533 19.7499 9.62196C19.7499 8.29066 18.6707 7.21143 17.3394 7.21143C16.0081 7.21143 14.9288 8.29066 14.9288 9.62196C14.9288 10.9533 16.0081 12.0325 17.3394 12.0325Z"
                fill="url(#paint0_linear)"
            />
            <path
                opacity="0.65"
                d="M17.3394 12.0325C18.6707 12.0325 19.7499 10.9533 19.7499 9.62196C19.7499 8.29066 18.6707 7.21143 17.3394 7.21143C16.0081 7.21143 14.9288 8.29066 14.9288 9.62196C14.9288 10.9533 16.0081 12.0325 17.3394 12.0325Z"
                fill="url(#paint1_linear)"
            />
            <path
                d="M30.7742 7.51583C30.7742 7.43356 30.7166 7.39243 30.6261 7.39243H30.4944V7.78732H30.5932V7.63101L30.7084 7.78732H30.8235L30.6919 7.62278C30.7413 7.61456 30.7742 7.57342 30.7742 7.51583ZM30.6096 7.57342H30.5932V7.46647H30.6096C30.659 7.46647 30.6837 7.48292 30.6837 7.51583C30.6837 7.55697 30.659 7.57342 30.6096 7.57342Z"
                fill="#231F20"
            />
            <path
                d="M30.6425 7.24434C30.4533 7.24434 30.297 7.40065 30.297 7.58988C30.297 7.7791 30.4533 7.93541 30.6425 7.93541C30.8318 7.93541 30.9881 7.7791 30.9881 7.58988C30.9881 7.40065 30.8318 7.24434 30.6425 7.24434ZM30.6425 7.87782C30.4944 7.87782 30.3628 7.75442 30.3628 7.58988C30.3628 7.43356 30.4862 7.30193 30.6425 7.30193C30.7906 7.30193 30.914 7.43356 30.914 7.58988C30.914 7.74619 30.7906 7.87782 30.6425 7.87782Z"
                fill="#231F20"
            />
            <rect x="1" y="1" width="31" height="20" stroke="black" strokeWidth="0.2" />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="18.5714"
                    y1="11.551"
                    x2="16.9051"
                    y2="8.94201"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F89F20" />
                    <stop offset="0.2502" stopColor="#F79A20" />
                    <stop offset="0.5331" stopColor="#F68D20" />
                    <stop offset="0.6196" stopColor="#F58720" />
                    <stop offset="0.7232" stopColor="#F48120" />
                    <stop offset="1" stopColor="#F37521" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="18.3083"
                    y1="11.5158"
                    x2="15.873"
                    y2="6.75581"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F58720" />
                    <stop offset="0.3587" stopColor="#E16F27" />
                    <stop offset="0.703" stopColor="#D4602C" />
                    <stop offset="0.9816" stopColor="#D05B2E" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default DiscoverCardSvg;
