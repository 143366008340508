import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Typography, Grid, Button, Container, Box } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import useAdminStyles from '../styles';

import { useApiService } from '../../contexts/api-service-context';
import { useUserService } from '../../contexts/user-context';
import storeContext from '../../contexts/store-context';
import HasPermission from '../../utilities/can';
import { joinNameParts } from '../../utilities';
import { ConstantType, ErrorType } from '../../types';
import {
    GetInfluencerReferralPaymentAttributes,
    GetPendingRealizedReferrals,
    InfluencerDetails,
    InfluencerReferralPaymentAttributes
} from '../../types/influencer';
import InfluencerReferralList from './referral-list';
import PaymentConstantForm from './payment-constant-form';
import MakePayment from '../manage-payout/make-payment';
import InfluencerEarnings from './influencer-earnings';
import clsx from 'clsx';
import { FetchPayoutTransactionResponse, PayoutTransactionAttributes } from '../../types/influencerPayout';
import ApprovalStatus from './approval-status';
import InfluencerAccessRevokedAlert from './influencer-revoked-alert';

export const useStyles = makeStyles((theme) => ({
    alert: {
        position: 'sticky',
        top: 10
    }
}));

type State = {
    isLoading: boolean;
    isSavingConstant: boolean;
    activeUser: InfluencerDetails | null;
    activePaymentConstant: GetInfluencerReferralPaymentAttributes | null;
    pendingReferralsRegistrations: GetPendingRealizedReferrals[];
    pendingReferralsTransactions: GetPendingRealizedReferrals[];
    earnings: FetchPayoutTransactionResponse;
    paypalPayoutFee: number;
    referralsSelectedForRegistrationPayment: GetPendingRealizedReferrals[];
    referralsSelectedForTransactionPayment: GetPendingRealizedReferrals[];
};

const ManageInfluencerDetails = () => {
    const adminClasses = useAdminStyles();
    const classes = useStyles();

    const history = useHistory();
    const userService = useUserService();
    const apiService = useApiService();
    const { appAction, influencerReferralPaymentAction, manageInfluencerAction, influencerPayoutAction } =
        storeContext();
    const { influencerUserId } = useParams<{ influencerUserId: string }>();

    const canRead = HasPermission(userService.user, 'MANAGE_INFLUENCER', 'READ');
    const canUpdate = HasPermission(userService.user, 'MANAGE_INFLUENCER', 'UPDATE');
    const canUpdatePaymentConstant = HasPermission(userService.user, 'MANAGE_INFLUENCER_REFERRAL_PAYMENT', 'UPDATE');
    const canCreatePayout = HasPermission(userService.user, 'MANAGE_PAYOUT', 'CREATE');
    const canReadPayout = HasPermission(userService.user, 'MANAGE_PAYOUT', 'READ');

    useEffect(() => {
        if (!canRead) {
            history.push('/dashboard');
            return;
        }
        appAction()?.renderFullHeader();
        init();
    }, []);

    const [state, setState] = useState<State>({
        isLoading: false,
        isSavingConstant: false,
        activeUser: null,
        earnings: [],
        activePaymentConstant: null,
        pendingReferralsRegistrations: [],
        pendingReferralsTransactions: [],
        paypalPayoutFee: 0,
        referralsSelectedForRegistrationPayment: [],
        referralsSelectedForTransactionPayment: []
    });

    const init = async () => {
        try {
            setState((prevState) => ({ ...prevState, isLoading: true }));

            const activeUser = await manageInfluencerAction()?.getInfluencerByUserId(+influencerUserId);
            const paymentConstants = await influencerReferralPaymentAction()?.getAll();
            const earnings = await influencerPayoutAction()?.getAllPayoutTransactionsByInfluencerUserId(
                +influencerUserId
            );
            const paypalPayoutFee = await fetchPaypalPayoutFee();
            const [pendingReferralsRegistrations = [], pendingReferralsTransactions = []] = await Promise.all([
                manageInfluencerAction()?.getPendingRealizedReferrals(+influencerUserId, 'SUCCESSFUL_REGISTRATION'),
                manageInfluencerAction()?.getPendingRealizedReferrals(+influencerUserId, 'SUCCESSFUL_TRANSACTION')
            ]);

            const activePaymentConstant =
                paymentConstants?.find((p) => p.influencerUserId === +influencerUserId) || null;

            setState((prevState) => ({
                ...prevState,
                activeUser: activeUser || null,
                isLoading: false,
                activePaymentConstant,
                pendingReferralsRegistrations: pendingReferralsRegistrations || [],
                pendingReferralsTransactions: pendingReferralsTransactions || [],
                referralsSelectedForRegistrationPayment: [],
                referralsSelectedForTransactionPayment: [],
                earnings: earnings || [],
                paypalPayoutFee
            }));
        } catch (e: ErrorType) {
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
    };

    const fetchPaypalPayoutFee = async () => {
        try {
            const apiResponse = await apiService.get(`/api/admin/constants/get-all`);
            const response = apiResponse.parsedBody;
            const paypalPayoutFee = +(
                response.data.find((c: ConstantType) => c.key === 'PAYPAL_PAYOUT')?.value?.fee || 0
            ) as number;
            return paypalPayoutFee;
        } catch (e: ErrorType) {
            return 0;
        }
    };

    const onPaymentConstantSave = async (data: Omit<InfluencerReferralPaymentAttributes, 'influencerUserId'>) => {
        const payload: InfluencerReferralPaymentAttributes[] = [];

        if (state.activeUser?.userId) {
            payload.push({
                ...data,
                influencerUserId: state.activeUser.userId
            });
        }

        setState((prevState) => ({ ...prevState, isSavingConstant: true }));
        await influencerReferralPaymentAction()?.updateAll(payload);
        setState((prevState) => ({
            ...prevState,
            isSavingConstant: false
        }));

        init();
    };

    return (
        <Grid item={true} xs={12} className={clsx(adminClasses.root, adminClasses.form)}>
            <Container className={adminClasses.container}>
                {state.activeUser && <InfluencerAccessRevokedAlert influencer={state.activeUser} />}

                <Box textAlign="left" marginBottom={4}>
                    <Button type="button" variant="contained" onClick={() => history.push('/manage-influencer')}>
                        Back
                    </Button>
                </Box>
                <Typography className={adminClasses.heading}>Manage Influencer</Typography>

                <Box textAlign="left" marginTop={4}>
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <Typography>Full Name: {joinNameParts(state.activeUser)}</Typography>
                        </Grid>
                        <Grid item md={6}>
                            <Typography>User ID: {state.activeUser?.userId}</Typography>
                        </Grid>
                        <Grid item md={6}>
                            <Typography>Email: {state.activeUser?.email}</Typography>
                        </Grid>
                        <Grid item md={6}>
                            <Typography>Profile Name: {state.activeUser?.profileName}</Typography>
                        </Grid>
                    </Grid>
                </Box>

                {canUpdate && state.activeUser && (
                    <ApprovalStatus influencer={state.activeUser} onAccessStatusUpdate={() => init()} />
                )}

                {canUpdatePaymentConstant && (
                    <PaymentConstantForm
                        isSaving={state.isSavingConstant}
                        onSave={onPaymentConstantSave}
                        paymentConstant={state.activePaymentConstant}
                    />
                )}

                {(state.activeUser && (
                    <InfluencerReferralList
                        influencer={state.activeUser}
                        paymentConstant={state.activePaymentConstant}
                    />
                )) ||
                    null}

                {state.activeUser && canCreatePayout && (
                    <MakePayment
                        activeUser={state.activeUser}
                        paymentConstant={state.activePaymentConstant}
                        pendingReferralsRegistrations={state.pendingReferralsRegistrations}
                        pendingReferralsTransactions={state.pendingReferralsTransactions}
                        referralsSelectedForTransactionPayment={state.referralsSelectedForTransactionPayment}
                        referralsSelectedForRegistrationPayment={state.referralsSelectedForRegistrationPayment}
                        paypalPayoutFee={state.paypalPayoutFee}
                        onCheckboxChange={(
                            referral: GetPendingRealizedReferrals,
                            reason: PayoutTransactionAttributes['paymentReason'],
                            isChecked: boolean
                        ) => {
                            if (isChecked) {
                                setState((prevState) => ({
                                    ...prevState,
                                    referralsSelectedForRegistrationPayment:
                                        reason === 'SUCCESSFUL_REGISTRATION'
                                            ? [...prevState.referralsSelectedForRegistrationPayment, referral]
                                            : prevState.referralsSelectedForRegistrationPayment,
                                    referralsSelectedForTransactionPayment:
                                        reason === 'SUCCESSFUL_TRANSACTION'
                                            ? [...prevState.referralsSelectedForTransactionPayment, referral]
                                            : prevState.referralsSelectedForTransactionPayment
                                }));
                                return;
                            }
                            setState((prevState) => ({
                                ...prevState,
                                referralsSelectedForRegistrationPayment:
                                    reason === 'SUCCESSFUL_REGISTRATION'
                                        ? prevState.referralsSelectedForRegistrationPayment.filter(
                                              (r) => r.userId !== referral.userId
                                          )
                                        : prevState.referralsSelectedForRegistrationPayment,
                                referralsSelectedForTransactionPayment:
                                    reason === 'SUCCESSFUL_TRANSACTION'
                                        ? prevState.referralsSelectedForTransactionPayment.filter(
                                              (r) => r.userId !== referral.userId
                                          )
                                        : prevState.referralsSelectedForTransactionPayment
                            }));
                        }}
                        onPaymentCompletion={() => init()}
                    />
                )}

                {canReadPayout && state.activeUser?.userId && (
                    <InfluencerEarnings
                        influencer={state.activeUser}
                        isLoading={state.isLoading}
                        earnings={state.earnings}
                        onRetryPayment={() => init()}
                    />
                )}
            </Container>
        </Grid>
    );
};

export default ManageInfluencerDetails;
