import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';

import { ConstantType, ErrorType, TransactionLimitSettingValue } from '../../types';

import useAdminStyles from '../styles';
import storeContext from '../../contexts/store-context';

import HasPermission from '../../utilities/can';
import { useUserService } from '../../contexts/user-context';
import { useApiService } from '../../contexts/api-service-context';
import { useToasterData } from '../../contexts/toaster-context';
import { AppTextField } from '../common/app-textfield';
import TransactionLimitForm from './form';
import ConfirmationDialog from '../common/confirmation-dialog';

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: 900
    },
    keyColumn: {
        display: 'flex',
        alignItems: 'center',
        '& h3': {
            fontWeight: 800
        }
    },
    heading: {
        fontSize: 24,
        fontFamily: '"Work Sans", sans-serif',
        marginBottom: 24
    },
    deleteButton: {
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.dark
        }
    }
}));

type State = {
    isConfirmationDialogOpen: boolean;
    isLoading: boolean;
    isSaving: boolean;
    renderAddForm: boolean;
    constants: Array<ConstantType>;
    activeTransactionLimitId: string | null;
};

const TransactionLimitSettings = () => {
    const adminClasses = useAdminStyles();
    const history = useHistory();
    const apiService = useApiService();
    const toasterContext = useToasterData();
    const { themeAction, appAction } = storeContext();

    const userService = useUserService();
    const canRead = HasPermission(userService.user, 'MANAGE_TRANSACTION_LIMIT_SETTINGS', 'READ');
    const canCreate = HasPermission(userService.user, 'MANAGE_TRANSACTION_LIMIT_SETTINGS', 'CREATE');

    const classes = useStyles();

    const [state, setState] = useState<State>({
        isConfirmationDialogOpen: false,
        activeTransactionLimitId: null,
        isLoading: false,
        isSaving: false,
        renderAddForm: false,
        constants: []
    });

    const transactionLimitSettings = (state.constants.find((c: ConstantType) => c.key === 'TRANSACTION_LIMIT_SETTINGS')
        ?.value || []) as TransactionLimitSettingValue[];

    useEffect(() => {
        if (!canRead) {
            history.push('/dashboard');
            return;
        }
        appAction()?.renderFullHeader();
        getTransactionLimitSettings();
    }, []);

    const getTransactionLimitSettings = async () => {
        try {
            setState((prevState) => ({ ...prevState, isLoading: true }));

            const constantsApiResponse = await apiService.get(`/api/admin/constants/get-all`);
            const constantsResponse = constantsApiResponse.parsedBody;

            setState((prevState) => ({ ...prevState, isLoading: false, constants: constantsResponse.data }));
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
    };

    const onSave = async (constants?: Array<ConstantType>) => {
        try {
            setState((prevState) => ({ ...prevState, isSaving: true }));
            await apiService.post(`/api/admin/constants/update-all`, { constants: constants || state.constants });
            toasterContext.setToaster({
                isOpen: true,
                message: 'Saved successfully',
                severity: 'success'
            });
            setState((prevState) => ({ ...prevState, isSaving: false, constants: constants || state.constants, renderAddForm: false, isConfirmationDialogOpen: false }));
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
            setState((prevState) => ({ ...prevState, isSaving: false, isConfirmationDialogOpen: false }));
        }
    };

    const setTransactionCostLimit = (settingId: string, amount: string, values: TransactionLimitSettingValue[]) => {
        return values.map((s) => {
            if (`${s.transactionLimitSettingId}` === settingId) {
                return {
                    ...s,
                    transactionCostLimit: amount
                };
            }
            return s;
        });
    };

    const setTransactionCountLimit = (settingId: string, count: number, values: TransactionLimitSettingValue[]) => {
        return values.map((s) => {
            if (`${s.transactionLimitSettingId}` === settingId) {
                return {
                    ...s,
                    transactionCountLimit: count
                };
            }
            return s;
        });
    };
    
    const setTransactionDefaultValue = (settingId: string, values: TransactionLimitSettingValue[]) => {
        return values.map((s) => {
            if (`${s.transactionLimitSettingId}` === settingId) {
                return {
                    ...s,
                    isDefault: true
                };
            }
            return {
                ...s,
                isDefault: false
            };
        });
    };

    const onDeleteConfirm = () => {
        if (!state.activeTransactionLimitId) {
            setState((prevState) => ({ ...prevState, isConfirmationDialogOpen: false, activeTransactionLimitId: null }));
            return;
        }

        const constants = state.constants.map((c) => {
            if (c.key === 'TRANSACTION_LIMIT_SETTINGS') {
                return { ...c, value: (c.value as Array<TransactionLimitSettingValue>).filter(tl => `${tl.transactionLimitSettingId}` !== state.activeTransactionLimitId) };
            }
            return c;
        });

        onSave(constants);
    }

    const keyMap = transactionLimitSettings.map(({ key }) => key);

    return (
        <Grid item={true} xs={12} className={adminClasses.root}>
            <Container className={clsx(adminClasses.container, classes.container)}>
                {!state.renderAddForm && canCreate && <Grid container={true} justifyContent="flex-end" alignItems='center'>
                    <Button variant="contained" color="secondary" onClick={e => setState(prevState => ({ ...prevState, renderAddForm: true }))}>Add NEW</Button>
                </Grid>}
                {state.renderAddForm && <Grid container={true}>
                    <TransactionLimitForm
                        order={transactionLimitSettings.length} // by default will be added at last of list
                        keyMap={keyMap}
                        isSaving={state.isSaving}
                        onSave={newTransactionLimit => {
                            const constants =
                                state.constants.map((c) => {
                                    if (c.key === 'TRANSACTION_LIMIT_SETTINGS') {
                                        return { ...c, value: [...c.value as Array<TransactionLimitSettingValue>, newTransactionLimit] };
                                    }
                                    return c;
                                });
                            onSave(constants);
                        }}
                        onCancel={() => setState(prevState => ({ ...prevState, renderAddForm: false }))}
                    />
                </Grid>}
                <Box>
                    <Typography variant="h1" className={classes.heading}>
                        Manage Transaction Limit
                    </Typography>

                    {state.isLoading && <CircularProgress />}

                    {!state.isLoading && (
                        <DragDropContext
                            onDragEnd={(result) => {
                                // dropped outside the list
                                if (!result.destination) {
                                    return;
                                }

                                const indexA = result.source.index;
                                const indexB = result.destination.index;

                                setState((prevState) => {
                                    const constants = prevState.constants.map((c) => {
                                        if (c.key === 'TRANSACTION_LIMIT_SETTINGS') {
                                            const settingsState = [
                                                ...(c.value as TransactionLimitSettingValue[]).map((s) => ({ ...s }))
                                            ];
                                            const tempOrder = settingsState[indexA].order;
                                            settingsState[indexA].order = settingsState[indexB].order;
                                            settingsState[indexB].order = tempOrder;

                                            return {
                                                ...c,
                                                value: settingsState.sort((a, b) => a.order - b.order)
                                            };
                                        }

                                        return c;
                                    });

                                    return {
                                        ...prevState,
                                        constants
                                    };
                                });
                            }}
                        >
                            <Droppable droppableId="droppable">
                                {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {transactionLimitSettings.map((setting, i) => {
                                            return (
                                                <Draggable
                                                    key={`${setting.transactionLimitSettingId}`}
                                                    draggableId={`${setting.transactionLimitSettingId}`}
                                                    index={i}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Grid container spacing={4}>
                                                                <Grid item xs={12} md={2} className={classes.keyColumn}>
                                                                    <Typography variant="h3" align="left">
                                                                        {setting.key}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={6} md={3}>
                                                                    <AppTextField
                                                                        variant="filled"
                                                                        label="Cost limit"
                                                                        value={setting.transactionCostLimit}
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                    $
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                        onChange={(e) => {
                                                                            const amount = e.target.value;

                                                                            if (
                                                                                !amount ||
                                                                                amount.match(/^\d{1,}(\.\d{0,2})?$/)
                                                                            ) {
                                                                                setState((prevState) => {
                                                                                    const constants =
                                                                                        prevState.constants.map((c) => {
                                                                                            if (
                                                                                                c.key ===
                                                                                                'TRANSACTION_LIMIT_SETTINGS'
                                                                                            ) {
                                                                                                return {
                                                                                                    ...c,
                                                                                                    value: setTransactionCostLimit(
                                                                                                        setting.transactionLimitSettingId,
                                                                                                        amount,
                                                                                                        c.value as TransactionLimitSettingValue[]
                                                                                                    )
                                                                                                };
                                                                                            }

                                                                                            return c;
                                                                                        });

                                                                                    return {
                                                                                        ...prevState,
                                                                                        constants
                                                                                    };
                                                                                });
                                                                            }
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={6} md={2}>
                                                                    <AppTextField
                                                                        variant="filled"
                                                                        label="Count limit"
                                                                        value={setting.transactionCountLimit}
                                                                        onChange={(e) => {
                                                                            const count = +e.target.value;

                                                                            if (!isNaN(count)) {
                                                                                setState((prevState) => {
                                                                                    const constants =
                                                                                        prevState.constants.map((c) => {
                                                                                            if (
                                                                                                c.key ===
                                                                                                'TRANSACTION_LIMIT_SETTINGS'
                                                                                            ) {
                                                                                                return {
                                                                                                    ...c,
                                                                                                    value: setTransactionCountLimit(
                                                                                                        `${setting.transactionLimitSettingId}`,
                                                                                                        count,
                                                                                                        c.value as TransactionLimitSettingValue[]
                                                                                                    )
                                                                                                };
                                                                                            }

                                                                                            return c;
                                                                                        });

                                                                                    return {
                                                                                        ...prevState,
                                                                                        constants
                                                                                    };
                                                                                });
                                                                            }
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={2}>
                                                                    {setting.isDefault ? (
                                                                        <Box alignItems="center" display="flex" mt={2}>
                                                                            <Typography color="secondary" variant="subtitle2"  align="left">
                                                                                DEFAULT LIMIT
                                                                            </Typography>
                                                                        </Box>
                                                                        
                                                                    ): (
                                                                        <Button
                                                                        type="button"
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        onClick={e => setState((prevState) => {
                                                                            const constants =
                                                                                prevState.constants.map((c) => {
                                                                                    if (
                                                                                        c.key ===
                                                                                        'TRANSACTION_LIMIT_SETTINGS'
                                                                                    ) {
                                                                                        return {
                                                                                            ...c,
                                                                                            value: setTransactionDefaultValue(
                                                                                                setting.transactionLimitSettingId,
                                                                                                c.value as TransactionLimitSettingValue[]
                                                                                            )
                                                                                        };
                                                                                    }

                                                                                    return c;
                                                                                });

                                                                            return {
                                                                                ...prevState,
                                                                                constants
                                                                            };
                                                                        }) }
                                                                        disabled={state.isSaving}
                                                                    >
                                                                        {state.isSaving && <CircularProgress className="button-loader" />}
                                                                        SET AS Default
                                                                    </Button>
                                                                    )}
                                                                </Grid>
                                                                <Grid item xs={12} md={2}>
                                                                    <Button
                                                                        type="button"
                                                                        variant="contained"
                                                                        onClick={e => setState(prevState => ({
                                                                            ...prevState,
                                                                            isConfirmationDialogOpen: true,
                                                                            activeTransactionLimitId: `${setting.transactionLimitSettingId}`
                                                                        }))}
                                                                        disabled={state.isSaving}
                                                                        className={classes.deleteButton}
                                                                    >
                                                                        {state.isSaving && <CircularProgress className="button-loader" />}
                                                                        DELETE
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    )}

                    <Box textAlign="right" marginTop={4}>
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={e => onSave()}
                            disabled={state.isSaving}
                        >
                            {state.isSaving && <CircularProgress className="button-loader" />}
                            Save
                        </Button>
                    </Box>
                </Box>
            </Container>

            <ConfirmationDialog
                header={'Are you sure?'}
                subHeader={`Click confirm to delete the transaction limit. Changes can't be reverted.`}
                isLoading={state.isSaving}
                open={state.isConfirmationDialogOpen}
                onClose={() =>
                    setState((prevState) => ({
                        ...prevState,
                        isConfirmationDialogOpen: false,
                        activeTransactionLimitId: null
                    }))
                }
                onConfirm={onDeleteConfirm}
            />
        </Grid>
    );
};

export default TransactionLimitSettings;
