import { createTheme } from '@material-ui/core';

const APP_PRIMARY_COLOR = '#03A9F4';
const APP_SECONDARY_COLOR = '#689F38';

const theme = createTheme({
    palette: {
        primary: {
            main: APP_PRIMARY_COLOR
        },
        secondary: {
            main: APP_SECONDARY_COLOR
        }
    },
    overrides: {
        MuiButton: {
            root: {
                fontWeight: 700
            },
            containedPrimary: {
                color: '#fff'
            }
        },
        MuiTypography: {
            h1: {
                letterSpacing: '0.00938em',
                fontWeight: 400,
                fontSize: '1rem',
                lineHeight: '1.5'
            },
            h2: {
                letterSpacing: '0.00938em',
                fontWeight: 400,
                fontSize: '1rem',
                lineHeight: '1.5'
            },
            h3: {
                letterSpacing: '0.00938em',
                fontWeight: 400,
                fontSize: '1rem',
                lineHeight: '1.5'
            },
            h4: {
                letterSpacing: '0.00938em',
                fontWeight: 400,
                fontSize: '1rem',
                lineHeight: '1.5'
            }
        }
    }
});

export default theme;