import React from 'react';

import { Typography, Box, Button, CircularProgress, makeStyles } from '@material-ui/core';

import { InfluencerDetails, UserInfluencerAccountAttributes } from '../../types';
import useAdminStyles from '../styles';
import storeContext from '../../contexts/store-context';
import { AppTextField } from '../common/app-textfield';

const REASON_MAX_LENGTH = 500;

type Props = {
    influencer: InfluencerDetails;
    onAccessStatusUpdate: () => void;
};

const useStyles = makeStyles((theme) => ({
    dangerBtn: {
        backgroundColor: theme.palette.error.dark,
        color: 'white'
    }
}));

const ApprovalStatus = (props: Props) => {
    const classes = useStyles();
    const adminClasses = useAdminStyles();
    const { manageInfluencerAction } = storeContext();

    const [text, setText] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const onClick = async (approvalStatus: UserInfluencerAccountAttributes['approvalStatus']) => {
        setLoading(true);
        await manageInfluencerAction()?.saveInfluencerAccessStatus(
            props.influencer.userId,
            approvalStatus,
            approvalStatus === 'REVOKED' ? text : ''
        );
        setText('');
        setLoading(false);
        props.onAccessStatusUpdate();
    };

    return (
        <Box className={adminClasses.form}>
            <Typography className={adminClasses.heading}>Affiliate Program Status</Typography>

            <Typography align="left">
                The influencer access is in {props.influencer.approvalStatus || 'PENDING'} state.
            </Typography>

            <Box textAlign="left" marginTop={4}>
                {(!props.influencer.approvalStatus ||
                    props.influencer.approvalStatus === 'PENDING' ||
                    props.influencer.approvalStatus === 'APPROVED') && (
                    <AppTextField
                        variant="outlined"
                        size="small"
                        label="Rejection reason"
                        placeholder="Reason for rejecting approval"
                        multiline={true}
                        fullWidth={true}
                        maxLength={REASON_MAX_LENGTH}
                        rows={4}
                        value={text}
                        helperText={
                            (text &&
                                text.length <= REASON_MAX_LENGTH &&
                                `${REASON_MAX_LENGTH - text.length} characters remaining`) ||
                            `maximum 500 characters allowed`
                        }
                        error={!!(text.length > REASON_MAX_LENGTH)}
                        onChange={(e) => setText(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                    />
                )}

                <Box display="flex" justifyContent="flex-end" gridGap={8} marginTop={2}>
                    {loading && <CircularProgress />}

                    {!loading && (
                        <>
                            {(!props.influencer.approvalStatus ||
                                props.influencer.approvalStatus === 'PENDING' ||
                                props.influencer.approvalStatus === 'APPROVED') && (
                                <Button
                                    type="button"
                                    variant="contained"
                                    onClick={() => onClick('REVOKED')}
                                    className={classes.dangerBtn}
                                >
                                    REJECT
                                </Button>
                            )}

                            {(!props.influencer.approvalStatus ||
                                props.influencer.approvalStatus === 'PENDING' ||
                                props.influencer.approvalStatus === 'REVOKED') && (
                                <Button
                                    type="button"
                                    color="primary"
                                    variant="contained"
                                    onClick={() => onClick('APPROVED')}
                                >
                                    APPROVE
                                </Button>
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default ApprovalStatus;
