import moment from 'moment';

export enum DateFormatTypeEnum {
    D_MMM_h_mm_a = 'D MMM-h:mm a',
    MMMM_D_YYYY = 'MMMM D, YYYY',
    MMM_DD_YYYY_h_mm_ss_SSS_a = 'MMM DD YYYY, h:mm:ss:SSS a',
    MM_DD_YYYY_h_mm_a = 'MM/DD/YYYY h:mm a',
    MM_DD_YYYY = 'MM/DD/YYYY',
    MMMM_D_YYYY_h_mm_a = 'MMMM D, YYYY h:mm a'
}

export const formatTimeStamp = (timeStamp?: Date | string | null, format?: DateFormatTypeEnum) => {
    if (!timeStamp) {
        return null;
    }

    return moment(timeStamp).tz('America/New_York').format(format || DateFormatTypeEnum.MMMM_D_YYYY);
};

/**
 * The offsets values states the difference from  GMT
 */
export const timeZones = [
    { type: 'PT', timezone: 'America/Los_Angeles' },
    { type: 'MT', timezone: 'America/Denver' },
    { type: 'CT', timezone: 'America/Chicago' },
    { type: 'ET', timezone: 'America/New_York' }
];

export type DateOption = {
    offset: number;
    startOf: 'day' | 'month' | 'year';
    label: string;
};

export const getDateRangeByOptionLabel = (
    dateOptions: DateOption[],
    label: DateOption['label']
): [string | null, string | null] => {
    const dateOption = dateOptions.find((option) => option.label === label);
    if (!dateOption) {
        return [null, null];
    }

    const { offset, startOf } = dateOption;
    const startDateObj = moment().subtract(offset, startOf).startOf(startOf);
    const endDateObj = startDateObj
        .clone()
        .add(offset === 1 ? 0 : offset, startOf)
        .endOf(startOf);

    return [startDateObj.format(), endDateObj.format()];
};
