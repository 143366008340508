import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import PublishIcon from '@material-ui/icons/Publish';

import { GetGiftCardCategoryAdmin, GiftCardProduct, GiftCardCategory } from '../../types';

import TableComponent, { HeadCell, TableResultRow } from '../common/table-component';

import useAdminStyles from '../styles';
import storeContext from '../../contexts/store-context';

import moment from 'moment';
import { joinNameParts, scrollTop } from '../../utilities';
import ConfirmationDialog from '../common/confirmation-dialog';
import HasPermission from '../../utilities/can';
import AddEditForm from './form';
import StatusButton from '../common/status-button';
import { useUserService } from '../../contexts/user-context';
import { EGifterProduct } from '../../types/eGifter';
import { ProductsNotInEGifter, ProductsNotInEGifterType } from '../ml-category';
import { useApiService } from '../../contexts/api-service-context';
import { useToasterData } from '../../contexts/toaster-context';
import { GifterCardTypeEnum, SavedProducts } from '../../types/savedProducts';
import CategoryFilter, { useCategoryFilter } from '../common/category-filter';
import { CircularProgress } from '@material-ui/core';
import CsvUploadModal from './csv-upload-modal';
import ExportCsvModal from '../common/export-csv-modal';

type ProductNotInAnyCategoryProps = {
    product: EGifterProduct;
    categories: GetGiftCardCategoryAdmin[];
    handleCategoryChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
};
const ProductNotInAnyCategory = (props: ProductNotInAnyCategoryProps) => {
    const { product, categories, handleCategoryChange } = props;
    const classes = makeStyles({
        container: {
            alignItems: 'center',
            paddingBottom: 8
        },
        formControl: {
            width: '100%'
        }
    })();
    const [state, setState] = React.useState('');

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setState(event.target.value as string);
        handleCategoryChange(event);
    };

    return (
        <ListItem key={product.id}>
            <Grid container className={classes.container}>
                <Grid item xs={12} sm={4}>
                    {product.name}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id={'category' + product.id}>Choose Category</InputLabel>
                        <Select labelId={'category' + product.id} value={state} onChange={handleChange}>
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {categories.map((c) => (
                                <MenuItem key={c.id} value={c.id}>
                                    {c.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </ListItem>
    );
};

type State = {
    isLoading: boolean;
    isDeleting: boolean;
    isSaving: boolean;
    renderAddEditForm: boolean;
    menuAnchorEle: HTMLElement | null;
    activeCategoryId: number | null;
    categories: GetGiftCardCategoryAdmin[];
    products: GiftCardProduct[];
    selectedRows: TableResultRow[];
    isConfirmationDialogOpen: boolean;
    productsNotInAnyCategory: EGifterProduct[];
    productsNotInEGifter: ProductsNotInEGifterType[];
    productsSavedInDB: SavedProducts[];
};

type CsvUploadModalState = {
    open: boolean;
    loading: boolean;
    file: File | null;
};

type CsvExportModalState = {
    open: boolean;
    loading: boolean;
};

const Category = () => {
    const adminClasses = useAdminStyles();
    const history = useHistory();
    const apiService = useApiService();
    const toasterContext = useToasterData();
    const userService = useUserService();
    const { categoryAction, giftCardAction, appAction } = storeContext();

    const canReadCategory = HasPermission(userService.user, 'CATEGORY', 'READ');
    const canCreateCategory = HasPermission(userService.user, 'CATEGORY', 'CREATE');
    const canUpdateCategory = HasPermission(userService.user, 'CATEGORY', 'UPDATE');

    const { giftCardType, countryCode, onCountryCodeChange, onGiftCardTypeChange } = useCategoryFilter();

    useEffect(() => {
        if (!canReadCategory) {
            history.push('/dashboard');
            return;
        }
        appAction()?.renderFullHeader();
    }, []);

    useEffect(() => {
        closeForm();
        getAllCategories(giftCardType, countryCode);
    }, [giftCardType, countryCode]);

    const [state, setState] = useState<State>({
        isLoading: false,
        isDeleting: false,
        isSaving: false,
        renderAddEditForm: false,
        menuAnchorEle: null,
        activeCategoryId: null,
        categories: [],
        products: [],
        selectedRows: [],
        isConfirmationDialogOpen: false,
        productsNotInAnyCategory: [],
        productsNotInEGifter: [],
        productsSavedInDB: []
    });

    const [csvUploadModalState, setCsvUploadModalState] = React.useState<CsvUploadModalState>({
        open: false,
        loading: false,
        file: null
    });
    const [csvExportModalState, setCsvExportModalState] = React.useState<CsvExportModalState>({
        open: false,
        loading: false
    });

    const activeCategory = state.categories.find((p) => p.id === state.activeCategoryId);

    const getAllCategories = async (giftCardType: typeof GifterCardTypeEnum[number], countryCode: string) => {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        const [categories, products, apiResponse] = await Promise.all([
            categoryAction()?.getAllCategories(giftCardType, countryCode),
            giftCardType === 'B2C'
                ? giftCardAction()?.getAllGiftCards()
                : giftCardAction()?.getAllB2BGiftCards({ cultureCode: `en-${countryCode}` }),
            apiService.get(`/api/admin/manage-product/get-all`)
        ]);

        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return;
        }

        const productsNotInAnyCategory: EGifterProduct[] = [];
        (products || []).forEach((p: EGifterProduct) => {
            const productFound = categories?.find((c) => c.productIds.includes(p.id));
            if (!productFound) {
                productsNotInAnyCategory.push(p);
            }
        });

        const productsSavedInDB = response.data as SavedProducts[];
        const productsNotInEGifter: Record<string, ProductsNotInEGifterType> = {};

        for (const category of categories || []) {
            for (const productId of category.productIds) {
                const productFoundInEGifter = products.find((p: EGifterProduct) => p.id === productId);
                const productFoundInDB = productsSavedInDB.find((p) => p.productId === productId);

                if (!productFoundInEGifter) {
                    if (productsNotInEGifter[productId]) {
                        productsNotInEGifter[productId].categories.push(category.name);
                    } else {
                        productsNotInEGifter[productId] = {
                            name: productFoundInDB?.name || productId,
                            categories: [category.name]
                        };
                    }
                }
            }
        }

        setState((prevState) => ({
            ...prevState,
            categories: categories || [],
            products: products || [],
            productsNotInAnyCategory,
            productsNotInEGifter: Object.values(productsNotInEGifter),
            isLoading: false,
            isSaving: false,
            productsSavedInDB
        }));
    };

    const onIsActiveChange = async (isActive: boolean, id: number) => {
        const activeCategory = state.categories.find((p) => p.id === id);
        if (!activeCategory) {
            return;
        }
        const savedPromoCode = await categoryAction()?.saveCategory({
            ...activeCategory,
            isActive,
            giftCardType,
            countryCode
        });

        setState((prevState) => {
            const newCategories = prevState.categories.map((p) => {
                if (p.id === id) {
                    return { ...p, ...savedPromoCode };
                }
                return p;
            });
            return { ...prevState, categories: newCategories };
        });
    };

    const openCSVUploadModal = () => {
        setCsvUploadModalState((prevState) => ({
            ...prevState,
            open: true
        }));
    };

    const headCells: Array<HeadCell> = [
        { id: 'name', label: 'Name' },
        { id: 'isActive', label: 'Is Active' },
        { id: 'options', label: '' }
    ];

    const tableRows: Array<TableResultRow> = state.categories
        .map((obj): TableResultRow => {
            const row: TableResultRow = {
                id: {
                    text: obj.id?.toString() || ''
                },
                name: {
                    align: 'left',
                    text: obj.name || '-'
                },
                isActive: {
                    align: 'left',
                    text: '',
                    element: canUpdateCategory ? (
                        <Switch
                            checked={obj.isActive}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onIsActiveChange(!obj.isActive, obj.id);
                            }}
                            color="primary"
                        />
                    ) : (
                        <StatusButton
                            options={[
                                { type: 'ACTIVE', text: 'Active', color: 'active' },
                                { type: 'INACTIVE', text: 'Inactive', color: 'warning' }
                            ]}
                            type={obj.isActive ? 'ACTIVE' : 'INACTIVE'}
                        />
                    )
                },
                options: {
                    align: 'right',
                    text: '',
                    helperText:
                        (obj.user &&
                            `updated by - ${obj.user?.firstName ? joinNameParts(obj.user) : obj.user?.email || ''}
                           ${obj?.updatedAt && ' - '}${
                                obj?.updatedAt && moment(new Date(obj?.updatedAt)).fromNow()
                            }`) ||
                        undefined,
                    element:
                        (canUpdateCategory && (
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setState((prevState) => ({
                                        ...prevState,
                                        menuAnchorEle: e.currentTarget,
                                        activeCategoryId: obj.id
                                    }));
                                }}
                            >
                                <MoreVertIcon />
                            </IconButton>
                        )) ||
                        undefined
                }
            };
            return row;
        })
        .sort((a, b) => a.name.text.localeCompare(b.name.text));

    const headerComponent = (
        <Grid item={true} xs={12} className={adminClasses.headerComponent}>
            <Button
                type="button"
                size="small"
                variant="contained"
                startIcon={<PublishIcon />}
                style={{ marginRight: 12, textTransform: 'unset' }}
                onClick={openCSVUploadModal}
            >
                Update Product IDs via CSV
            </Button>
            <Button
                onClick={() =>
                    setState((prevState) => ({ ...prevState, renderAddEditForm: true, activeCategoryId: null }))
                }
                size="small"
                color="primary"
                variant="contained"
            >
                <AddIcon />
                &nbsp;Create New Category
            </Button>
        </Grid>
    );

    const handleMenuClose = () => {
        setState((prevState) => ({
            ...prevState,
            menuAnchorEle: null,
            activeCategoryId: null
        }));
    };

    const saveCategory = async (category: GiftCardCategory) => {
        setState((prevState) => ({ ...prevState, isSaving: true }));
        const savedCategory = await categoryAction()?.saveCategory({
            ...category,
            countryCode,
            giftCardType
        });
        if (!savedCategory) {
            setState((prevState) => ({ ...prevState, isSaving: false }));
            return;
        }
        setState((prevState) => {
            let newCategories: GetGiftCardCategoryAdmin[] = [];

            if (category.id) {
                // update
                newCategories = prevState.categories.map((p) => {
                    if (p.id === savedCategory.id) {
                        return {
                            ...p,
                            ...savedCategory
                        };
                    }
                    return p;
                });
            } else {
                // add
                if (savedCategory) {
                    newCategories = [...prevState.categories, savedCategory];
                }
            }

            return { ...prevState, isSaving: false, categories: newCategories, renderAddEditForm: !savedCategory };
        });
        getAllCategories(giftCardType, countryCode);
    };

    const openFormForEdit = (id: number) => {
        if (!canUpdateCategory) {
            return;
        }

        setState((prevState) => ({
            ...prevState,
            renderAddEditForm: true,
            activeCategoryId: id,
            menuAnchorEle: null
        }));
        setTimeout(() => scrollTop());
    };

    const onDeleteConfirm = async () => {
        const ids: number[] = [];

        if (activeCategory?.id) {
            ids.push(activeCategory.id);
        } else if (state.selectedRows) {
            const idsToDelete = state.selectedRows.map((row) => +row.id.text);
            Array.prototype.push.apply(ids, idsToDelete);
        }

        setState((prevState) => ({ ...prevState, isDeleting: true }));
        const CategoriesToDelete = state.categories
            .filter((p) => ids.includes(p.id))
            .map((p) => ({ ...p, giftCardType, countryCode, isDeleted: true }));
        await categoryAction()?.updateAllCategories(CategoriesToDelete);
        setState((prevState) => {
            const newCategories = prevState.categories.filter((p) => !ids.includes(p.id));
            return {
                ...prevState,
                categories: newCategories,
                isDeleting: false,
                isConfirmationDialogOpen: false,
                menuAnchorEle: null
            };
        });
        getAllCategories(giftCardType, countryCode);
    };

    const handleCategoryChange = async (categoryId: number, productId: string) => {
        const categoryToSave = { ...state.categories.find((c) => c.id === categoryId) };
        if (!categoryToSave?.productIds) {
            throw new Error('Could not find category to save');
        }
        categoryToSave.productIds = [...categoryToSave.productIds, productId];

        setState((prevState) => ({ ...prevState, isSaving: true }));
        const savedCategory = await categoryAction()?.saveCategory({
            ...(categoryToSave as GiftCardCategory),
            giftCardType,
            countryCode
        });
        if (!savedCategory) {
            setState((prevState) => ({ ...prevState, isSaving: false }));
            return;
        }

        getAllCategories(giftCardType, countryCode);
    };

    const onExportConfirm = async (selectedColumns: string[]) => {
        setCsvExportModalState((prevState) => ({
            ...prevState,
            loading: true
        }));
        await categoryAction()?.exportCategories(giftCardType, countryCode, selectedColumns);
        setCsvExportModalState((prevState) => ({
            ...prevState,
            loading: false,
            open: false,
        }));
    };

    const openExportModal = () => {
        setCsvExportModalState((prevState) => ({
            ...prevState,
            open: true
        }));
    };

    const closeExportModal = () => {
        setCsvExportModalState((prevState) => ({
            ...prevState,
            open: false
        }));
    };

    const onUploadCSV = (file: File) => {
        setCsvUploadModalState((prevState) => ({
            ...prevState,
            file
        }));
    };

    const onCSVUploadModalClose = () => {
        setCsvUploadModalState((prevState) => ({
            ...prevState,
            open: false
        }));
    };

    const onCSVUploadConfirm = async () => {
        if (!csvUploadModalState.file) {
            return;
        }
        setCsvUploadModalState((prevState) => ({
            ...prevState,
            loading: true
        }));

        const formData = new FormData();
        formData.append('file', csvUploadModalState.file);

        await categoryAction()?.updateProductsForCategoryViaCSV(giftCardType, countryCode, formData);

        setCsvUploadModalState((prevState) => ({
            ...prevState,
            loading: false,
            open: false
        }));
    };

    const closeForm = () => setState((prevState) => ({ ...prevState, renderAddEditForm: false }));

    return (
        <Grid item={true} xs={12} className={adminClasses.root}>
            <Container className={adminClasses.container}>
                <Box display="flex" justifyContent="space-between">
                    <CategoryFilter
                        countryCode={countryCode}
                        giftCardType={giftCardType}
                        onCountryCodeChange={onCountryCodeChange}
                        onGiftCardTypeChange={onGiftCardTypeChange}
                    />
                    <ExportCsvModal
                        isLoading={csvExportModalState.loading}
                        open={csvExportModalState.open}
                        label="Export Categories"
                        columns={['id', 'name', 'key', 'productIds', 'countryCode']}
                        openModal={openExportModal}
                        onClose={closeExportModal}
                        onConfirm={onExportConfirm}
                    />
                </Box>

                {state.renderAddEditForm && (
                    <AddEditForm
                        isSaving={state.isSaving}
                        products={state.products}
                        productsSavedInDB={state.productsSavedInDB}
                        category={activeCategory}
                        onCancel={closeForm}
                        onSave={saveCategory}
                    />
                )}

                <Menu anchorEl={state.menuAnchorEle} open={!!state.menuAnchorEle} onClose={handleMenuClose}>
                    <MenuItem onClick={() => state.activeCategoryId && openFormForEdit(state.activeCategoryId)}>
                        Edit
                    </MenuItem>
                    <MenuItem
                        onClick={() => setState((prevState) => ({ ...prevState, isConfirmationDialogOpen: true }))}
                    >
                        Delete
                    </MenuItem>
                </Menu>

                <TableComponent
                    headerComponent={(!state.renderAddEditForm && canCreateCategory && headerComponent) || undefined}
                    showPaginator={{ bottom: true }}
                    showCheckbox={true}
                    showSearch={true}
                    isLoading={state.isLoading}
                    rows={tableRows}
                    headCells={headCells}
                    selectedRows={state.selectedRows}
                    onDelete={() => setState((prevState) => ({ ...prevState, isConfirmationDialogOpen: true }))}
                    onCheckboxSelect={(selectedRows) => setState((prevState) => ({ ...prevState, selectedRows }))}
                    keyField="id"
                    rowTooltip={(canUpdateCategory && 'Click to edit') || undefined}
                    onRowClick={(id) => openFormForEdit(+id)}
                    fillEmptyRows={false}
                    noOfRowsPerPage={10}
                />

                {state.productsNotInEGifter?.length ? (
                    <ProductsNotInEGifter productsNotInEGifter={state.productsNotInEGifter} />
                ) : null}
            </Container>

            {state.productsNotInAnyCategory.length > 0 && (
                <Box m={3}>
                    <Card>
                        <Box p={2}>
                            <Typography variant="h3" align="left" gutterBottom>
                                Products not part of any category:
                            </Typography>
                            {state.isSaving || state.isLoading ? (
                                <CircularProgress />
                            ) : (
                                <List>
                                    {state.productsNotInAnyCategory.map((product) => (
                                        <ProductNotInAnyCategory
                                            key={product.id}
                                            product={product}
                                            categories={state.categories}
                                            handleCategoryChange={(e) =>
                                                handleCategoryChange(+(e.target.value as string), product.id)
                                            }
                                        />
                                    ))}
                                </List>
                            )}
                        </Box>
                    </Card>
                </Box>
            )}

            <ConfirmationDialog
                header={'Are you sure?'}
                subHeader={'Click CONFIRM to delete the category'}
                isLoading={state.isDeleting}
                open={state.isConfirmationDialogOpen}
                onClose={() =>
                    setState({
                        ...state,
                        isConfirmationDialogOpen: false,
                        activeCategoryId: null,
                        menuAnchorEle: null
                    })
                }
                onConfirm={onDeleteConfirm}
            />

            <CsvUploadModal
                open={csvUploadModalState.open}
                isLoading={csvUploadModalState.loading}
                onUpload={onUploadCSV}
                onClose={onCSVUploadModalClose}
                onConfirm={onCSVUploadConfirm}
            />
        </Grid>
    );
};

export default Category;
