import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { DateTimePicker } from '@material-ui/pickers';

import { GetPromoCode } from '../../types';

import { AppTextField } from '../common/app-textfield';

import useAdminStyles from '../styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

import moment from 'moment';
import Box from '@material-ui/core/Box';
import { validateNumber } from '../../utilities';
import { InputAdornment, InputLabel, MenuItem, Select } from '@material-ui/core';
import { CURRENCY_OPTIONS } from '../../constants/currency-options';

type KeyRecord = keyof GetPromoCode;

type AddEditFormState = {
    isPhoneValid: null | boolean;
    promocode: Omit<GetPromoCode, 'value' | 'maxAmount' | 'minOrderValue' | 'noOfUses' | 'userUsageLimit'> & {
        value: string;
        maxAmount: string | null;
        minOrderValue: string | null;
        noOfUses: string | null;
        userUsageLimit: string | null;
    };
    errors: {
        [key in KeyRecord]?: string | null;
    };
};

type AddEditFormProps = {
    onCancel: () => void;
    onSave: (promocode: GetPromoCode) => void;
    promocode?: GetPromoCode;
    isSaving: boolean;
};

const DEFAULT_PROMO_CODE: AddEditFormState['promocode'] = {
    type: 'AMOUNT',
    value: '',
    description: '',
    key: '',
    isDeleted: false,
    isActive: true,
    expiryDate: null,
    maxAmount: null,
    minOrderValue: null,
    promoCodeId: -1,
    noOfUses: null,
    currencyCode: 'USD',
    userUsageLimit: '1'
};

const useStyles = makeStyles({
    datepicker: {
        width: '100%',
        '& label': {
            top: -2
        },
        '& input': {
            paddingTop: 23,
            paddingBottom: 7
        }
    },
    promoCodeToolTip: {
        marginLeft: 32
    },
    infoIcon: {
        marginLeft: 4,
        fontSize: 16
    },
    currencySelect: {
        textAlign: 'left'
    }
});

const AddEditForm = (props: AddEditFormProps) => {
    const adminClasses = useAdminStyles();
    const classes = useStyles();

    const { onCancel, onSave, promocode, isSaving } = props;

    const [state, setState] = useState<AddEditFormState>({
        isPhoneValid: true,
        promocode: DEFAULT_PROMO_CODE,
        errors: {}
    });

    const maxAmountTooltipInfo = 'Maximum amount that can be waived off using this Promo code.';

    const minOrderTooltipInfo = 'Minimum order amount for using this Promo code.';

    const maxUsageTooltipInfo = 'Maximum number of times this Promo code can be used.';

    const userUsageLimitTooltipInfo = 'Maximum number of times, a user can use this Promo code.';

    React.useEffect(() => {
        promocode &&
            setState((prevState) => ({
                ...prevState,
                promocode:
                    {
                        ...promocode,
                        value: promocode?.value.toString() || '',
                        maxAmount: promocode?.maxAmount?.toString() || '',
                        minOrderValue: promocode?.minOrderValue?.toString() || '',
                        noOfUses: promocode?.noOfUses?.toString() || '',
                        userUsageLimit: promocode?.userUsageLimit?.toString() || '1'
                    } || DEFAULT_PROMO_CODE
            }));
    }, [promocode]);

    const isOldDate = (date: Date) => moment(date).isBefore(moment());

    const isInvalidPercentage = (value: string) => +value < 1 || +value > 100;

    const inValidPatternMessage = (promoCode: string) =>
        /^[A-Z\-_0-9]{6,}$/g.test(promoCode)
            ? undefined
            : 'Promo Code is invalid, it must be at least 6 characters and contains only [A-Z\\-_0-9] characters';

    const savePromoCode = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // validations
        const promoCodePatternError = inValidPatternMessage(state.promocode.key.toUpperCase());
        const errors: AddEditFormState['errors'] = {};
        if (!state.promocode.key) {
            errors.key = 'Key is a required field';
        } else if (promoCodePatternError) {
            errors.key = promoCodePatternError;
        }

        if (state.promocode.expiryDate && isOldDate(state.promocode.expiryDate)) {
            errors.expiryDate = 'Expiry Date cannot be older than today';
        }

        if (!state.promocode.value) {
            errors.value = 'Value is a required field';
        } else if (state.promocode.type === 'PERCENTILE' && isInvalidPercentage(state.promocode.value)) {
            errors.value = 'Value must be between 1-100';
        } else if (state.promocode.type === 'AMOUNT' && !validateNumber(state.promocode.value)) {
            errors.value = 'Please enter valid value';
        }

        if (state.promocode.maxAmount && !validateNumber(state.promocode.maxAmount)) {
            errors.maxAmount = 'Please enter valid Max Amount';
        }

        if (state.promocode.minOrderValue && !validateNumber(state.promocode.minOrderValue)) {
            errors.minOrderValue = 'Please enter valid Min Order Amount';
        }

        if (state.promocode.noOfUses && !validateNumber(state.promocode.noOfUses)) {
            errors.noOfUses = 'Please enter valid Max Limit';
        }

        if (state.promocode.userUsageLimit && !validateNumber(state.promocode.userUsageLimit)) {
            errors.userUsageLimit = 'Please enter valid user usage limit';
        }

        const errorKeys = Object.keys(errors);
        if (errorKeys.length) {
            const input = document.querySelector(`input[name=promocode-${errorKeys[0]}]`);
            input?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'start'
            });
            setState((prevState) => ({ ...prevState, errors }));
            return;
        }

        onSave({
            ...state.promocode,
            key: state.promocode.key.toUpperCase(),
            value: +state.promocode.value,
            maxAmount: state.promocode.maxAmount ? +state.promocode.maxAmount : null,
            minOrderValue: state.promocode.minOrderValue ? +state.promocode.minOrderValue : null,
            noOfUses: state.promocode.noOfUses ? +state.promocode.noOfUses : null,
            userUsageLimit: state.promocode.userUsageLimit ? +state.promocode.userUsageLimit : 1
        });
    };

    return (
        <Grid item={true} xs={12} className={adminClasses.form}>
            <Typography className={adminClasses.heading}>
                {' '}
                {(props.promocode && 'Update') || 'Create New'} Promo Code{' '}
            </Typography>

            <form noValidate autoComplete="off" onSubmit={savePromoCode}>
                <Grid container={true} spacing={4}>
                    <Grid item={true} xs={12} sm={6}>
                        <AppTextField
                            className={adminClasses.textField}
                            required={true}
                            variant="filled"
                            size="small"
                            label="Promo Code"
                            fullWidth={true}
                            value={state.promocode?.key || ''}
                            error={Boolean(state.errors.key)}
                            helperText={state.errors.key}
                            name="promocode-key"
                            onChange={(e) => {
                                const promoCode = e.target.value.toUpperCase();
                                setState((prevState) => ({
                                    ...prevState,
                                    promocode: { ...prevState.promocode, key: promoCode },
                                    errors: {
                                        ...prevState.errors,
                                        key: promoCode
                                            ? inValidPatternMessage(promoCode)
                                            : 'Promo Code is a required field'
                                    }
                                }));
                            }}
                        />
                    </Grid>

                    <Grid item={true} xs={12} sm={6}>
                        <DateTimePicker
                            label="Expiry Date"
                            value={state.promocode.expiryDate}
                            onChange={(date) => {
                                setState((prevState) => ({
                                    ...prevState,
                                    promocode: {
                                        ...prevState.promocode,
                                        expiryDate: date ? moment(date).toDate() : null
                                    },
                                    errors: {
                                        ...prevState.errors,
                                        expiryDate:
                                            date && isOldDate(date)
                                                ? 'Expiry Date cannot be older than today'
                                                : undefined
                                    }
                                }));
                            }}
                            renderInput={(params) => (
                                <AppTextField
                                    name="promocode-expiryDate"
                                    variant="filled"
                                    className={classes.datepicker}
                                    {...params}
                                    error={Boolean(state.errors.expiryDate)}
                                    helperText={state.errors.expiryDate}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <AppTextField
                            multiline
                            rows={3}
                            className={adminClasses.textField}
                            required={false}
                            variant="filled"
                            size="small"
                            label="Description"
                            name="description"
                            fullWidth={true}
                            value={state.promocode?.description || ''}
                            onChange={(e) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    promocode: { ...prevState.promocode, description: e.target.value }
                                }))
                            }
                        />
                    </Grid>

                    <Grid item={true} xs={12} sm={2}>
                        <FormControl
                            variant="filled"
                            size="small"
                            required
                            className={adminClasses.textField}
                        >
                            <InputLabel id="select-currency-label">Currency</InputLabel>
                            <Select
                                labelId="select-currency-label"
                                id="select-currency"
                                value={state.promocode.currencyCode}
                                classes={{ root: classes.currencySelect }}
                                onChange={(e) =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        promocode: {
                                            ...prevState.promocode,
                                            currencyCode: e.target.value as string
                                        }
                                    }))
                                }
                            >
                                {CURRENCY_OPTIONS.map((currency) => (
                                    <MenuItem key={currency.value} value={currency.value}>
                                        {currency.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item={true} xs={12} sm={5}>
                        <AppTextField
                            className={adminClasses.textField}
                            required={true}
                            variant="filled"
                            size="small"
                            label="Value"
                            fullWidth={true}
                            name="promocode-value"
                            value={state.promocode?.value || ''}
                            error={Boolean(state.errors.value)}
                            helperText={state.errors.value}
                            InputProps={
                                state.promocode.type === 'PERCENTILE'
                                    ? {
                                          startAdornment: <InputAdornment position="start">%</InputAdornment>
                                      }
                                    : {}
                            }
                            onChange={(e) =>
                                setState((prevState) => {
                                    let errorMessage = e.target.value ? '' : 'Value is a required field';
                                    if (
                                        prevState.promocode.type === 'PERCENTILE' &&
                                        isInvalidPercentage(e.target.value)
                                    ) {
                                        errorMessage = 'Value must be between 1-100';
                                    } else if (
                                        prevState.promocode.type === 'AMOUNT' &&
                                        !validateNumber(e.target.value)
                                    ) {
                                        errorMessage = 'Please enter valid value';
                                    }

                                    return {
                                        ...prevState,
                                        promocode: { ...prevState.promocode, value: e.target.value },
                                        errors: {
                                            ...prevState.errors,
                                            value: errorMessage
                                        }
                                    };
                                })
                            }
                        />
                    </Grid>

                    <Grid item={true} xs={12} sm={5}>
                        <FormControl component="fieldset" fullWidth className="text-left">
                            <FormLabel component="legend">Type *</FormLabel>
                            <RadioGroup
                                row
                                aria-label="type"
                                name="type-promocode"
                                value={state.promocode.type}
                                onChange={(e) =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        promocode: {
                                            ...prevState.promocode,
                                            type: e.target.value as GetPromoCode['type'],
                                            value: ''
                                        },
                                        errors: {
                                            ...prevState.errors,
                                            value: null
                                        }
                                    }))
                                }
                            >
                                <FormControlLabel value="AMOUNT" control={<Radio color="primary" />} label="Amount" />
                                <FormControlLabel
                                    value="PERCENTILE"
                                    control={<Radio color="primary" />}
                                    label="Percentile"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item={true} xs={12} sm={4}>
                        <AppTextField
                            className={adminClasses.textField}
                            required={false}
                            name="promocode-maxAmount"
                            variant="filled"
                            size="small"
                            label="Max Amount"
                            fullWidth={true}
                            value={state.promocode?.maxAmount || ''}
                            error={Boolean(state.errors.maxAmount)}
                            helperText={state.errors.maxAmount}
                            onChange={(e) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    promocode: { ...prevState.promocode, maxAmount: e.target.value },
                                    errors: {
                                        ...prevState.errors,
                                        maxAmount: validateNumber(e.target.value)
                                            ? null
                                            : 'Please enter valid Max Amount'
                                    }
                                }))
                            }
                            InputProps={{
                                endAdornment: (
                                    <Tooltip
                                        leaveTouchDelay={3000}
                                        enterTouchDelay={50}
                                        placement="top"
                                        title={maxAmountTooltipInfo}
                                        classes={{ tooltipPlacementTop: classes.promoCodeToolTip }}
                                    >
                                        <InfoIcon className={classes.infoIcon} />
                                    </Tooltip>
                                )
                            }}
                        />
                    </Grid>

                    <Grid item={true} xs={12} sm={4}>
                        <AppTextField
                            className={adminClasses.textField}
                            required={false}
                            variant="filled"
                            size="small"
                            label="Min Order Value"
                            name="promocode-minOrderValue"
                            fullWidth={true}
                            value={state.promocode?.minOrderValue || ''}
                            error={Boolean(state.errors.minOrderValue)}
                            helperText={state.errors.minOrderValue}
                            onChange={(e) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    promocode: { ...prevState.promocode, minOrderValue: e.target.value },
                                    errors: {
                                        ...prevState.errors,
                                        minOrderValue: validateNumber(e.target.value)
                                            ? null
                                            : 'Please enter valid Min Order Amount'
                                    }
                                }))
                            }
                            InputProps={{
                                endAdornment: (
                                    <Tooltip
                                        leaveTouchDelay={3000}
                                        enterTouchDelay={50}
                                        placement="top"
                                        title={minOrderTooltipInfo}
                                        classes={{ tooltipPlacementTop: classes.promoCodeToolTip }}
                                    >
                                        <InfoIcon className={classes.infoIcon} />
                                    </Tooltip>
                                )
                            }}
                        />
                    </Grid>

                    <Grid item={true} xs={12} sm={4}>
                        <AppTextField
                            className={adminClasses.textField}
                            required={false}
                            variant="filled"
                            size="small"
                            label="Max Promo code usage limit"
                            name="promocode-noOfUses"
                            fullWidth={true}
                            value={state.promocode?.noOfUses || ''}
                            error={Boolean(state.errors.noOfUses)}
                            helperText={state.errors.noOfUses}
                            onChange={(e) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    promocode: { ...prevState.promocode, noOfUses: e.target.value },
                                    errors: {
                                        ...prevState.errors,
                                        noOfUses: validateNumber(e.target.value)
                                            ? null
                                            : 'Please enter valid Max Promo code usage limit'
                                    }
                                }))
                            }
                            InputProps={{
                                endAdornment: (
                                    <Tooltip
                                        leaveTouchDelay={3000}
                                        enterTouchDelay={50}
                                        placement="top"
                                        title={maxUsageTooltipInfo}
                                        classes={{ tooltipPlacementTop: classes.promoCodeToolTip }}
                                    >
                                        <InfoIcon className={classes.infoIcon} />
                                    </Tooltip>
                                )
                            }}
                        />
                    </Grid>

                    <Grid item={true} xs={12} sm={4}>
                        <AppTextField
                            className={adminClasses.textField}
                            required={false}
                            variant="filled"
                            size="small"
                            label="User usage limit"
                            fullWidth={true}
                            name="promocode-userUsageLimit"
                            value={state.promocode?.userUsageLimit || ''}
                            error={Boolean(state.errors.userUsageLimit)}
                            helperText={state.errors.userUsageLimit}
                            onChange={(e) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    promocode: { ...prevState.promocode, userUsageLimit: e.target.value },
                                    errors: {
                                        ...prevState.errors,
                                        userUsageLimit: validateNumber(e.target.value)
                                            ? null
                                            : 'Please enter valid user usage limit'
                                    }
                                }))
                            }
                            InputProps={{
                                endAdornment: (
                                    <Tooltip
                                        leaveTouchDelay={3000}
                                        enterTouchDelay={50}
                                        placement="top"
                                        title={userUsageLimitTooltipInfo}
                                        classes={{ tooltipPlacementTop: classes.promoCodeToolTip }}
                                    >
                                        <InfoIcon className={classes.infoIcon} />
                                    </Tooltip>
                                )
                            }}
                        />
                    </Grid>

                    <Grid item={true} xs={12} sm={3}>
                        <Box textAlign="left">
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={state.promocode.isActive}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                promocode: { ...state.promocode, isActive: e.target.checked }
                                            })
                                        }
                                        color="primary"
                                    />
                                }
                                label="Is Active"
                            />
                        </Box>
                    </Grid>

                    {props.promocode && (
                        <Grid item={true} xs={12} sm={12}>
                            <Box textAlign="left" display="flex" justifyContent="flex-start" gridGap={'6rem'}>
                                {props.promocode.b2bUserId && (
                                    <Typography color="textSecondary">
                                        Created by Merchant User ID: <strong>{props.promocode?.b2bUserId}</strong>
                                    </Typography>
                                )}
                                {props.promocode.updatedByB2bUser && (
                                    <Typography color="textSecondary">
                                        Updated by Merchant User ID:{' '}
                                        <strong>{props.promocode?.updatedByB2bUser.userId}</strong>
                                    </Typography>
                                )}
                            </Box>

                            <Box textAlign="left" display="flex" justifyContent="flex-start" gridGap={'6rem'}>
                                {props.promocode.adminUserId && (
                                    <Typography color="textSecondary">
                                        Created by Admin User ID: <strong>{props.promocode?.adminUserId}</strong>
                                    </Typography>
                                )}
                                {props.promocode.updatedByAdminUser && (
                                    <Typography color="textSecondary">
                                        Updated by Admin User ID:{' '}
                                        <strong>{props.promocode?.updatedByAdminUser.userId}</strong>
                                    </Typography>
                                )}
                            </Box>
                        </Grid>
                    )}
                </Grid>

                <Grid item={true} xs={12} className={adminClasses.footer}>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={(e) => {
                            onCancel();
                            setState({ ...state, promocode: DEFAULT_PROMO_CODE });
                        }}
                        disabled={isSaving}
                    >
                        Cancel
                    </Button>

                    <Button variant="contained" color="primary" size="small" disabled={isSaving} type="submit">
                        save
                        {isSaving && <CircularProgress className="button-loader" />}
                    </Button>
                </Grid>
            </form>
        </Grid>
    );
};

export default AddEditForm;
