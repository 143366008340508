import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import useAdminStyles from '../styles';
import { UserRoleEnum, AccessStatusTypeEnum } from '../../types';
import UserRatings from './ratings-list';

export type UserRating = {
    ratingId: number;
    userId: number;
    rating: number;
    review: string;
    email: string;
    phone: string;
    companyName: string;
    firstName: string;
    lastName: string;
    createdAt: string;
    updatedAt: string;
    role: typeof UserRoleEnum[number];
    accessStatus: typeof AccessStatusTypeEnum[number];
    accessUpdateReason: string;
    isDeleted: boolean;
};

const ManageRatings = () => {
    const adminClasses = useAdminStyles();
    const ratingClasses = useRatingStyles();

    return (
        <Grid item xs={12} className={clsx(adminClasses.root, ratingClasses.root)}>
           
           <Typography align="left" variant="h1" className={ratingClasses.dashboardHeading}>Manage Ratings</Typography>

            <UserRatings />
        </Grid>
    );
};

const useRatingStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto 14px',
        [theme.breakpoints.up('md')]: {
            margin: 'auto 24px'
        }
    },
    dashboardHeading: {
        fontSize: 20,
        marginBottom: 16,
        fontWeight: 600,
        color: theme.palette.primary.main
    }
}));

export default ManageRatings;
