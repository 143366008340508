import Dinero, { Currency } from 'dinero.js';
import loadable, { DefaultComponent } from '@loadable/component';
import { timeout } from 'promise-timeout';
import AppLoader from '../components/common/app-loader';

import { COUNTRY_CODES } from '../constants/country-codes';
import { GiftCardProduct } from '../types';
import { RGBColor } from 'react-color';

export const validateRequiredString = (value?: string | null): boolean => {
    if (!value) {
        return false;
    }

    const requiredValidString = /^[a-zA-Z]+\s?[a-zA-Z\s]+$/;
    return requiredValidString.test(value);
};

export const validateEmail = (email?: string | null): boolean => {
    if (!email) {
        return false;
    }
    // eslint-disable-next-line no-useless-escape
    const emailPatternRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailPatternRegex.test(email);
};

export const validateNumber = (value?: string | null): boolean => {
    if (!value) {
        return true;
    }

    const pattern = /^\d*\.?\d*$/;
    return pattern.test(value);
};

export const validateAmount = (value?: string | null): boolean => {
    if (!value) {
        return true;
    }

    const pattern = /^\d{0,5}(\.\d{0,2})?$/;
    return pattern.test(value);
};

export const validatePercentage = (value?: string | null): boolean => {
    if (!value) {
        return true;
    }

    const pattern = /^\d{0,3}(\.\d{0,2})?$/;
    return pattern.test(value) ? +value >= 0 && +value <= 100 : false;
};

export const cardHolderNameValidation = (value?: string | null): boolean => {
    if (!value) {
        return true;
    }

    const pattern = /^[a-zA-Z\-'.\s]*$/g;
    return pattern.test(value);
};

export const isPasswordStrong = (password?: string | null): boolean => {
    if (!password) {
        return false;
    }
    // eslint-disable-next-line no-useless-escape
    const strongPasswordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[%$#@!&*?^])(?=.*[A-Z])[0-9a-zA-Z%$#@!&*?^]{8,20}$/;
    return strongPasswordRegex.test(password);
};

export const validateTaxId = (value?: string | null): boolean => {
    if (!value || !(value || '').trim()) {
        return true;
    }

    const match = /\d/g.test(value);
    if (!match) {
        return false;
    }
    const regExpTax = /^\d{2}-?\d{7}$/;
    return regExpTax.test(value);
};

export const validateZip = (value?: string | null): boolean => {
    if (!value || !(value || '').trim()) {
        return false;
    }

    const match = /\d/g.test(value);
    if (!match) {
        return false;
    }

    return value.replace(/[^0-9+]/g, '').length === 5;
};

export const scrollTop = () => {
    if (window) {
        window.scrollTo(0, 0);
    }
};

export const scrollToElement = (
    selector: string,
    behavior: ScrollBehavior = 'smooth',
    block: ScrollLogicalPosition = 'start'
) => {
    const element = document.querySelector(selector);
    element?.scrollIntoView({
        behavior,
        block,
        inline: 'nearest'
    });
};

type NameObjType = {
    firstName?: null | string;
    middleName?: null | string;
    lastName?: null | string;
};
export const generateNameInitials = (nameObj?: NameObjType | null) => {
    if (!nameObj) {
        return null;
    }
    const fullName = `${nameObj.firstName || ''} ${nameObj.lastName || ''}`;
    const matches = fullName.match(/\b(\w)/g);
    const initals = matches !== null && matches !== undefined ? matches.join('') : '';
    return initals.substring(0, 2);
};

// capitalize all words of a string
export const capitalizeWords = (string: string): string => {
    return string.replace(/(?:^|\s)\S/g, function (a) {
        return a.toUpperCase();
    });
};

export const joinNameParts = (nameObj?: NameObjType | null) => {
    if (!nameObj) {
        return null;
    }

    if (!nameObj.firstName && !nameObj.lastName && !nameObj.middleName) {
        return null;
    }

    const fullName = [
        nameObj.firstName && nameObj.firstName.trim() !== '' ? nameObj.firstName.trim() : null,
        nameObj.middleName && nameObj.middleName.trim() !== '' ? nameObj.middleName.trim() : null,
        nameObj.lastName && nameObj.lastName.trim() !== '' ? nameObj.lastName.trim() : null
    ]
        .filter((obj) => obj)
        .join(' ');

    return fullName;
};

export const generateDinero = (value: number, currency: string | null = 'USD') => {
    let integerValue = Math.round(value);
    if (isNaN(integerValue)) {
        console.warn('getDinero price is NaN'); // eslint-disable-line no-console
        integerValue = 0;
    }
    return Dinero({ amount: integerValue, currency: currency as Currency, precision: 2 });
};

export const getDinero = (value: number, currency: string | null = 'USD') => generateDinero(value * 100, currency);

const DEFAULT_FORMAT = '$0,0.00';
export const formatDinero = (dinero: Dinero.Dinero, format: string = DEFAULT_FORMAT) => dinero.toFormat(format);

/**
 * Mobile number utilities
 */
export const getRawPhoneNumber = (
    phone: string | null | undefined,
    country?: keyof typeof COUNTRY_CODES,
    withoutCountryCode = true
) => {
    if (!phone || !country) {
        return null;
    }
    const countryCode = COUNTRY_CODES[country || '']?.code || '';
    const countryCodeLength = (withoutCountryCode && countryCode.length) || 0;
    return phone.replace(/[^0-9+]/g, '').substring(countryCodeLength, phone.length);
};

export const maskPhoneNumber = (phoneNumber: string | null | undefined, country?: keyof typeof COUNTRY_CODES) => {
    if (!phoneNumber || !country) {
        return null;
    }
    const phoneMask = COUNTRY_CODES[country].phoneMask.replace(/[^0-9+\s+\w+-]/g, '').replace(/[-]/g, ' ');
    const phoneMaskWithoutCountryCode = phoneMask.substring(COUNTRY_CODES[country].code.length + 1, phoneMask.length);
    const phoneMaskArray: Array<string> = phoneMaskWithoutCountryCode.split(' ');
    const lastChunkIndex = phoneMaskArray.length - 1;

    const phoneLength = phoneNumber.length;
    const hiddenCharPosition = phoneLength - phoneMaskArray[lastChunkIndex].length;

    const maskedPhoneNumber = phoneMaskArray.map((chunk, index) => {
        if (index === lastChunkIndex) {
            return (phoneNumber || '').substring(hiddenCharPosition, phoneLength);
        }
        return chunk.replace(/[0-9]/g, '*');
    });

    return maskedPhoneNumber.join(' ');
};

export const formatPhone = (phone?: string | null, phoneCountry?: string | null): string => {
    const phoneCountryCode = phoneCountry ? COUNTRY_CODES[phoneCountry].code : '';
    return phone?.replace(phoneCountryCode, `${phoneCountryCode} `) || '';
};

export const stripHtml = (html?: string | null) => {
    if (!html) {
        return null;
    }
    const temporalDivElement = document.createElement('div');
    temporalDivElement.innerHTML = html;
    return temporalDivElement.textContent || temporalDivElement.innerText || null;
};

export const truncateLongString = (value: string | null | undefined, max: number | undefined = 255) =>
    (value || '').substring(0, Math.min((value || '').length, max));

/**
 * @returns photo url for e-gifter products
 */
export const getPhoto = (giftCard?: GiftCardProduct) => {
    return (
        (giftCard &&
            giftCard.media.faceplates &&
            giftCard.media.faceplates.length &&
            giftCard.media.faceplates[0].path) ||
        undefined
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const componentLoader = (loadFn: Promise<DefaultComponent<any>>, ssr = true) => {
    return loadable(() => timeout(loadFn, 30000), { ssr, fallback: <AppLoader /> });
};

/**
 * sort object or array
 */
export const sortObjByKey = (value: Record<string, unknown> | Record<string, unknown>[]): unknown => {
    return typeof value === 'object'
        ? Array.isArray(value)
            ? value.map(sortObjByKey)
            : Object.keys(value)
                  .sort()
                  .reduce((o, key) => {
                      const v = value[key] as Record<string, unknown>;
                      o[key] = sortObjByKey(v);
                      return o;
                  }, {} as Record<string, unknown>)
        : value;
};

/**
 * sort object or array and stringify
 */
export const JSONstringifyOrder = (obj: Record<string, unknown> | Record<string, unknown>[]) => {
    return JSON.stringify(sortObjByKey(obj), null, 2);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function buildFormData(formData: FormData, data: any, parentKey: string | null = null) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach((key) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            buildFormData(formData, data[key] as any, parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;

        parentKey && formData.append(parentKey, value as string);
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const jsonToFormData = (data: any) => {
    const formData = new FormData();

    buildFormData(formData, data);

    return formData;
};

export const getColorStringFromRGBColor = (color: RGBColor) => `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;

export const randomId = (length = 32) => {
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
};

export const getMerchantCardImageUrl = (s3Key: string) => {
    if (!s3Key) {
        return null;
    }

    // if the photo attribute consists of the s3 bucket path, append the s3 buckets' path to access the file publically

    const isPhotoFromS3Bucket = s3Key.indexOf(`/gift-cards/`) !== -1;

    const image = (isPhotoFromS3Bucket && `${process.env.REACT_APP_AWS_S3_USER_PHOTOS_BASE_URL}/${s3Key}`) || s3Key;
    return image;
};

export * from './dateTime.util';
