import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import TablePagination from '@material-ui/core/TablePagination';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SearchIcon from '@material-ui/icons/Search';

import CircularProgress from '@material-ui/core/CircularProgress';

import clsx from 'clsx';

import ItemsOrdered from './ordered-items';
import {
    DateFormatTypeEnum,
    formatDinero,
    formatTimeStamp,
    generateDinero,
    getDinero,
    scrollTop
} from '../../../utilities';
import { DataLoader } from '../../common/data-loader';
import {
    B2BOrderTypeEnum,
    ErrorType,
    OrderAttributes,
    OrderItemRecipientDeliveryTypeEnum,
    OrderItemRecipientStatusEnum,
    OrderStatusEnum,
    OrderSummary,
    TransactionLimitSettingValue,
    UserAccount
} from '../../../types';
import { useApiService } from '../../../contexts/api-service-context';
import { useToasterData } from '../../../contexts/toaster-context';
import storeContext from '../../../contexts/store-context';
import OrderHistoryFilter, { B2C_USER, B2B_USER } from './order-history-filter';
import { AppTextField } from '../../common/app-textfield';

import moment from 'moment';
import { PaymentAttributes, PaymentStatusEnum } from '../../../types/payment';
import { WalletAttributes } from '../../../types/wallet';
import { Link } from '@material-ui/core';
import ManageUserEditForm from '../../manage-user/editForm';
import ManageBusinessUserEditForm, { UserAccountWithBillingAddress } from '../../manage-business-user/editForm';
import ConfirmationDialog from '../../common/confirmation-dialog';
import { getDefaultSetting } from '../../../utilities/transactionLimitSettings.util';
import OrderDetails from './order-details';
import { B2BUserFundTransactionAttributes } from '../../../types/b2bUserFundTransaction';

export const OrderUserTypeEnum = ['B2C_USER', 'B2B_USER'] as const;

type TransactionRequest = {
    userId: number | null;
    orderId: number | null;
    date: [string | null, string | null];
    status: typeof OrderStatusEnum[number] | null;
    emailDeliveryStatus: typeof OrderItemRecipientStatusEnum[number] | null;
    paymentStatus: typeof PaymentStatusEnum[number] | null;
    orderUserType: typeof OrderUserTypeEnum[number];
    orderType: typeof B2BOrderTypeEnum[number] | null;
    deliveryType: typeof OrderItemRecipientDeliveryTypeEnum[number] | null;
};

export type RefundList = {
    orderId: number | null;
    value: number | null;
};

type State = {
    expandedAccordions: Set<number>;
    searchText: string | null;
    page: number;
    rowsPerPage: number;
    isLoading: boolean;
    isResendButtonLoading: boolean;
    orders: OrderSummary[];
    orderUserType: typeof OrderUserTypeEnum[number];
    ordersFetched: boolean;
};

const DEFAULT_STATE: State = {
    expandedAccordions: new Set(),
    searchText: null,
    page: 0,
    rowsPerPage: 5,
    isLoading: false,
    isResendButtonLoading: false,
    orders: [],
    orderUserType: B2C_USER,
    ordersFetched: false
};

export const useOrderStyles = makeStyles((theme) => ({
    card: {
        background: theme.palette.common.white,
        boxShadow: theme.shadows['1']
    },
    cardRow: {
        marginTop: 16,
        marginBottom: 4,
        padding: 16,
        '&:first-child': {
            marginTop: 0,
            marginBottom: 6
        },
        '& $cardRowGrid': {
            '& > div': {
                textAlign: 'left',
                paddingBottom: 16,
                '&$cardRowGridPullRight': {
                    textAlign: 'right'
                },
                '&$cardRowGridPullCenter': {
                    textAlign: 'center'
                },
                [theme.breakpoints.up('sm')]: {
                    paddingBottom: 'unset',
                    marginBottom: 12,
                    '&$cardRowGridPullRight': {
                        textAlign: 'left'
                    },
                    '&$cardRowGridPullCenter': {
                        textAlign: 'left'
                    }
                }
            }
        },
        '& $actionButtonContainer': {
            textAlign: 'center',
            marginTop: 'unset',
            marginLeft: 'auto',
            width: '100%',
            maxWidth: 250,
            [theme.breakpoints.up('sm')]: {
                textAlign: 'right'
            },
            [theme.breakpoints.between('sm', 'md')]: {
                marginTop: 6
            },
            '& button': {
                width: 175,
                color: 'rgba(0, 0, 0, 0.54)',
                marginBottom: 8,
                [theme.breakpoints.up('lg')]: {
                    width: '100%'
                }
            }
        },
        '& h6': {
            fontSize: 10,
            fontWeight: 'normal',
            letterSpacing: '1.5px',
            lineHeight: '12px',
            marginBottom: 4
        },
        '& p': {
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: '0.1px'
        }
    },
    showDetailButton: {
        marginTop: 16
    },
    paginator: {
        marginLeft: 'auto !important',
        display: 'block !important',
        paddingTop: '24px !important',
        paddingBottom: '12px !important',
        border: 'none !important',
        overflow: 'unset !important',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '14px !important',
            paddingBottom: '14px !important'
        },
        '& .MuiTablePagination-selectRoot': {
            marginRight: 16
        },
        '& .MuiTablePagination-actions': {
            marginLeft: 0
        },
        '& > div': {
            flexWrap: 'wrap',
            paddingLeft: 0,
            justifyContent: 'flex-end'
        }
    },
    searchInput: {
        marginRight: 32,
        marginTop: 16,
        width: '100%',
        borderRadius: 2,
        background: '#fff',
        '& input': {
            padding: 10
        },
        '& svg': {
            color: 'rgba(0, 0, 0, 0.24)'
        }
    },
    cardRowGrid: {},
    cardRowGridPullRight: {},
    cardRowGridPullCenter: {},
    actionButtonContainer: {}
}));

const OrderPaymentStatus = ({
    status
}: {
    status: OrderSummary['status'] | OrderSummary['orderFailureReason'] | PaymentAttributes['status'];
}) => {
    const classes = makeStyles((theme) => ({
        root: {
            justifyContent: 'flex-end',
            [theme.breakpoints.up('sm')]: {
                justifyContent: 'flex-start'
            }
        },
        statusIcon: {
            height: 14,
            width: 14
        },
        warning: {
            color: theme.palette.warning.main
        },
        text: {
            marginBottom: '0 !important',
            fontWeight: 500,
            fontSize: '12px !important',
            marginLeft: 5
        }
    }))();

    const statusIconMap = {
        COMPLETE: {
            icon: <CheckCircleIcon color="primary" className={classes.statusIcon} />,
            text: (
                <Typography color="primary" variant="subtitle2" className={classes.text}>
                    COMPLETE
                </Typography>
            )
        },
        SUCCESS: {
            icon: <CheckCircleIcon color="primary" className={classes.statusIcon} />,
            text: (
                <Typography color="primary" variant="subtitle2" className={classes.text}>
                    SUCCESS
                </Typography>
            )
        },
        PENDING: {
            icon: <WarningIcon className={clsx(classes.warning, classes.statusIcon)} />,
            text: (
                <Typography className={clsx(classes.text, classes.warning)} color="primary" variant="subtitle2">
                    PENDING
                </Typography>
            )
        },
        SCHEDULED: {
            icon: <ScheduleIcon className={clsx(classes.warning, classes.statusIcon)} />,
            text: (
                <Typography className={clsx(classes.text, classes.warning)} color="primary" variant="subtitle2">
                    SCHEDULED
                </Typography>
            )
        },
        FAIL: {
            icon: <ErrorIcon color="error" className={classes.statusIcon} />,
            text: (
                <Typography color="error" variant="subtitle2" className={classes.text}>
                    FAILED
                </Typography>
            )
        },
        CANCELED: {
            icon: <ErrorIcon color="error" className={classes.statusIcon} />,
            text: (
                <Typography color="error" variant="subtitle2" className={classes.text}>
                    CANCELED
                </Typography>
            )
        },
        CANCELLATION_FAILED: {
            icon: <ErrorIcon color="error" className={classes.statusIcon} />,
            text: (
                <Typography color="error" variant="subtitle2" className={classes.text}>
                    CANCELLATION FAILED
                </Typography>
            )
        },
        ATTENTION: {
            icon: <ErrorIcon color="error" className={classes.statusIcon} />,
            text: (
                <Typography color="error" variant="subtitle2" className={classes.text}>
                    NEED ATTENTION
                </Typography>
            )
        },
        ON_HOLD: {
            icon: <WarningIcon className={clsx(classes.warning, classes.statusIcon)} />,
            text: (
                <Typography className={clsx(classes.text, classes.warning)} color="primary" variant="subtitle2">
                    ON HOLD
                </Typography>
            )
        },
        GIFT_CARD_ORDER_FAILURE: {
            icon: <ErrorIcon color="error" className={classes.statusIcon} />,
            text: (
                <Typography color="error" variant="subtitle2" className={classes.text}>
                    EGIFTER ORDER FAILURE
                </Typography>
            )
        },
        PAYMENT_FAILURE: {
            icon: <ErrorIcon color="error" className={classes.statusIcon} />,
            text: (
                <Typography color="error" variant="subtitle2" className={classes.text}>
                    PAYMENT FAILURE
                </Typography>
            )
        },
        FRAUD: {
            icon: <ErrorIcon color="error" className={classes.statusIcon} />,
            text: (
                <Typography color="error" variant="subtitle2" className={classes.text}>
                    FRAUD USER
                </Typography>
            )
        },
        INTERNAL_FAILURE: {
            icon: <ErrorIcon color="error" className={classes.statusIcon} />,
            text: (
                <Typography color="error" variant="subtitle2" className={classes.text}>
                    SERVER ERROR
                </Typography>
            )
        }
    };

    const { icon, text } = statusIconMap[status || 'FAIL'];
    return (
        <Box display="flex" alignItems="center" className={classes.root}>
            {icon}
            {text}
        </Box>
    );
};

const filterOrders = (orders: OrderSummary[], state: State) => {
    // sort the orders by createdAt date so that latest orders are always on top
    orders = orders.sort((a, b) => moment(b.updatedAt).diff(moment(a.updatedAt)));
    const { rowsPerPage, page, searchText } = state;
    let totalRecords = orders.length;

    // filter the orders for matching search text
    orders =
        (searchText &&
            orders.filter((o) => {
                const userIdMatch = `${o.userId}`.indexOf(searchText) !== -1;
                if (userIdMatch) {
                    return true;
                }
                const orderIdMatch = `${o.orderId}`.indexOf(searchText) !== -1;
                if (orderIdMatch) {
                    return true;
                }
                const itemMatch = o.items.find((item) => item.name?.match(new RegExp(searchText, 'i')));
                if (itemMatch) {
                    return true;
                }
                const totalQuantityMatch = o.items.reduce((acc, cur) => acc + cur.quantity, 0) === +searchText;
                if (totalQuantityMatch) {
                    return true;
                }
                const orderTotal = o.total || o.baseTotal || 0;
                const priceMatch = formatDinero(generateDinero(orderTotal))
                    .replace('$', '')
                    .match(new RegExp(searchText, 'i'));
                if (priceMatch) {
                    return true;
                }

                return false;
            })) ||
        orders;

    totalRecords = orders.length;

    // page limit
    orders = (rowsPerPage > 0 && orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)) || orders;

    return { orders, totalRecords };
};

const calculateProfit = (o: OrderSummary) => {
    const orderTotal = o.orderUserType === 'B2C_USER' ? o.total || 0 : o.convertedTotal || 0;
    const total = generateDinero(orderTotal, o.convertedCurrency);

    const eGifterDiscountAmount = o.items.reduce((totalDiscountAmount, item) => {
        const price = item.price || item.basePrice || 0;
        const discountAmount = generateDinero(price).percentage((item.eGifterDiscount || 0) / 100);
        const discountAmountForQuantity = discountAmount.multiply(item.quantity);

        return totalDiscountAmount.add(discountAmountForQuantity);
    }, generateDinero(0));
    const eGifterFee = generateDinero(o.eGifterFee);
    const cashback = generateDinero(o.cashback || 0);
    const paymentGatewayFee = generateDinero(o.paymentGatewayFee || 0);

    let promoCodeDiscount =
        (o.promoCode && o.promoCode.type === 'PERCENTILE' && total.percentage(o.promoCode.value || 0)) ||
        (o.promoCode && o.promoCode.type === 'AMOUNT' && getDinero(o.promoCode.value)) ||
        generateDinero(0);

    const promoCodeMaxAmount =
        (o.promoCode && o.promoCode.maxAmount && getDinero(o.promoCode.maxAmount)) || promoCodeDiscount;
    promoCodeDiscount = promoCodeDiscount.greaterThan(promoCodeMaxAmount) ? promoCodeMaxAmount : promoCodeDiscount;

    // egifter fee + payment gateway fee + cashback amount + promocode amount
    const cost = eGifterFee.add(paymentGatewayFee.add(cashback.add(promoCodeDiscount)));
    const profit = eGifterDiscountAmount.subtract(cost);

    return profit;
};

type DialogState = {
    type: 'USER_DETAILS' | 'ORDER_DETAILS';
    open: boolean;
    user: UserAccount | UserAccountWithBillingAddress | null;
    order: OrderAttributes | null;
    transactionLimitSetting: TransactionLimitSettingValue | null;
    loading: boolean;
};

const OrderHistory = () => {
    const classes = useOrderStyles();

    const apiService = useApiService();
    const toasterContext = useToasterData();

    const [state, setState] = useState<State>(DEFAULT_STATE);
    const [defaultLimitSettings, setDefaultSettings] = useState<TransactionLimitSettingValue | null>(null);
    const [dialog, setDialog] = React.useState<DialogState>({
        type: 'USER_DETAILS',
        open: false,
        loading: false,
        user: null,
        order: null,
        transactionLimitSetting: null
    });

    const [refundList, setRefundList] = useState<RefundList[]>([]);

    const clearAutomaticRefresh = React.useRef<unknown>();

    const { appAction, manageUserAction, manageTransactionLimitSettingsAction, manageBusinessUserAction } =
        storeContext();

    const getOrderHistory = async (showLoader = true, requestBody?: TransactionRequest) => {
        try {
            const apiResponse = await apiService.post('/api/admin/transaction/get-all', requestBody);
            const response = apiResponse.parsedBody;
            if (!response.status) {
                toasterContext.setToaster({
                    isOpen: true,
                    message: response.message,
                    severity: 'error'
                });
                setState({
                    ...state,
                    isLoading: showLoader,
                    page: 0,
                    ordersFetched: true,
                    orderUserType: requestBody?.orderUserType || B2C_USER
                });
                return;
            }

            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                page: 0,
                ordersFetched: true,
                orderUserType: requestBody?.orderUserType || B2C_USER,
                orders: response.data as OrderSummary[]
            }));
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
        }
    };

    const getRefundCashbackHistory = async (orderId: number) => {
        try {
            const apiResponse = await apiService.get(
                `/api/admin/transaction/refund/status/get-by-order-id/${orderId}?orderUserType=${state.orderUserType}`
            );
            const response = apiResponse.parsedBody;
            setRefundList((prevrefundList) => [
                ...prevrefundList,
                ...response.data.map((value: WalletAttributes | B2BUserFundTransactionAttributes) => {
                    if ('amount' in value) {
                        return { orderId: value.orderId, value: value.amount };
                    }
                    return { orderId: value.orderId, value: value.value };
                })
            ]);
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
        }
    };

    const getOrderDetailsByOrderId = async (orderId: number) => {
        try {
            const apiResponse = await apiService.get(
                `/api/admin/transaction/order/${orderId}?orderUserType=${state.orderUserType}`
            );
            const response = apiResponse.parsedBody;

            if (!response.status) {
                toasterContext.setToaster({
                    isOpen: true,
                    message: response.message,
                    severity: 'error'
                });
                return null;
            }

            return response.data as OrderAttributes | null;
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
            return null;
        }
    };

    const resendEmailsRequest = async (recipientIds: number[]) => {
        try {
            const apiResponse = await apiService.post('/api/admin/transaction/resend-giftcards', {
                recipientIds,
                orderUserType: state.orderUserType
            });
            const response = apiResponse.parsedBody;
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'success'
            });
            return response.data as OrderSummary[];
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
            return null;
        }
    };

    const voidPaymentMethod = async (orderId: number) => {
        try {
            const apiResponse = await apiService.post('/api/admin/transaction/void-payment', {
                orderId: orderId,
                orderUserType: state.orderUserType
            });
            const response = apiResponse.parsedBody;
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'success'
            });
            getOrderHistory(true, {
                userId: null,
                orderId: null,
                date: [null, null],
                status: null,
                emailDeliveryStatus: null,
                paymentStatus: null,
                orderUserType: state.orderUserType,
                orderType: null,
                deliveryType: null
            });
            return response.data as OrderSummary[];
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
            return null;
        }
    };

    const resendPaymentMethod = async (
        orderId: number,
        total: number,
        amount: number,
        orderItemRecipientId: number
    ) => {
        try {
            const apiResponse = await apiService.post('/api/admin/transaction/refund-payment', {
                orderId: orderId,
                total: total / 100,
                amount: amount,
                orderItemRecipientId: orderItemRecipientId,
                orderUserType: state.orderUserType
            });
            const response = apiResponse.parsedBody;
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'success'
            });
            getOrderHistory(true, {
                userId: null,
                orderId: null,
                date: [null, null],
                status: null,
                emailDeliveryStatus: null,
                paymentStatus: null,
                orderUserType: state.orderUserType,
                orderType: null,
                deliveryType: null
            });
            return response.data as WalletAttributes[];
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
            return null;
        }
    };

    const resendSuccessfulGiftCard = async (recipientId: number) => {
        try {
            const apiResponse = await apiService.post('/api/admin/transaction/resend-successful-giftcard', {
                recipientId: recipientId,
                orderUserType: state.orderUserType
            });
            const response = apiResponse.parsedBody;
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'success'
            });
            return response.data as OrderSummary[];
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
            return null;
        }
    };

    const resendBulkEmails = async (recipientIds: number[]) => {
        if (recipientIds.length === 0) {
            toasterContext.setToaster({
                isOpen: true,
                message: 'No PENDING or FAILED gift cards in the selected orders',
                severity: 'warning'
            });
            return;
        }
        setState((prevState) => ({ ...prevState, isResendButtonLoading: true }));
        const orders = await resendEmailsRequest(recipientIds);
        setState((prevState) => ({
            ...prevState,
            orders: orders || prevState.orders,
            isResendButtonLoading: false
        }));
    };

    const resendEmail = async (recipientId: number) => {
        const orders = await resendEmailsRequest([recipientId]);
        setState((prevState) => ({
            ...prevState,
            orders: orders || prevState.orders
        }));
    };

    const resendSuccessfulGiftCardMethod = async (recipientId: number) => {
        const resendOrders = await resendSuccessfulGiftCard(recipientId);
        setState((prevState) => ({
            ...prevState,
            orders: resendOrders || prevState.orders
        }));
    };

    const getDefaultTransactionSettings = async () => {
        const transactionLimitSettings = await manageTransactionLimitSettingsAction()?.getTransactionLimitSettings();
        const defaultTransactionSetting = getDefaultSetting(transactionLimitSettings || []);
        setDefaultSettings(defaultTransactionSetting);
    };

    useEffect(() => {
        appAction()?.renderFullHeader();
        getOrderHistory(true);
        getDefaultTransactionSettings();
        clearAutomaticRefresh.current = setInterval(() => getOrderHistory(false), 1000 * 60 * 5);
        return () => clearInterval(clearAutomaticRefresh.current as number);
    }, []);

    const toggleAccordion = async (orderId: number) => {
        await getRefundCashbackHistory(orderId);
        setState((prevState) => {
            const expandedAccordions = new Set(prevState.expandedAccordions);
            if (expandedAccordions.has(orderId)) {
                expandedAccordions.delete(orderId);
            } else {
                expandedAccordions.add(orderId);
            }

            return {
                ...prevState,
                expandedAccordions
            };
        });
    };

    const isExpanded = (orderId: number) => state.expandedAccordions.has(orderId);

    const handleChangePage = (event: unknown, page: number) => {
        setState({ ...state, page });
        setTimeout(() => scrollTop());
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (state.rowsPerPage > +event.target.value) {
            setTimeout(() => scrollTop());
        }
        setState({ ...state, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        setState({ ...state, searchText: text });
    };

    const { orders, totalRecords } = filterOrders(state.orders, state);

    const closeDialog = () => {
        setDialog((prevState) => ({ ...prevState, open: false }));
    };

    const onFraudDetailsClick = async (orderId: number) => {
        setDialog((prevState) => ({ ...prevState, type: 'ORDER_DETAILS', open: true, loading: true }));

        const order = await getOrderDetailsByOrderId(orderId);

        setDialog((prevState) => ({
            ...prevState,
            loading: false,
            order
        }));
    };

    const onUserIdClick = async (userId: number) => {
        setDialog((prevState) => ({ ...prevState, type: 'USER_DETAILS', open: true, loading: true }));

        const userPromise =
            state.orderUserType === 'B2C_USER'
                ? manageUserAction()?.getUserByUserId(userId)
                : manageBusinessUserAction()?.getBusinessUserByUserId(userId);

        const [user, transactionLimitSetting] = await Promise.all([
            userPromise,
            state.orderUserType === 'B2C_USER'
                ? manageTransactionLimitSettingsAction()?.getUserTransactionLimitSettingsByUserId(userId)
                : Promise.resolve(null)
        ]);

        setDialog((prevState) => ({
            ...prevState,
            loading: false,
            user: user || null,
            transactionLimitSetting:
                transactionLimitSetting || (state.orderUserType === 'B2C_USER' ? defaultLimitSettings : null)
        }));
    };

    const getModalDetails = () => {
        if (dialog.type === 'ORDER_DETAILS' && dialog.order) {
            return <OrderDetails order={dialog.order} />;
        }

        if (dialog.type === 'USER_DETAILS' && dialog.user) {
            return state.orderUserType === B2C_USER ? (
                <ManageUserEditForm
                    hideRevokingReasonFormField
                    user={dialog.user}
                    transactionLimitSetting={dialog.transactionLimitSetting}
                />
            ) : (
                <ManageBusinessUserEditForm hideRevokingReasonFormField user={dialog.user} />
            );
        }
    };

    return (
        <Box>
            <Box className={classes.card}>
                <OrderHistoryFilter
                    fetchingOrders={state.isLoading}
                    onFilter={ async (data) => {
                        const startDateUTC = data.dateRange[0] ? moment(data.dateRange[0]).utc().format() : null;
                        const endDateUTC = data.dateRange[1] ? moment(data.dateRange[1]).utc().format() : null;
                        await getOrderHistory(true, {
                            userId: data.userId,
                            orderId: data.orderId,
                            date: [startDateUTC, endDateUTC],
                            status: data.orderStatus === 'ALL' ? null : data.orderStatus,
                            emailDeliveryStatus: data.emailDeliveryStatus === 'ALL' ? null : data.emailDeliveryStatus,
                            paymentStatus: data.paymentStatus === 'ALL' ? null : data.paymentStatus,
                            orderUserType: data.orderUserType ?? B2C_USER,
                            orderType: data.orderType === 'ALL' ? null : data.orderType,
                            deliveryType: data.deliveryType === 'ALL' ? null : data.deliveryType
                        });
                    }}
                />
            </Box>

            <Box px={2} py={2} my={4} className={classes.card}>
                <Box display="flex" justifyContent="space-between">
                    <Typography align="left">Search Results</Typography>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() =>
                            resendBulkEmails(
                                orders.reduce((acc, cur) => {
                                    const recipientIds = cur.items.reduce(
                                        (recipientIds, item) => [
                                            ...recipientIds,
                                            ...item.recipients
                                                .filter((recipient) =>
                                                    ['FAIL', 'PENDING'].includes(recipient.status || '')
                                                )
                                                .map((recipient) => recipient.recipientId)
                                        ],
                                        [] as number[]
                                    );

                                    return [...acc, ...recipientIds];
                                }, [] as number[])
                            )
                        }
                        disabled={state.isResendButtonLoading}
                    >
                        Bulk gift card resend
                        {state.isResendButtonLoading && <CircularProgress className="button-loader" />}
                    </Button>
                </Box>

                <AppTextField
                    variant="outlined"
                    placeholder="Search"
                    className={classes.searchInput}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                    value={state.searchText || ''}
                    onChange={handleSearch}
                />

                {orders && !orders.length ? <DataLoader isLoading={state.isLoading} items={orders} /> : null}

                {orders.map((o) => {
                    const total = generateDinero(o.total || o.baseTotal || 0);
                    const profit = calculateProfit(o);
                    const cardmoolaFundsAdded = o.cardmoolaFundsAdded
                        ? o.cardmoolaFundsAdded > 0
                            ? true
                            : false
                        : false;

                    return (
                        <React.Fragment key={o.orderId}>
                            <Card key={o.orderId} className={classes.cardRow}>
                                <Grid container className={classes.cardRowGrid}>
                                    <Grid item xs={6} sm={4} lg={2}>
                                        <Typography component="h6">ORDER #</Typography>
                                        <Typography>{o.orderId}</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={4} lg={2}>
                                        <Box flexWrap="wrap">
                                            <Typography component="h6">CREATED BY USER ID</Typography>
                                            <Link className="cursor-pointer" onClick={() => onUserIdClick(o.userId!)}>
                                                #{o.userId}
                                            </Link>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} sm={4} lg={2}>
                                        <Typography component="h6">CREATION DATE</Typography>
                                        <Typography>
                                            {formatTimeStamp(o.createdAt, DateFormatTypeEnum.MMMM_D_YYYY_h_mm_a)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5} sm={4} lg={2}>
                                        <Typography component="h6">PRICE</Typography>
                                        <Typography>{formatDinero(total)}</Typography>
                                    </Grid>
                                    {state.orderUserType === B2C_USER && state.ordersFetched && (
                                        <Grid item xs={5} sm={4} lg={2}>
                                            <Typography component="h6">PROFIT</Typography>
                                            <Typography color={profit.isNegative() ? 'error' : 'secondary'}>
                                                {formatDinero(profit)}
                                            </Typography>
                                        </Grid>
                                    )}
                                    {!cardmoolaFundsAdded && (
                                        <Grid item xs={'auto'} sm={4} lg={2} className={classes.cardRowGridPullCenter}>
                                            <Typography component="h6">ITEMS</Typography>
                                            <Typography>
                                                {o.orderType === 'CARDMOOLA_FUNDS' || o.orderType === 'SUBSCRIPTION'
                                                    ? 1
                                                    : o.items.length}
                                            </Typography>
                                        </Grid>
                                    )}

                                    {cardmoolaFundsAdded && (
                                        <Grid item xs={'auto'} sm={4} lg={2} className={classes.cardRowGridPullCenter}>
                                            <Typography component="h6">ITEMS</Typography>
                                            <Typography>FUND CREDIT</Typography>
                                        </Grid>
                                    )}
                                    <Grid item xs={5} sm={4} lg={2} className={classes.cardRowGridPullRight}>
                                        <Typography component="h6">ORDER STATUS</Typography>
                                        <Box mt={1}>
                                            <OrderPaymentStatus status={o.status} />
                                        </Box>
                                    </Grid>
                                    {o.orderFailureReason && (
                                        <Grid item xs={5} sm={4} lg={2} className={classes.cardRowGridPullRight}>
                                            <Typography component="h6">FAILURE REASON</Typography>
                                            <Box mt={1}>
                                                <OrderPaymentStatus status={o.orderFailureReason} />
                                            </Box>
                                        </Grid>
                                    )}
                                    <Grid item xs={5} sm={4} lg={2}>
                                        <Typography component="h6">FAILURE REASON DETAIL</Typography>
                                        <Typography color="error">
                                            {o.orderFailureReasonDetail?.replace(/_/g, ' ') || '-'}
                                        </Typography>
                                    </Grid>
                                    {o.payment && o.payment.status && (
                                        <Grid item xs={5} sm={4} lg={2} className={classes.cardRowGridPullRight}>
                                            <Typography component="h6">PAYMENT STATUS</Typography>
                                            <Box mt={1}>
                                                <OrderPaymentStatus status={o.payment.status} />
                                            </Box>
                                        </Grid>
                                    )}
                                    {o.payment && (
                                        <Grid item xs={5} sm={4} lg={2} className={classes.cardRowGridPullRight}>
                                            <Typography component="h6">EXTERNAL PAYMENT ID</Typography>
                                            <Typography>{o.payment?.externalPaymentCaptureId || 'NA'}</Typography>
                                        </Grid>
                                    )}
                                    {o.orderFailureErrorMessage && (
                                        <Grid item xs={12} className={classes.cardRowGridPullRight}>
                                            <Typography component="h6">FAILURE ERROR MESSAGE</Typography>
                                            <Typography>{o?.orderFailureErrorMessage}</Typography>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} sm={12} lg={12} className={classes.showDetailButton}>
                                        <Box className={classes.actionButtonContainer}>
                                            <Button variant="contained" onClick={() => onFraudDetailsClick(o.orderId)}>
                                                View Fraud Details
                                            </Button>
                                            <Button variant="outlined" onClick={() => toggleAccordion(o.orderId)}>
                                                {isExpanded(o.orderId) ? 'HIDE DETAILS' : 'SHOW DETAILS'}
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Card>
                            <Collapse in={isExpanded(o.orderId)} timeout="auto" unmountOnExit={true}>
                                <ItemsOrdered
                                    orderStatus={o.status}
                                    orderType={o.orderType}
                                    subscription={o.subscription}
                                    items={o.items}
                                    promoCode={o.promoCode}
                                    total={o.total || o.baseTotal || 0}
                                    convertedTotal={o.convertedTotal}
                                    convertedCurrency={o.convertedCurrency}
                                    orderId={o.orderId}
                                    cashback={o.cashback || 0}
                                    walletFundsUsed={o.walletFundsUsed || 0}
                                    resendEmail={resendEmail}
                                    resendSuccessfulGiftCardMethod={resendSuccessfulGiftCardMethod}
                                    voidMethod={voidPaymentMethod}
                                    refundMethod={resendPaymentMethod}
                                    refund={refundList}
                                    eGifterFee={o.eGifterFee}
                                    paymentGatewayFee={o.paymentGatewayFee}
                                    paymentDetails={o.payment}
                                    redemptionDetails={o.redemptionDetails}
                                    title={o.cardmoolaFundsAdded ? 'Credit Details' : 'Order Details'}
                                    cardmoolaFundsUsed={o.cardmoolaFundsUsed || 0}
                                    cardmoolaFundsAdded={o.cardmoolaFundsAdded || 0}
                                    orderUserType={state.orderUserType}
                                    ordersFetched={state.ordersFetched}
                                    getOrderHistory={getOrderHistory}
                                    getRefundCashbackHistory={getRefundCashbackHistory}
                                />
                            </Collapse>
                        </React.Fragment>
                    );
                })}

                <TablePagination
                    classes={{ root: classes.paginator }}
                    rowsPerPageOptions={[5, 10, 25]}
                    count={totalRecords}
                    component="div"
                    rowsPerPage={state.rowsPerPage}
                    page={state.page}
                    SelectProps={{ inputProps: { 'aria-label': 'rows per page' } }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />

                <ConfirmationDialog
                    additionalContent={
                        <>
                            {dialog.loading && <CircularProgress />}
                            {!dialog.loading && getModalDetails()}
                        </>
                    }
                    open={dialog.open}
                    maxWidth={dialog.type === 'USER_DETAILS' ? 'md' : 'xl'}
                    fullWidth
                    onClose={closeDialog}
                />
            </Box>
        </Box>
    );
};

export default OrderHistory;
