export interface CountryCode {
    [key: string]: {
        name: string;
        code: string;
        phoneMask: string;
        phoneLength: number;
    };
}

export const COUNTRY_CODES: CountryCode = {
    // 'AD': { name: 'Andorra', code: '+376' },
    // 'AE': { name: 'United Arab Emirates', code: '+971' },
    // 'AF': { name: 'Afghanistan', code: '+93' },
    // 'AG': { name: 'Antigua and Barbuda', code: '+1-268' },
    // 'AI': { name: 'Anguilla', code: '+1-264' },
    // 'AL': { name: 'Albania', code: '+355' },
    // 'AM': { name: 'Armenia', code: '+374' },
    // 'AO': { name: 'Angola', code: '+244' },
    // 'AQ': { name: 'Antarctica', code: '+672' },
    // 'AR': { name: 'Argentina', code: '+54' },
    // 'AS': { name: 'American Samoa', code: '+1-684' },
    // 'AT': { name: 'Austria', code: '+43' },
    // 'AU': { name: 'Australia', code: '+61' },
    // 'AW': { name: 'Aruba', code: '+297' },
    // 'AX': { name: 'Alland Islands', code: '+358' },
    // 'AZ': { name: 'Azerbaijan', code: '+994' },
    // 'BA': { name: 'Bosnia and Herzegovina', code: '+387' },
    // 'BB': { name: 'Barbados', code: '+1-246' },
    // 'BD': { name: 'Bangladesh', code: '+880' },
    // 'BE': { name: 'Belgium', code: '+32' },
    // 'BF': { name: 'Burkina Faso', code: '+226' },
    // 'BG': { name: 'Bulgaria', code: '+359' },
    // 'BH': { name: 'Bahrain', code: '+973' },
    // 'BI': { name: 'Burundi', code: '+257' },
    // 'BJ': { name: 'Benin', code: '+229' },
    // 'BL': { name: 'Saint Barthelemy', code: '+590' },
    // 'BM': { name: 'Bermuda', code: '+1-441' },
    // 'BN': { name: 'Brunei Darussalam', code: '+673' },
    // 'BO': { name: 'Bolivia', code: '+591' },
    // 'BR': { name: 'Brazil', code: '+55' },
    // 'BS': { name: 'Bahamas', code: '+1-242' },
    // 'BT': { name: 'Bhutan', code: '+975' },
    // 'BV': { name: 'Bouvet Island', code: '+47' },
    // 'BW': { name: 'Botswana', code: '+267' },
    // 'BY': { name: 'Belarus', code: '+375' },
    // 'BZ': { name: 'Belize', code: '+501' },
    CA: { name: 'Canada', code: '+1', phoneMask: '+1 (999) 999-9999', phoneLength: 10 },
    // 'CC': { name: 'Cocos (Keeling) Islands', code: '+61' },
    // 'CD': { name: 'Congo, Democratic Republic of the', code: '+243' },
    // 'CF': { name: 'Central African Republic', code: '+236' },
    // 'CG': { name: 'Congo, Republic of the', code: '+242' },
    // 'CH': { name: 'Switzerland', code: '+41' },
    // 'CI': { name: `Cote d'Ivoire`, code: '+225' },
    // 'CK': { name: 'Cook Islands', code: '+682' },
    // 'CL': { name: 'Chile', code: '+56' },
    // 'CM': { name: 'Cameroon', code: '+237' },
    // 'CN': { name: 'China', code: '+86' },
    // 'CO': { name: 'Colombia', code: '+57' },
    // 'CR': { name: 'Costa Rica', code: '+506' },
    // 'CU': { name: 'Cuba', code: '+53' },
    // 'CV': { name: 'Cape Verde', code: '+238' },
    // 'CW': { name: 'Curacao', code: '+599' },
    // 'CX': { name: 'Christmas Island', code: '+61' },
    // 'CY': { name: 'Cyprus', code: '+357' },
    // 'CZ': { name: 'Czech Republic', code: '+420' },
    // 'DE': { name: 'Germany', code: '+49' },
    // 'DJ': { name: 'Djibouti', code: '+253' },
    // 'DK': { name: 'Denmark', code: '+45' },
    // 'DM': { name: 'Dominica', code: '+1-767' },
    // 'DO': { name: 'Dominican Republic', code: '+1-809' },
    // 'DZ': { name: 'Algeria', code: '+213' },
    // 'EC': { name: 'Ecuador', code: '+593' },
    // 'EE': { name: 'Estonia', code: '+372' },
    // 'EG': { name: 'Egypt', code: '+20' },
    // 'EH': { name: 'Western Sahara', code: '+212' },
    // 'ER': { name: 'Eritrea', code: '+291' },
    // 'ES': { name: 'Spain', code: '+34' },
    // 'ET': { name: 'Ethiopia', code: '+251' },
    // 'FI': { name: 'Finland', code: '+358' },
    // 'FJ': { name: 'Fiji', code: '+679' },
    // 'FK': { name: 'Falkland Islands (Malvinas)', code: '+500' },
    // 'FM': { name: 'Micronesia, Federated States of', code: '+691' },
    // 'FO': { name: 'Faroe Islands', code: '+298' },
    // 'FR': { name: 'France', code: '+33' },
    // 'GA': { name: 'Gabon', code: '+241' },
    // 'GB': { name: 'United Kingdom', code: '+44' },
    // 'GD': { name: 'Grenada', code: '+1-473' },
    // 'GE': { name: 'Georgia', code: '+995' },
    // 'GF': { name: 'French Guiana', code: '+594' },
    // 'GG': { name: 'Guernsey', code: '+44' },
    // 'GH': { name: 'Ghana', code: '+233' },
    // 'GI': { name: 'Gibraltar', code: '+350' },
    // 'GL': { name: 'Greenland', code: '+299' },
    // 'GM': { name: 'Gambia', code: '+220' },
    // 'GN': { name: 'Guinea', code: '+224' },
    // 'GP': { name: 'Guadeloupe', code: '+590' },
    // 'GQ': { name: 'Equatorial Guinea', code: '+240' },
    // 'GR': { name: 'Greece', code: '+30' },
    // 'GS': { name: 'South Georgia and the South Sandwich Islands', code: '+500' },
    // 'GT': { name: 'Guatemala', code: '+502' },
    // 'GU': { name: 'Guam', code: '+1-671' },
    // 'GW': { name: 'Guinea-Bissau', code: '+245' },
    // 'GY': { name: 'Guyana', code: '+592' },
    // 'HK': { name: 'Hong Kong', code: '+852' },
    // 'HM': { name: 'Heard Island and McDonald Islands', code: '+672' },
    // 'HN': { name: 'Honduras', code: '+504' },
    // 'HR': { name: 'Croatia', code: '+385' },
    // 'HT': { name: 'Haiti', code: '+509' },
    // 'HU': { name: 'Hungary', code: '+36' },
    // 'ID': { name: 'Indonesia', code: '+62' },
    // 'IE': { name: 'Ireland', code: '+353' },
    // 'IL': { name: 'Israel', code: '+972' },
    // 'IM': { name: 'Isle of Man', code: '+44' },
    IN: { name: 'India', code: '+91', phoneMask: '+\\91 99999-99999', phoneLength: 10 },
    // 'IO': { name: 'British Indian Ocean Territory', code: '+246' },
    // 'IQ': { name: 'Iraq', code: '+964' },
    // 'IR': { name: 'Iran, Islamic Republic of', code: '+98' },
    // 'IS': { name: 'Iceland', code: '+354' },
    // 'IT': { name: 'Italy', code: '+39' },
    // 'JE': { name: 'Jersey', code: '+44' },
    // 'JM': { name: 'Jamaica', code: '+1-876' },
    // 'JO': { name: 'Jordan', code: '+962' },
    // 'JP': { name: 'Japan', code: '+81' },
    // 'KE': { name: 'Kenya', code: '+254' },
    // 'KG': { name: 'Kyrgyzstan', code: '+996' },
    // 'KH': { name: 'Cambodia', code: '+855' },
    // 'KI': { name: 'Kiribati', code: '+686' },
    // 'KM': { name: 'Comoros', code: '+269' },
    // 'KN': { name: 'Saint Kitts and Nevis', code: '+1-869' },
    // 'KP': { name: `Korea, Democratic People's Republic of`, code: '+850' },
    // 'KR': { name: 'Korea, Republic of', code: '+82' },
    // 'KW': { name: 'Kuwait', code: '+965' },
    // 'KY': { name: 'Cayman Islands', code: '+1-345' },
    // 'KZ': { name: 'Kazakhstan', code: '+7' },
    // 'LA': { name: `Lao People's Democratic Republic`, code: '+856' },
    // 'LB': { name: 'Lebanon', code: '+961' },
    // 'LC': { name: 'Saint Lucia', code: '+1-758' },
    // 'LI': { name: 'Liechtenstein', code: '+423' },
    // 'LK': { name: 'Sri Lanka', code: '+94' },
    // 'LR': { name: 'Liberia', code: '+231' },
    // 'LS': { name: 'Lesotho', code: '+266' },
    // 'LT': { name: 'Lithuania', code: '+370' },
    // 'LU': { name: 'Luxembourg', code: '+352' },
    // 'LV': { name: 'Latvia', code: '+371' },
    // 'LY': { name: 'Libya', code: '+218' },
    // 'MA': { name: 'Morocco', code: '+212' },
    // 'MC': { name: 'Monaco', code: '+377' },
    // 'MD': { name: 'Moldova, Republic of', code: '+373' },
    // 'ME': { name: 'Montenegro', code: '+382' },
    // 'MF': { name: 'Saint Martin (French part)', code: '+590' },
    // 'MG': { name: 'Madagascar', code: '+261' },
    // 'MH': { name: 'Marshall Islands', code: '+692' },
    // 'MK': { name: 'Macedonia, the Former Yugoslav Republic of', code: '+389' },
    // 'ML': { name: 'Mali', code: '+223' },
    // 'MM': { name: 'Myanmar', code: '+95' },
    // 'MN': { name: 'Mongolia', code: '+976' },
    // 'MO': { name: 'Macao', code: '+853' },
    // 'MP': { name: 'Northern Mariana Islands', code: '+1-670' },
    // 'MQ': { name: 'Martinique', code: '+596' },
    // 'MR': { name: 'Mauritania', code: '+222' },
    // 'MS': { name: 'Montserrat', code: '+1-664' },
    // 'MT': { name: 'Malta', code: '+356' },
    // 'MU': { name: 'Mauritius', code: '+230' },
    // 'MV': { name: 'Maldives', code: '+960' },
    // 'MW': { name: 'Malawi', code: '+265' },
    MX: { name: 'Mexico', code: '+52', phoneMask: '+52 999-999-9999', phoneLength: 10 },
    // 'MY': { name: 'Malaysia', code: '+60' },
    // 'MZ': { name: 'Mozambique', code: '+258' },
    // 'NA': { name: 'Namibia', code: '+264' },
    // 'NC': { name: 'New Caledonia', code: '+687' },
    // 'NE': { name: 'Niger', code: '+227' },
    // 'NF': { name: 'Norfolk Island', code: '+672' },
    // 'NG': { name: 'Nigeria', code: '+234' },
    // 'NI': { name: 'Nicaragua', code: '+505' },
    // 'NL': { name: 'Netherlands', code: '+31' },
    // 'NO': { name: 'Norway', code: '+47' },
    // 'NP': { name: 'Nepal', code: '+977' },
    // 'NR': { name: 'Nauru', code: '+674' },
    // 'NU': { name: 'Niue', code: '+683' },
    // 'NZ': { name: 'New Zealand', code: '+64' },
    // 'OM': { name: 'Oman', code: '+968' },
    // 'PA': { name: 'Panama', code: '+507' },
    // 'PE': { name: 'Peru', code: '+51' },
    // 'PF': { name: 'French Polynesia', code: '+689' },
    // 'PG': { name: 'Papua New Guinea', code: '+675' },
    // 'PH': { name: 'Philippines', code: '+63' },
    // 'PK': { name: 'Pakistan', code: '+92' },
    PL: { name: 'Poland', code: '+48', phoneMask: '+48 99 999 99 99', phoneLength: 9 },
    // 'PM': { name: 'Saint Pierre and Miquelon', code: '+508' },
    // 'PN': { name: 'Pitcairn', code: '+870' },
    // 'PR': { name: 'Puerto Rico', code: '+1' },
    // 'PS': { name: 'Palestine, State of', code: '+970' },
    // 'PT': { name: 'Portugal', code: '+351' },
    // 'PW': { name: 'Palau', code: '+680' },
    // 'PY': { name: 'Paraguay', code: '+595' },
    QA: { name: 'Qatar', code: '+974', phoneMask: '+\\974 9999-9999', phoneLength: 8 },
    // 'RE': { name: 'Reunion', code: '+262' },
    // 'RO': { name: 'Romania', code: '+40' },
    // 'RS': { name: 'Serbia', code: '+381' },
    // 'RU': { name: 'Russian Federation', code: '+7' },
    // 'RW': { name: 'Rwanda', code: '+250' },
    // 'SA': { name: 'Saudi Arabia', code: '+966' },
    // 'SB': { name: 'Solomon Islands', code: '+677' },
    // 'SC': { name: 'Seychelles', code: '+248' },
    // 'SD': { name: 'Sudan', code: '+249' },
    // 'SE': { name: 'Sweden', code: '+46' },
    // 'SG': { name: 'Singapore', code: '+65' },
    // 'SH': { name: 'Saint Helena', code: '+290' },
    // 'SI': { name: 'Slovenia', code: '+386' },
    // 'SJ': { name: 'Svalbard and Jan Mayen', code: '+47' },
    // 'SK': { name: 'Slovakia', code: '+421' },
    // 'SL': { name: 'Sierra Leone', code: '+232' },
    // 'SM': { name: 'San Marino', code: '+378' },
    // 'SN': { name: 'Senegal', code: '+221' },
    // 'SO': { name: 'Somalia', code: '+252' },
    // 'SR': { name: 'Suriname', code: '+597' },
    // 'SS': { name: 'South Sudan', code: '+211' },
    // 'ST': { name: 'Sao Tome and Principe', code: '+239' },
    // 'SV': { name: 'El Salvador', code: '+503' },
    // 'SX': { name: 'Sint Maarten (Dutch part)', code: '+1-721' },
    // 'SY': { name: 'Syrian Arab Republic', code: '+963' },
    // 'SZ': { name: 'Swaziland', code: '+268' },
    // 'TC': { name: 'Turks and Caicos Islands', code: '+1-649' },
    // 'TD': { name: 'Chad', code: '+235' },
    // 'TF': { name: 'French Southern Territories', code: '+262' },
    // 'TG': { name: 'Togo', code: '+228' },
    // 'TH': { name: 'Thailand', code: '+66' },
    // 'TJ': { name: 'Tajikistan', code: '+992' },
    // 'TK': { name: 'Tokelau', code: '+690' },
    // 'TL': { name: 'Timor-Leste', code: '+670' },
    // 'TM': { name: 'Turkmenistan', code: '+993' },
    // 'TN': { name: 'Tunisia', code: '+216' },
    // 'TO': { name: 'Tonga', code: '+676' },
    // 'TR': { name: 'Turkey', code: '+90' },
    // 'TT': { name: 'Trinidad and Tobago', code: '+1-868' },
    // 'TV': { name: 'Tuvalu', code: '+688' },
    // 'TW': { name: 'Taiwan, Province of China', code: '+886' },
    // 'TZ': { name: 'United Republic of Tanzania', code: '+255' },
    // 'UA': { name: 'Ukraine', code: '+380' },
    // 'UG': { name: 'Uganda', code: '+256' },
    US: { name: 'United States', code: '+1', phoneMask: '+1 (999) 999-9999', phoneLength: 10 }
    // 'UY': { name: 'Uruguay', code: '+598' },
    // 'UZ': { name: 'Uzbekistan', code: '+998' },
    // 'VA': { name: 'Holy See (Vatican City State)', code: '+379' },
    // 'VC': { name: 'Saint Vincent and the Grenadines', code: '+1-784' },
    // 'VE': { name: 'Venezuela', code: '+58' },
    // 'VG': { name: 'British Virgin Islands', code: '+1-284' },
    // 'VI': { name: 'US Virgin Islands', code: '+1-340' },
    // 'VN': { name: 'Vietnam', code: '+84' },
    // 'VU': { name: 'Vanuatu', code: '+678' },
    // 'WF': { name: 'Wallis and Futuna', code: '+681' },
    // 'WS': { name: 'Samoa', code: '+685' },
    // 'XK': { name: 'Kosovo', code: '+383' },
    // 'YE': { name: 'Yemen', code: '+967' },
    // 'YT': { name: 'Mayotte', code: '+262' },
    // 'ZA': { name: 'South Africa', code: '+27' },
    // 'ZM': { name: 'Zambia', code: '+260' },
    // 'ZW': { name: 'Zimbabwe', code: '+263' }
};

export const SUPPORTED_CURRENCIES: string[] = ['USD', 'CAD', 'GBP', 'EUR'];

export const SUPPORTED_ENGLISH_CULTURE_CODES: string[] = ['en-US', 'en-CA', 'en-GB'];

export const SUPPORTED_TRANSLATION_LANGUAGES: Record<string, string> = {
    en: 'English',
    ar: 'Arabic'
};
