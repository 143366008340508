import React from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { CashbackSlabsRecord, useStyles } from './settings';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { AppTextField } from '../common/app-textfield';
import { nanoid } from 'nanoid';
import ConfirmationDialog from '../common/confirmation-dialog';

type CashbackSlabRowObj = {
    type: 'BELOW' | 'ABOVE' | 'BETWEEN';
    valueA: string;
    valueB: string;
    helperText: string;
};
type CashbackSlabRowObjState = {
    errors: Record<string, boolean>;
};
const SLAB_TYPES: CashbackSlabRowObj['type'][] = ['BELOW', 'ABOVE', 'BETWEEN'];

export const slabKeyToObj = (slabKey: string): CashbackSlabRowObj => {
    if (slabKey.startsWith('BELOW')) {
        const valueA = slabKey.match(/\d/g)?.join('') || '';
        return {
            type: 'BELOW',
            valueA,
            valueB: '0',
            helperText: `It will be applied on value less than or equal to ${valueA}`
        };
    }

    if (slabKey.startsWith('ABOVE')) {
        const valueA = slabKey.match(/\d/g)?.join('') || '';
        return {
            type: 'ABOVE',
            valueA,
            valueB: '0',
            helperText: `It will be applied on value greater than ${valueA}`
        };
    }

    const [valueA, valueB] = slabKey.split('_TO_');
    return {
        type: 'BETWEEN',
        valueA,
        valueB,
        helperText: `It will be applied on value greater than ${valueA} and value less than or equal to ${valueB}`
    };
};

export const objToSlabKey = (obj: CashbackSlabRowObj): string => {
    if (obj.type !== 'BETWEEN') {
        return `${obj.type}_${obj.valueA}`;
    }

    return `${obj.valueA}_TO_${obj.valueB}`;
};

type CashbackSlabRowProps = {
    slabKey: string;
    id: string;
    cashbackPercentage: number;
    onProfitMarginChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    onSlabKeyChange: (slabKey: string) => void;
    onDelete: () => void;
};
const CashbackSlabRow = (props: CashbackSlabRowProps) => {
    const settingsClasses = useStyles();

    const fixedMarginPercentage = 100 - props.cashbackPercentage;

    const [state, setState] = React.useState<CashbackSlabRowObjState>({
        errors: {}
    });

    React.useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            ...slabKeyToObj(props.slabKey)
        }));
    }, [props.slabKey]);

    const slabObj = slabKeyToObj(props.slabKey);

    return (
        <Grid item={true} xs={12}>
            <Box display="flex" alignItems="center" gridGap={6} mb={2}>
                <FormControl>
                    <Select
                        labelId="slab-type-label"
                        id="slab-type"
                        value={slabObj.type}
                        onChange={(e) => {
                            const value = e.target.value as CashbackSlabRowObj['type'];
                            const obj = {
                                ...slabObj,
                                type: value,
                                valueB: value === 'BETWEEN' ? slabObj.valueB : ''
                            };

                            setState((prevState) => {
                                const newState: CashbackSlabRowObjState = {
                                    ...prevState,
                                    errors: {
                                        ...prevState.errors,
                                        valueB: value !== 'BETWEEN' ? false : prevState.errors.valueB
                                    }
                                };
                                return newState;
                            });
                            props.onSlabKeyChange(objToSlabKey(obj));
                        }}
                    >
                        {SLAB_TYPES.map((type) => (
                            <MenuItem key={type} value={type}>
                                {type}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl>
                    <AppTextField
                        value={slabObj.valueA}
                        onChange={(e) => {
                            const obj = {
                                ...slabObj,
                                valueA: e.target.value
                            };
                            setState((prevState) => {
                                const newState: CashbackSlabRowObjState = {
                                    ...prevState,
                                    errors: {
                                        ...prevState.errors,
                                        valueA: Boolean(!e.target.value)
                                    }
                                };

                                return newState;
                            });
                            props.onSlabKeyChange(objToSlabKey(obj));
                        }}
                        error={Boolean(state.errors.valueA)}
                        helperText={state.errors.valueA && 'This field is required'}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                    />
                </FormControl>

                {slabObj.type === 'BETWEEN' && (
                    <Box display="flex" alignItems="center">
                        <Box mr={1}>TO</Box>
                        <AppTextField
                            value={slabObj.valueB}
                            onChange={(e) => {
                                const obj = {
                                    ...slabObj,
                                    valueB: e.target.value
                                };
                                setState((prevState) => {
                                    const newState: CashbackSlabRowObjState = {
                                        ...prevState,
                                        errors: {
                                            ...prevState.errors,
                                            valueB: Boolean(!e.target.value)
                                        }
                                    };

                                    return newState;
                                });
                                props.onSlabKeyChange(objToSlabKey(obj));
                            }}
                            error={Boolean(state.errors.valueB)}
                            helperText={state.errors.valueB && 'This field is required'}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                        />
                    </Box>
                )}
            </Box>

            <Grid item={true} xs={12} className={settingsClasses.textFieldGrid}>
                <TextField
                    type="number"
                    size="small"
                    variant="outlined"
                    label="Profit Margin Percentage"
                    value={fixedMarginPercentage || 0}
                    onChange={props.onProfitMarginChange}
                    InputProps={{ endAdornment: <InputAdornment position="start">%</InputAdornment> }}
                />

                <Box marginLeft={1}>
                    <TextField
                        disabled={true}
                        type="number"
                        size="small"
                        variant="outlined"
                        label="Cashback Percentage"
                        value={props.cashbackPercentage}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                            readOnly: true,
                            disabled: true
                        }}
                    />
                </Box>

                <Box marginLeft={1} marginTop={'4px'}>
                    <Button
                        variant="contained"
                        style={{ backgroundColor: '#f48fb1' }}
                        startIcon={<DeleteIcon />}
                        size="small"
                        onClick={() => props.onDelete()}
                    >
                        Delete
                    </Button>
                </Box>
            </Grid>

            <Grid item={true} xs={12}>
                <Typography className={settingsClasses.slabHelperText}>
                    <InfoIcon />{slabObj.helperText}
                </Typography>
            </Grid>

        </Grid>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getItemStyle = (isDragging: boolean, draggableStyle?: any) => ({
    userSelect: 'none',
    padding: '2px 8px',
    marginBottom: 16,
    background: isDragging ? 'rgba(0,0,0,0.1)' : '#fff',
    borderBottom: '1px dashed',
    // styles we need to apply on draggables
    ...draggableStyle
});

type State = {
    openDialog: boolean;
    activeCashbackSlabId: string | null;
};

type Props = {
    cashbackSlabs: CashbackSlabsRecord[];
    isSavingCashbackSlabs: boolean;
    /**
     * Default: false
     */
    hideSaveButton?: boolean;
    onCashbackSlabSave?: (cashbackSlabs: CashbackSlabsRecord[]) => void;
    onSlabUpdate: (cashbackSlabs: CashbackSlabsRecord[]) => void;
    onSlabDelete?: (cashbackSlabs: CashbackSlabsRecord[]) => void;
};
const GlobalCashbackSlab = (props: Props) => {
    const classes = useStyles();

    const [state, setState] = React.useState<State>({
        openDialog: false,
        activeCashbackSlabId: null
    });

    const swapArray = (cashbackSlabs: CashbackSlabsRecord[], indexA: number, indexB: number) => {
        const slabs = [...cashbackSlabs];

        [slabs[indexA], slabs[indexB]] = [slabs[indexB], slabs[indexA]];

        return slabs;
    };

    const activeSlab = state.activeCashbackSlabId ? props.cashbackSlabs.find(slab => slab.id === state.activeCashbackSlabId) : null;

    const header = (activeSlab &&
        `Are you sure you want to remove ${activeSlab?.key || ''} slab?`)
        || 'Are you sure you want to remove the slab?';

    return (
        <Grid item={true} xs={12}>
            <Typography color="secondary" className={classes.subHeading}>
                Cashback Slabs
                <Typography component="span" className={classes.helperText}>
                    Note: The values will be saved automatically, on change. Upon closing the dialog, values will be automatically saved.
                    <Typography component="span"><InfoIcon />These slabs are sortable and can be re-ordered.</Typography>
                </Typography>
            </Typography>

            <DragDropContext
                onDragEnd={(result) => {
                    // dropped outside the list
                    if (!result.destination) {
                        return;
                    }

                    const indexA = result.source.index;
                    const indexB = result.destination.index;

                    const updatedSlabs = swapArray(props.cashbackSlabs, indexA, indexB);
                    props.onSlabUpdate(updatedSlabs);
                }}
            >
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {props.cashbackSlabs.map((cashbackSlab, i) => {
                                const cashbackPercentage = cashbackSlab.cashbackPercentage;

                                return (
                                    <Draggable key={cashbackSlab.id} draggableId={cashbackSlab.id} index={i}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                            >
                                                <Grid item={true} xs={12} className={classes.rowContainer}>
                                                    <DragIndicatorIcon className={classes.dragIndicatorIcon} />
                                                    <CashbackSlabRow
                                                        key={cashbackSlab.id}
                                                        slabKey={cashbackSlab.key}
                                                        id={cashbackSlab.id}
                                                        cashbackPercentage={cashbackPercentage}
                                                        onProfitMarginChange={(e) => {
                                                            const value = +e.target.value;
                                                            const regex = /^\d+(\.\d{0,2})?$/;

                                                            if (isNaN(value) || value < 0 || !regex.test(`${value}`)) {
                                                                return;
                                                            }

                                                            props.onSlabUpdate([
                                                                ...props.cashbackSlabs.map((slab) => {
                                                                    if (slab.id === cashbackSlab.id) {
                                                                        return {
                                                                            ...slab,
                                                                            cashbackPercentage: 100 - value
                                                                        };
                                                                    }
                                                                    return slab;
                                                                })
                                                            ]);
                                                        }}
                                                        onSlabKeyChange={(slabKey) => {
                                                            props.onSlabUpdate([
                                                                ...props.cashbackSlabs.map((slab) => {
                                                                    if (slab.id === cashbackSlab.id) {
                                                                        return {
                                                                            ...slab,
                                                                            key: slabKey
                                                                        };
                                                                    }
                                                                    return slab;
                                                                })
                                                            ]);
                                                        }}
                                                        onDelete={() => {
                                                            setState((prevState) => ({
                                                                ...prevState,
                                                                activeCashbackSlabId: cashbackSlab.id,
                                                                openDialog: true
                                                            }))
                                                        }}
                                                    />
                                                </Grid>
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <Grid item={true} xs={12}>
                <Box textAlign="left" mt={4}>
                    <Button
                        color="default"
                        startIcon={<AddIcon />}
                        variant="contained"
                        size="small"
                        onClick={() =>
                            props.onSlabUpdate([
                                ...props.cashbackSlabs,
                                {
                                    key: 'ABOVE_100',
                                    id: nanoid(6),
                                    cashbackPercentage: 100,
                                    order: props.cashbackSlabs.length
                                }
                            ])
                        }
                    >
                        Add
                    </Button>
                </Box>
            </Grid>

            {!props.hideSaveButton && (
                <Grid item={true} xs={12} className={classes.footer}>
                    <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={() => {
                            props.onCashbackSlabSave && props.onCashbackSlabSave(props.cashbackSlabs);
                        }}
                        disabled={props.isSavingCashbackSlabs}
                    >
                        save
                        {props.isSavingCashbackSlabs && <CircularProgress className="button-loader" />}
                    </Button>
                </Grid>
            )}

            <ConfirmationDialog
                open={state.openDialog}
                header={header}
                subHeader={'Deleted slabs cannot be reverted back. Click CONFIRM to proceed'}
                onClose={() =>
                    setState((prevState) => ({
                        ...prevState,
                        activeCashbackSlabId: null,
                        openDialog: false
                    }))
                }
                onConfirm={() => {
                    if (state.activeCashbackSlabId) {
                        const updatedSlab = [
                            ...props.cashbackSlabs.filter((slab) => slab.id !== state.activeCashbackSlabId)
                        ];
                        props.onSlabUpdate(updatedSlab);
                        const updatedState = {
                            ...state,
                            activeCashbackSlabId: null,
                            openDialog: false
                        };
                        setState(updatedState);
                        props.onCashbackSlabSave && props.onCashbackSlabSave(updatedSlab);
                        props.onSlabDelete && props.onSlabDelete(updatedSlab);
                    }
                }}
            />
        </Grid>
    );
};

export default GlobalCashbackSlab;
