import React from 'react';
import Switch from '@material-ui/core/Switch';
import { Typography, Box, Card, Button, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useApiService } from '../../../contexts/api-service-context';
import { useToasterData } from '../../../contexts/toaster-context';
import { ConstantType, CustomerType, ErrorType, OrderRestrictionValue } from '../../../types';
import ConfirmationDialog from '../../common/confirmation-dialog';

const OrderRestriction = (props: {
    customerType: CustomerType;
    checked: boolean;
    onChange: (checked: boolean) => void;
}) => {
    return (
        <div>
            <Typography component="div">
                <Box fontWeight="fontWeightMedium">Restrict {props.customerType} Orders</Box>
            </Typography>
            <Switch checked={props.checked} onChange={(_, checked) => props.onChange(checked)} color="primary" />
            <Alert severity={props.checked ? 'error' : 'success'}>
                {props.checked
                    ? `${props.customerType} orders will be restricted.`
                    : `${props.customerType} orders will NOT be restricted.`}
            </Alert>
        </div>
    );
};

const OrderRestrictionContainer = () => {
    const apiService = useApiService();
    const toasterContext = useToasterData();

    const [constant, setConstant] = React.useState<ConstantType | null>(null);
    const [loading, setLoading] = React.useState(true);
    const [saving, setSaving] = React.useState(false);
    const [isDirty, setIsDirty] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);

    const orderRestrictionData = constant?.value as OrderRestrictionValue | undefined;

    const handleSwitchChange = (customerType: CustomerType, checked: boolean) => {
        setConstant((prevState) => ({
            ...(prevState as ConstantType),
            value: {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ...(prevState as any).value,
                [customerType]: checked
            }
        }));
        setIsDirty(true);
    };

    const fetchOrderRestrictions = async () => {
        try {
            setLoading(true);
            const apiResponse = await apiService.get(`/api/admin/constants/get-all`);
            const response = apiResponse.parsedBody;
            const constantData: ConstantType = response.data.find((c: ConstantType) => c.key === 'ORDER_RESTRICTION');
            setConstant(constantData);
            setLoading(false);
            setIsDirty(false);
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
            setLoading(false);
        }
    };

    const updateConstant = async () => {
        try {
            setSaving(true);
            const apiResponse = await apiService.post(`/api/admin/constants/update`, { constant });
            const response = apiResponse.parsedBody;
            if (!response || !response.status) {
                toasterContext.setToaster({
                    isOpen: true,
                    message: response.message,
                    severity: 'error'
                });
                setSaving(false);
                return;
            }
            setSaving(false);
            setIsDirty(false);
            setOpenDialog(false);
            toasterContext.setToaster({
                isOpen: true,
                message: `Settings updated successfully!`,
                severity: 'success'
            });
        } catch (e: ErrorType) {
            toasterContext.setToaster({
                isOpen: true,
                message: e.message,
                severity: 'error'
            });
            setSaving(false);
        }
    };

    React.useEffect(() => {
        fetchOrderRestrictions();
    }, []);

    return (
        <Card>
            <Box p={4} textAlign="left">
                <Typography component="h2" style={{ marginBottom: 8, fontSize: 22, fontWeight: 'bold' }}>
                    Order Restriction
                </Typography>

                {loading && <CircularProgress />}

                {!loading && orderRestrictionData && (
                    <>
                        <OrderRestriction
                            customerType="B2C"
                            checked={orderRestrictionData.B2C}
                            onChange={(checked) => handleSwitchChange('B2C', checked)}
                        />

                        <br />
                        <br />

                        <OrderRestriction
                            customerType="B2B"
                            checked={orderRestrictionData.B2B}
                            onChange={(checked) => handleSwitchChange('B2B', checked)}
                        />

                        <Box mt={3} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                            <Box textAlign="left" mt={3}>
                                {isDirty && <Typography>Click the SAVE button to update the settings</Typography>}
                            </Box>
                            <Box textAlign="right" mt={3}>
                                {isDirty && (
                                    <Button
                                        type="button"
                                        variant="contained"
                                        style={{ marginRight: 8 }}
                                        onClick={fetchOrderRestrictions}
                                    >
                                        CANCEL
                                    </Button>
                                )}

                                <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    disabled={saving}
                                    onClick={() => setOpenDialog(true)}
                                >
                                    SAVE
                                    {saving && <CircularProgress className="button-loader" />}
                                </Button>
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
            <ConfirmationDialog
                header={'Confirm Setting?'}
                
                subHeader={'Click CONFIRM to update the setting'}
                isLoading={saving}
                open={openDialog}
                onClose={() =>
                    setOpenDialog(false)
                }
                cancelButtonText='CLOSE'
                confirmationButtonText='CONFIRM'
                onConfirm={updateConstant}
            />
        </Card>
    );
};

export default OrderRestrictionContainer;
