import React from 'react';

const VisaCardSvg = (props) => {
    return (
        <svg {...props} viewBox="0 0 32 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.6763 0.400024H0.200012V20.4H31.6763V0.400024Z" fill="white" />
            <path d="M31.1192 17.1688H0.701385V19.8986H31.1192V17.1688Z" fill="#FCB316" />
            <path d="M31.1192 0.957092H0.701385V3.6869H31.1192V0.957092Z" fill="#10357F" />
            <path
                d="M12.902 6.69527L9.7822 14.2162H7.72092L6.16103 8.25516C6.04961 7.86518 5.9939 7.75376 5.71535 7.58663C5.26967 7.30808 4.48972 7.08524 3.8212 6.97382L3.87691 6.75098H7.16382C7.6095 6.75098 7.94376 7.02953 8.05518 7.53092L8.89084 11.8763L10.8964 6.75098L12.902 6.69527ZM20.9243 11.7092C20.9243 9.70363 18.1945 9.64792 18.1945 8.75655C18.1945 8.478 18.473 8.19945 19.0301 8.14374C19.3087 8.08803 20.0886 8.08803 20.9243 8.478L21.2585 6.8624C20.8128 6.69527 20.2 6.52814 19.4201 6.52814C17.5259 6.52814 16.1332 7.53092 16.1332 9.0351C16.1332 10.0936 17.0803 10.7064 17.8045 11.0964C18.5844 11.4864 18.8073 11.7092 18.8073 12.0435C18.8073 12.5448 18.1945 12.7677 17.6374 12.7677C16.6346 12.7677 16.0775 12.4891 15.6318 12.2663L15.2975 13.9376C15.7432 14.1604 16.5789 14.3276 17.4702 14.3276C19.5872 14.2719 20.9243 13.2691 20.9243 11.7092ZM25.9939 14.1604H27.7766L26.2167 6.63956H24.5454C24.1555 6.63956 23.8769 6.8624 23.7098 7.19666L20.8128 14.1604H22.8741L23.2641 13.0462H25.7711L25.9939 14.1604ZM23.8212 11.5421L24.824 8.70084L25.4368 11.5421H23.8212ZM15.6875 6.69527L14.0719 14.2162H12.122L13.7376 6.69527H15.6875V6.69527Z"
                fill="#10357F"
            />
        </svg>
    );
};

export default VisaCardSvg;
