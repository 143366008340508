export interface UserSessionAuthData {
    isError?: boolean;
    email?: string | null;
    phone?: string | null;
    country?: string | null;
    message?: string | null;
}

const INITIAL_AUTH_DATA: UserSessionAuthData = {
    email: null,
    phone: null,
    country: 'US',
    message: null
};

export interface UserSessionState {
    isLoading: boolean;
    authData: UserSessionAuthData;
}

export const initialState: UserSessionState = {
    isLoading: true, // default true,
    authData: INITIAL_AUTH_DATA
};

export const userSessionReducer = (state: UserSessionState, action: UserSessionActionType) => {
    switch (action.type) {
        case 'SET_LOADING':
            return { ...state, isLoading: action.isLoading };
        case 'SET_AUTH_DATA':
            return { ...state, authData: action.authData ? action.authData : INITIAL_AUTH_DATA };
        default:
            return state;
    }
};

type SetLoading = {
    type: 'SET_LOADING';
    isLoading: boolean;
};

type SetAuthData = {
    type: 'SET_AUTH_DATA';
    authData: UserSessionAuthData | null;
};

export type UserSessionActionType = SetLoading | SetAuthData;
