import React from 'react';

import Slide, { SlideProps } from '@material-ui/core/Slide';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface ConfirmationDialogProps {
    header?: string;
    subHeader?: string | JSX.Element;
    confirmationButtonText?: string;
    open: boolean;
    onConfirm?: () => void;
    onClose?: () => void;
    onSubHeaderScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
    isLoading?: boolean;
    isDisabled?: boolean;
    cancelButtonText?: string;
    zIndex?: number;
    /**
     * Default sm
     */
    maxWidth?: DialogProps['maxWidth'];
    fullScreen?: boolean;
    fullWidth?: boolean;
    additionalContent?: JSX.Element;
    autoFocus?: boolean;
    paperClass?: string;
    headerClass?: string;
    /**
     * Override default buttons
     */
    customButtons?: JSX.Element;
}

const ConfirmationDialog = React.forwardRef((props: ConfirmationDialogProps, ref) => {
    const {
        header,
        subHeader,
        confirmationButtonText,
        open,
        onConfirm,
        onClose,
        onSubHeaderScroll,
        isLoading,
        isDisabled,
        cancelButtonText,
        zIndex,
        maxWidth = 'sm',
        fullScreen = false,
        fullWidth = false,
        additionalContent = false,
        autoFocus = true,
        paperClass = '',
        headerClass = '',
        customButtons // will override default buttons
    } = props;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            transitionDuration={300}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            style={{ zIndex: zIndex || 1300 }}
            maxWidth={maxWidth}
            fullScreen={fullScreen}
            fullWidth={fullWidth}
            PaperProps={{
                className: paperClass
            }}
            innerRef={ref}
        >
            {header && (
                <DialogTitle id="alert-dialog-slide-title" className={headerClass}>
                    {header}
                </DialogTitle>
            )}
            <DialogContent onScroll={(e) => onSubHeaderScroll && onSubHeaderScroll(e)}>
                {subHeader && (
                    <DialogContentText component="div" id="alert-dialog-slide-description">
                        {subHeader}
                    </DialogContentText>
                )}
                {additionalContent}
            </DialogContent>
            <DialogActions>
                {customButtons ? (
                    customButtons
                ) : (
                    <>
                        <Button onClick={onClose}>{cancelButtonText || 'Cancel'}</Button>
                        {onConfirm && (
                            <Button
                                onClick={onConfirm}
                                autoFocus={autoFocus}
                                color="primary"
                                variant="contained"
                                disabled={isDisabled || isLoading}
                                data-testid="confirm-dialog-button"
                            >
                                {confirmationButtonText || 'CONFIRM'}
                                {isLoading && <CircularProgress className="button-loader" />}
                            </Button>
                        )}
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
});

export default ConfirmationDialog;
