import React from 'react';
import { Box, Button, Paper, Typography } from '@material-ui/core';
import storeContext from '../../../contexts/store-context';
import { ConstantType, GeoLocationCountry } from '../../../types';
import TableComponent, { HeadCell, TableResultRow } from '../../common/table-component';
import { useUserService } from '../../../contexts/user-context';
import HasPermission from '../../../utilities/can';
import ConfirmationDialog from '../../common/confirmation-dialog';
import { useApiService } from '../../../contexts/api-service-context';
import { useToasterData } from '../../../contexts/toaster-context';
import ChooseCountry from './choose-country';
import { useHistory } from 'react-router-dom';
import useAdminStyles from '../../styles';
import { SUPPORTED_CURRENCIES } from '../../../constants';
import { Alert, AlertTitle } from '@material-ui/lab';
import ManageTranslationsModal from './manage-translations-modal';

type DialogState = {
    open: boolean;
    type: 'ADD' | 'DELETE' | 'MANAGE_TRANSLATIONS';
};

const FilterCountries = () => {
    const { geoLocationAction } = storeContext();
    const userService = useUserService();
    const apiService = useApiService();
    const toasterContext = useToasterData();
    const history = useHistory();
    const adminClasses = useAdminStyles();

    const canRead = HasPermission(userService.user, 'MANAGE_PRODUCT', 'READ');
    const canUpdate = HasPermission(userService.user, 'MANAGE_PRODUCT', 'UPDATE');

    const [allGeoData, setAllGeoData] = React.useState<GeoLocationCountry[]>([]);
    const [loadingAllGeoData, setLoadingAllGeoData] = React.useState(true);

    const [filteredGeoData, setFilteredGeoData] = React.useState<GeoLocationCountry[]>([]);
    const [loadingFilteredGeoData, setLoadingFilteredGeoData] = React.useState(true);

    const [selectedRows, setSelectedRows] = React.useState<TableResultRow[]>([]);
    const [isSaving, setIsSaving] = React.useState(false);
    const [constants, setConstants] = React.useState<ConstantType[]>([]);

    const [dialog, setDialog] = React.useState<DialogState>({
        open: false,
        type: 'ADD'
    });

    const [activeGeoData, setAciveGeoData] = React.useState<GeoLocationCountry | null>(null);

    const filteredGeoDataShortNames = new Set(filteredGeoData.map((fgd) => fgd.shortName));

    const headCells: Array<HeadCell> = [
        { id: 'name', label: 'Country' },
        { id: 'currency', label: 'Currency' },
        { id: 'translations', label: 'Manage Translations' }
    ];

    const savedTableRows: Array<TableResultRow> = filteredGeoData.map((p) => {
        return {
            id: {
                align: 'left',
                text: p.shortName?.toString() || ''
            },
            name: {
                align: 'left',
                text: `${p.name} (${p.shortName})`
            },
            currency: {
                align: 'left',
                text: p.currency
            },
            translations: {
                align: 'left',
                text: '',
                element: (
                    <Button type="button" variant="contained" onClick={() => openManageTranslations(p)}>
                        Manage Translations
                    </Button>
                )
            }
        };
    });

    const openManageTranslations = (geoData: GeoLocationCountry) => {
        setAciveGeoData(geoData);
        openDialog('MANAGE_TRANSLATIONS')();
    };

    const fetchAllGeoData = async () => {
        setLoadingAllGeoData(true);
        const newGeoData = await geoLocationAction()?.fetchAllGeoData();
        setAllGeoData(newGeoData || []);
        setLoadingAllGeoData(false);
    };

    const fetchFilteredGeoData = async () => {
        setLoadingFilteredGeoData(true);
        const newGeoData = await geoLocationAction()?.fetchFilteredGeoData();
        setFilteredGeoData(newGeoData || []);
        setLoadingFilteredGeoData(false);
    };

    const fetchConstants = async () => {
        const apiResponse = await apiService.get(`/api/admin/constants/get-all`);
        const response = apiResponse.parsedBody;
        const constants = response.data as ConstantType[];
        setConstants(constants);
    };

    const updateFilteredGeoData = async () => {
        setIsSaving(true);

        const selectedCountryCodes = selectedRows.map((row) => row.id.text);
        const updatedConstants: Array<ConstantType> = constants.map((c) => {
            if (c.key === 'B2B_EGIFTER_COUNTRIES') {
                return {
                    ...c,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    value: (c.value as any[]).filter((v) => !selectedCountryCodes.includes(v.shortName))
                };
            }
            return c;
        });

        const apiResponse = await apiService.post(`/api/admin/constants/update-all`, { constants: updatedConstants });
        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            setIsSaving(false);
            return;
        }

        setIsSaving(false);
        setSelectedRows([]);
        closeDialog();
        init();
    };

    const init = async () => {
        if (!canRead) {
            history.push('/dashboard');
            return;
        }
        fetchConstants();
        fetchAllGeoData();
        fetchFilteredGeoData();
    };

    const openDialog = (type: DialogState['type']) => () =>
        setDialog((prevState) => ({ ...prevState, open: true, type }));

    const closeDialog = () => setDialog((prevState) => ({ ...prevState, open: false }));

    const onCountryAdd = async (geoData: GeoLocationCountry) => {
        setIsSaving(true);
        const updatedConstants: Array<ConstantType> = constants.map((c) => {
            if (c.key === 'B2B_EGIFTER_COUNTRIES') {
                return {
                    ...c,
                    value: [
                        ...(c.value as []),
                        {
                            name: geoData.name,
                            currency: geoData.currency.split(',')[0],
                            shortName: geoData.shortName
                        }
                    ]
                };
            }
            return c;
        });

        const apiResponse = await apiService.post(`/api/admin/constants/update-all`, { constants: updatedConstants });
        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            setIsSaving(false);
            return;
        }
        setIsSaving(false);
        closeDialog();
        init();
    };

    const onNameChange = (cultureCode: string, name: string) => {
        setAciveGeoData((prevState) => {
            if (!prevState) {
                return null;
            }

            if (!prevState.translations) {
                prevState.translations = [
                    {
                        cultureCode,
                        name
                    }
                ];

                return prevState;
            }

            if (!prevState.translations.find((o) => o.cultureCode === cultureCode)) {
                prevState.translations = [
                    ...prevState.translations,
                    {
                        cultureCode,
                        name
                    }
                ];

                return prevState;
            }

            return {
                ...prevState,
                translations: prevState.translations.map((o) => {
                    if (o.cultureCode === cultureCode) {
                        return {
                            ...o,
                            name
                        };
                    }

                    return o;
                })
            };
        });
    };

    const onManageTranslationsConfirm = async () => {
        if (!activeGeoData) {
            return;
        }

        setIsSaving(true);
        const updatedConstants: Array<ConstantType> = constants.map((c) => {
            if (c.key === 'B2B_EGIFTER_COUNTRIES') {
                return {
                    ...c,
                    value: (c.value as GeoLocationCountry[]).map((obj) => {
                        if (obj.shortName === activeGeoData.shortName) {
                            return {
                                ...obj,
                                translations: activeGeoData.translations.filter(t => Boolean(t.name))
                            };
                        }
                        return obj;
                    })
                };
            }
            return c;
        });

        const apiResponse = await apiService.post(`/api/admin/constants/update-all`, { constants: updatedConstants });
        const response = apiResponse.parsedBody;
        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            setIsSaving(false);
            return;
        }
        setIsSaving(false);
        closeDialog();
        init();
    };

    React.useEffect(() => {
        init();
    }, []);

    return (
        <Box my={5}>
            <Paper>
                <Box m={2} pt={3} textAlign="left">
                    <Typography align="left" className={adminClasses.heading}>
                        Manage Countries displayed in B2B Buy Gift Cards Page
                    </Typography>
                    <Alert severity="info">
                        <AlertTitle>Supported Currencies:</AlertTitle>
                        {SUPPORTED_CURRENCIES.map((currency) => currency).join(', ')}
                    </Alert>
                    {!loadingAllGeoData && (
                        <ChooseCountry
                            allGeoData={allGeoData.filter((geoData) => {
                                const currency = geoData.currency.split(',')[0];
                                return SUPPORTED_CURRENCIES.includes(currency);
                            })}
                            filteredGeoDataShortNames={filteredGeoDataShortNames}
                            isDialogOpen={dialog.type === 'ADD' && dialog.open}
                            isSaving={isSaving}
                            onDialogOpen={openDialog('ADD')}
                            onDialogClose={closeDialog}
                            onCountryAdd={onCountryAdd}
                        />
                    )}
                </Box>

                <TableComponent
                    rowHover={canUpdate}
                    showCheckbox={true}
                    showPaginator={{ bottom: true }}
                    showSearch={true}
                    isLoading={loadingFilteredGeoData}
                    rows={savedTableRows}
                    headCells={headCells}
                    fillEmptyRows={false}
                    keyField="id"
                    header="Filtered Countries"
                    selectedRows={selectedRows}
                    onDelete={openDialog('DELETE')}
                    onCheckboxSelect={(selectedRows) => setSelectedRows((prevState) => [...prevState, ...selectedRows])}
                    noOfRowsPerPage={20}
                    rowsOptions={[20, 50]}
                />

                <ManageTranslationsModal
                    open={dialog.type === 'MANAGE_TRANSLATIONS' && dialog.open}
                    activeGeoData={activeGeoData}
                    isLoading={isSaving}
                    onNameChange={onNameChange}
                    onClose={closeDialog}
                    onConfirm={onManageTranslationsConfirm}
                />

                <ConfirmationDialog
                    header={'Are you sure?'}
                    subHeader={'Click CONFIRM to remove the selected item(s)'}
                    isLoading={isSaving}
                    open={dialog.type === 'DELETE' && dialog.open}
                    onClose={closeDialog}
                    onConfirm={updateFilteredGeoData}
                />
            </Paper>
        </Box>
    );
};

export default FilterCountries;
