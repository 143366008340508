import { StoreActionProps } from '.';
import { ErrorType } from '../types';
import { FetchPayoutTransactionResponse } from '../types/influencerPayout';

const getAllPayoutTransactions = async (props: StoreActionProps) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/manage-payout/get-all-transactions`);
        const response = apiResponse.parsedBody;

        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return [];
        }
        return response.data as FetchPayoutTransactionResponse;
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return [];
    }
};

const getAllPayoutTransactionsByInfluencerUserId = async (props: StoreActionProps, influencerUserId: number) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(`/api/admin/manage-payout/get-all-transactions/${influencerUserId}`);
        const response = apiResponse.parsedBody;

        if (!response || !response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return [];
        }
        return response.data as FetchPayoutTransactionResponse;
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return [];
    }
};

const influencerPayoutAction = (props?: StoreActionProps) => {
    if (!props) {
        return null;
    }

    return {
        getAllPayoutTransactions: () => getAllPayoutTransactions(props),
        getAllPayoutTransactionsByInfluencerUserId: (influencerUserId: number) =>
            getAllPayoutTransactionsByInfluencerUserId(props, influencerUserId)
    };
};

export default influencerPayoutAction;

export interface InfluencerPayoutAction {
    influencerPayoutAction: typeof influencerPayoutAction;
}
