import { BlackListedEmailAttributes } from '../types/blackListedEmail';

export const BLACK_LISTED_EMAIL_REASONS: BlackListedEmailAttributes['reason'][] = [
    'EMAIL_BOUNCED',
    'EMAIL_COMPLAINED',
    'FRAUD',
    'MALICIOUS_FLAGS',
    'FRAUD_RISK',
    'FRAUD_ERROR'
];
