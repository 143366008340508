import React from 'react';
import { Box } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { InfluencerDetails } from '../../types';

type Props = {
    influencer: InfluencerDetails;
};

const InfluencerAccessRevokedAlert = (props: Props) => {
    return (
        <>
            {props.influencer.approvalStatus === 'REVOKED' && (
                <Box mb={4}>
                    <Alert severity="error">
                        <AlertTitle>
                            {' '}
                            Influencer Access is currently <strong>REVOKED</strong>{' '}
                        </AlertTitle>
                    </Alert>
                </Box>
            )}
        </>
    );
};

export default InfluencerAccessRevokedAlert;
